import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";

import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { LaboratoryBranchStock } from "src/app/models/laboratory-branch.model";
import { LaboratoryBranchService } from "src/app/services/laboratory-branch.service";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";
import { BoxStockFormComponent } from "../box-stock-form/box-stock-form.component";

@Component({
  selector: "app-box-stock-list",
  templateUrl: "./box-stock-list.component.html",
  styleUrls: ["./box-stock-list.component.scss"],
  providers: [DialogService, ConfirmationService],
})
export class BoxStockListComponent implements OnInit, OnDestroy {
  static className = "BoxStockListComponent";
  listStockBox: LaboratoryBranchStock[] = [];
  copyListStockBox: LaboratoryBranchStock[] = [];
  showTable: boolean = false;
  columns: DataTableColumn[] = [];
  totalRecords: number = 0;
  filterClass: string = "";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messagesService: OveraMessagesService,
    private laboratoryBranchService: LaboratoryBranchService
  ) {
    this.filterClass = "custom-filter";
  }
  ngOnInit() {
    console.log("**stockBoxList**");
    this.showTable = false;
    this.spinner.show("spinnerListBoxStock");
    this.laboratoryBranchService.getAllBoxStock().subscribe({
      next: (items) => {
        console.log("items", items);
        this.listStockBox = items;
        this.columns = this.createColumns();
        console.log("listStockBox", this.listStockBox);
        if (this.listStockBox.length > 0) {
          this.assignedFunctionsTable();
        }
        this.totalRecords = this.listStockBox.length;
        this.copyListStockBox = [...this.listStockBox];
        this.showTable = true;
        this.spinner.hide("spinnerListBoxStock");
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("spinnerListBoxStock");
      },
    });
  }
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "laboratoryName",
        header: this.translateService.instant("COMMON_FORMS.LABORATORY"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "laboratoryBranchName",
        header: this.translateService.instant("COMMON_FORMS.LABORATORYBRANCH"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "address",
        header: this.translateService.instant("COMMON_FORMS.ADDRESS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "phoneNumber",
        header: this.translateService.instant("COMMON_FORMS.PHONE"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "contact",
        header: this.translateService.instant("COMMON_FORMS.CONTACT_PERSON"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "boxCount",
        header: this.translateService.instant("COMMON_FORMS.BOX_STOCK"),
        type: "System.Number",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "kitCount",
        header: this.translateService.instant("COMMON_FORMS.KITS_STOCK"),
        type: "System.Number",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "addBoxStock",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("BOX_STOCK_LIST.SET_STOCK"),
        type: "System.Button",
        icon: "pi pi-box",
        cssClass: "button-add-box-stock-list",
      },
    ];
  }
  assignedFunctionsTable() {
    let itemsTemp: any[] = [];

    if (this.listStockBox.length > 0) {
      const goToAddBoxStock = (index: any) => {
        if (this.listStockBox && this.listStockBox[index]) {
          this.openForm(this.listStockBox[index]);
        }
      };
      this.listStockBox.forEach((element: any) => {
        element.addBoxStock = {
          value: true, //TODO: add value from object
          command: goToAddBoxStock,
        };
        itemsTemp.push(element);
      });
      this.listStockBox = [...itemsTemp];
    }
  }
  openForm(item: LaboratoryBranchStock | undefined) {
    const isNew = !item;
    let laboratoryBranchId = item?.laboratoryBranchId
      ? item.laboratoryBranchId
      : "";
    let header = "";
    let translateKey = "BOX_STOCK_FORM.TITLE";
    this.translateService.get(translateKey).subscribe((a: string) => {
      header = a;
    });
    var ref = this.dialogService.open(BoxStockFormComponent, {
      width: "85%",
      height: "90%",
      header: header,
      data: {
        isNew: isNew,
        dataItem: item,
        laboratoryBranchId: laboratoryBranchId,
      },
    });
    ref.onClose.subscribe((item: any) => {
      console.log(item);
      if (item) {
        this.spinner.show("spinnerListBoxStock");
        this.ngOnInit();
      }
    });
  }
  ngOnDestroy() {}
  onSearchTextChange(searchText: any) {
    console.log(searchText);
    if (searchText) {
      searchText = searchText.toLowerCase();
      if (this.copyListStockBox) {
        let filters = [...this.copyListStockBox];
        let newfilters = filters.filter((laboratoryBranchStock) => {
          return (
            laboratoryBranchStock.laboratoryName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            laboratoryBranchStock.laboratoryBranchName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            laboratoryBranchStock.address
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            laboratoryBranchStock.phoneNumber
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            laboratoryBranchStock.contact
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            laboratoryBranchStock.kitCount
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            laboratoryBranchStock.boxCount
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        this.listStockBox = newfilters;
      }
    } else {
      if (this.copyListStockBox) this.listStockBox = [...this.copyListStockBox];
    }

    this.listStockBox = [...this.listStockBox];
    console.log(this.listStockBox);
  }
}
