import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { AnalysisSubtype } from "src/app/models/analysis-subtype.model";
import { AppPermissions } from "src/app/models/app-permission.model";
import { AnalysisSubtypeService } from "src/app/services/analysis-subtype.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  BaseListComponent,
  AccountService,
  SecurityStateManagementService,
  Hotkeys,
  ResponseNotificationService,
} from "@overa/security";
import { OveraDataTableComponent } from "../../../../shared/temporal_components/overa-datatable/overa-datatable.component";
import { DataTableColumn } from "../../../../shared/temporal_components/models/dataTablecolumn.model";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { AnalysisTypeFormComponent } from "../../analysis-type/analysis-type-form/analysis-type-form.component";
import { AnalysisSubtypeFormComponent } from "../analysis-subtype-form/analysis-subtype-form.component";

@Component({
  selector: "app-analysis-subtype-list",
  templateUrl: "./analysis-subtype-list.component.html",
  styleUrls: ["./analysis-subtype-list.component.scss"],
  providers: [ConfirmationService, DialogService],
})
export class AnalysisSubtypeListComponent implements OnInit, OnDestroy {
  static className = "AnalysisSubtypeListComponent";

  items: AnalysisSubtype[] = [];
  itemsTemp: AnalysisSubtype[] = [];
  allItems: AnalysisSubtype[] = [];

  currentPageIndex: number = 0;
  currentPageSize: number = 10;
  searching = false;
  columns: DataTableColumn[] = [];
  data: AnalysisSubtype[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  totalRecords = 0;
  canView = true;

  canCreate = true;
  @ViewChild("datatable")
  dataTable?: OveraDataTableComponent<any>;

  @Input()
  analysisTypeId = "";

  showTable = false;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private analysisSubtypeService: AnalysisSubtypeService,
    private RNService: ResponseNotificationService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef
  ) {
    //super(accountService, SSMService, router, hotkeys);
    //this.viewPermission = AppPermissions.PERMISSIONS_DATA.viewAnalysisSubtype.value;
    //this.createPermission = AppPermissions.PERMISSIONS_DATA.createAnalysisSubtype.value;
    //this.deletePermission = AppPermissions.PERMISSIONS_DATA.deleteAnalysisSubtype.value;
    //this.baseRoute = '/maintenances/analysisSubtype';
    //this.onBuild = () => {
    //};
    //this.loadData = () => {
    //  this.spinner.show();
    //  this.analysisSubtypeService.get().subscribe({
    //    next:(items) => {
    //    console.log(items);
    //    this.analysisSubtypeService.notifyupdate(items);
    //      this.data = items;
    //      this.spinner.hide();
    //  },
    //    error:(error: any) => {
    //      this.RNService.showError(error);
    //      this.spinner.hide();
    //  }
    //  });
    //};
    //this.onInit = () => {
    //  this.loadData();
    //  this.analysisSubtypeService.analysisSubtypeObservable.subscribe(items => {
    //    this.data = items;
    //  });
    //};
  }
  getData() {
    this.spinner.show();

    // console.log("GETDATA ->", pageIndex, pageSize, filters);
    this.analysisSubtypeService
      //.get(filters || new CampusFilter(), pageIndex, pageSize)
      .getByAnalysisType(this.analysisTypeId)
      //.pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (values: AnalysisSubtype[]) => {
          // console.log("VALUES ->", pageIndex, pageSize, values);

          this.data = [...values];
          this.totalRecords = this.data.length;
          this.generateItems(values);
          if (this.dataTable) this.dataTable.itemsTemp = this.itemsTemp;
          this.spinner.hide();

          //  this.loadDataFunction();
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
  }
  loadDataFunction = (): AnalysisSubtype[] => {
    //if (

    //console.log("LOAD DATA FUNCTION (CHANGE PAGE)", pag, items_per_pag);
    //this.currentPageIndex = pag;
    //this.currentPageSize = items_per_pag;
    //  this.getData().finally(() => { });
    return this.items;

    //  return this.items;
  };
  ngOnInit() {
    this.data = [];
    this.columns = this.createColumns();
    setTimeout(() => this.getData(), 1);
  }

  openForm(id: string | null) {
    //this.router.navigateByUrl('maintenances/analysis-subtype/' + id);
    // this.laboratoryService.notifyupdateRoute(id);
    let header = "";
    let translateKey = "ANALYSIS_SUBTYPE_MAINTENANCE.FORM.HEADER_EDIT";
    if (id == "new") {
      translateKey = "ANALYSIS_SUBTYPE_MAINTENANCE.FORM.HEADER_NEW";
    }
    this.translateService.get(translateKey).subscribe((a: string) => {
      header = a;
    });

    var ref = this.dialogService.open(AnalysisSubtypeFormComponent, {
      width: "85%",
      height: "90%",
      header: header,
      data: {
        isNew: id == "new",
        id: id,
        analysisTypeId: this.analysisTypeId,
      },
    });

    ref.onClose.subscribe((campus: any) => {
      console.log(campus);
      if (campus) {
        //TODO improve
        this.ngOnInit();
      }
      this.spinner.hide();
    });
  }

  generateItems(data: any[]) {
    this.itemsTemp = [];
    const goToDetail = (index: any) => {
      if (data[index].selected) {
        this.openForm(data[index].id);
      }
    };
    const deleteItem = (index: any) => {
      if (data[index].deleteAction) {
        this.delete(data[index].id);
      }
    };

    let items = data;
    items.forEach((element: any) => {
      element.selected = {
        value: false, //TODO: add value from object
        command: goToDetail,
      };
      element.deleteAction = {
        value: false, //TODO: add value from object
        command: deleteItem,
      };
      this.itemsTemp.push(element);
    });
    this.data = this.itemsTemp;
  }

  createColumns(): DataTableColumn[] {
    return [
      {
        field: "name",
        header: this.translateService.instant("COMMON_FORMS.NAME"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },

      {
        field: "selected",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("COMMON_FORMS.SELECTION"),
        type: "System.Button",
        icon: "pi pi-pencil",
        cssClass: "button-selected-analysis-subtype-list",
      },
      {
        field: "deleteAction",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("ACTION.DELETE"),
        type: "System.Button",
        icon: "pi pi-trash",
        cssClass: "button-delete-analysis-subtype-list",
      },
    ];
  }

  delete(id: string) {
    let trmessage,
      messageAccept = "";
    this.translateService
      .get("COMMONS.ASK_DELETE_RECORD")
      .subscribe((a: string) => {
        trmessage = a;
      });
    this.translateService.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        this.analysisSubtypeService.delete(id ?? "").subscribe({
          next: () => {
            let indice = this.data.findIndex((item) => item.id === id);
            if (indice !== -1) {
              this.data.splice(indice, 1);
              this.data = [...this.data];
              this.cdr.detectChanges();
            }

            this.ngOnInit();
            this.spinner.hide();

            //this.messagesService.showToast("MESSAGES.SUCCESS.DELETE", "MESSAGES.SUCCESS.DELETE_LONG", "success", "", "BILLING_TYPE.TITLE")
            //this.cancel();
            this.spinner.hide();
          },
          error: (err) => {
            console.log(err);
            //this.messagesService.showError(err);
            this.spinner.hide();
          },
        });
      },
      reject: () => {},
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
