<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerUsersList">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card">
  <div class="card-body">
    <div class="card-content">
      <div class="row">
        <div class="col-1">
          <div class="d-flex flex-row justify-content-right">
            <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
              [pTooltip]="'ACTION.ADD' | translate" tooltipPosition="bottom" tooltipStyleClass="tooltipDiagnostica"
              (click)="openForm(undefined)"></button>
          </div>
        </div>
      </div>
      <div class="th-2-3-right" *ngIf="showTable">
        <overa-datatable #datatableUsers [columns]="columns" [showPaginator]="true" [rowsPerPage]="[10, 20]"
          [items]="listLaboratoryBranchUsersDataList" [totalRecords]="totalRecords">
        </overa-datatable>
      </div>
    </div>
  </div>
</div>