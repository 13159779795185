<!-- Plantilla de tarjeta por defecto -->
<ng-template #defaultCardTemplate let-item let-index="index">
  <div class="default-card">
    <ng-container *ngFor="let column of columns">
      <div *ngIf="column.responsive && column.responsiveHeader" class="default-card-header">
        <ng-container *ngIf="
            column.type === 'System.Button' || column.type === 'System.Boolean';
            else nodefout
          ">
          <div *ngIf="column.type === 'System.Button'">
            <button pButton [ngStyle]="item[column.field]?.style" icon="{{
                item[column.field]?.icon
                  ? item[column.field].icon
                  : column.icon
                  ? column.icon
                  : 'pi pi-check'
              }}" (click)="item[column.field]?.command(index, item)" [pTooltip]="column.header"
              [ngClass]="column.cssClass"></button>
          </div>
          <div *ngIf="column.type === 'System.Boolean'">
            <overa-checkbox [id]="'odt-' + index" [isDisabled]="false" [(checked)]="item[column.field]"
              [pTooltip]="column.header"></overa-checkbox>
          </div>
        </ng-container>
        <ng-template #nodefout>
          <div>
            <label [pTooltip]="column.header" tooltipStyleClass="tooltipOveraDatatable" [showDelay]="1000"
              [hideDelay]="1000">
              <b>{{ column.header }}</b> : {{ item[column.field] }}</label>
          </div>
        </ng-template>
      </div>
      <div *ngIf="column.responsive && !column.responsiveHeader" class="default-card-content">
        <div>
          <label [pTooltip]="column.header" tooltipStyleClass="tooltipOveraDatatable" [showDelay]="1000"
            [hideDelay]="1000">
            {{ item[column.field] }}</label>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<div class="card-container" #cardcontainer (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)">
  <ng-container *ngFor="let item of items; let i = index">
    <ng-container *ngTemplateOutlet="
        customCardTemplate ? customCardTemplate : defaultCardTemplate;
        context: { $implicit: item, index: i }
      "></ng-container>
  </ng-container>
</div>

<button class="toggle-filter-btn" (click)="toggleFilter()" *ngIf="showGlobalFilter && !isFilterVisible">
  <i class="pi pi-filter"></i>
</button>

<div class="filter-overlay" *ngIf="isFilterVisible && showGlobalFilter">
  <div class="filter-overlay-content">
    <button class="toggle-filter-btn close-btn" (click)="toggleFilter()">
      <i class="pi pi-times"></i>
    </button>
    <span class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input pInputText type="text" oninput="{ event => tt.filterGlobal(event.target.value, 'contains') }"
        placeholder="Global Filter" />
    </span>
    <div class="sort-buttons-container">
      <ng-container *ngFor="let column of columns">
        <div *ngIf="column.responsive">
          <button (click)="onSort({ field: column.header, order: 1 })">
            {{ column.header }} Asc
          </button>
          <button (click)="onSort({ field: column.header, order: -1 })">
            {{ column.header }} Desc
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="table-container">
  <p-table #tt [ngClass]="containerClass" [columns]="selectedColumns"
    [value]="groupedItems.length ? groupedItems : items" selectionMode="single"
    (onEditComplete)="onEditCompleted($event)" (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)" (onColReorder)="onColumnReorder($event)" [rowHover]="true" [rows]="pageSize"
    [first]="first" [showCurrentPageReport]="showCurrentPageReport" [paginator]="showPaginator"
    [totalRecords]="totalRecords" [globalFilterFields]="globalFilterFields"
    [currentPageReportTemplate]="currentPageReport" [rowsPerPageOptions]="rowsPerPage" [lazy]="true"
    [reorderableColumns]="true" [scrollable]="true" (onLazyLoad)="lazyLoad($event)" [sortField]="sortField"
    [sortOrder]="sortOrder" [exportFilename]="title" styleClass="p-datatable-items">
    <ng-template pTemplate="caption">
      <div class="table-header">
        <span *ngIf="showTitle">{{ title }}</span>
        <div class="filter-container" *ngIf="showGlobalFilter">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" oninput="{ event => tt.filterGlobal(event.target.value, 'contains') }"
              placeholder="Global Filter" />
            <p-multiSelect *ngIf="selectedColumns" [options]="columns" [(ngModel)]="selectedColumns"
              optionLabel="header" selectedItemsLabel="{0} columns selected" [style]="{ minWidth: '200px' }"
              placeholder="Choose Columns">
            </p-multiSelect>
          </span>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end start" *ngIf="showExports">
          <p-splitButton icon="pi pi-file-o" [model]="exportOptions"></p-splitButton>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th id="checkboxHeader" style="width: 2.85em" *ngIf="selectionMode == 'multiple'">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>

        <ng-container *ngFor="let col of groupedColumns">
          <ng-container *ngIf="getColumnByFieldName(col) as c">
            <th>
              {{ c.header }}
            </th>
          </ng-container>
        </ng-container>

        <th id="columnHeader" *ngFor="let column of nonGroupedColumns" [pSortableColumn]="column.field"
          pReorderableColumn [pTooltip]="column.header" tooltipPosition="bottom"
          tooltipStyleClass="tooltipOveraDatatable" [showDelay]="1000" [hideDelay]="1000">
          {{ column.header }}
          <p-sortIcon [field]="column.field"></p-sortIcon>
        </th>
        <th id="buttonsHeader" *ngIf="buttons && buttons.length > 0">
          Actions
        </th>
      </tr>
      <tr *ngIf="showFilter">
        <th id="checkboxHeader" *ngIf="selectionMode == 'multiple'"></th>
        <th id="filterHeader" *ngFor="let column of columns" [ngSwitch]="column.type">
          <div *ngSwitchCase="'System.String'">
            <input pInputText type="text"
              oninput="{ event => tt.filter(event.target.value, column.field, column.filterMatchMode) }"
              placeholder="{{ 'COMMONS.FILTER_BY' }} {{ column.header }}" class="p-column-filter" />
          </div>
          <div *ngSwitchCase="'rel'"></div>
        </th>
        <th id="buttonsHeader" *ngIf="buttons && buttons.length > 0"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <tr (dblclick)="edit(rowData)">
        <td *ngIf="selectionMode == 'multiple'">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>

        <ng-container *ngFor="let col of groupedColumns; let i = index">
          <td *ngIf="rowData.GINFO[i] > 0" [attr.rowspan]="rowData.GINFO[i] + 1">
            {{ rowData[col] }}
          </td>
          <td *ngIf="rowData.GINFO[i] == 0">
            {{ rowData[col] }}
          </td>
        </ng-container>

        <td *ngFor="let column of nonGroupedColumns" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex"
          [ngSwitch]="column.type" pEditableColumn>
          <span class="p-column-title">{{ column.header }}</span>

          <ng-container *ngIf="editableColumns.includes(column.field); else nonEditable">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <div [ngSwitch]="column.type">
                  <p-calendar *ngSwitchCase="'System.DateTime'" [(ngModel)]="rowData[column.field]"
                    [showIcon]="true"></p-calendar>
                  <input pInputText type="number" step="1" *ngSwitchCase="'System.Int32'"
                    [(ngModel)]="rowData[column.field]" />
                  <input pInputText type="number" step="0.01" *ngSwitchCase="'System.Decimal'"
                    [(ngModel)]="rowData[column.field]" />
                  <input pInputText type="text" *ngSwitchDefault [(ngModel)]="rowData[column.field]" />
                </div>
              </ng-template>
              <ng-template pTemplate="output">
                <div [ngSwitch]="column.type">
                  <span *ngSwitchCase="'System.DateTime'">
                    {{ rowData[column.field] | date : "dd/MM/yyyy" }}
                  </span>
                  <span *ngSwitchCase="'System.Int32'">
                    {{ rowData[column.field] }}
                  </span>
                  <span *ngSwitchCase="'System.Decimal'">
                    {{ rowData[column.field] | number : "1.2-2" }}
                  </span>

                  <span *ngSwitchDefault>{{ rowData[column.field] }}</span>
                </div>
              </ng-template>
            </p-cellEditor>
          </ng-container>
          <ng-template #nonEditable>
            <div *ngSwitchCase="'System.Guid'">
              {{ rowData[column.field] }}
            </div>
            <div *ngSwitchCase="'System.Boolean'">
              <overa-checkbox [id]="'odt-' + rowIndex" [isDisabled]="false"
                [(checked)]="rowData[column.field]"></overa-checkbox>
            </div>
            <div *ngSwitchCase="'System.Boolean.Command'">
              <overa-checkbox [id]="'odt-' + rowIndex" [isDisabled]="false" [(checked)]="rowData[column.field].value"
                (checkedChange)="rowData[column.field].command(rowIndex)"></overa-checkbox>
            </div>
            <div *ngSwitchCase="'OveraTextarea'">
              <overa-text-area [(value)]="rowData[column.field]"></overa-text-area>
            </div>
            <div *ngSwitchCase="'OveraInputEditor'">
              <overa-input-editor [(value)]="rowData[column.field]"></overa-input-editor>
            </div>

            <div *ngSwitchCase="'System.Button'">
              <button pButton [ngStyle]="rowData[column.field]?.style" icon="{{
                  rowData[column.field]?.icon
                    ? rowData[column.field].icon
                    : column.icon
                    ? column.icon
                    : 'pi pi-check'
                }}" (click)="rowData[column.field]?.command(rowIndex, rowData)" [ngClass]="column.cssClass"
                tooltipPosition="bottom" [pTooltip]="column.header" tooltipStyleClass="tooltipOveraDatatable"
                [showDelay]="1000" [hideDelay]="1000"></button>
            </div>
            <div *ngSwitchCase="'System.DateTime'">
              {{ rowData[column.field] | date : "dd/MM/yyyy" }}
            </div>
            <div *ngSwitchCase="'rel'">
              {{ rowData[column.field] }}
            </div>
            <div *ngSwitchDefault>
              {{ rowData[column.field] }}
            </div>
          </ng-template>
        </td>
        <td *ngIf="buttons.length > 0">
          <div *ngFor="let button of buttons"></div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
      <tr *ngIf="summaryColumns.length > 0">
        <td [attr.colspan]="
            groupedColumns.length +
            nonGroupedColumns.length -
            summaryColumns.length -
            2
          ">
          TOTAL
        </td>
        <td *ngFor="let col of summaryColumns">{{ totals[col] }}</td>
      </tr>

      <tr>
        <td *ngFor="let fcolumn of footerColumns" [attr.colspan]="fcolumn.colspan ? fcolumn.colspan : null">
          {{ fcolumn.value }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="flex align-items-center justify-content-center" *ngIf="showPaginator">
    <div class="flex-1">
      <p-paginator #paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="pageSize"
        [totalRecords]="totalRecords" [rowsPerPageOptions]="rowsPerPage"></p-paginator>
    </div>
  </div>
</div>