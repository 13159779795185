import { AnalysisStatus } from "./enums/analysis-status.model";
import { LaboratoryBranch } from "./laboratory-branch.model";
import { Laboratory } from "./laboratory.model";
import { BillingState } from "./enums/billing-state.model";
import { AnalysisType } from "./analysis-type.model";
import { SampleState } from "./enums/sample-state.model";
import { ShippingLabel } from "./shipping-label.model";
import { Payment } from "./payment.model";
import { Invoice } from "./invoice.model";
import { Delivery } from "./delivery.model";
import { DocumentApi } from "./document-api.model";

export class Sample {
  id: string | undefined;
  code: string | undefined;
  sampleDate?: Date;
  deliveryEmail?: string;
  invoiceNumber?: string;
  isPaid?: boolean;
  datarEntryDate?: Date;
  datarDepartureDate?: Date;
  deliveryDate?: Date;
  arrivalDate?: Date;
  reportDate?: Date;
  patientBirthDate?: Date;
  patientName?: string;
  patientLastName?: string;
  medicalRecordNumber?: string;
  patientIdentityId?: string;
  deliveryState?: SampleState;
  billingState?: BillingState;
  shippingLabel?: ShippingLabel;
  shippingLabelId?: string;
  noValidCause?: string;
  laboratoryBranch?: LaboratoryBranch;
  laboratoryBranchId?: string;
  testType?: AnalysisType;
  testTypeId?: string;
  payment?: Payment;
  paymentId?: string;
  invoice?: Invoice;
  invoiceId?: string;
  delivery?: Delivery;
  deliveryId?: string;
  filed?: number;
  patientForm?: DocumentApi;
  patientFormId?: string;

  fields?: AnalysisField[];

  // description: string | undefined;
  // lab?: Laboratory;
  // campus?: LaboratoryBranch;
  status?: AnalysisStatus;
  roles?: any[];
  constructor() {}
}
export class AnalysisField {
  id?: string;
  name?: string;
  value?: any;
  type?: string;
}
