<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerFormStockBox">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
        <span>{{ "BOX_STOCK_LIST.TITLE" | translate }}</span>
      </h4>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="loadedForm">
      <form autocomplete="off" [formGroup]="form" *ngIf="loadedForm">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 input-form">
            <overa-input [controlName]="'laboratoryName'" [title]="'COMMON_FORMS.LABORATORY' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <!--<div class="input-form"><overa-input [controlName]="'description'" [title]="'COMMON_FORMS.DESCRIPTION' | translate" [formGroup]="form"></overa-input></div>-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 input-form ¡">
            <overa-input [controlName]="'laboratoryBranchName'" [title]="'COMMON_FORMS.LABORATORYBRANCH' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 input-form ">
            <overa-input [controlName]="'boxCount'" [title]="'COMMON_FORMS.BOX_STOCK' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <!--<div class="input-form"><overa-input [controlName]="'description'" [title]="'COMMON_FORMS.DESCRIPTION' | translate" [formGroup]="form"></overa-input></div>-->
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 input-form">
            <overa-input [controlName]="'kitCount'" [title]="'COMMON_FORMS.KITS_STOCK' | translate"
              [formGroup]="form"></overa-input>
          </div>
        </div>
      </form>
    </div>
    <div class="container-form-actions divContainerButtons" [align]="'end'">
      <button mat-raised-button (click)="create()" [disabled]="!form.valid">
        {{ "ACTION.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>