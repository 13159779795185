import { Permissions } from "@overa/security";
export abstract class AppPermissions extends Permissions {
  public static override readonly PERMISSIONS_DATA = {
    ...Permissions.PERMISSIONS_DATA,
    viewTests: {
      name: "Ver Tests",
      value: "Sample.view",
    },
    createTests: {
      name: "Create tests",
      value: "Sample.create",
    },
    updateTests: {
      name: "Update tests",
      value: "Sample.update",
    },
    deleteTests: {
      name: "Delete tests",
      value: "Sample.delete",
    },
    viewLaboratorys: {
      name: "Ver Laboratorys",
      value: "Laboratory.view",
    },
    createLaboratorys: {
      name: "Create Laboratorys",
      value: "Laboratory.create",
    },
    updateLaboratorys: {
      name: "Update Laboratorys",
      value: "Laboratory.update",
    },
    deleteLaboratorys: {
      name: "Delete Laboratorys",
      value: "Laboratory.delete",
    },
    viewCampus: {
      name: "Ver LaboratoryBranch",
      value: "LaboratoryBranch.view",
    },
    createCampus: {
      name: "Create LaboratoryBranch",
      value: "LaboratoryBranch.create",
    },
    updateCampus: {
      name: "Update LaboratoryBranch",
      value: "LaboratoryBranch.update",
    },
    deleteCampus: {
      name: "Delete LaboratoryBranch",
      value: "LaboratoryBranch.delete",
    },
    viewTestType: {
      name: "Ver TestType",
      value: "TestType.view",
    },
    createTestType: {
      name: "Create TestType",
      value: "TestType.create",
    },
    updateTestType: {
      name: "Update TestType",
      value: "TestType.update",
    },
    deleteTestType: {
      name: "Delete TestType",
      value: "TestType.delete",
    },
  };
  public static override readonly PERMISSIONS_GROUP_DATA = {
    ...Permissions.PERMISSIONS_GROUP_DATA,
    tests: {
      name: "Tests",
      value: "tests",
    },
  };
}
