import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { NgxSpinnerService } from "ngx-spinner";
import {
  Observable,
  Subject,
  Subscription,
  forkJoin,
  map,
  mergeMap,
  of,
  takeUntil,
} from "rxjs";
import { CustomerSummaryViewModel } from "src/app/models/customer-summary-viewmodel";
import { PaginatedResponse } from "src/app/models/paginatedResponse.model";
import { ProjectBudgetViewModel } from "src/app/models/project-budget-viewmodel.model";
import { ProjectCardViewModel } from "src/app/models/project-card-viewmodel.model";
import { ProjectCategoryViewModel } from "src/app/models/project-category-viewmodel.model";
import { ProjectCardSearchDTO } from "src/app/models/project-filters.model";
import { BudgetService } from "src/app/services/budget.service";
import { CategoryService } from "src/app/services/category.service";
import { CustomerService } from "src/app/services/customer.service";
import { ProjectService } from "src/app/services/projects.service";
import {
  CARD_BUDGET,
  CARD_BUSSINES_NAME,
  CARD_CATEGORY,
  CARD_ENDDATE,
  CARD_STARTDATE,
  createFilter,
  transformProjectCardsToOveraCardItems,
} from "src/app/shared/project-card-utils";
import { OveraCardItem } from "src/app/shared/temporal_components/models/overa-card-item.model";
import { OveraGridFiltersType } from "src/app/shared/temporal_components/models/overa-grid-filter-types.model";
import { OveraGridFilter } from "src/app/shared/temporal_components/models/overa-grid-filter.model";
import { OveraKeyValuePair } from "src/app/shared/temporal_components/models/overa-keyValue-pair.model";
import { Sample } from "../../models/sample.model";
import { AnalysisStatus } from "../../models/enums/analysis-status.model";
import { OveraGridColumnData } from "../../shared/temporal_components/models/overa-grid-columns-data.model";
import { Globals } from "../../utils/globals";
import { Utils } from "../../utils/utils";
import { SampleService } from "src/app/services/sample.service";
import { AnalysisType } from "src/app/models/analysis-type.model";
import { DiagnosticaSample } from "src/app/models/grid-home/diagnostica-sample-wrapper.model";
import { OveraCardItemField, OveraMessagesService } from "@overa/components";
import { LaboratorySample } from "src/app/models/grid-home/laboratory-sample-wrapper.model";
import { DatarSample } from "src/app/models/grid-home/datar-sample-wrapper.model";
import { HeaderStatus } from "src/app/models/enums/header-status.model";
import { ProjectWorkOrderCardViewModel } from "src/app/models/project-work-order-card-viewmodel.model";
import { MatDialog } from "@angular/material/dialog";
import { OveraDropDownMenuOption } from "src/app/shared/temporal_components/models/overa-dropdown-menu-option.model";
import { ConfirmationService } from "primeng/api";
import { SampleState } from "src/app/models/enums/sample-state.model";
import { OveraCardHeaderIconsOption } from "src/app/shared/temporal_components/models/overa-card-header-icons-option.model";
import { StorageService } from "@overa/storage";
import { BillingState } from "src/app/models/enums/billing-state.model";

@Component({
  selector: "app-project-grid",
  templateUrl: "./project-grid.component.html",
  styleUrls: ["./project-grid.component.scss"],
  providers: [ConfirmationService],
})
export class ProjectGridComponent implements OnInit, OnDestroy {
  showForm = false;
  subscription: any;
  filters: OveraGridFilter[];
  gridStyles: {};
  containerStyles: string;
  //itemsWithoutColumns: OveraCardItem[];
  columnData: any;
  criticColor: string;
  neutralColor: string;
  checkColor: string;
  cardFilter: ProjectCardSearchDTO;
  isFirstLoad: boolean = true;
  unsubscribe$: Subject<void> = new Subject<void>();
  categories: ProjectCategoryViewModel[];
  budgets: ProjectBudgetViewModel[];
  customers: CustomerSummaryViewModel[];
  allProjects: ProjectCardViewModel[] = [];
  title = "HOME";
  itemsWithColumns: OveraGridColumnData[] = [];
  copyItemsWithColumns: OveraGridColumnData[] = [];
  isDiagnostica = false;
  isLab = false;
  isDatar = false;
  roleName = "";
  loaded: boolean = false;
  fieldsTranslateDatar: string[] = ["COMMONS_FIELDS", "DATAR_FIELDS"];
  fieldsTranslateLaboratory: string[] = ["COMMONS_FIELDS", "LABORATORY_FIELDS"];
  fieldsTranslateDiagnostica: string[] = [
    "COMMONS_FIELDS",
    "DIAGNOSTICA_FIELDS",
  ];
  fieldsSelectedAccordingUrl: string[] = [];
  url: string = "";
  laboratoryId: string = "";
  allItems: any;
  isReadOnly?: boolean = false;
  isIconClicked: boolean = false;
  translatedFields: { [key: string]: string } = {};
  keysToCheckForRegisteredDiagnostica = [
    "DATARENTRYDATE",
    "DATARDEPARTUREDATE",
    "DELIVERYDATE",
  ];
  keysToCheckForReceivedDiagnostica = ["DATARDEPARTUREDATE", "DELIVERYDATE"];
  keysToCheckForFinishedDiagnostica = ["DELIVERYDATE"];
  keysToCheckForRegisteredLaboratory = ["DATARENTRYDATE", "DATARDEPARTUREDATE"];
  keysToCheckForSamplesReceivedLaboratory = ["DATARDEPARTUREDATE"];
  keysSummaryDiagnostica = ["SUMMARY_DIAGNOSTICA"];
  fieldsTranslateHomeDiagnostica = [
    "SUMMARY_DIAGNOSTICA",
    "TOOLTIP_DIAGNOSTICA",
  ];
  fieldsTranslateHomeLaboratory = ["TOOLTIP_LABORATORY"];
  fieldsTranslateHomeDatar = ["TOOLTIP_DATAR"];
  filterClass: string = "";
  constructor(
    private projectService: ProjectService,
    private categoryService: CategoryService,
    private customerService: CustomerService,
    private budgetService: BudgetService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private utils: Utils,
    private globals: Globals,
    private route: Router,
    private sampleService: SampleService,
    public activateRoute: ActivatedRoute,
    private messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService
  ) {
    this.filters = [];
    this.gridStyles = {};
    this.containerStyles = "custom-container";
    this.criticColor = "#E99099";
    this.neutralColor = "#B3B3B3";
    this.checkColor = "#78C5C0";
    this.itemsWithColumns = [];
    this.cardFilter = new ProjectCardSearchDTO();
    this.categories = [];
    this.budgets = [];
    this.customers = [];
    this.filterClass = "custom-filter";
  }
  //TODO añadir color si esta registered o registering
  ngOnInit() {
    console.log("ee");
    console.log(this.activateRoute.url.toString());
    this.activateRoute.url.subscribe((urlSegments) => {
      const url = urlSegments
        .map((segment) => segment.path)
        .slice(1)
        .join("/");
      console.log(url);
      this.url = url;
      console.log(this.globals);
      this.loaded = false;
      this.itemsWithColumns = [];
      if (
        this.globals.userLaboratory?.laboratoryBranchUserLaboratories
          ?.laboratory?.id
      ) {
        this.laboratoryId =
          this.globals.userLaboratory?.laboratoryBranchUserLaboratories?.laboratory?.id;
      }
      //TODO this.utils.isDatarRole() isDiagnosticaRole isLabRole()
      if (this.url === "diagnostica") {
        this.subscription = this.sampleService.getDiagnosticSampleDto();
        this.fieldsSelectedAccordingUrl = this.fieldsTranslateDiagnostica;
      }
      if (this.url === "datar") {
        this.subscription = this.sampleService.getDatarSamples();
        this.fieldsSelectedAccordingUrl = this.fieldsTranslateDatar;
      }
      if (this.url === "laboratory") {
        if (this.laboratoryId) {
          this.subscription = this.sampleService.getLaboratorySamples(
            this.laboratoryId
          );
        } else {
          this.subscription = null;
        }
        this.fieldsSelectedAccordingUrl = this.fieldsTranslateLaboratory;
      }
      if (this.subscription) {
        this.subscription.subscribe({
          next: (items: any) => {
            console.log("ITEMS", items);
            this.allItems = items;
            this.itemsWithColumns = this.transformSampleToOveraCardItems(items);
            this.copyItemsWithColumns = [...this.itemsWithColumns];
            console.log(this.itemsWithColumns);
            this.loaded = true;
          },
          error: (err: any) => {
            console.error(err);
            this.allItems = [];
            this.spinner.hide();
            this.loaded = true;
          },
        });
      } else {
        this.allItems = [];
        this.messagesService.showToast(
          "MESSAGES.INFO.INFO",
          "STEP1.MESSAGES.MESSAGE_USER_NOT_LABORATORY",
          "info",
          ""
        );
      }
    });

    //this.querySearchProjectCards();
    //this.innitFilters();
  }
  initFields(fields: string[]): Observable<[string[], string[]]> {
    const arrayFields = fields;
    const observables: Observable<string[]>[] = arrayFields.map((field) =>
      this.translateService.get(field).pipe(takeUntil(this.unsubscribe$))
    );

    return forkJoin(observables).pipe(
      takeUntil(this.unsubscribe$),
      map(([commonFields, anotherField]) => {
        return [commonFields, anotherField];
      })
    );
  }

  transformSampleToOveraCardItems(items: any): OveraGridColumnData[] {
    var statuses = this.getView();
    let translatedFields: any;
    let translatedCommonsFields: any;
    let arrayFields: string[] = [];
    const subscriptions: Subscription[] = [];
    let arrayObservables = this.initFields(this.fieldsSelectedAccordingUrl);

    const subscription = arrayObservables.subscribe(
      ([commonFields, anotherField]) => {
        translatedCommonsFields = commonFields;
        translatedFields = anotherField;
      }
    );
    subscription.unsubscribe();
    console.log(translatedCommonsFields, translatedFields);

    let columns: OveraGridColumnData[] = [];
    for (let status in statuses) {
      let field = HeaderStatus[statuses[status]].toLowerCase();
      if (field === "samples_received") {
        field = "samplesReceived";
      }
      if (field === "reports_received") {
        field = "reportsReceived";
      }
      if (field === "report_finished") {
        field = "reportFinished";
      }
      // console.log("FIELD", field);
      console.log(items[field]);
      let itemFilter = items[field];
      let column = new OveraGridColumnData("", []);
      let overaCardItems: OveraCardItem[] = [];

      if (this.url === "diagnostica") {
        let summaryFields: any;
        let tooltipsIcon: any;
        let summaryFieldsFiltered: any;
        let arrayItemsFields: any[] = [];
        arrayItemsFields = items[field];
        let arrayObservables1 = this.initFields(
          this.fieldsTranslateHomeDiagnostica
        );

        const subscription = arrayObservables1.subscribe(
          ([fieldsKeys, tooltips]) => {
            summaryFields = fieldsKeys;
            tooltipsIcon = tooltips;
          }
        );
        subscription.unsubscribe();
        // console.log("SUMMARYFIELDS", summaryFields);
        overaCardItems = itemFilter?.map((card: any) =>
          this.transformAnySampleToOveraCardItem(
            card as DiagnosticaSample,
            translatedFields,
            this.translateService.currentLang,
            field
          )
        );
        switch (field) {
          case "registered":
            let copySummaryFields = { ...summaryFields };
            delete copySummaryFields["ASSOCIATE_INVOICE"];
            delete copySummaryFields["ASSOCIATE_PAYMENT"];
            delete copySummaryFields["ASSOCIATE_REPORT"];
            delete copySummaryFields["REMOVE_ERROR"];
            delete copySummaryFields["SEND"];
            summaryFieldsFiltered = copySummaryFields;
            break;

          case "received":
            let copySummaryFields1 = { ...summaryFields };
            delete copySummaryFields1["MARK_ERROR"];
            delete copySummaryFields1["SEND"];
            summaryFieldsFiltered = copySummaryFields1;
            break;

          case "finished":
            let copySummaryFields2 = { ...summaryFields };
            delete copySummaryFields2["ASSOCIATE_REPORT"];
            delete copySummaryFields2["REMOVE_ERROR"];
            delete copySummaryFields2["MARK_ERROR"];
            summaryFieldsFiltered = copySummaryFields2;
            break;
          case "sent":
            let copySummaryFields3 = { ...summaryFields };
            delete copySummaryFields3["ASSOCIATE_REPORT"];
            delete copySummaryFields3["REMOVE_ERROR"];
            delete copySummaryFields3["MARK_ERROR"];
            delete copySummaryFields3["SEND"];
            summaryFieldsFiltered = copySummaryFields3;
            break;

          default:
            break;
        }

        overaCardItems.forEach((item) => {
          if (item.id) {
            const matchingItemField = arrayItemsFields.find(
              (itemField) => itemField.code === item.id
            );
            if (field === "finished") {
              let arrayIcons = [];
              if (
                matchingItemField.sampleReportStoragId &&
                matchingItemField.sampleReportStoragId !=
                  "00000000-0000-0000-0000-000000000000"
              ) {
                let itemIconFile = this.createFileIcon(
                  matchingItemField.sampleReportStoragId,
                  tooltipsIcon["DOWNLOAD_REPORT"]
                );
                arrayIcons.push(itemIconFile);
              }

              let itemIconCalendar: any = {
                iconClass: "",
                color: "",
                tooltip: "",
              };
              itemIconCalendar.iconClass = "pi-calendar";
              itemIconCalendar.color = matchingItemField.isDeliveryTermExceeded
                ? "#FF6862"
                : "#8CD23E";
              itemIconCalendar.tooltip =
                matchingItemField.isDeliveryTermExceeded
                  ? tooltipsIcon["OUT_OF_TIME"]
                  : tooltipsIcon["IN_TIME"];
              arrayIcons.push(itemIconCalendar);

              item.headerIconsOptions = this.generateIconsOptionsForActionGrid(
                item.id,
                arrayIcons
              );
              summaryFieldsFiltered = this.menuFieldsInvoicePayment(
                matchingItemField,
                summaryFields
              );
              delete summaryFieldsFiltered["ASSOCIATE_REPORT"];
              delete summaryFieldsFiltered["REMOVE_ERROR"];
            }
            if (field === "sent") {
              let arrayIcons = [];
              if (
                matchingItemField.sampleReportStoragId &&
                matchingItemField.sampleReportStoragId !=
                  "00000000-0000-0000-0000-000000000000"
              ) {
                let itemIconFile = this.createFileIcon(
                  matchingItemField.sampleReportStoragId,
                  tooltipsIcon["DOWNLOAD_REPORT"]
                );
                arrayIcons.push(itemIconFile);
              }
              item.headerIconsOptions = this.generateIconsOptionsForActionGrid(
                item.id,
                arrayIcons
              );
              summaryFieldsFiltered = this.menuFieldsInvoicePayment(
                matchingItemField,
                summaryFields
              );
              delete summaryFieldsFiltered["ASSOCIATE_REPORT"];
              delete summaryFieldsFiltered["REMOVE_ERROR"];
              delete summaryFieldsFiltered["SEND"];
            }
            item =
              this.changePercentageWithFieldIsMaxAllowedInvoiceNumberExceeded(
                item,
                matchingItemField.isMaxAllowedInvoiceNumberExceeded
              );
            let arrayItemsMenus: any[] = [];

            //PONER EN ROJO SI TIENE ESTADO DATARRECEIVEDWITHERROR
            if (field === "received") {
              summaryFieldsFiltered = this.menuFieldsError(
                item,
                matchingItemField,
                summaryFields
              );
              summaryFieldsFiltered = this.menuFieldsInvoicePayment(
                matchingItemField,
                summaryFieldsFiltered
              );
              delete summaryFieldsFiltered["SEND"];
            }
            if (matchingItemField) {
              item.menuOptions = [];
              Object.entries(summaryFieldsFiltered).forEach(([key, value]) => {
                let itemMenu: any = {
                  title: "",
                  url: null,
                  isMaxAllowedInvoiceNumberExceeded: false,
                };
                itemMenu.title = value;
                itemMenu.url = null;
                itemMenu.isMaxAllowedInvoiceNumberExceeded =
                  matchingItemField.isMaxAllowedInvoiceNumberExceeded;

                if (key === "ASSOCIATE_INVOICE") {
                  itemMenu.url = {
                    initial: "/invoices/2",
                    laboratoryId: matchingItemField.laboratoryId,
                    laboratoryBranchId: matchingItemField.laboratoryBranchId,
                    idSample: matchingItemField.id,
                  };
                } else if (key == "ASSOCIATE_PAYMENT") {
                  itemMenu.url = {
                    initial: "/payments/2",
                    laboratoryId: matchingItemField.laboratoryId,
                    laboratoryBranchId: matchingItemField.laboratoryBranchId,
                    idSample: matchingItemField.id,
                  };
                } else if (key == "ASSOCIATE_REPORT") {
                  itemMenu.url = {
                    initial: "/reports",
                    idSample: matchingItemField.id,
                  };
                } else if (key == "REMOVE_ERROR") {
                  itemMenu.url = {
                    initial: "/validateSample/2",
                    idSample: item.id,
                  };
                  console.log("itemMenu last", itemMenu);
                } else if (key == "MARK_ERROR") {
                  itemMenu.url = {
                    initial: "/validateSample/2",
                    idSample: item.id,
                  };
                } else if (key == "SEND") {
                  itemMenu.url = {
                    initial: "/delivery/2",
                    laboratoryId: matchingItemField.laboratoryId,
                    laboratoryBranchId: matchingItemField.laboratoryBranchId,
                    idSample: matchingItemField.id,
                  };
                } else if (key == "ARCHIVED") {
                  itemMenu.url = {
                    initial: "archivedSample",
                    idSample: matchingItemField.id,
                  };
                }
                arrayItemsMenus.push(itemMenu);
              });
              if (item.id)
                item.menuOptions = this.generateArrayMenuOptions(
                  item.id,
                  arrayItemsMenus
                );
            }
          }
        });
      }

      if (this.url === "datar") {
        let tooltipsIcon: any;
        let arrayItemsFieldsDatar: any[] = [];
        arrayItemsFieldsDatar = items[field];
        let arrayObservables3 = this.initFields(this.fieldsTranslateHomeDatar);
        const subscription = arrayObservables3.subscribe(([tooltips]) => {
          tooltipsIcon = tooltips;
        });
        subscription.unsubscribe();

        overaCardItems = itemFilter?.map((card: any) =>
          this.transformAnySampleToOveraCardItem(
            card as DatarSample,
            translatedFields,
            this.translateService.currentLang
          )
        );

        let title = "";
        let title2 = "";
        let url = "";
        let nameFunction = "";
        let nameFunction2 = "";
        let idSample = "";
        let validate: boolean | undefined = undefined;
        switch (field) {
          case "errored":
            title = this.translateService.instant("SUMMARY_DATAR.REMOVE_ERROR");
            title2 = this.translateService.instant("SUMMARY_DATAR.ARCHIVED");
            url = "/validateSample/2";
            nameFunction2 = "archivedSample";
            break;

          case "registered":
            title = this.translateService.instant("SUMMARY_DATAR.MARK_ERROR");
            url = "/validateSample/2";
            validate = false;
            break;

          case "finished":
            title = this.translateService.instant("SUMMARY_DATAR.ARCHIVED");
            nameFunction = "archivedSample";
            break;

          default:
            break;
        }
        overaCardItems.forEach((item) => {
          if (item.id) {
            const matchingItemField = arrayItemsFieldsDatar.find(
              (itemField) => itemField.code === item.id
            );
            idSample = matchingItemField.id;
            item.menuOptions = this.generateMenuOption(
              item.id,
              title,
              url,
              nameFunction,
              idSample,
              validate
            );
            if (field === "finished") {
              let arrayIcons = [];
              if (
                matchingItemField.reportStorageId &&
                matchingItemField.reportStorageId !=
                  "00000000-0000-0000-0000-000000000000"
              ) {
                let itemIconFile = this.createFileIcon(
                  matchingItemField.reportStorageId,
                  tooltipsIcon["DOWNLOAD_REPORT"]
                );
                arrayIcons.push(itemIconFile);
              }
              item.headerIconsOptions = this.generateIconsOptionsForActionGrid(
                item.id,
                arrayIcons
              );
            }

            //PONER EN ROJO SI TIENE ESTADO DATARRECEIVEDWITHERROR
            if (field === "errored") {
              item.percentage = 100;
              item.percentageIsCritic = true;
              let archiveOption = this.generateMenuOption(
                item.id,
                title2,
                "",
                nameFunction2,
                idSample
              );
              item.menuOptions = item.menuOptions.concat(archiveOption);
            }
          }
        });
      }

      if (this.url === "laboratory") {
        let tooltipsIcon: any;
        let arrayItemsFieldsLaboratory: any[] = [];
        arrayItemsFieldsLaboratory = items[field];

        let arrayObservables2 = this.initFields(
          this.fieldsTranslateHomeLaboratory
        );
        const subscription = arrayObservables2.subscribe(([tooltips]) => {
          tooltipsIcon = tooltips;
        });
        subscription.unsubscribe();

        overaCardItems = itemFilter?.map((card: any) =>
          this.transformAnySampleToOveraCardItem(
            card as LaboratorySample,
            translatedFields,
            this.translateService.currentLang,
            field
          )
        );
        let title = "";
        let url = "";
        let title2 = "";
        let url2 = "";
        let nameFunction = "";
        let idSample = "";
        switch (field) {
          case "samplesReceived":
            title = this.translateService.instant("SUMMARY_DATAR.ARCHIVED");
            nameFunction = "archivedSample";
            title2 = this.translateService.instant(
              "SUMMARY_DATAR.REMOVE_ERROR"
            );
            url2 = "/validateSample/2";
            break;

          case "registered":
            title = this.translateService.instant("SUMMARY_DATAR.MARK_ERROR");
            url = "/validateSample/2";
            break;

          case "reportsReceived":
            title = this.translateService.instant("SUMMARY_DATAR.ARCHIVED");
            nameFunction = "archivedSample";
            break;

          default:
            break;
        }
        overaCardItems.forEach((item) => {
          if (item.id) {
            const matchingItemField = arrayItemsFieldsLaboratory.find(
              (itemField) => itemField.code === item.id
            );
            idSample = matchingItemField.id;
            item.menuOptions = this.generateMenuOption(
              item.id,
              title,
              url,
              nameFunction,
              idSample
            );

            //PONER EN ROJO SI TIENE ESTADO DATARRECEIVEDWITHERROR
            if (
              field === "samplesReceived" &&
              matchingItemField.deliveryState ===
                SampleState.DATARRECEIVEDWITHERROR
            ) {
              item.percentage = 100;
              item.percentageIsCritic = true;
              let removeOption = this.generateMenuOption(
                item.id,
                title2,
                url2,
                "",
                idSample
              );
              item.menuOptions = removeOption.concat(item.menuOptions);
            }

            if (field === "reportsReceived") {
              let arrayIcons = [];
              if (
                matchingItemField.reportStorageId &&
                matchingItemField.reportStorageId !==
                  "00000000-0000-0000-0000-000000000000"
              ) {
                let itemIconFile = this.createFileIcon(
                  matchingItemField.reportStorageId,
                  tooltipsIcon["DOWNLOAD_REPORT"]
                );
                arrayIcons.push(itemIconFile);
              }
              item.headerIconsOptions = this.generateIconsOptionsForActionGrid(
                item.id,
                arrayIcons
              );
            }
          }
        });
      }

      column.items = overaCardItems;
      const translatedStatus = this.translateService.instant(
        "STATUS_FIELDS" + "." + HeaderStatus[statuses[status]]
      );
      let title = "";
      if (
        (this.url === "laboratory" &&
          statuses[status] === HeaderStatus.REPORTS_RECEIVED) ||
        (this.url === "datar" && statuses[status] === HeaderStatus.FINISHED)
      ) {
        title = translatedCommonsFields["REPORTS"];
      } else {
        title = translatedCommonsFields["ANALYSIS"];
      }
      column.columnTitle = title + " " + translatedStatus;
      columns.push(column);
    }
    // console.log("COLUMNs", columns);
    return columns;
  }
  createFileIcon(fileStorageId: any, tooltip: string): any {
    const itemIconFile: any = {
      color: "#818080",
      fileStorageId: fileStorageId,
      iconClass: "pi-file",
      tooltip: tooltip,
    };
    return itemIconFile;
  }
  transformAnySampleToOveraCardItem(
    card: any,
    translatedFields: any,
    currentLang: string,
    field?: string
  ): OveraCardItem {
    const fields: OveraCardItemField[] = this.createOveraCardItemFields(
      card,
      translatedFields,
      currentLang,
      field
    );
    // console.log("CARD", card);
    let menuOptions: OveraDropDownMenuOption[] = [];

    return new OveraCardItem({
      id: card.code,
      title: new OveraCardItemField(translatedFields["ANALYSIS"], card.code),
      percentage: card.percentage,
      percentageIsCritic: card.percentageIsCritic, // TODO: adjust the formula
      fields: fields,
      menuOptions: menuOptions,
    });
  }
  createOveraCardItemFields(
    card: any,
    translatedFields: any,
    currentLang: string,
    field?: string
  ): OveraCardItemField[] {
    const opciones = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    } as const;
    // console.log("TRANSLATEDFIELDS", translatedFields, field);
    const fields: OveraCardItemField[] = Object.entries(card)
      .map(([key, value]) => {
        const uppercaseKey = key.toUpperCase();
        if (translatedFields.hasOwnProperty(uppercaseKey)) {
          let ruleOut = false;
          if (this.url === "diagnostica" && field) {
            if (
              field === "registered" &&
              this.keysToCheckForRegisteredDiagnostica.includes(uppercaseKey)
            ) {
              ruleOut = true;
            }
            if (
              field === "received" &&
              this.keysToCheckForReceivedDiagnostica.includes(uppercaseKey)
            ) {
              ruleOut = true;
            }
            if (
              field === "finished" &&
              this.keysToCheckForFinishedDiagnostica.includes(uppercaseKey)
            ) {
              ruleOut = true;
            }
          }
          if (this.url === "laboratory" && field) {
            if (
              field === "registered" &&
              this.keysToCheckForRegisteredLaboratory.includes(uppercaseKey)
            ) {
              ruleOut = true;
            }
            if (
              field === "samplesReceived" &&
              this.keysToCheckForSamplesReceivedLaboratory.includes(
                uppercaseKey
              )
            ) {
              ruleOut = true;
            }
          }
          if (value !== null && value !== "" && !ruleOut) {
            if (
              key === "sampleDate" ||
              key === "patientBirthDate" ||
              key === "datarEntryDate" ||
              key === "datarDepartureDate" ||
              key === "deliveryDate" ||
              key === "arrivalDate" ||
              key === "reportDate"
            ) {
              if (typeof value === "string" || typeof value === "number") {
                value = new Date(value).toLocaleDateString(
                  currentLang,
                  opciones
                );
              }
            }
            if (key === "isPaid") {
              let yes = this.translateService.instant("ACTION.YES");
              let no = this.translateService.instant("ACTION.NO");
              value = value === true ? yes : no;
            }
            if (
              key !== "id" &&
              key !== "deliveryState" &&
              key !== "billingState" &&
              key !== "code"
            ) {
              return new OveraCardItemField(
                translatedFields[key.toUpperCase()],
                value
              );
            }
            return null;
          } else {
            // return null;
            if (ruleOut) {
              return null;
            } else {
              return new OveraCardItemField(
                translatedFields[key.toUpperCase()],
                ""
              );
            }
          }
        } else {
          return null;
        }
      })
      .filter(Boolean) as OveraCardItemField[];

    return fields;
  }

  private innitFilters() {
    const requests = forkJoin([
      this.categoryService.getCategoriesAsync(),
      this.budgetService.getBudgetsAsync(),
      this.customerService.getCustomersAsync(),
    ]);
    requests.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: ([categories, budgets, customers]) => {
        this.categories = categories.items;
        this.budgets = budgets.items;
        this.customers = customers.items;
        let categoryNames = categories.items
          .map(
            (s) =>
              new OveraKeyValuePair(s.categoryId.toString(), s.categoryName)
          )
          .sort();
        let budgetsCode = budgets.items
          .map((s) => new OveraKeyValuePair(s.code, s.code))
          .sort();
        let customersBussinesName = customers.items
          .map((s) => new OveraKeyValuePair(s.code, s.businessName))
          .sort();
        this.innitFiltersOnGrid(
          categoryNames,
          budgetsCode,
          customersBussinesName
        );
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  // private querySearchProjectCards(projectCardFilters?: ProjectCardSearchDTO) {
  //   this.spinner.show();
  //   this.projectService
  //     .getSearchProjectCards(projectCardFilters)
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe({
  //       next: (values: PaginatedResponse<ProjectCardViewModel>) => {
  //         this.spinner.hide();
  //         this.allProjects = values.items;
  //         var statuses = this.getView();
  //         this.itemsWithColumns = transformProjectCardsToOveraCardItems(
  //           values.items,
  //           this.translateService,
  //           statuses
  //         );
  //         console.log(this.itemsWithColumns);
  //       },
  //       error: (error) => {
  //         this.spinner.hide();
  //         TODO: overamessage;
  //       },
  //     });
  // }

  private innitFiltersOnGrid(
    categoyNames: OveraKeyValuePair[],
    contractCodes: OveraKeyValuePair[],
    bussinesNames: OveraKeyValuePair[],
    values?: ProjectCardViewModel[]
  ) {
    const filterText = createFilter(
      OveraGridFiltersType.TEXT,
      "",
      "textFilter"
    );
    const filterStartDate = createFilter(
      OveraGridFiltersType.DATE,
      CARD_STARTDATE,
      "fromDate"
    );
    const filterEndDate = createFilter(
      OveraGridFiltersType.DATE,
      CARD_ENDDATE,
      "toDate"
    );
    const filterDropdownRazon = createFilter(
      OveraGridFiltersType.DROPDOWN,
      CARD_BUSSINES_NAME,
      "customerCodes",
      bussinesNames
    );
    const filterDropdownOferta = createFilter(
      OveraGridFiltersType.MULTISELECT,
      CARD_BUDGET,
      "budgetCodes",
      contractCodes
    );
    const filterDropdownCategoria = createFilter(
      OveraGridFiltersType.MULTISELECT,
      CARD_CATEGORY,
      "categoryIds",
      categoyNames
    );
    console.log("filterDropdownRazon", filterDropdownRazon);
    this.filters = [
      filterText,
      filterStartDate,
      filterEndDate,
      filterDropdownRazon,
      filterDropdownOferta,
      filterDropdownCategoria,
    ];
  }

  onFilterChange(event: OveraGridFilter) {
    this.checkIfMinOrMaxDate(event);
    let value: any;
    if (event.selected instanceof Date) {
      value = event.selected;
    } else if (
      ["categoryIds", "customerCodes", "budgetCodes"].includes(event.key) &&
      event.selected
    ) {
      if (Array.isArray(event.selected)) {
        value = event.selected.map((s) => s.key);
      } else if (event.selected instanceof OveraKeyValuePair) {
        value = event.selected.key;
      }
    }

    this.cardFilter[event.key as keyof ProjectCardSearchDTO] = value;
    // this.querySearchProjectCards(this.cardFilter);
  }

  private checkIfMinOrMaxDate(event: OveraGridFilter) {
    if (event.type == OveraGridFiltersType.DATE) {
      if (event.key == "startDate") {
        this.filters.filter((s) => s.key == "enddate")[0].minDate =
          event.selected as Date;
      } else if (event.key == "enddate") {
        this.filters.filter((s) => s.key == "startDate")[0].maxDate =
          event.selected as Date;
      }
    }
  }

  onCardClick(event: OveraCardItem) {
    console.log(event);
    const selected: any = Object.values(this.allItems)
      .flat()
      .find((item: any) => item.code === event.id);
    if (selected) {
      if (this.url === "diagnostica") {
      }
      if (this.url === "datar") {
        if (this.isIconClicked) {
          this.isIconClicked = false;
        } else {
          let url = "/validateSample/2/" + selected.id;
          this.route.navigate(["/validateSample/2/", selected.code]);
        }
      }
      if (this.url === "laboratory") {
        if (this.isIconClicked) {
          this.isIconClicked = false;
        } else {
          let url = "/createAnalisys/1/" + selected.id;
          this.route.navigate(["/createAnalisys/1/", selected.id]);
        }
      }
    }
  }
  private getView(): HeaderStatus[] {
    let roleName = this.globals.diagnosticaRoleName;

    if (this.url === "diagnostica") {
      this.title = "HOME DIAGNÓSTICA";
      this.isDiagnostica = true;
    }
    if (this.url === "datar") {
      this.title = "HOME DATAR";
      this.isDatar = true;
    }
    if (this.url === "laboratory") {
      this.title = "HOME LABORATORIO";
      this.isLab = true;
    }
    var headers: HeaderStatus[] = [];

    if (this.isDiagnostica) {
      this.isDiagnostica = true;
      roleName = this.globals.diagnosticaRoleName;
      headers = [
        HeaderStatus.REGISTERED,
        HeaderStatus.RECEIVED,
        HeaderStatus.FINISHED,
        HeaderStatus.SENT,
      ];
    } else if (this.isDatar) {
      headers = [
        HeaderStatus.REGISTERED,
        HeaderStatus.ERRORED,
        HeaderStatus.FINISHED,
      ];
    } else if (this.isLab) {
      headers = [
        HeaderStatus.REGISTERED,
        HeaderStatus.SAMPLES_RECEIVED,
        HeaderStatus.REPORTS_RECEIVED,
      ];
    }
    this.roleName = roleName.toLowerCase();
    console.log("HEADERS", headers);
    return headers;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  generateMenuOption(
    code: string,
    title: string,
    url: string,
    nameFunction?: string,
    idItem?: string,
    validate?: boolean
  ): OveraDropDownMenuOption[] {
    let menuOption = new OveraDropDownMenuOption();
    if (url != null && url != "") {
      menuOption.title = title;
      menuOption.callBack = () => {
        this.route.navigate([url, code], { queryParams: { validate } });
      };
    } else {
      menuOption = new OveraDropDownMenuOption({
        title: title,
        callBack: () => {
          if (nameFunction && nameFunction === "archivedSample") {
            if (idItem) this.archivedSample(idItem);
          }
        },
      });
    }
    return [menuOption];
  }
  generateArrayMenuOptions(
    id: string,
    array: any[]
  ): OveraDropDownMenuOption[] {
    let list: OveraDropDownMenuOption[] = [];
    array.forEach((element) => {
      let menuOption = new OveraDropDownMenuOption();
      if (element.url != null && element.url != "") {
        menuOption.title = element.title;
        menuOption.callBack = () => {
          if (element.url.initial === "/validateSample/2") {
            console.log("validateSample", element);
            this.route.navigate(["/validateSample/2/", element.url.idSample]);
          } else if (element.url.initial === "delete") {
            console.log("delete", element);
            this.deleteAnalysis(element.url.idSample);
          } else if (element.url.initial === "archivedSample") {
            console.log("archive", element);
            this.archivedSample(element.url.idSample);
          } else {
            console.log("other", element);
            this.route.navigate([element.url.initial], {
              queryParams: {
                laboratoryId: element.url.laboratoryId || null,
                laboratoryBranchId: element.url.laboratoryBranchId || null,
                sampleId: element.url.idSample || null,
              },
            });
          }
        };
      } else {
        menuOption = new OveraDropDownMenuOption({
          title: element.title,
          callBack: () => {},
        });
      }
      list.push(menuOption);
    });
    return list;
  }
  generateIconsOptionsForActionGrid(
    id: string,
    arrayIcons: any[]
  ): OveraCardHeaderIconsOption[] {
    let arrayIconsSend: OveraCardHeaderIconsOption[] = [];
    arrayIcons.forEach((icon: any) => {
      let headerIcon = new OveraCardHeaderIconsOption();
      headerIcon.iconClass = icon.iconClass;
      headerIcon.color = icon.color;
      headerIcon.tooltip = icon.tooltip;
      if (headerIcon.iconClass === "pi-file") {
        headerIcon.iconClick = () => {
          console.log(icon);
          this.isIconClicked = true;
          this.storageService.downloadFile(icon.fileStorageId);
        };
      } else {
        headerIcon.iconClick = () => {};
      }
      arrayIconsSend.push(headerIcon);
    });
    // console.log("arrayIconsSend", arrayIconsSend);
    return arrayIconsSend;
  }

  deleteAnalysis(id: string) {
    let trmessage,
      messageAccept = "";
    this.translateService
      .get("COMMONS.ASK_DELETE_RECORD")
      .subscribe((a: string) => {
        trmessage = a;
      });
    this.translateService.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();

        if (id) {
          this.sampleService.delete(id).subscribe({
            next: () => {
              this.messagesService.showToast(
                "MESSAGES.SUCCESS.DELETE",
                "MESSAGES.SUCCESS.DELETE",
                "success",
                "HEADER_TITLE.HEADER_TITLE"
              );
              this.spinner.hide("spinnerListShippingLabel");
              this.ngOnInit();
            },
            error: (err) => {
              console.log(err);
              this.spinner.hide();
            },
          });
        }
      },
      reject: () => {},
    });
  }
  changePercentageWithFieldIsMaxAllowedInvoiceNumberExceeded(
    item: any,
    isMaxAllowedInvoiceNumberExceeded: boolean
  ) {
    if (isMaxAllowedInvoiceNumberExceeded) {
      item.percentage = 75;
      item.percentageIsCritic = true;
    }
    return item;
  }
  archivedSample(idItem: string) {
    let trmessage,
      messageAccept = "";
    this.translateService
      .get("COMMONS.ASK_ARCHIVE_SAMPLE")
      .subscribe((a: string) => {
        trmessage = a;
      });
    this.translateService.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();

        this.sampleService.archiveAsync(idItem).subscribe({
          next: () => {
            this.messagesService.showToast(
              "MESSAGES.SUCCESS.UPDATE",
              "MESSAGES.SUCCESS.UPDATE_LONG",
              "success",
              "",
              ""
            );
            this.ngOnInit();
            this.spinner.hide();
          },
          error: (err) => {
            console.error(err);
            this.messagesService.showToast(
              "ERROR.CRITICAL",
              "ERROR",
              "error",
              "",
              ""
            );
            this.spinner.hide();
          },
        });
      },
      reject: () => {},
    });
  }
  onSearchTextChange(searchText: any) {
    if (searchText) {
      searchText = searchText.toLowerCase();
      this.itemsWithColumns = [];

      let filters = [...this.copyItemsWithColumns];
      console.log(filters);
      let newfilters = filters.map((column) => {
        const filteredItems = [];
        for (const item of column.items) {
          if (
            item.id?.toString().toLowerCase().includes(searchText) ||
            item.fields.some((field) =>
              field.value?.toString().toLowerCase().includes(searchText)
            )
          ) {
            filteredItems.push(item);
          }
        }
        return { ...column, items: filteredItems };
      });

      this.itemsWithColumns = newfilters;
    } else {
      this.itemsWithColumns = [...this.copyItemsWithColumns];
    }
  }

  private menuFieldsInvoicePayment(
    matchingItemField: any,
    summaryFields: any
  ): any {
    let copySummaryFields = { ...summaryFields };
    if (matchingItemField.billingState === BillingState.PAID) {
      delete copySummaryFields["ASSOCIATE_INVOICE"];
      delete copySummaryFields["ASSOCIATE_PAYMENT"];
    } else if (matchingItemField.billingState === BillingState.BILLED) {
      delete copySummaryFields["ASSOCIATE_INVOICE"];
    }
    delete copySummaryFields["MARK_ERROR"];
    return copySummaryFields;
  }

  private menuFieldsError(
    item: OveraCardItem,
    matchingItemField: any,
    summaryFields: any
  ): any {
    let copySummaryFields = { ...summaryFields };
    if (
      matchingItemField.deliveryState === SampleState.DATARRECEIVEDWITHERROR
    ) {
      item.percentage = 100;
      item.percentageIsCritic = true;
      delete copySummaryFields["ASSOCIATE_REPORT"];
      delete copySummaryFields["ASSOCIATE_INVOICE"];
      delete copySummaryFields["ASSOCIATE_PAYMENT"];
    } else if (matchingItemField.deliveryState == SampleState.DATARRECEIVED) {
      delete copySummaryFields["REMOVE_ERROR"];
    }
    return copySummaryFields;
  }
}
