<p class="onoff">
  <input *ngIf="!controlName"
         type="checkbox"
         id="cb-{{ id }}"
         [disabled]="isDisabled"
         [(ngModel)]="checked"
         value="1"
         (change)="onCheckboxChange()" />
  <ng-container *ngIf="controlName && formGroup" [formGroup]="formGroup">
    <input type="checkbox"
           id="cb-{{ id }}"
           [disabled]="isDisabled"
           [formControlName]="controlName"
           value="1"
           (change)="onCheckboxChange()" />
  </ng-container>
  <label for="cb-{{ id }}"></label>
</p>
