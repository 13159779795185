import { Injectable } from "@angular/core";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
import { Config } from "@overa/security";
import { Observable } from "rxjs";
import {
  CreateDelivery,
  Delivery,
  DeliverySummaryField,
} from "../models/delivery.model";

@Injectable({ providedIn: "root" })
export class DeliveryService extends OveraBaseService<Delivery> {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/delivery";
  }
  createDelivery(createDelivery: CreateDelivery) {
    const endpointUrl = `${this.baseUrl}/create`;
    return this.http.post<Delivery>(endpointUrl, createDelivery);
  }
  getDeliverySummary(deliveryId: string) {
    const endpointUrl = `${this.baseUrl}/summary/${deliveryId}`;
    return this.http.get<DeliverySummaryField[]>(endpointUrl);
  }
}
