import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import {
  APP_INITIALIZER,
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  Injectable,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

import {
  AuthService,
  AccountService,
  BreadcrumbModule,
  MenuService,
  Menu,
  Permissions,
  UsersSelectModule,
} from "@overa/security";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  SidebarModule,
  FooterModule,
  FixedpluginModule,
  IconsModule,
  ComponentLoaderModule,
  ConfigBase,
} from "@overa/base";
import { VideoChatModule } from "@overa/video";

import { AuthInterceptor } from "@overa/security";
import { TenantInterceptor } from "@overa/security";
import { UserInterceptor } from "@overa/security";

import { TestComponent } from "./components/test/test.component";
import { TestFormComponent } from "./components/test/test-form/test-form.component";
import { TestListComponent } from "./components/test/test-list/test-list.component";

//import { OveraCardGridModule, OveraStepperModule } from "@overa/components";

import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from "@ngx-translate/core";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastrModule } from "ngx-toastr";

import { NgPipesModule } from "ngx-pipes";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxSpinnerModule } from "ngx-spinner";

import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

import { Config } from "@overa/security";
import { ConfigBpm } from "@overa/bpm";
import { ConfigNotification } from "@overa/notification";
import { ConfigCalendar } from "@overa/calendar";
import { ConfigDashboard } from "@overa/dashboard";
import { ConfigStorage, FileuploadModule } from "@overa/storage";

import { ConfigShared, MaterialModule, SafePipeModule } from "@overa/shared";

import { PushMiddlewareService } from "@overa/notification";
import { LayoutComponent } from "./components/layout.component";
import { setTranslations } from "./translate.service";

import { ConfigApiDynamic } from "@overa/dynamic";
import { OveraComponentsDynamicModule } from "@overa/dynamic";
import {
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OwlDateTimeIntl,
} from "@danielmoncada/angular-datetime-picker";
import { AppPermissions } from "./models/app-permission.model";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { Globals, UserLaboratory } from "./utils/globals";
import { Utils } from "./utils/utils";
import { BaseStepsComponent } from "./base-steps/base-steps.component";
import { NewAnalysisComponent } from "./base-steps/new-analysis/new-analysis.component";
import { OveraInputComponent } from "./shared/temporal_components/overa-input/overa-input.component";
import { OveraDropdownComponent } from "./shared/temporal_components/overa-dropdown/overa-dropdown.component";
import { CalendarModule as PrimeCalendarModule } from "primeng/calendar";
import { CalendarModule } from "@overa/calendar";
import { DropdownModule } from "primeng/dropdown";
import { OveraDatepickerComponent } from "./shared/temporal_components/overa-datepicker/overa-datepicker.component";
import { ProjectGridComponent } from "./components/project-grid/project-grid.component";
import { OveraCardGridModule } from "./shared/temporal_components/overa-card-grid/overa-card-grid.module";
// import { AutocompleteModule } from "@overa/components";
import { LaboratoryListComponent } from "./components/maintenance/laboratory/laboratory-list/laboratory-list.component";
import { LaboratoryFormComponent } from "./components/maintenance/laboratory/laboratory-form/laboratory-form.component";
import { InputTextareaModule } from "primeng/inputtextarea";
import { InputTextModule } from "primeng/inputtext";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { DialogModule } from "primeng/dialog";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { LaboratoryBranchListComponent } from "./components/maintenance/campus/laboratory-branch-list/laboratory-branch-list.component";
import { LaboratoryBranchFormComponent } from "./components/maintenance/campus/laboratory-branch-form/laboratory-branch-form.component";
import { AnalysisTypeFormComponent } from "./components/maintenance/analysis-type/analysis-type-form/analysis-type-form.component";
import { AnalysisTypeListComponent } from "./components/maintenance/analysis-type/analysis-type-list/analysis-type-list.component";
import { OveraDataTableComponent } from "./shared/temporal_components/overa-datatable/overa-datatable.component";
import { TableModule } from "primeng/table";
import { TooltipModule } from "primeng/tooltip";
import { ButtonModule } from "primeng/button";
import { TabViewModule } from "primeng/tabview";
import { InputNumberModule } from "primeng/inputnumber";
import { OveraInputNumberComponent } from "./shared/temporal_components/overa-inputnumber/overa-inputnumber.component";
import { AnalysisSubtypeListComponent } from "./components/maintenance/analysis-subtype/analysis-subtype-list/analysis-subtype-list.component";
import { AnalysisSubtypeFormComponent } from "./components/maintenance/analysis-subtype/analysis-subtype-form/analysis-subtype-form.component";
import { OveraTextAreaComponent } from "./shared/temporal_components/overa-text-area/overa-text-area.component";
import { BillingTypeFormComponent } from "./components/maintenance/billing-type/billing-type-form/billing-type-form.component";
import { BillingTypeListComponent } from "./components/maintenance/billing-type/billing-type-list/billing-type-list.component";
import { OveraCheckboxComponent } from "./shared/temporal_components/overa-checkbox/overa-checkbox.component";
//import { NewAnalysisStep2Component } from "./base-steps/step2/step2.component";
//import { NewAnalysisStep3Component } from "./base-steps/step3/step3.component";
import { OveraUserDropdownComponent } from "./shared/temporal_components/overa-user-dropdown/overa-user-dropdown.component";
import { OveraInputEditorComponent } from "./shared/temporal_components/overa-input-editor/overa-input-editor.component";
import { EditorModule } from "primeng/editor";
import { OveraButtonComponent } from "./shared/temporal_components/overa-button/overa-button.component";
import { OveraStepperComponent } from "./shared/temporal_components/overa-stepper/overa-stepper.component";
import { AnalysisTypeLaboratoryBranchFormComponent } from "./components/maintenance/campus/analysis-type-laboratory-branch-form/analysis-type-laboratory-branch-form.component";
import { UsersListComponent } from "./components/maintenance/users/users-list/users-list.component";
import { UserFormComponent } from "./components/maintenance/users/user-form/user-form.component";
import { OveraAutoCompleteComponent } from "./shared/temporal_components/overa-autocomplete/overa-autocomplete.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ChipsModule } from "primeng/chips";
import { ListShippinglabelComponent } from "./components/maintenance/stock-shippinglabel/list-shippinglabel/list-shippinglabel.component";
import { FormShippinglabelComponent } from "./components/maintenance/stock-shippinglabel/form-shippinglabel/form-shippinglabel.component";
import { ListShippinglabelStockComponent } from "./components/maintenance/stock-shippinglabel/list-shippinglabel-stock/list-shippinglabel-stock.component";
import { LaboratoryBranchUserService } from "./services/laboratory-branch-user.service";
import { LaboratoryBranchUserLaboratories } from "./models/laboratory-branch-user-laboratories.model";
import { NewAnalysisPatientComponent } from "./base-steps/new-analysis-patient/new-analysis-patient.component";
import { PanelNoticesInstructionsComponent } from "./components/maintenance/panel-notices-instructions/panel-notices-instructions.component";
import { FieldsetModule } from "primeng/fieldset";
import { NewAnalysisDeliveryComponent } from "./base-steps/new-analysis-delivery/new-analysis-delivery.component";
import { InputSwitchModule } from "primeng/inputswitch";
import { HomeComponent } from "./components/home/home.component";
import { ValidateSampleStepsComponent } from "./components/validate-sample-steps/validate-sample-steps.component";
import { ValidateSampleCodeStep1Component } from "./components/validate-sample-steps/validate-sample-code-step1/validate-sample-code-step1.component";
import { ValidateSampleDataStep2Component } from "./components/validate-sample-steps/validate-sample-data-step2/validate-sample-data-step2.component";
import { BoxStockListComponent } from "./components/maintenance/box-stock/box-stock-list/box-stock-list.component";
import { BoxStockFormComponent } from "./components/maintenance/box-stock/box-stock-form/box-stock-form.component";
import { InvoicesStepsComponent } from "./components/maintenance/invoices-steps/invoices-steps.component";
import { InvoicesStep1SelectLaboratoryComponent } from "./components/maintenance/invoices-steps/invoices-step1-select-laboratory/invoices-step1-select-laboratory.component";
import { InvoicesStep2SelectLaboratoryBranchComponent } from "./components/maintenance/invoices-steps/invoices-step2-select-laboratory-branch/invoices-step2-select-laboratory-branch.component";
import { InvoicesStep3SummaryComponent } from "./components/maintenance/invoices-steps/invoices-step3-summary/invoices-step3-summary.component";
import { PaymentsStepsComponent } from "./components/maintenance/payments-steps/payments-steps.component";
import { PaymentsStep1Component } from "./components/maintenance/payments-steps/payments-step1/payments-step1.component";
import { PaymentsStep2Component } from "./components/maintenance/payments-steps/payments-step2/payments-step2.component";
import { PaymentsStep3Component } from "./components/maintenance/payments-steps/payments-step3/payments-step3.component";
import { DeliveryStepsComponent } from "./components/maintenance/delivery-steps/delivery-steps.component";
import { DeliveryStep1Component } from "./components/maintenance/delivery-steps/delivery-step1/delivery-step1.component";
import { DeliveryStep2Component } from "./components/maintenance/delivery-steps/delivery-step2/delivery-step2.component";
import { DeliveryStep3Component } from "./components/maintenance/delivery-steps/delivery-step3/delivery-step3.component";
import { ReportsComponent } from "./components/maintenance/reports/reports.component";
import { ReportFileComponent } from "./components/maintenance/reports/report-file/report-file.component";
import { ConfigFilemanager } from "@overa/filemanager";
import { FileUploadMaintenanceComponent } from "./components/maintenance/file-upload-maintenance/file-upload-maintenance.component";
import { OveraFilterTextComponent } from "./shared/temporal_components/overa-filter-text/overa-filter-text.component";
import { NavbarModule } from "./shared/temporal_components/overa-navbar/overa-navbar.module";
registerLocaleData(localeEs, "es");

// export class DefaultIntl2 extends OwlDateTimeIntl {
//   /** A label for the up second button (used by screen readers).  */
//   setBtnLabel: 'Select';
// }

@Injectable()
export class DefaultIntl2 extends OwlDateTimeIntl {
  /** A label for the up second button (used by screen readers).  */
  // override setBtnLabel: "Seleccionar";
}

export function initializeAuthService(
  http: HttpClient,
  authService: AuthService,
  accountService: AccountService,
  notificationMiddleware: PushMiddlewareService,
  translateService: TranslateService,
  menuService: MenuService,
  globals: Globals,
  laboratoryBranchUserService: LaboratoryBranchUserService
) {
  const defaultMenuMaintenanceIcon =
    "PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiAgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiAgdmlld0JveD0iMCAwIDQ4IDQ4IiAgc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48bGluZWFyR3JhZGllbnQgaWQ9IjlpSFhNdXZWN2JyU1g3aEZ0fnRzbmFfUmRwM0F5ZExGWTJBX2dyMSIgeDE9IjEyLjA2NiIgeDI9IjM0Ljg5MSIgeTE9Ii4wNjYiIHkyPSIyMi44OTEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBvZmZzZXQ9Ii4yMzciIHN0b3AtY29sb3I9IiMzYmM5ZjMiPjwvc3RvcD48c3RvcCBvZmZzZXQ9Ii44NSIgc3RvcC1jb2xvcj0iIzE1OTFkOCI+PC9zdG9wPjwvbGluZWFyR3JhZGllbnQ+PHBhdGggZmlsbD0idXJsKCM5aUhYTXV2VjdiclNYN2hGdH50c25hX1JkcDNBeWRMRlkyQV9ncjEpIiBkPSJNNDMsMTVINWMtMS4xLDAtMi0wLjktMi0ydi0yYzAtMS4xLDAuOS0yLDItMmgzOGMxLjEsMCwyLDAuOSwyLDJ2MkM0NSwxNC4xLDQ0LjEsMTUsNDMsMTV6Ij48L3BhdGg+PGxpbmVhckdyYWRpZW50IGlkPSI5aUhYTXV2VjdiclNYN2hGdH50c25iX1JkcDNBeWRMRlkyQV9ncjIiIHgxPSIxMi4wNjYiIHgyPSIzNC44OTEiIHkxPSIxMi4wNjYiIHkyPSIzNC44OTEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBvZmZzZXQ9Ii4yMzciIHN0b3AtY29sb3I9IiMzYmM5ZjMiPjwvc3RvcD48c3RvcCBvZmZzZXQ9Ii44NSIgc3RvcC1jb2xvcj0iIzE1OTFkOCI+PC9zdG9wPjwvbGluZWFyR3JhZGllbnQ+PHBhdGggZmlsbD0idXJsKCM5aUhYTXV2VjdiclNYN2hGdH50c25iX1JkcDNBeWRMRlkyQV9ncjIpIiBkPSJNNDMsMjdINWMtMS4xLDAtMi0wLjktMi0ydi0yYzAtMS4xLDAuOS0yLDItMmgzOGMxLjEsMCwyLDAuOSwyLDJ2MkM0NSwyNi4xLDQ0LjEsMjcsNDMsMjd6Ij48L3BhdGg+PGxpbmVhckdyYWRpZW50IGlkPSI5aUhYTXV2VjdiclNYN2hGdH50c25jX1JkcDNBeWRMRlkyQV9ncjMiIHgxPSIxMi4wNjYiIHgyPSIzNC44OTEiIHkxPSIyNC4wNjYiIHkyPSI0Ni44OTEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBvZmZzZXQ9Ii4yMzciIHN0b3AtY29sb3I9IiMzYmM5ZjMiPjwvc3RvcD48c3RvcCBvZmZzZXQ9Ii44NSIgc3RvcC1jb2xvcj0iIzE1OTFkOCI+PC9zdG9wPjwvbGluZWFyR3JhZGllbnQ+PHBhdGggZmlsbD0idXJsKCM5aUhYTXV2VjdiclNYN2hGdH50c25jX1JkcDNBeWRMRlkyQV9ncjMpIiBkPSJNNDMsMzlINWMtMS4xLDAtMi0wLjktMi0ydi0yYzAtMS4xLDAuOS0yLDItMmgzOGMxLjEsMCwyLDAuOSwyLDJ2MkM0NSwzOC4xLDQ0LjEsMzksNDMsMzl6Ij48L3BhdGg+PC9zdmc+";

  return () =>
    new Promise<void>((resolve) => {
      const baseURI = document.baseURI.endsWith("/")
        ? document.baseURI
        : `${document.baseURI}/`;
      const url = `${baseURI}Home/Configuration`;

      http
        .get<any>(url)
        .toPromise()
        .then(async (data) => {
          Config.scopes = `${data.scopes}`;
          Config.identityBaseUrl = `${data.identityApiUrl}`;
          // Config.identityBaseUrl = 'https://localhost:5105';
          Config.apiBaseUrl = `${data.baseApiUrl}`;

          Config.baseHref =
            data.path_Base === null || data.path_Base === undefined
              ? ""
              : data.path_Base; // (environment.production ? '' : '');

          Config.extraQueryParams = {
            Tenant: "main",
          };

          Config.permissionsClass = AppPermissions;

          ConfigNotification.notificationBaseUrl = data.notificationApiUrl
            ? `${data.notificationApiUrl}`
            : "";
          ConfigNotification.pushServerPublicKey = data.pushServerPublicKey
            ? data.pushServerPublicKey
            : "";
          ConfigBpm.tasksBaseUrl = data.bpmnApiUrl ? `${data.bpmnApiUrl}` : "";
          ConfigCalendar.calendarBaseUrl = data.calendarApiUrl
            ? `${data.calendarApiUrl}`
            : "";
          ConfigDashboard.dashboardBaseUrl = data.dashboardApiUrl
            ? `${data.dashboardApiUrl}`
            : "";
          ConfigStorage.storageId = `${data.storageId}`;
          ConfigStorage.storageBaseUrl = data.storageApiUrl
            ? data.storageApiUrl
            : "";

          ConfigApiDynamic.configApiDynamic = data.baseApiUrl
            ? `${data.baseApiUrl}`
            : "";

          ConfigShared.appConfig = {
            useNewDesign: true,
            useGoldenLayout: false,
            allwaysNewTabOnGL: true,
            newTabWithCtrlClick: false,
          };
          // ConfigStorage.storageId = data.storageId ? `${data.storageId}` : "";
          authService.setupUserManager(
            (): void => {
              accountService.userPermissionsAndRolesLoaded.subscribe((_) => {
                setTranslations(translateService, accountService);
                menuService.loadMenu();

                menuService
                  .getMenuByUser(accountService.currentUser.id)
                  .subscribe({
                    next: (menuLinks) => {
                      //console.log(menuLinks);
                      let menuItems = menuLinks;

                      var hasMenuAccess =
                        accountService.currentUser.isGlobalAdmin;

                      if (
                        hasMenuAccess &&
                        !menuItems
                          .map((x) => x.uiUrl)
                          .includes("/core/security/menu")
                      ) {
                        const menuItem = new Menu();
                        menuItem.uiUrl = "/core/security/menu";
                        menuItem.name = "Menu";
                        menuItem.svg = defaultMenuMaintenanceIcon;
                        menuItem.toolTip = "Menu";

                        menuItems.push(menuItem);
                      }

                      let menuViews = menuItems.filter(
                        (m) => m.children?.length == 0
                      );
                      ConfigBase.menuViews = menuViews;
                      //console.log("ConfigBase.menuViews->", ConfigBase.menuViews);

                      menuItems = menuItems.filter((x) => !x.parentId);

                      menuItems.forEach((x) => {
                        if (x.uiUrl) {
                          x.type = "link";
                        }
                        if (x.children && x.children.length) {
                          x.type = "sub";
                          x.collapse = "child" + x.id;
                        }
                      });
                      let finalItems: Menu[] = [];
                      menuItems.forEach((menu) => {
                        if (!finalItems.map((x) => x.id).includes(menu.id)) {
                          finalItems.push(menu);
                        }
                      });
                      menuItems = [...finalItems];
                      ConfigBase.menuItems = menuItems;
                      //console.log("ConfigBase.menuItems->", ConfigBase.menuItems);
                      globals.user = accountService.currentUser;
                      if (globals.user.id) {
                        laboratoryBranchUserService
                          .getLaboratoriesByIdentityUserId(globals.user.id)
                          .subscribe({
                            next: (item) => {
                              if (item) {
                                console.log("ITEMAPPMODULE", item);
                                let laboratoryBranchUserLaboratory =
                                  new LaboratoryBranchUserLaboratories();
                                laboratoryBranchUserLaboratory.laboratory =
                                  item.laboratory || undefined;
                                laboratoryBranchUserLaboratory.laboratoryBranches =
                                  item.laboratoryBranches || [];
                                let userlaboratory = new UserLaboratory();
                                userlaboratory.identityUserId = globals.user.id;
                                userlaboratory.laboratoryBranchUserLaboratories =
                                  laboratoryBranchUserLaboratory;
                                globals.userLaboratory = userlaboratory;
                              }
                              if (ConfigBase.menuItems) {
                                console.log("userPermissionsAndRolesLoaded");

                                menuService.menuLoaded.subscribe((__) => {
                                  setTranslations(
                                    translateService,
                                    accountService
                                  );
                                  resolve();
                                });
                              }
                            },
                            error: (err) => {
                              console.error(err);
                              resolve();
                            },
                          });
                      } else {
                        if (
                          ConfigBase.menuItems &&
                          ConfigBase.menuItems.length
                        ) {
                          console.log("userPermissionsAndRolesLoaded");

                          menuService.menuLoaded.subscribe((__) => {
                            setTranslations(translateService, accountService);
                            resolve();
                          });
                        }
                      }
                    },
                    error: (msg) => {
                      console.error(msg);
                    },
                  });
              });
              accountService.setupTenantChangeObservable();
            },
            (error): void => {
              console.log("APPMODULE. AUTH ERROR: ", error);
              setTranslations(translateService, accountService);
              if (
                window.location.href.toString().indexOf("login-redirect") < 0 &&
                window.location.href.toString().indexOf("silent-renew") < 0
              ) {
                authService.login("/").finally();
              } else {
                resolve();
              }
            }
          );
        });
    });
}

export function ngxSpinnerConfig() {
  return {
    type: "line-scale-pulse-out",
    size: "default",
    color: "#e1e1e2",
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    TestComponent,
    TestFormComponent,
    TestListComponent,
    BaseStepsComponent,
    NewAnalysisComponent,
    //NewAnalysisStep2Component,
    //NewAnalysisStep3Component,
    ProjectGridComponent,
    LaboratoryListComponent,
    LaboratoryFormComponent,
    LaboratoryBranchListComponent,
    LaboratoryBranchFormComponent,
    AnalysisTypeFormComponent,
    AnalysisTypeListComponent,
    AnalysisSubtypeFormComponent,
    AnalysisSubtypeListComponent,
    BillingTypeFormComponent,
    BillingTypeListComponent,
    OveraInputComponent,
    OveraDropdownComponent,
    OveraDatepickerComponent,
    OveraDataTableComponent,
    OveraInputNumberComponent,
    OveraTextAreaComponent,
    OveraCheckboxComponent,
    OveraUserDropdownComponent,
    OveraInputEditorComponent,
    OveraButtonComponent,
    OveraStepperComponent,
    OveraFilterTextComponent,
    AnalysisTypeLaboratoryBranchFormComponent,
    UsersListComponent,
    UserFormComponent,
    OveraAutoCompleteComponent,
    ListShippinglabelComponent,
    FormShippinglabelComponent,
    ListShippinglabelStockComponent,
    NewAnalysisPatientComponent,
    PanelNoticesInstructionsComponent,
    NewAnalysisDeliveryComponent,
    HomeComponent,
    ValidateSampleStepsComponent,
    ValidateSampleCodeStep1Component,
    ValidateSampleDataStep2Component,
    BoxStockListComponent,
    BoxStockFormComponent,
    InvoicesStepsComponent,
    InvoicesStep1SelectLaboratoryComponent,
    InvoicesStep2SelectLaboratoryBranchComponent,
    InvoicesStep3SummaryComponent,
    PaymentsStepsComponent,
    PaymentsStep1Component,
    PaymentsStep2Component,
    PaymentsStep3Component,
    DeliveryStepsComponent,
    DeliveryStep1Component,
    DeliveryStep2Component,
    DeliveryStep3Component,
    ReportsComponent,
    ReportFileComponent,
    FileUploadMaintenanceComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgPipesModule,
    BreadcrumbModule,
    ReactiveFormsModule,
    FooterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MaterialModule,
    NgxSpinnerModule,
    TooltipModule,
    CalendarModule,
    SidebarModule,
    NavbarModule,
    OveraComponentsDynamicModule,
    ComponentLoaderModule,
    TableModule,
    FixedpluginModule,
    MaterialModule,
    BrowserAnimationsModule,
    InputTextareaModule,
    PrimeCalendarModule,
    DropdownModule,
    DialogModule,
    VideoChatModule,
    FlexLayoutModule,
    ConfirmDialogModule,
    OveraCardGridModule,
    VirtualScrollerModule,
    NgxSpinnerModule,
    UsersSelectModule,
    TabViewModule,
    ButtonModule,
    InputTextModule,
    EditorModule,
    InputNumberModule,
    AutoCompleteModule,
    ChipsModule,
    FieldsetModule,
    SafePipeModule,
    InputSwitchModule,
    FileuploadModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: "es",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
      timeOut: 5000,
      enableHtml: true,
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerImmediately",
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuthService,
      deps: [
        HttpClient,
        AuthService,
        AccountService,
        TranslateService,
        PushMiddlewareService,
        MenuService,
        Globals,
        LaboratoryBranchUserService,
      ],
      multi: true,
    },
    Globals,
    Utils,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserInterceptor, multi: true },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl2 },
    { provide: LOCALE_ID, useValue: "es-ES" },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {
  constructor(translate: TranslateService, accountService: AccountService) {
    translate.addLangs(["en", "es"]);

    setTranslations(translate, accountService);
  }
}
