<ngx-spinner
  bdColor="rgba(42,55,84,0.4)"
  size="default"
  color="#e1e1e2"
  type="line-scale-pulse-out"
  [fullScreen]="false"
  name="validateSampleStep1"
>
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="container-step p-4 mb-2">
  <div class="form-step">
    <div class="row">
      <div class="col-12 container-center">
        <overa-input
          [title]="'VALIDATE_SAMPLE_STEP1.TITLE_INPUT_CODE' | translate"
          [isDisabled]="false"
          [(value)]="code"
          [isRequired]="true"
        ></overa-input>
      </div>
    </div>
  </div>
  <div>
    <button
      class="btn-continuar"
      (click)="next()"
      [disabled]="code != '' ? false : true"
    >
      {{ "ACTION.CONTINUE" | translate }} <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>
