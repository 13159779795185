<overa-dropdown
  [options]="users"
  [title]="title"
  [isRequired]="isRequired"
  [isDisabled]="isDisabled"
  [optionLabel]="'fullName'"
  [value]="selectedUser"
  (valueChange)="selectUser($event)"
  (dropdownShow)="loadUsers()"
></overa-dropdown>
