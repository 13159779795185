<div class="circular-progress">
  <svg [attr.viewBox]="'0 0 ' + (radius * 2 + strokeWidth) + ' ' + (radius * 2 + strokeWidth)" [ngStyle]="{'width': size + 'px', 'height': size + 'px'}">
    <path class="circle-bg"
          [attr.d]="getPath()"
          [attr.stroke]=incompletedColor
          [attr.stroke-width]="strokeWidth"
          fill-opacity="0"
    />
    <path class="circle"
          [attr.d]="getPath()"
          [attr.stroke]=completedColor
          [attr.stroke-width]="strokeWidth"
          fill-opacity="0"
          [attr.stroke-dasharray]="circumference + ' ' + circumference"
          [attr.stroke-dashoffset]="dashoffset"
    />
    <text [attr.x]="radius + strokeWidth / 2" [attr.y]="radius + strokeWidth / 2 + fontSize / 3" class="percentage" text-anchor="middle" [ngStyle]="{'font-size': fontSize + 'px', 'fill': textColor}">{{ percent }}%</text>
  </svg>
</div>
