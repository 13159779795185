import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "@overa/security";
import { Observable, ReplaySubject } from "rxjs";
import { PaginatedResponse } from "../models/paginatedResponse.model";
import { ProjectCardViewModel } from "../models/project-card-viewmodel.model";
import { ProjectCardSearchDTO } from "../models/project-filters.model";
import { ProjectDTO } from "../models/projectDTO.model";
import { ProjectViewModel } from "../models/project-viewmodel.model";

@Injectable({ providedIn: "root" })
export class ProjectService {
  private projectSubject: ReplaySubject<ProjectDTO[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public projectObservable: Observable<ProjectDTO[]> = this.projectSubject.asObservable();

  private routeProjectSubject: ReplaySubject<string> = new ReplaySubject(1);
  public routeProjectObservable: Observable<string> =
    this.routeProjectSubject.asObservable();

  get baseUrl() {
    return Config.apiBaseUrl + "/api/Projects";
  }

  constructor(private http: HttpClient) {}

  getSearchProjectCards(
    searchDTO?: ProjectCardSearchDTO
  ): Observable<PaginatedResponse<ProjectCardViewModel>> {
    let params = new HttpParams();
    if (searchDTO) {
      console.log("searchDTO", searchDTO);
      if (searchDTO.textFilter)
        params = params.set("textFilter", searchDTO.textFilter);
      if (searchDTO.customerCodes && !Array.isArray(searchDTO.customerCodes))
        params = params.append("customerCodes", searchDTO.customerCodes);
      if (searchDTO.budgetCodes)
        searchDTO.budgetCodes.forEach(
          (code) => (params = params.append("budgetCodes", code))
        );
      if (searchDTO.categoryIds)
        searchDTO.categoryIds.forEach(
          (id) => (params = params.append("categoryIds", id.toString()))
        );
      if (searchDTO.fromDate)
        params = params.set("fromDate", searchDTO.fromDate.toISOString());
      if (searchDTO.toDate)
        params = params.set("toDate", searchDTO.toDate.toISOString());
    }
    console.log("params", params);
    return this.http.get<PaginatedResponse<ProjectCardViewModel>>(
      `${this.baseUrl}/CardsView`,
      {
        params,
      }
    );
  }

  getProjectById(projectId: string): Observable<ProjectViewModel> {
    return this.http.get<ProjectViewModel>(`${this.baseUrl}/${projectId}`, {});
  }

  //HACK: to generatre a guid, we need a external library for it
  generateGUID(): string {
    //NOSONAR
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  createProject(project: ProjectDTO): Observable<ProjectDTO> {
    let requestId = this.generateGUID(); //TODO: create a new GUID to pass;
    let headers = new HttpHeaders().set("x-requestid", requestId);

    return this.http.post<ProjectDTO>(`${this.baseUrl}`, project, { headers });
  }

  setProjectStatePlotPointLevel(
    projectId: string,
    statePlotPointLevelId: string
  ): Observable<any> {
    let requestId = this.generateGUID(); //TODO: create a new GUID to pass;
    const url = `${this.baseUrl}/${projectId}/StatePlotPointLevel`;
    const headers = new HttpHeaders()
      .set("x-requestid", requestId)
      .set("content-Type", "application/json");
    return this.http.post(url, `"${statePlotPointLevelId}"`, { headers });
  }

  unsetProjectStatePlotPointLevel(
    projectId: string,
    statePlotPointLevelId: string
  ): Observable<any> {
    let requestId = this.generateGUID(); //TODO: create a new GUID to pass;
    const url = `${this.baseUrl}/${projectId}/StatePlotPointLevel`;
    const headers = new HttpHeaders()
      .set("x-requestid", requestId)
      .set("content-Type", "application/json");
    const options = {
      headers: headers,
      body: statePlotPointLevelId,
    };
    return this.http.delete(url, options);
  }

  notifyupdate(projects: ProjectDTO[]) {
    this.projectSubject.next(projects);
  }

  notifyupdateRoute(id: string) {
    this.routeProjectSubject.next(id);
  }
}
