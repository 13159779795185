<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerListBoxStock">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="row row-with-title">
      <div class="col-6 title-view">
        <h4 class="card-title title-inside-steps-h4">
          {{ "BOX_STOCK_LIST.TITLE" | translate }}
        </h4>
      </div>
      <div class="col-6 filter-text-div">
        <overa-filter-text (searchChangeKeyPressEvent)="onSearchTextChange($event)"
          (searchChangeClickEvent)="onSearchTextChange($event)" [styleClass]="filterClass"></overa-filter-text>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="card-content">
      <div class="row">
        <div class="col-1">
          <div class="d-flex flex-row justify-content-right"></div>
        </div>
      </div>
      <div *ngIf="showTable">
        <overa-datatable #datatableShippingLabel [columns]="columns" [showPaginator]="true" [rowsPerPage]="[10, 20]"
          [items]="listStockBox" [totalRecords]="totalRecords">
        </overa-datatable>
      </div>
    </div>
  </div>
</div>