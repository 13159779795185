export class Test {
  id?: string;
  code?: string;
  description?: string;

  constructor(obj?: {
    id?: string;
    code?: string;
    description?: string;
  }) {
    this.id = (obj && obj.id) || undefined;
    this.code = (obj && obj.code) || undefined;
    this.description = (obj && obj.description) || undefined;
  }
}
