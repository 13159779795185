import { DocumentLanguage } from "./document-api.model";

export class SampleReport {
  id?: string;
  code?: string;
  sampleDate?: Date;
  testTypeName?: string;
  laboratoryBranchName?: string;
  reportFileName?: string;
  documentLanguages?: DocumentLanguage;
  reportStorageId?: string;
  withFile?: boolean;
  constructor() {}
}
