import { Validators } from "@angular/forms";

export const EmailValidator = Validators.pattern(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
export const PhoneValidator = Validators.pattern(/^\+?(?:[0-9] ?){6,14}[0-9]$/);
export const WebValidator = Validators.pattern(
  /^(www.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
);
export const CifValidator = Validators.pattern(
  /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
);
export const AccountValidator = Validators.pattern(
  /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[a-zA-Z0-9]{7,23}$/
);
export const DniValidator = Validators.pattern(
  /^([0-9]{8}[A-Z])|[XYZ][0-9]{7}[A-Z]$/
);
