import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { CreateInvoice } from "src/app/models/invoice.model";
import { NoticePanel } from "src/app/models/notice-panel.model";
import { SampleInvoice } from "src/app/models/sample-invoice.model";
import { InvoiceService } from "src/app/services/invoice.service";
import { SampleService } from "src/app/services/sample.service";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";
import { ConfigStorage } from "@overa/storage";

@Component({
  selector: "app-invoices-step2-select-laboratory-branch",
  templateUrl: "./invoices-step2-select-laboratory-branch.component.html",
  styleUrls: ["./invoices-step2-select-laboratory-branch.component.scss"],
})
export class InvoicesStep2SelectLaboratoryBranchComponent
  implements OnInit, OnDestroy
{
  laboratoryRoute?: any;
  laboratoryBranchRoute?: any;
  invoiceIdRoute?: any;
  listSampleInvoices?: SampleInvoice[] = [];
  copyListSampleInvoices?: SampleInvoice[] = [];
  showTable?: boolean = false;
  columns: DataTableColumn[] = [];
  totalRecords: number = 0;
  listNoticePanel: NoticePanel[] = [];
  titlePanel: string = "STEP1.NOTICE.ALERT";
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  listIdSamples: string[] = [];
  storageId = ConfigStorage.storageId;
  fileId = "";
  fileName = "";
  sampleId = "";
  showButtonHome: boolean = false;
  filterClass: string = "";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService,
    private cd: ChangeDetectorRef,
    private sampleService: SampleService,
    private invoiceService: InvoiceService,
    private cdr: ChangeDetectorRef
  ) {
    this.filterClass = "custom-filter";
  }

  ngOnInit() {
    this.spinner.show("invoicesStep2");
    this.resetVariables();
    this.route.queryParams.subscribe({
      next: (params) => {
        console.log(params);
        this.laboratoryRoute = params["laboratoryId"];
        this.laboratoryBranchRoute = params["laboratoryBranchId"];
        this.sampleId = params["sampleId"] || "";
        // this.invoiceIdRoute = params["invoiceId"];
        console.log(
          "LABORATORYS",
          this.laboratoryBranchRoute,
          this.laboratoryRoute
        );

        let subscription;
        if (this.laboratoryBranchRoute) {
          subscription =
            this.sampleService.getPendingSamplesByLaboratoryBranchId(
              this.laboratoryBranchRoute
            );
        } else {
          subscription = this.sampleService.getPendingSamplesByLaboratoryId(
            this.laboratoryRoute
          );
        }
        subscription.subscribe({
          next: (items) => {
            this.listSampleInvoices = items ? items : [];

            this.createForm();
            this.columns = this.createColumns();
            let filteredList;
            if (this.listSampleInvoices.length > 0) {
              // this.assignedFunctionsTable();
              this.listSampleInvoices.forEach((item) => {
                item.selectSampleInvoice = false;
              });
              if (this.sampleId !== "" && this.sampleId !== null) {
                this.listSampleInvoices = this.listSampleInvoices.filter(
                  (item) => item.id === this.sampleId
                );
              }
            }
            this.totalRecords = this.listSampleInvoices.length;
            this.assignedNoticePanel();
            this.copyListSampleInvoices = [...this.listSampleInvoices];
            this.showTable = true;
            this.loadedForm = true;
            this.spinner.hide("invoicesStep2");
            if (
              this.sampleId !== "" &&
              this.sampleId !== null &&
              this.listSampleInvoices.length < 1
            ) {
              this.showButtonHome = true;
              this.messagesService.showToast(
                "MESSAGES.INFO.INFO",
                "INVOICES_STEP2.MESSAGE_EMPTY_ELEMENT",
                "info",
                ""
              );
            }
          },
          error: (err) => {
            console.error(err);
            this.spinner.show("invoicesStep2");
          },
        });
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("invoicesStep2");
      },
    });
  }
  resetVariables() {
    this.listSampleInvoices = [];
    this.loadedForm = false;
    this.showTable = false;
    this.listIdSamples = [];
    this.showButtonHome = false;
  }
  assignedNoticePanel() {
    let notice = new NoticePanel();
    notice.text = this.translateService.instant(
      "INVOICES_STEP2.NOTICE_PANEL_1"
    );
    this.listNoticePanel.push(notice);
  }
  onAddFile(event: any) {
    if (event?.file?.id) {
      this.fileId = event.file.id;
      this.fileName = event.file.displayName;
    }
  }

  fileDeleted(event: any) {
    console.log("DELETE FILE: ", event);
  }

  // assignedFunctionsTable() {
  //   let itemsTemp: any[] = [];

  //   if (this.listSampleInvoices && this.listSampleInvoices.length > 0) {
  //     const changeValueselectSampleInvoice = (index: any) => {
  //       if (this.listSampleInvoices && this.listSampleInvoices[index]) {
  //         this.changeSelect(this.listSampleInvoices[index]);
  //       }
  //     };
  //     this.listSampleInvoices.forEach((element: any) => {
  //       element.selectSampleInvoice = {
  //         value: false, //TODO: add value from object
  //         command: changeValueselectSampleInvoice,
  //       };
  //       itemsTemp.push(element);
  //     });
  //     this.listSampleInvoices = [...itemsTemp];
  //   }
  // }
  changeSelect(element: any) {
    console.log("ELEMENT", element);
  }
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "code",
        header: this.translateService.instant("COMMON_FORMS.CODE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "testTypeName",
        header: this.translateService.instant("COMMON_FORMS.TYPE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "sampleDate",
        header: this.translateService.instant("COMMON_FORMS.DATE_SAMPLE"),
        type: "System.DateTime",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "testTypePrice",
        header: this.translateService.instant("COMMON_FORMS.AMOUNT"),
        type: "System.Number",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "laboratoryBranchName",
        header: this.translateService.instant("COMMON_FORMS.LABORATORYBRANCH"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "selectSampleInvoice",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("ACTION.SELECT"),
        type: "System.Boolean",
        command: (index: number) => {
          if (
            index &&
            this.listSampleInvoices &&
            this.listSampleInvoices[index] &&
            this.listSampleInvoices[index].selectSampleInvoice
          ) {
            this.listSampleInvoices[index].selectSampleInvoice =
              !this.listSampleInvoices[index].selectSampleInvoice;
          }
        },
      },
    ];
  }
  createForm() {
    this.form = new FormGroup({
      invoiceNumber: new FormControl<string>("", [Validators.required]),
      file: new FormControl<any>(null, [Validators.required]),
    });
  }
  next() {
    console.log(this.listSampleInvoices);
    if (this.listSampleInvoices && this.listSampleInvoices.length > 0) {
      let thereAreSelect = false;
      this.listSampleInvoices.forEach((invoice) => {
        if (invoice.selectSampleInvoice === true) {
          thereAreSelect = true;
        }
      });

      if (thereAreSelect && this.form.valid) {
        this.listIdSamples = [];
        this.listSampleInvoices.forEach((invoice) => {
          if (
            invoice.selectSampleInvoice === true &&
            invoice.id !== null &&
            invoice.id !== undefined
          ) {
            this.listIdSamples.push(invoice.id);
          }
        });

        console.log("NEW LIST ID", this.listIdSamples);

        let createInvoiceNew = new CreateInvoice();
        createInvoiceNew.invoiceNumber = this.form.value.invoiceNumber;
        createInvoiceNew.sampleIdList = this.listIdSamples;
        createInvoiceNew.invoiceStorageId = this.fileId;
        createInvoiceNew.fileName = this.fileName;
        console.log("NEW CREATE INVOICE", createInvoiceNew);
        this.spinner.show("invoicesStep2");
        this.invoiceService.createInvoice(createInvoiceNew).subscribe({
          next: (item) => {
            console.log(item);
            this.spinner.hide("invoicesStep2");
            this.router.navigate(["/invoices/3"], {
              queryParams: {
                laboratoryId: this.laboratoryRoute,
                laboratoryBranchId: this.laboratoryBranchRoute,
                invoiceId: item.id,
              },
            });
          },
          error: (err) => {
            console.log(err);
            this.spinner.hide("invoicesStep2");
          },
        });
      } else {
        if (!thereAreSelect) {
          this.spinner.hide("invoicesStep2");
          this.messagesService.showToast(
            "MESSAGES.INFO.INFO",
            "INVOICES_STEP2.MESSAGE_EMPTY_LISTID",
            "info",
            ""
          );
        }
        if (!this.form.valid) {
          this.messagesService.showToast(
            "MESSAGES.INFO.INFO",
            "INVOICES_STEP2.MESSAGE_EMPTY_FORM",
            "info",
            ""
          );
        }
      }
    } else {
      this.messagesService.showToast(
        "MESSAGES.INFO.INFO",
        "INVOICES_STEP2.MESSAGE_EMPTY_LIST",
        "info",
        ""
      );
    }
  }
  goHome() {
    this.router.navigate(["/home"]);
  }
  ngOnDestroy() {}
  // back() {
  //   this.router.navigate(["/invoices/1"], {
  //     queryParams: {
  //       laboratoryId: this.laboratoryRoute,
  //       laboratoryBranchId: this.laboratoryBranchRoute,
  //       // invoiceId: this.invoiceIdRoute ? this.invoiceIdRoute : null,
  //     },
  //   });
  // }
  onSearchTextChange(searchText: any) {
    console.log(searchText);
    if (searchText) {
      searchText = searchText.toLowerCase();
      if (this.copyListSampleInvoices) {
        this.listSampleInvoices = [];
        let filters = [...this.copyListSampleInvoices];
        let newfilters = filters.filter((sampleInvoice) => {
          return (
            sampleInvoice.code
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            sampleInvoice.testTypeName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            sampleInvoice.sampleDate
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            sampleInvoice.testTypePrice
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            sampleInvoice.laboratoryBranchName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        this.listSampleInvoices = newfilters;
      }
    } else {
      if (this.copyListSampleInvoices)
        this.listSampleInvoices = [...this.copyListSampleInvoices];
    }
    if (this.listSampleInvoices && this.listSampleInvoices?.length > 0) {
      this.listSampleInvoices = [...this.listSampleInvoices];
    } else {
      this.listSampleInvoices = [];
    }
    this.listSampleInvoices = [...this.listSampleInvoices];
    console.log(this.listSampleInvoices);
    this.cdr.detectChanges();
  }
}
