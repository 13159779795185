import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "overa-filter-text",
  templateUrl: "./overa-filter-text.component.html",
  styleUrls: ["./overa-filter-text.component.scss"],
})
export class OveraFilterTextComponent {
  searchText: string = "";
  @Output() searchChangeInputEvent = new EventEmitter<string>();
  @Output() searchChangeKeyPressEvent = new EventEmitter<string>();
  @Output() searchChangeClickEvent = new EventEmitter<string>();

  @Input() styleClass: string = "";
  @Input() placeholder: string = "COMMONS.SEARCH_PLACEHOLDER_SHORT";
  @Input() iconRightSide: boolean = true;
  @Input() iconClass: string = "pi pi-filter-fill";
  onSearchTextInputChange() {
    this.searchChangeInputEvent.emit(this.searchText);
  }
  onSearchTextClickChange() {
    this.searchChangeClickEvent.emit(this.searchText);
  }
  onSearchTextKeyChange() {
    this.searchChangeKeyPressEvent.emit(this.searchText);
  }
}
