import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Laboratory } from "src/app/models/laboratory.model";
import { AppPermissions } from "src/app/models/app-permission.model";
import { LaboratoryService } from "src/app/services/laboratory.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  BaseListComponent,
  AccountService,
  SecurityStateManagementService,
  Hotkeys,
  ResponseNotificationService,
} from "@overa/security";
import { DataTableColumn } from "../../../../shared/temporal_components/models/dataTablecolumn.model";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { OveraDataTableComponent } from "../../../../shared/temporal_components/overa-datatable/overa-datatable.component";
import { LaboratoryFilter } from "../../../../models/budget-filter.model";

@Component({
  selector: "app-laboratory-list",
  templateUrl: "./laboratory-list.component.html",
  styleUrls: ["./laboratory-list.component.scss"],
})
export class LaboratoryListComponent implements OnInit, OnDestroy {
  static className = "LaboratoryListComponent";
  items: Laboratory[] = [];
  itemsTemp: Laboratory[] = [];
  allItems: Laboratory[] = [];
  filter: string = "";
  filters: LaboratoryFilter = new LaboratoryFilter();
  @ViewChild("datatable")
  dataTable?: OveraDataTableComponent<any>;
  currentPageIndex: number = 0;
  currentPageSize: number = 10;
  searching = false;
  columns: DataTableColumn[] = [];
  data: Laboratory[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  totalRecords = 0;
  canView = true;
  canCreate = true;

  showTable = false;
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private laboratoryService: LaboratoryService,
    private RNService: ResponseNotificationService,
    private spinner: NgxSpinnerService
  ) {
    //super(accountService, SSMService, router, hotkeys);
    //this.viewPermission = AppPermissions.PERMISSIONS_DATA.viewLaboratorys.value;
    //this.createPermission = AppPermissions.PERMISSIONS_DATA.createLaboratorys.value;
    //this.deletePermission = AppPermissions.PERMISSIONS_DATA.deleteLaboratorys.value;
    //this.baseRoute = '/maintenances/laboratory';
    //this.onBuild = () => {
    //};
    //this.loadData = () => {
    //   this.spinner.show('spinnerListLaboratory');;
    //  this.laboratoryService.get().subscribe({
    //    next: (items) => {
    //      console.log(items);
    //     // this.laboratoryService.notifyupdate(items);
    //      this.data = items;
    //       this.spinner.hide('spinnerListLaboratory');;
    //    },
    //    error: (error: any) => {
    //      this.RNService.showError(error);
    //       this.spinner.hide('spinnerListLaboratory');;
    //    }
    //  });
    //};
    //this.onInit = () => {
    //  this.loadData();
    //};
  }
  ngOnInit() {
    this.columns = this.createColumns();
    setTimeout(() => this.getData(), 1);
  }

  openForm(id: string | null) {
    this.router.navigateByUrl("maintenances/laboratory/" + id);
    // this.laboratoryService.notifyupdateRoute(id);
  }

  async getData(
    filters?: LaboratoryFilter,
    pageIndex: number = 0,
    pageSize: number = 10
  ) {
    this.spinner.show("spinnerListLaboratory");

    console.log("GETDATA ->", pageIndex, pageSize, filters);
    await this.laboratoryService
      //.getWithFilters(filters || new LaboratoryFilter(), pageIndex, pageSize)
      .get()
      //.pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (values: Laboratory[]) => {
          console.log("VALUES ->", pageIndex, pageSize, values);

          this.data = values;
          this.totalRecords = this.data.length;
          this.generateItems(values);
          if (this.dataTable) this.dataTable.itemsTemp = this.itemsTemp;
          this.spinner.hide("spinnerListLaboratory");

          this.loadDataFunction(pageIndex, pageSize, []);
        },
        error: (error) => {
          this.spinner.hide("spinnerListLaboratory");
        },
      });
  }
  loadDataFunction = (
    pag: number,
    items_per_pag: number,
    order: { field: string; order: number }[]
  ): Laboratory[] => {
    if (
      pag !== this.currentPageIndex ||
      items_per_pag !== this.currentPageSize
    ) {
      console.log("LOAD DATA FUNCTION (CHANGE PAGE)", pag, items_per_pag);
      this.currentPageIndex = pag;
      this.currentPageSize = items_per_pag;
      this.getData(this.filters, pag, items_per_pag).finally(() => {});
      return this.items;
    }

    return this.items;
  };
  generateItems(data: any[]) {
    const goToDetail = (index: any) => {
      if (data[index].selected) {
        this.openForm(data[index].id);
      }
    };

    let items = data;
    items.forEach((element: any) => {
      element.selected = {
        value: false, //TODO: add value from object
        command: goToDetail,
      };
      this.itemsTemp.push(element);
    });
    this.data = this.itemsTemp;
  }

  createColumns(): DataTableColumn[] {
    return [
      {
        field: "name",
        header: this.translateService.instant("COMMON_FORMS.NAME"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },

      {
        field: "selected",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("COMMON_FORMS.SELECTION"),
        type: "System.Button",
        icon: "pi pi-pencil",
        cssClass: "button-selected-laboratory-list",
      },
    ];
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
