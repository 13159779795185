import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { ConfigStorage } from "@overa/storage";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { ShippingLabelStock } from "src/app/models/shipping-label-stock.model";
import { ShippingLabel } from "src/app/models/shipping-label.model";
import { ShippingLabelService } from "src/app/services/shipping-label.service";
import { DocumentType } from "src/app/models/enums/document-type.model";
import { CreateShippingLabel } from "src/app/models/create-shipping-label.model";

@Component({
  selector: "app-form-shippinglabel",
  templateUrl: "./form-shippinglabel.component.html",
  styleUrls: ["./form-shippinglabel.component.scss"],
  providers: [ConfirmationService],
})
export class FormShippinglabelComponent implements OnInit, OnDestroy {
  isNew: boolean = false;
  laboratoryBranchId: string = "";
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  dataItem: ShippingLabelStock = new ShippingLabelStock();
  enabled: boolean = false;
  detail: boolean = false;
  storageId = ConfigStorage.storageId;
  fileId = "";
  fileName = "";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private shippingLabelService: ShippingLabelService,
    private messagesService: OveraMessagesService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) {
    this.isNew = this.dialogConfig.data?.isNew;
    this.laboratoryBranchId = this.dialogConfig.data?.laboratoryBranchId;
    this.dataItem = this.dialogConfig.data?.dataItem;
  }
  ngOnDestroy() {}
  ngOnInit() {
    console.log(
      this.isNew,
      this.laboratoryBranchId,
      this.dataItem,
      this.translateService.currentLang
    );
    this.enabled = true;
    this.detail = false;
    if (this.laboratoryBranchId) {
      this.loadedForm = false;
      this.createForm();
      this.detail = true;
    }
  }
  createForm() {
    this.form = new FormGroup({
      code: new FormControl<string>("", [Validators.required]),
      link: new FormControl<string>("", []),
      file: new FormControl<any>(null, [Validators.required]),
    });
    this.loadedForm = true;
  }
  cancel() {
    // this.form.disable();
    this.dialogRef.close();
  }
  back() {
    this.cancel();
  }
  create() {
    if (this.detail && this.form.valid) {
      let newshippingLabel = new CreateShippingLabel();
      newshippingLabel = { ...newshippingLabel, ...this.form.value };
      newshippingLabel.laboratoryBranchId = this.laboratoryBranchId;
      console.log("newshippingLabel", newshippingLabel);
      if (this.fileId) {
        newshippingLabel.pdfDocumentFileName = this.fileName;
        newshippingLabel.pdfDocumentStorageId = this.fileId;
      }
      if (newshippingLabel.laboratoryBranchId && this.fileId) {
        this.spinner.show("spinnerFormShippingLabel");
        this.shippingLabelService
          .createWithStorageId(newshippingLabel)
          .subscribe({
            next: () => {
              this.messagesService.showToast(
                "MESSAGES.SUCCESS.CREATE",
                "MESSAGES.SUCCESS.CREATE",
                "success",
                ""
              );
              this.spinner.hide("spinnerFormShippingLabel");
              this.dialogRef.close(newshippingLabel);
            },
            error: (err) => {
              console.error(err);
              this.spinner.hide("spinnerFormShippingLabel");
            },
          });
      } else {
        this.messagesService.showToast(
          "MESSAGES.INFO.INFO",
          "SHIPPINGLABEL_MAINTENANCE.INFO_DOCUMENT_EMPTY",
          "info",
          ""
        );
        this.spinner.hide("spinnerFormShippingLabel");
      }
    }
  }
  createAttachment(event: any) {
    console.log(event);
    if (event?.file?.id) {
      this.fileId = event.file.id;
      this.fileName = event.file.displayName;
    }

    console.log("createAttachment");
  }
  fileDeleted(event: any) {
    console.log(event);
  }
}
