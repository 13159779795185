import { Component, ContentChildren, Input, OnInit, QueryList, TemplateRef } from "@angular/core";

@Component({
  selector: "overa-stepper",
  templateUrl: "overa-stepper.component.html",
  styleUrls: ["overa-stepper.component.scss"],
})
export class OveraStepperComponent implements OnInit {
  @ContentChildren(TemplateRef) templates?: QueryList<TemplateRef<any>>;
  /**
   * Class to add styles to all component
   */
  @Input() gridHeaderClass:string = '';
  /**
   * Lenght of the array will be the number of steps
   * Can add items as {command: (event: any) =>  console.log("Paso 1")} to add actions to the change of step by click
   */
  @Input() items:any[] = [];
  activeIndex:number = 0;

  ngOnInit() {
  }

  changePage(page:number){
    this.activeIndex = page;
    if (this.items[page] && this.items[page].command) {
      this.items[page].command();
    }
  }
}
