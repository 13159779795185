import { TranslateService } from "@ngx-translate/core";

import { ProjectCardViewModel } from "../models/project-card-viewmodel.model";
import { OveraCardItem } from "./temporal_components/models/overa-card-item.model";
import { OveraCardItemField } from "./temporal_components/models/overa-card-item-field.model";
import { OveraGridFiltersType } from "./temporal_components/models/overa-grid-filter-types.model";
import { OveraGridFilter } from "./temporal_components/models/overa-grid-filter.model";
import { OveraKeyValuePair } from "./temporal_components/models/overa-keyValue-pair.model";
import { OveraGridColumnData } from "./temporal_components/models/overa-grid-columns-data.model";
import { AnalysisStatus } from "../models/enums/analysis-status.model";

export const STATUS_FIELDS: string = "ANALYSIS_STATUS";
export const COMMON_FIELDS: string = "COMMON_FIELDS";
export const CARD_STARTDATE: string = "COMMON_FIELDS.STARTDATE";
export const CARD_ENDDATE: string = "COMMON_FIELDS.ENDDATE";
export const CARD_PROJECT: string = "COMMON_FIELDS.PROJECT";
export const CARD_BUDGET: string = "COMMON_FIELDS.BUDGET";
export const CARD_CATEGORY: string = "COMMON_FIELDS.CATEGORY";
export const CARD_CUSTOMER: string = "COMMON_FIELDS.CUSTOMER";
export const CARD_BUSSINES_NAME: string = "COMMON_FIELDS.BUSINESSNAME";
export function transformProjectCardsToOveraCardItems(
  projectCards: ProjectCardViewModel[],
  translateService: TranslateService,
  statuses: AnalysisStatus[],
): OveraGridColumnData[] {
  let translatedFields: any;
  const subscription = translateService.get(COMMON_FIELDS).subscribe({
    next: (data) => {
      translatedFields = data;
    },
    error: (err) => {
      console.error(err);
    },
  });
  subscription.unsubscribe();
  let columns: OveraGridColumnData[] = [];
  for (let status in statuses) {
    var projectCardsFilter = projectCards.filter(x => x.status == statuses[0]);

    let column = new OveraGridColumnData('', []);
    let overaCardItems = projectCardsFilter?.map((projectCard) =>
      transformProjectCardToOveraCardItem(
        projectCard,
        translatedFields,
        translateService.currentLang
      )
    );
    column.items = overaCardItems;


    column.columnTitle = STATUS_FIELDS + '.' + status.toString();
  }
  return columns;

}

function transformProjectCardToOveraCardItem(
  projectCard: ProjectCardViewModel,
  translatedFields: any,
  currentLang: string
): OveraCardItem {
  const opciones = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  } as const;
  const fields: OveraCardItemField[] = Object.entries(projectCard)
    .filter(([key]) => key !== "id" && key !== "code" && key !== "progress")
    .map(([key, value]) => {
      if (key === "startdate" || key === "enddate") {
        value = new Date(value).toLocaleDateString(currentLang, opciones);
      }
      return new OveraCardItemField(translatedFields[key.toUpperCase()], value);
    });

  return new OveraCardItem({

    id: projectCard.id,
    title: new OveraCardItemField(
      translatedFields["ANALYSIS"],
      projectCard.code
    ),
    percentage: projectCard.progress,
    percentageIsCritic: projectCard.progress < 50, // TODO: adjust the formula
    fields: fields,
  });
}

export function createFilter(
  type: OveraGridFiltersType,
  name: string,
  key: string,
  options?: OveraKeyValuePair[]
): OveraGridFilter {
  let filter = new OveraGridFilter();
  filter.type = type;
  filter.name = name;
  filter.options = options;
  filter.key = key;
  filter.selected = null;
  return filter;
}

export function getUniqueValuesByKey(
  items: ProjectCardViewModel[],
  targetKey: string
): any[] {
  let values = items.map((s) => s[targetKey as keyof ProjectCardViewModel]);
  let uniqueValues = Array.from(new Set(values));
  return uniqueValues;
}
