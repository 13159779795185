import { BillingType } from "./billing-type.model";

export class Laboratory {
  id?: string;
  name: string = '';
  description?: string;
  vat?: string;
  billingData?: string;
  billingType?: BillingType;
  billingTypeId?: string;
  observations?: string;


  constructor() {
  
  }

}
