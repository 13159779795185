<!--<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out" [fullScreen]="false">
  <p class="loading-text">{{ 'MESSAGES.LOADING' | translate }}</p>
</ngx-spinner>-->
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card ">
  <div class="card-header card-header-warning card-header-text ">
    <div class="card-text">
      <h4 class="card-title ">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
        <span>{{ (selectedDescription ? ('ANALYSIS_SUBTYPE_MAINTENANCE.FORM.HEADER' | translate: { value:
          selectedDescription }) : 'ANALYSIS_SUBTYPE_MAINTENANCE.FORM.HEADER_NEW' | translate) }}</span>
      </h4>
    </div>
  </div>
  <div class="card-body">
    <mat-card-content *ngIf="canView && data && loadedForm">
      <form autocomplete="off" [formGroup]="form" *ngIf="loadedForm">
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <overa-input [controlName]="'name'" [title]="'COMMON_FORMS.NAME' | translate"
                [formGroup]="form"></overa-input>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
              <overa-input-editor [controlName]="'description'" [title]="'COMMON_FORMS.DESCRIPTION' | translate"
                [formGroup]="form"></overa-input-editor>
            </div>

          </div>
        </div>

        <!--<div class="textarea">
      <overa-text-area [controlName]="'observations'"
                      [title]="'COMMON_FORMS.OBSERVATIONS' | translate" [formGroup]="form"></overa-text-area>
    </div>-->

      </form>

    </mat-card-content>

    <mat-card-actions [align]="'end'">
      <div class="container-form-actions divContainerButtons" [align]="'center'">
        <button mat-raised-button (click)="back()" *ngIf="enabled" class="cancel-button">{{ 'ACTION.CANCEL' | translate
          }}</button>
        <!--<button mat-raised-button (click)="delete()" *ngIf="detail  && canDelete">{{ 'ACTION.DELETE' | translate }}</button>-->
        <button mat-raised-button (click)="create()" *ngIf="!detail && enabled && canCreate" [disabled]="!form.valid">{{
          'ACTION.CREATE' | translate }}</button>
        <button mat-raised-button (click)="update()" color="primary" *ngIf="detail && enabled"
          [disabled]="!form.valid">{{
          'ACTION.SAVE' | translate }}</button>
      </div>
    </mat-card-actions>
  </div>
</div>