<div [ngClass]="allContainerClass">
  <!-- START OF HEADERS -->
  <div class="grid-container" [ngClass]="gridHeaderClass">
    <ng-container *ngIf="hasGridHeader">
      <div class="grid-item first">
        <div class="title-grid">
          <div *ngIf="firstBreadCrumb">
            <img
              *ngIf="svgPath"
              class="breadcrumb-top-image"
              [src]="svgPath"
              alt="icon"
            />
            <h2 class="primary-title breadcrumb-left">{{ firstBreadCrumb }}</h2>
          </div>
          <div *ngIf="firstBreadCrumb && secondBreadCrumb">
            <span class="breadcrumb-separator">/</span>
          </div>
          <div *ngIf="secondBreadCrumb">
            <h3 class="secondary-title breadcrumb-right">
              {{ secondBreadCrumb }}
            </h3>
          </div>
        </div>
        <!-- START OF FILTERS -->
        <!-- <div class="mobile-filter-container">
          <i
            class="pi pi-search mobile-filter-button"
            (click)="toggleFiltersModal()"
          ></i>
        </div>
        <div
          *ngIf="hasFilters && filters != null && filters.length > 0"
          class="filters"
          [ngClass]="customFiltersClass"
        >
          <ng-container *ngFor="let filter of filters">
            <div
              *ngIf="filter.type == filterTypes.TEXT"
              class="common-filter text-filter"
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  type="text"
                  (ngModelChange)="onFilterTextChange(filter, $event)"
                  pInputText
                  [(ngModel)]="filter.selected"
                />
              </span>
            </div>
            <div
              *ngIf="filter.type == filterTypes.DATE"
              class="common-filter calendar"
            >
              <p-calendar
                [(ngModel)]="filter.selected"
                [showIcon]="true"
                (ngModelChange)="onFilterTextChange(filter, $event)"
                inputId="icon"
                placeholder="{{ filter.name | translate }}"
                [minDate]="filter.minDate ?? defaultDate"
                [maxDate]="filter.maxDate ?? defaultDate"
              ></p-calendar>
            </div>
            <div
              *ngIf="filter.type == filterTypes.DROPDOWN"
              class="common-filter dropdown sizeM-visibility"
            >
              <p-dropdown
                [options]="filter.options ?? []"
                (ngModelChange)="onFilterTextChange(filter, $event)"
                [(ngModel)]="filter.selected"
                placeholder="{{ filter.name | translate }}"
                [showClear]="true"
                [filter]="true"
                filterBy="value"
                optionLabel="value"
                emptyFilterMessage="{{ 'FILTERS.INFO.NO_RESULTS' | translate }}"
              ></p-dropdown>
            </div>

            <div
              *ngIf="filter.type == filterTypes.MULTISELECT"
              class="common-filter multiselect dropdown sizeM-visibility"
            >
              <p-multiSelect
                [options]="filter.options ?? []"
                (ngModelChange)="onFilterTextChange(filter, $event)"
                [(ngModel)]="filter.selected"
                placeholder="{{ filter.name | translate }}"
                [showClear]="true"
                optionLabel="value"
                emptyFilterMessage="{{ 'FILTERS.INFO.NO_RESULTS' | translate }}"
              ></p-multiSelect>
            </div>
          </ng-container>
          <div class="dropdown-invisble">
            <i
              class="pi pi-sliders-v mobile-filter-button"
              (click)="toggleSemiModalFilters()"
            ></i>
          </div>
        </div> -->
        <!-- END FILTERS -->
      </div>
    </ng-container>
    <!-- END OF HEADERS -->
  </div>
  <!-- START OF GRID WITH COLUMNS-->
  <div class="grid" *ngIf="isNeedHeaderColumns">
    <div
      *ngFor="let column of itemsForGridWithColummns"
      class="grid-column"
      [ngClass]="customGridColumnsClass"
    >
      <div class="column-header">
        <span>{{ column.columnTitle }}</span>
      </div>
      <div class="cards-container">
        <ng-container *ngFor="let item of column.items">
          <ng-container
            *ngTemplateOutlet="
              cardTemplate ? cardTemplate : defaultCardTemplate;
              context: { $implicit: item }
            "
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- END OF GRID WITH COLUMNS -->

  <!-- START OF GRID WITHOUT COLUMNS -->
  <div
    *ngIf="!isNeedHeaderColumns"
    class="custom-flat-grid"
    [ngClass]="customGridClass"
  >
    <ng-container *ngFor="let item of itemsForGrid">
      <ng-container
        *ngTemplateOutlet="
          cardTemplate ? cardTemplate : defaultCardTemplate;
          context: { $implicit: item }
        "
      ></ng-container>
    </ng-container>
  </div>
  <!-- END OF GRID WITHOUT COLUMNS -->
</div>
<!-- DEFAULT TEMPLATE FOR CARDS -->
<ng-template #defaultCardTemplate let-item>
  <overa-card
    [cardId]="item.id"
    [cardTitle]="item.title"
    [cardInfo]="item.fields"
    [showProgressSpinner]="item.percentage != null"
    [percentage]="item.percentage"
    [isCritic]="item.percentageIsCritic"
    [cardMenuOptions]="item.menuOptions || []"
  ></overa-card>
</ng-template>
<!-- END OF DEFAULT TEMPLATE FOR CARDS -->
<!-- START FILTERS FOR MOBILE VIEW -->
<div class="modal-container" *ngIf="showFiltersModal">
  <div class="modal-content">
    <span class="close" (click)="toggleFiltersModal()">&times;</span>
    <div class="mobile-filters">
      <ng-container *ngFor="let filter of filters">
        <div
          *ngIf="filter.type == filterTypes.TEXT"
          class="common-filter text-filter"
        >
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              type="text"
              (ngModelChange)="onFilterTextChange(filter, $event)"
              pInputText
              [(ngModel)]="filter.selected"
            />
          </span>
        </div>
        <div
          *ngIf="filter.type == filterTypes.DATE"
          class="common-filter calendar"
        >
          <p-calendar
            [(ngModel)]="filter.selected"
            [showIcon]="true"
            (ngModelChange)="onFilterTextChange(filter, $event)"
            inputId="icon"
            placeholder="{{ filter.name | translate }}"
            [minDate]="filter.minDate ?? defaultDate"
            [maxDate]="filter.maxDate ?? defaultDate"
          ></p-calendar>
        </div>
        <div
          *ngIf="filter.type == filterTypes.DROPDOWN"
          class="common-filter dropdown"
        >
          <p-dropdown
            [options]="filter.options ?? []"
            (ngModelChange)="onFilterTextChange(filter, $event)"
            [(ngModel)]="filter.selected"
            placeholder="{{ filter.name | translate }}"
            [showClear]="true"
            [filter]="true"
            filterBy="value"
            optionLabel="value"
            emptyFilterMessage="{{ 'FILTERS.INFO.NO_RESULTS' | translate }}"
          ></p-dropdown>
        </div>

        <div
          *ngIf="filter.type == filterTypes.MULTISELECT"
          class="common-filter multiselect dropdown"
        >
          <p-multiSelect
            [options]="filter.options ?? []"
            (ngModelChange)="onFilterTextChange(filter, $event)"
            [(ngModel)]="filter.selected"
            placeholder="{{ filter.name | translate }}"
            [showClear]="true"
            optionLabel="value"
            emptyFilterMessage="{{ 'FILTERS.INFO.NO_RESULTS' | translate }}"
          ></p-multiSelect>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!-- END FILTERS FOR MOBILE VIEW -->
<div class="modal-container" *ngIf="showFiltersModalSizeM">
  <div class="modal-content">
    <span class="close" (click)="toggleSemiModalFilters()">&times;</span>
    <div class="sizeM-filters">
      <ng-container *ngFor="let filter of filters">
        <div
          *ngIf="filter.type == filterTypes.DROPDOWN"
          class="common-filter dropdown"
        >
          <p-dropdown
            [options]="filter.options ?? []"
            (ngModelChange)="onFilterTextChange(filter, $event)"
            [(ngModel)]="filter.selected"
            placeholder="{{ filter.name | translate }}"
            [showClear]="true"
            optionLabel="value"
            filterBy="value"
            emptyFilterMessage="{{ 'FILTERS.INFO.NO_RESULTS' | translate }}"
          ></p-dropdown>
        </div>

        <div
          *ngIf="filter.type == filterTypes.MULTISELECT"
          class="common-filter multiselect dropdown"
        >
          <p-multiSelect
            [options]="filter.options ?? []"
            (ngModelChange)="onFilterTextChange(filter, $event)"
            [(ngModel)]="filter.selected"
            placeholder="{{ filter.name | translate }}"
            [showClear]="true"
            optionLabel="value"
            emptyFilterMessage="{{ 'FILTERS.INFO.NO_RESULTS' | translate }}"
          ></p-multiSelect>
        </div>
      </ng-container>
    </div>
  </div>
</div>
