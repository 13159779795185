import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  ViewChild,
} from "@angular/core";
import { LaboratoryBranch } from "src/app/models/laboratory-branch.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { LaboratoryBranchService } from "src/app/services/laboratory-branch.service";
import { OveraMessagesService } from "@overa/components";
import { ConfirmationService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { DataTableColumn } from "../../../../shared/temporal_components/models/dataTablecolumn.model";
import { OveraDataTableComponent } from "../../../../shared/temporal_components/overa-datatable/overa-datatable.component";
import { AnalysisTypeLaboratoryBranch } from "../../../../models/analysis-type.model";
import { AnalysisTypeLaboratoryBranchFormComponent } from "../analysis-type-laboratory-branch-form/analysis-type-laboratory-branch-form.component";
import { LaboratoryBranchAnalysisTypeService } from "src/app/services/laboratory-branch-analysis-type.service";

@Component({
  selector: "app-laboratory-branch-form",
  templateUrl: "./laboratory-branch-form.component.html",
  styleUrls: ["./laboratory-branch-form.component.scss"],
  providers: [ConfirmationService],
})
export class LaboratoryBranchFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  static className = "LaboratoryBranchFormComponent";
  form: FormGroup = new FormGroup({});
  //@Input()
  readonly = false;
  loadedForm = false;
  //@Input()
  id: string | null = null;
  //@Input()
  laboratoryId?: string = undefined;

  selectedDescription: string = "";
  detail = false;
  enabled = false;
  data: LaboratoryBranch = new LaboratoryBranch();
  //TODO check
  canView = true;
  canDelete = true;
  canUpdate = true;
  canCreate = true;
  @ViewChild("datatableTypes") datatableTypes?: OveraDataTableComponent<any>;
  columns: DataTableColumn[] = [
    {
      field: "name",
      header: this.translate.instant("COMMON_FORMS.NAME"),
      type: "System.String",
      responsive: true,
      filterMatchMode: "contains",
      responsiveHeader: true,
    },

    {
      field: "selected",
      responsive: true,
      responsiveHeader: true,
      header: this.translate.instant("COMMON_FORMS.SELECTION"),
      type: "System.Button",
      icon: "pi pi-pencil",
      cssClass: "button-selected-laboratory-branch-form",
    },
    {
      field: "deleteAction",
      responsive: true,
      responsiveHeader: true,
      header: this.translate.instant("ACTION.DELETE"),
      type: "System.Button",
      icon: "pi pi-trash",
      cssClass: "button-delete-laboratory-branch-form",
    },
  ];
  constructor(
    private campusService: LaboratoryBranchService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    public dialogConfig: DynamicDialogConfig,
    public dialogService: DialogService,
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private campusAnalysisTypeService: LaboratoryBranchAnalysisTypeService
  ) {
    console.log("campus-form");
    this.id = this.dialogConfig.data?.id;
    this.laboratoryId = this.dialogConfig.data?.laboratoryId;
  }

  ngOnInit() {
    this.detail = false;
    this.selectedDescription = "";
    this.data = new LaboratoryBranch();
    this.form = new FormGroup({
      name: new FormControl<string>("", [Validators.required]),
      deliveryEmail: new FormControl<string>("", [Validators.required]),
      // description: new FormControl<string>('', [Validators.required]),
      phoneNumber: new FormControl<string>("", [Validators.required]),
      contact: new FormControl<string>("", []),
      city: new FormControl<string>("", []),
      country: new FormControl<string>("", []),
      street: new FormControl<string>("", []),
      postalCode: new FormControl<string>("", []),
    });

    const id = this.id;

    if (id) {
      this.spinner.show("spinnerLaboratoryBranchForm");
      if (id === "new") {
        this.detail = false;
        this.data = new LaboratoryBranch();
        this.selectedDescription = "";
        if (!this.readonly) {
          this.form.enable();
          this.enabled = true;
        }
        this.spinner.hide("spinnerLaboratoryBranchForm");
        this.loadedForm = true;
      } else {
        this.detail = true;

        this.campusService.getById(id).subscribe({
          next: (response: LaboratoryBranch) => {
            console.log("RESPONSEDATACAMPUS", response);
            this.loadedForm = true;
            this.spinner.hide("spinnerLaboratoryBranchForm");
            this.detail = true;
            if (!this.readonly) {
              this.form.enable();
              this.enabled = true;
            }

            this.data = response;
            if (this.data.id) {
              this.spinner.show("spinnerLaboratoryBranchForm");
              this.campusAnalysisTypeService
                .getLaboratoryBranchWithTestType(this.data.id)
                .subscribe({
                  next: (items) => {
                    this.data.laboratoryBranchTestTypes = items;
                    this.data.laboratoryBranchTestTypes.forEach((x) => {
                      x.name = x.testType?.name;
                    });
                    let itemsTemp: AnalysisTypeLaboratoryBranch[] = [];
                    this.form.enable();
                    var formData: LaboratoryBranch = new LaboratoryBranch();
                    formData.name = this.data.name;

                    //formData.description = this.data.description;
                    formData.contact = this.data.contact;
                    formData.phoneNumber = this.data.phoneNumber;
                    formData.postalCode = this.data.postalCode;
                    formData.street = this.data.street;
                    formData.city = this.data.city;
                    formData.country = this.data.country;
                    formData.postalCode = this.data.postalCode;
                    formData.deliveryEmail = this.data.deliveryEmail;
                    this.form.setValue({ ...formData });
                    if (this.data.laboratoryBranchTestTypes != undefined) {
                      const goToDetail = (index: any) => {
                        if (
                          this.data.laboratoryBranchTestTypes &&
                          this.data.laboratoryBranchTestTypes[index]
                        ) {
                          this.openFormAnalysisType(
                            this.data.laboratoryBranchTestTypes[index]
                          );
                        }
                      };
                      const deleteLaboratoryBranchTestType = (index: any) => {
                        console.log(index);

                        if (
                          this.data.laboratoryBranchTestTypes &&
                          this.data.laboratoryBranchTestTypes[index]
                        ) {
                          this.delete(
                            this.data.laboratoryBranchTestTypes[index]
                          );
                        }
                      };
                      this.data.laboratoryBranchTestTypes.forEach(
                        (element: any) => {
                          element.selected = {
                            value: true, //TODO: add value from object
                            command: goToDetail,
                          };
                          element.deleteAction = {
                            value: true, //TODO: add value from object
                            command: deleteLaboratoryBranchTestType,
                          };
                          itemsTemp.push(element);
                        }
                      );
                      this.data.laboratoryBranchTestTypes = [...itemsTemp];
                    }

                    this.selectedDescription = response.name || "";
                    console.log("DATACAMPUS", this.data);
                    this.spinner.hide("spinnerLaboratoryBranchForm");
                  },
                  error: (err) => {
                    console.error(err);
                    this.spinner.hide("spinnerLaboratoryBranchForm");
                  },
                });
            }
          },
          error: (error) => {
            this.spinner.hide("spinnerLaboratoryBranchForm");
            if (error.status === 500 || error.status === 0) {
              this.messagesService.showToast(
                "ERROR.CRITICAL",
                error.error,
                "error",
                "",
                ""
              );
            } else {
              this.messagesService.showToast(
                "ERROR.CRITICAL",
                "ERROR",
                "error",
                "",
                ""
              );
            }
            this.loadedForm = true;
            // TODO : Mensaje error
          },
        });
      }
    } else {
      this.selectedDescription = "";
      this.detail = false;
      this.form.disable();
      this.enabled = false;
      this.data = new LaboratoryBranch();
      this.spinner.hide("spinnerLaboratoryBranchForm");
      this.loadedForm = true;
    }

    // });
  }
  openFormAnalysisType(analysisType?: AnalysisTypeLaboratoryBranch) {
    console.log(analysisType);
    let header = "";
    const isNew = !analysisType;
    let translateKey = "ANALYSIS_TYPE_MAINTENANCE.FORM.HEADER_EDIT";
    if (isNew) {
      translateKey = "ANALYSIS_TYPE_MAINTENANCE.FORM.HEADER_NEW";
    }
    this.translate.get(translateKey).subscribe((a: string) => {
      header = a;
    });

    var ref = this.dialogService.open(
      AnalysisTypeLaboratoryBranchFormComponent,
      {
        width: "75%",
        height: "85%",
        header: header,
        data: {
          isNew: isNew,
          analysisTypeCampus: analysisType,
          campus: this.data,
        },
      }
    );

    ref.onClose.subscribe((analysisTypeResult: any) => {
      console.log(analysisTypeResult);
      if (analysisTypeResult) {
        //TODO improve
        console.log("analysisTypeResult", analysisTypeResult);
        this.ngOnInit();
      }
      this.spinner.hide("spinnerLaboratoryBranchForm");
    });
  }

  // ======================== CRUD ========================
  ngOnChanges() {
    this.ngOnInit();
  }
  setData() {
    this.data = { ...this.data, ...this.form.value };
  }

  create() {
    this.spinner.show("spinnerLaboratoryBranchForm");
    this.setData();
    if (this.laboratoryId) {
      this.data.laboratoryId = this.laboratoryId;
    }

    this.campusService.add(this.data).subscribe({
      next: (response: any) => {
        this.data = response;
        //this.campusService.get().subscribe((campus) => {
        // this.dialogRef.close(response);
        // });
        this.messagesService.showToast(
          "MESSAGES.SUCCESS.CREATE",
          "MESSAGES.SUCCESS.CREATE_LONG",
          "success",
          this.data.description || "",
          "CAMPUS_MAINTENANCE.TITLE"
        );
        this.id = response.id;
        this.ngOnInit();
        //this.router.navigateByUrl("/maintenances/campus");
        this.spinner.hide("spinnerLaboratoryBranchForm");
      },
      error: (err: any) => {
        this.spinner.hide("spinnerLaboratoryBranchForm");
        this.messagesService.showError(err);
      },
    });
  }
  cleanForm() {
    this.form.reset();
  }
  edit() {
    this.form.enable();
    this.enabled = true;
  }

  update() {
    this.spinner.show("spinnerLaboratoryBranchForm");
    this.setData();
    if (this.laboratoryId) {
      this.data.laboratoryId = this.laboratoryId;
    }
    this.campusService.update(this.data).subscribe({
      next: () => {
        this.campusService.get().subscribe((campus) => {
          this.dialogRef.close(campus);
        });

        this.messagesService.showToast(
          "MESSAGES.SUCCESS.UPDATE",
          "MESSAGES.SUCCESS.UPDATE_LONG",
          "success",
          this.data.name || "",
          "CAMPUS_MAINTENANCE.TITLE"
        );

        this.cancel();
        this.spinner.hide("spinnerLaboratoryBranchForm");
      },
      error: (error) => {
        this.spinner.hide("spinnerLaboratoryBranchForm");
        this.messagesService.showError(error);
      },
    });
  }
  delete(analysisTypeCampus: AnalysisTypeLaboratoryBranch) {
    console.log(analysisTypeCampus);
    let trmessage,
      messageAccept = "";
    this.translate.get("COMMONS.ASK_DELETE_RECORD").subscribe((a: string) => {
      trmessage = a;
    });
    this.translate.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show("spinnerLaboratoryBranchForm");
        this.campusAnalysisTypeService
          .delete(analysisTypeCampus.id ?? "")
          .subscribe({
            next: () => {
              this.ngOnInit();
              this.spinner.hide("spinnerLaboratoryBranchForm");
            },
            error: (err) => {
              console.log(err);
              this.spinner.hide("spinnerLaboratoryBranchForm");
            },
          });
      },
      reject: () => {},
    });
  }

  ngOnDestroy() {}
  cancel() {
    this.form.disable();
    this.dialogRef.close();
  }
  back() {
    this.cancel();
  }
}
