<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerListLaboratory">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>

<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <span>{{ "LABORATORY_MAINTENANCE.LIST.HEADER" | translate }}</span>
        <!--<input [(ngModel)]="filter" #inputsearch [hidden]="searching"
            placeholder="{{ 'COMMONS.SEARCH_PLACEHOLDER' | translate }}" />-->
      </h4>
    </div>
    <!--<div class="keypad">
        <button mat-icon-button color="primary" (click)="searching=!searching;">
            <mat-icon aria-label="search">{{ !searching ? 'close' : 'search' }}</mat-icon>
        </button>
        <button *ngIf="canCreate" mat-icon-button color="primary"  (click)="openForm('new')">
            <mat-icon aria-label="ADD">add</mat-icon>
        </button>
    </div>-->
  </div>
  <div class="card-body th-2-right">
    <div class="d-flex">
      <button pButton pRipple type=" button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
        (click)="openForm('new')" [pTooltip]="'ACTION.ADD' | translate" tooltipPosition="bottom"
        tooltipStyleClass="tooltipDiagnostica"></button>
    </div>

    <overa-datatable #datatable *ngIf="columns && columns.length" [columns]="columns"
      [loadDataFunction]="loadDataFunction" [showPaginator]="true" [showGlobalFilter]="false" [rowsPerPage]="[10, 20]"
      [selectedColumns]="columns" [items]="data" [totalRecords]="totalRecords">
    </overa-datatable>
  </div>
</div>