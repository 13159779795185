<div class="p-grid menubar-main-container">
  <div class="p-col menubar-container" style="display: flex">
    <div *ngFor="let item of items" class="menubar-item">
      <div [ngSwitch]="item.type">
        <p-dropdown appDropdown (dropdownInitialized)="dropdowns.push($event)" class="menu-dropdown"
          *ngSwitchCase="'dropdown'" [options]="item.items || []" [(ngModel)]="item.selectedId" optionValue="id"
          (onChange)="onDropdownChange(item, $event.value)">
          <ng-template pTemplate="item" let-item>
            {{ (item.label || '') | translate }}

          </ng-template>
          <ng-template pTemplate="selectedItem" let-item>
            {{ (item.label || '') | translate }}
          </ng-template>

        </p-dropdown>
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="(!item.svg && item.icon) else svgTemplate">
            <button #btn pButton [label]="(item.label || '')  | translate" [icon]="item.icon"
              (click)="openCm($event, btn, item, cm)"></button>
          </ng-container>
          <ng-template #svgTemplate>
            <button #btn pButton [label]="(item.label || '') | translate" (click)="openCm($event, btn, item, cm)">
              <img *ngIf="item.svg" [src]="('data:image/svg+xml;base64,'+ item.svg) | safeResourceUrl" alt="icon"
                width="24" height="24">
              <span *ngIf="item.label && item.showName">{{item.label | translate}}</span>
            </button>
          </ng-template>
        </ng-container>
        <p-contextMenu #cm [model]="item.items || []" [baseZIndex]="zIndex" appendTo="body"></p-contextMenu>
      </div>
    </div>
  </div>
</div>