import { Directive, EventEmitter, OnInit, Output } from "@angular/core";
import { Dropdown } from "primeng/dropdown";

@Directive({
  selector: "[appDropdown]",
})
export class DropdownDirective implements OnInit {
  @Output() dropdownInitialized = new EventEmitter<Dropdown>();

  constructor(private dropdown: Dropdown) {}

  ngOnInit() {
    this.dropdownInitialized.emit(this.dropdown);
  }
}
