import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import {
  DetailDocumentLanguage,
  DetailInstructions,
} from "src/app/models/analysis-type.model";
import { SampleState } from "src/app/models/enums/sample-state.model";
import { NoticePanel } from "src/app/models/notice-panel.model";
import { SampleModified } from "src/app/models/sample-modified.model";
import { SampleShippingInfo } from "src/app/models/sample-shipping-info.model";
import { ShippingLabel } from "src/app/models/shipping-label.model";
import { SampleService } from "src/app/services/sample.service";
import { Globals } from "src/app/utils/globals";

@Component({
  selector: "app-new-analysis-delivery",
  templateUrl: "./new-analysis-delivery.component.html",
  styleUrls: ["./new-analysis-delivery.component.scss"],
})
export class NewAnalysisDeliveryComponent implements OnInit, OnDestroy {
  id: string = "";
  sampleCreated: SampleModified = new SampleModified();
  sampleShippingInfo: SampleShippingInfo = new SampleShippingInfo();
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  listNoticePanel: NoticePanel[] = [];
  titlePanel: string = "";
  listDefaultSampleDeliveryInstructions: DetailInstructions[] = [];

  listFileteredLanguageDefaultSampleDeliveryInstructions: DetailInstructions[] =
    [];
  defaultLanguageLocale: string = "";
  optionsShippingLabel: ShippingLabel[] = [];
  checked: boolean = false;
  isReadOnly?: boolean = false;
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService,
    private globals: Globals,
    private sampleService: SampleService
  ) {}
  ngOnInit() {
    console.log("STEP 2");
    console.log(this.router.url.toString().split("/").pop());
    this.defaultLanguageLocale = this.translateService.defaultLang;
    this.loadedForm = false;
    this.createForm();
    this.route.params.subscribe({
      next: (paramsId) => {
        this.id = paramsId["id"];
        console.log(this.id);
        if (this.id) {
          this.spinner.show("step3");
          this.sampleService.getFullSample(this.id).subscribe({
            next: (sample) => {
              console.log(sample);
              this.sampleCreated = sample;
              this.mapInstructions(sample);
              console.log(this.sampleCreated);
              this.sampleService.getShippingInfo(this.id).subscribe({
                next: (shippingInfo) => {
                  this.sampleShippingInfo = shippingInfo;
                  this.setForm();
                  this.isReadOnlyView();
                  this.loadedForm = true;
                  this.spinner.hide("step3");
                },
                error: (err) => {
                  console.error(err);
                  this.spinner.hide("step3");
                },
              });
            },
            error: (err) => {
              console.error(err);
              this.spinner.hide("step3");
            },
          });
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("step3");
      },
    });
    this.form.get("checked")?.valueChanges.subscribe((newValue) => {
      this.checked = newValue;
    });
  }
  ngOnDestroy() {}
  isReadOnlyView() {
    if (
      this.sampleCreated.deliveryState !== SampleState.REGISTERING &&
      this.sampleCreated.deliveryState !== SampleState.REGISTERED
    ) {
      this.isReadOnly = true;
    }
    if (this.isReadOnly) {
      this.form.get("checked")?.setValue(true);
      this.form.disable();
    }
  }
  createForm() {
    this.form = new FormGroup({
      code: new FormControl<string>("", []),
      link: new FormControl<string>("", []),
      checked: new FormControl<boolean>(false, []),
    });
  }
  setForm() {
    this.form.setValue({
      code: this.sampleShippingInfo.code ? this.sampleShippingInfo.code : "",
      link: this.sampleShippingInfo.link ? this.sampleShippingInfo.link : "",
      checked: false,
    });
    this.form.get("code")?.disable();
    this.form.get("link")?.disable();
  }
  mapInstructions(sample: SampleModified) {
    if (sample.testType?.defaultSampleDeliveryInstructions?.documentLanguages) {
      sample.testType?.defaultSampleDeliveryInstructions?.documentLanguages?.forEach(
        (item) => {
          let detailLanguage = this.createDetail(item);
          this.listDefaultSampleDeliveryInstructions.push(detailLanguage);
        }
      );
    }
    console.log("LISTAS", this.listDefaultSampleDeliveryInstructions);
    this.filteredListInstructionsToLocalLanguage();
  }
  createDetail(item: DetailDocumentLanguage): DetailInstructions {
    let detailLanguage = new DetailInstructions();
    detailLanguage.id = item.id;
    detailLanguage.text = item.text;
    detailLanguage.languageId = item.languageId;
    detailLanguage.name = item.language?.name;
    return detailLanguage;
  }
  filteredListInstructionsToLocalLanguage() {
    // this.listFileteredLanguageDefaultSampleDeliveryInstructions =
    //   this.listDefaultSampleDeliveryInstructions.filter(
    //     (item) => item.name === this.defaultLanguageLocale
    //   );
    this.listFileteredLanguageDefaultSampleDeliveryInstructions =
      this.listDefaultSampleDeliveryInstructions.filter(
        (item) => item.name === this.defaultLanguageLocale
      );
    if (
      this.listFileteredLanguageDefaultSampleDeliveryInstructions.length > 0
    ) {
      this.createNoticePanel();
    }
  }
  createNoticePanel() {
    this.titlePanel = "STEP2.NOTICE.ALERT";
    this.listFileteredLanguageDefaultSampleDeliveryInstructions.forEach(
      (item) => {
        let notice = new NoticePanel();
        notice.textInnerHTML = item.text;
        this.listNoticePanel.push(notice);
      }
    );
    console.log("LISTNOTICEPANEL", this.listNoticePanel);
  }
  save() {
    if (this.checked) {
      if (this.sampleCreated.deliveryState === SampleState.REGISTERING) {
        this.sampleCreated.deliveryState = SampleState.REGISTERED;
        delete this.sampleCreated.laboratory;
        delete this.sampleCreated.shippingCode;
        if (this.sampleCreated.laboratoryBranch) {
          delete this.sampleCreated.laboratoryBranch;
        }
        if (this.sampleCreated.testType) {
          delete this.sampleCreated.testType;
        }
        // PatientFormId tiene que tener el valor del campo PatientFormDocumentId
        this.sampleCreated.patientFormId =
          this.sampleCreated.patientFormDocumentId;
        console.log(this.sampleCreated);

        this.spinner.show("step3");
        this.sampleService.update(this.sampleCreated).subscribe({
          next: (item) => {
            console.log("UPDATEDITEM", item);
            this.spinner.hide("step3");
            this.router.navigate(["/home"]);
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide("step3");
          },
        });
      }
      this.router.navigate(["/home"]);
    }
  }
  back() {
    this.router.navigate(["/createAnalisys/2", this.id]);
  }
}
