import { OveraCardItem } from "./overa-card-item.model";

export class OveraGridColumnData {
  columnTitle: string;
  items: OveraCardItem[];
  constructor(columnTitle: string, items: OveraCardItem[]) {
    this.columnTitle = columnTitle;
    this.items = items;
  }
}
