import { Injectable } from "@angular/core";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
import { Config } from "@overa/security";
import { Observable } from "rxjs";
import {
  CreatePayment,
  Payment,
  PaymentSummaryField,
} from "../models/payment.model";

@Injectable({ providedIn: "root" })
export class PaymentService extends OveraBaseService<Payment> {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/payment";
  }
  createPayment(createPayment: CreatePayment) {
    const endpointUrl = `${this.baseUrl}/create`;
    return this.http.post<Payment>(endpointUrl, createPayment);
  }
  getPaymentSummary(paymentId: string) {
    const endpointUrl = `${this.baseUrl}/summary/${paymentId}`;
    return this.http.get<PaymentSummaryField[]>(endpointUrl);
  }
}
