<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerReportFile">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
        <span>{{ "REPORT.TITLE" | translate }}</span>
      </h4>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="loadedForm">
      <form autocomplete="off" [formGroup]="form" *ngIf="loadedForm">
        <div class="row">
          <div class="container-form-actions divContainerButtons mb-3" [align]="'end'">
            <!-- <overa-input
              [controlName]="'fileName'"
              [title]="'COMMON_FORMS.NAME_FILE' | translate"
              [formGroup]="form"
            ></overa-input> -->
            <overa-file-upload [id]="'file'" [form]="form" [controlName]="'file'" [fileData]="fileDataJson"
              [storageId]="storageId" (fileUpload)="onAddFile($event)" (fileDeleted)="fileDeleted($event)"
              [placeholder]="
                'SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT' | translate
              " [readonly]="isReadOnly">
            </overa-file-upload>
          </div>

        </div>
      </form>
    </div>
    <div class="container-form-actions divContainerButtons" [align]="'end'">
      <button mat-raised-button (click)="create()" [disabled]="!form.valid" *ngIf="!isReadOnly">
        {{ "ACTION.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>