import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { OveraNavbarComponent } from "./overa-navbar.component";

import { MatButtonModule } from "@angular/material/button";

import {
  OveraTranslateModule,
  SafePipeModule,
  DynamicPipeModule,
} from "@overa/shared";

import { FormsModule } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { OveraMenuBarComponent } from "../overa-menubar/overa-menubar.component";

import { ContextMenuModule } from "primeng/contextmenu";
import { ButtonModule } from "primeng/button";
import { SplitButtonModule } from "primeng/splitbutton";
import { DropdownModule } from "primeng/dropdown";
import { DropdownDirective } from "../overa-menubar/dropdown.directive";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    FormsModule,
    SafePipeModule,
    DynamicPipeModule,
    OveraTranslateModule,
    ContextMenuModule,
    ButtonModule,
    SplitButtonModule,
    DropdownModule,
  ],
  declarations: [
    DropdownDirective,
    OveraNavbarComponent,
    OveraMenuBarComponent,
  ],
  exports: [OveraNavbarComponent],
})
export class NavbarModule {}
