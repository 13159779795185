<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
        <span>{{
          selectedDescription
          ? ("ANALYSIS_TYPE_MAINTENANCE.FORM.HEADER"
          | translate : { value: selectedDescription })
          : ("ANALYSIS_TYPE_MAINTENANCE.FORM.HEADER_NEW" | translate)
          }}</span>
      </h4>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="canView && data && loadedForm">
      <form autocomplete="off" [formGroup]="form" *ngIf="loadedForm">
        <div class="row">
          <div class="input-form col-6">
            <overa-input [controlName]="'name'" [title]="'COMMON_FORMS.NAME' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <div class="input-form col-6">
            <overa-input [controlName]="'description'" [title]="'COMMON_FORMS.DESCRIPTION' | translate"
              [formGroup]="form"></overa-input>
          </div>
        </div>
        <div class="row">
          <div class="input-form col-6">
            <overa-input [controlName]="'sampleType'" [title]="'COMMON_FORMS.TYPE' | translate"
              [formGroup]="form"></overa-input>
          </div>

          <!--<div class="input-form col-6">
            <overa-dropdown [options]="subtypes" [optionLabel]="'name'" [title]="'COMMON_FORMS.SUBTYPE' | translate" [formGroup]="form"
                            [controlName]="'subtype'"></overa-dropdown>
          </div>-->
        </div>
        <div class="row">
          <div class="input-form col-6 col-lg-3">
            <overa-inputnumber [controlName]="'sampleValidityTime'" [formGroup]="form"
              [title]="'COMMON_FORMS.VALIDITY_TIME' | translate">
            </overa-inputnumber>
          </div>

          <div class="input-form col-6 col-lg-3">
            <overa-inputnumber [controlName]="'deliveryTerm'" [formGroup]="form"
              [title]="'COMMON_FORMS.DELIVERY_TERMS' | translate">
            </overa-inputnumber>
          </div>
          <div class="input-form col-6 col-lg-3">
            <overa-inputnumber [controlName]="'diagnosticaPrice'" [formGroup]="form"
              [title]="'COMMON_FORMS.DIAGNOSTICA_PRICE' | translate" [minFractionDigits]="2">
            </overa-inputnumber>
          </div>
          <div class="input-form col-6 col-lg-3">
            <overa-datepicker dateFormat="dd/mm/yy" controlName="priceValidityDate" [formGroup]="form"
              [title]="'COMMON_FORMS.PRICE_VALIDITY_DATE' | translate"></overa-datepicker>
            <!--<p-calendar [showIcon]="true" formControlName="priceValidityDate" appendTo="body"></p-calendar>-->
          </div>
        </div>
        <!--<div class="textarea">
      <overa-text-area [controlName]="'observations'"


                      [title]="'COMMON_FORMS.OBSERVATIONS' | translate" [formGroup]="form"></overa-text-area>
    </div>-->
      </form>

      <div class="instructions mt-4" *ngIf="
          defaultSampleDeliveryInstructions &&
          defaultSampleProcedureInstructions
        ">
        <p-tabView class="tabviewLabs" *ngIf="detail">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>
                {{ "ANALYSIS_SUBTYPE_MAINTENANCE.LIST.HEADER" | translate }}
              </span>
            </ng-template>
            <ng-container *ngIf="loadedForm">
              <app-analysis-subtype-list [analysisTypeId]="data.id || ''"></app-analysis-subtype-list>
            </ng-container>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>
                <span>{{
                  "COMMON_FORMS.DEFAULT_PROCEDURE_INSTRUCTIONS" | translate
                  }}</span>
              </span>
            </ng-template>
            <ng-container>
              <div class="th-2-3-right">
                <overa-datatable [columns]="languageColumns" [items]="defaultSampleProcedureInstructions || []">
                </overa-datatable>
              </div>
            </ng-container>
          </p-tabPanel>

          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>
                <span>{{
                  "COMMON_FORMS.DEFAULT_DELIVERY_INSTRUCTIONS" | translate
                  }}</span>
              </span>
            </ng-template>
            <ng-container>
              <div class="th-2-3-right">
                <overa-datatable [columns]="languageColumns" [items]="defaultSampleDeliveryInstructions || []">
                </overa-datatable>
              </div>
            </ng-container>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>
                <span>{{ "COMMON_FORMS.PATIENT_FORM" | translate }}</span>
              </span>
            </ng-template>
            <ng-container *ngIf="loadedFormFile">
              <div class="th-2-3-right">
                <!-- <overa-datatable
                  [columns]="languageColumns"
                  [items]="patientManual || []"
                >
                </overa-datatable> -->
                <p-table [value]="patientFormLanguages">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>{{ "COMMON_FORMS.LANGUAGE" | translate }}</th>
                      <th>{{ "COMMON_FORMS.PATIENT_FORM" | translate }}</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-patientForm>
                    <tr>
                      <td>{{ patientForm.language }}</td>
                      <td class="containerStorage">
                        <label class="truncate-text"><b>{{
                            "COMMON_FORMS.PATIENT_FORM" | translate
                            }}</b></label>
                        <overa-file-upload [id]="patientForm.controlName" [form]="formFile"
                          [controlName]="patientForm.controlName" [fileData]="patientForm.fileDataJson"
                          [storageId]="storageId" (fileUpload)="onAddFile($event, patientForm)"
                          (fileDeleted)="fileDeleted($event, patientForm)" [placeholder]="
                            'SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT'
                              | translate
                          " [readonly]="false"></overa-file-upload>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </ng-container>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>
                <span>{{ "COMMON_FORMS.PATIENT_MANUAL" | translate }}</span>
              </span>
            </ng-template>
            <ng-container *ngIf="loadedFormFile">
              <div class="th-2-3-right">
                <!-- <overa-datatable
                  [columns]="languageColumns"
                  [items]="patientManual || []"
                >
                </overa-datatable> -->
                <p-table [value]="patientManualLanguages">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>{{ "COMMON_FORMS.LANGUAGE" | translate }}</th>
                      <th>{{ "COMMON_FORMS.PATIENT_MANUAL" | translate }}</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-patientManual>
                    <tr>
                      <td>{{ patientManual.language }}</td>
                      <td class="containerStorage">
                        <label class="truncate-text"><b>{{
                            "COMMON_FORMS.PATIENT_MANUAL" | translate
                            }}</b></label>
                        <overa-file-upload [id]="patientManual.controlName" [form]="formFile"
                          [controlName]="patientManual.controlName" [fileData]="patientManual.fileDataJson"
                          [storageId]="storageId" (fileUpload)="onAddFile($event, patientManual)"
                          (fileDeleted)="fileDeleted($event, patientManual)" [placeholder]="
                            'SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT'
                              | translate
                          " [readonly]="false"></overa-file-upload>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </ng-container>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>

    <div class="container-form-actions divContainerButtons" [align]="'center'">
      <mat-card-actions [align]="'end'">
        <button mat-raised-button (click)="back()" *ngIf="enabled" class="cancel-button">
          {{ "ACTION.CANCEL" | translate }}
        </button>
        <button mat-raised-button (click)="delete()" *ngIf="detail && canDelete" [disabled]="isDeleting">
          {{ "ACTION.DELETE" | translate }}
        </button>
        <button mat-raised-button (click)="create()" *ngIf="!detail && enabled && canCreate" [disabled]="!form.valid">
          {{ "ACTION.CREATE" | translate }}
        </button>
        <button mat-raised-button (click)="update()" color="primary" *ngIf="detail && enabled"
          [disabled]="!form.valid || isSaving">
          {{ "ACTION.SAVE" | translate }}
        </button>
      </mat-card-actions>
    </div>
  </div>
</div>