import { SampleState } from "./enums/sample-state.model";

export class ShortSample {
  id?: string;
  code?: string;
  sampleDate?: Date;
  testTypeName?: string;
  patientName?: string;
  patientLastName?: string;
  patientIdentityId?: string;
  deliveryState?: SampleState;
  isValid?: boolean;
  noValidCause?: string;
  constructor() {}
}
