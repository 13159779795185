<div class="p-field formField">
  <label class="truncate-text" [title]="title"><span *ngIf="isRequired">*</span><b>{{title}}</b></label><br>
  <p-inputNumber class="inputNumber" *ngIf="!controlName"
                 [(ngModel)]="value"
                 mode="decimal"
                 [showButtons]="showButtons"
                 [minFractionDigits]="decimalDigits"
                  [maxFractionDigits]="decimalDigits"
                ></p-inputNumber>

  <ng-container [formGroup]="formGroup" *ngIf="formGroup && controlName">
    <p-inputNumber class="inputNumber"
                   [showButtons]="showButtons"
                   mode="decimal"
                   [formControlName]="controlName"
                   [minFractionDigits]="decimalDigits"
                   [maxFractionDigits]="decimalDigits"
                  ></p-inputNumber>
  </ng-container>
</div>
