import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { AccountService, User } from "@overa/security";
import { OveraDropdownComponent } from "../overa-dropdown/overa-dropdown.component";

@Component({
  selector: "overa-user-dropdown",
  templateUrl: "overa-user-dropdown.component.html",
  styleUrls: ["overa-user-dropdown.component.scss"],
})
export class OveraUserDropdownComponent
  extends OveraDropdownComponent
  implements OnInit, AfterViewInit
{
  @Input() users: User[] = [];
  @Output() selectedUserChange = new EventEmitter<User>();
  @Input() override isDisabled: boolean = false;
  _id: string = "";

  constructor(private accountService: AccountService) {
    super();
  }

  ngAfterViewInit(): void {}

  get selectedUser(): User {
    return this.value;
  }

  set selectedUser(value: User) {
    if (value && this.value != value) {
      this.value = value;
      this._id = value.id;
    }
  }

  @Input()
  get selectedUserId(): string {
    return this._id;
  }

  set selectedUserId(value: string) {
    if (value && this._id != value) {
      this._id = value;
      this.accountService.getUser(value).subscribe((user) => {
        if (this.users.length == 0) {
          this.users = [user];
        }
        this.selectedUser = user;
      });
    }
  }

  selectUser(event: User) {
    if (event && this.selectedUser != event) {
      this.selectedUser = event;
      this.selectedUserChange.emit(event);
    }
  }

  loadUsers() {
    if (!this.users) {
      this.accountService.getUsers().subscribe((users) => {
        this.users = users;
      });
    }
  }

  ngOnInit(): void {}
}
