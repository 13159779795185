export class TestTypeManuals {
  testTypeId?: string;
  testTypeManuals?: Manuals;
  constructor() {}
}
export class Manuals {
  defaultLanguageLocale?: string;
  name?: string;
  languageManuals?: { [lang: string]: ManualForm };
  constructor() {}
}
export class ManualForm {
  fileName?: string;
  storageId?: string;
  constructor() {}
}
