<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="invoicesStep2">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="container-step p-4 mb-2">
  <div class="form-step">
    <div class="row">
      <div class="col-6 title-view">
        <h4 class="card-title title-inside-steps-h4">
          {{ "INVOICES_STEP2.INVOICES_TITLE" | translate }}
        </h4>
      </div>
      <div class="col-6 filter-text-div">
        <overa-filter-text (searchChangeKeyPressEvent)="onSearchTextChange($event)"
          (searchChangeClickEvent)="onSearchTextChange($event)" [styleClass]="filterClass"></overa-filter-text>
      </div>
    </div>
    <div class="row">
      <div class="col-12 container-center" *ngIf="showTable">
        <overa-datatable #datatableStep2Invoices [columns]="columns" [showPaginator]="true" [rowsPerPage]="[10, 20]"
          [items]="listSampleInvoices!" [totalRecords]="totalRecords">
        </overa-datatable>
      </div>
    </div>
    <div class="row marginContainerInvoiceNumber" [formGroup]="form" *ngIf="loadedForm">

      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"></div>

      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3">
        <overa-input [title]="'COMMON_FORMS.INVOICE_NUMBER' | translate" [isDisabled]="false"
          [controlName]="'invoiceNumber'" [formGroup]="form" [isRequired]="true"></overa-input>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 containerStorage">
        <label class="truncate-text"><b>{{
            "COMMON_FORMS.INVOICE_FORM_STORAGE_PLACEHOLDER" | translate
            }}</b></label>
        <overa-file-upload [id]="'file'" [form]="form" [controlName]="'file'" [storageId]="storageId"
          (fileUpload)="onAddFile($event)" (fileDeleted)="fileDeleted($event)" [placeholder]="
            'COMMON_FORMS.INVOICE_FORM_STORAGE_PLACEHOLDER' | translate
          ">
        </overa-file-upload>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3"></div>

    </div>
    <div class="row panel-notice" *ngIf="showTable">
      <app-panel-notices-instructions [listPanel]="listNoticePanel"
        [titleHeader]="titlePanel"></app-panel-notices-instructions>
    </div>
  </div>
  <div class="container-invoices-step2">
    <!-- <button class="btn-continuar" (click)="back()">
      <i class="fa fa-arrow-left"></i> {{ "ACTION.BACK_STEP" | translate }}
    </button> -->
    <button class="btn-continuar" (click)="next()" [disabled]="!form.valid" *ngIf="!showButtonHome">
      {{ "ACTION.CONTINUE" | translate }} <i class="fa fa-arrow-right"></i>
    </button>
    <button class="btn-continuar" (click)="goHome()" *ngIf="showButtonHome">
      <i class="pi pi-home"></i>
    </button>
  </div>
</div>