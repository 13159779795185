import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Globals } from "src/app/utils/globals";
import { Utils } from "src/app/utils/utils";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  isDatar: boolean = false;
  isDiagnostica: boolean = false;
  isLaboratory: boolean = false;

  constructor(
    private utils: Utils,
    private globals: Globals,
    public router: Router
  ) {}
  ngOnInit() {
    this.isDatar = this.utils.isDatarRole();
    this.isDiagnostica = this.utils.isDiagnosticaRole();
    this.isLaboratory = this.utils.isLabRole();
    if (this.isDiagnostica) {
      this.router.navigate(["/home/diagnostica"]);
    } else if (this.isDatar) {
      this.router.navigate(["/home/datar"]);
    } else if (this.isLaboratory) {
      this.router.navigateByUrl("/home/laboratory");
    } else {
      if (this.globals.user.isAdmin) {
        this.router.navigate(["/home/diagnostica"]);
      }
    }
  }
}
