import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Config } from "@overa/security";
import { Observable, of, ReplaySubject } from "rxjs";
import {
  AnalysisType,
  AnalysisTypeLaboratoryBranch,
} from "src/app/models/analysis-type.model";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";

@Injectable({ providedIn: "root" })
export class LaboratoryBranchAnalysisTypeService extends OveraBaseService<AnalysisTypeLaboratoryBranch> {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/laboratoryBranchTestType";
  }

  constructor(http: HttpClient) {
    super(http);
  }
  getLaboratoryBranchWithTestType(campusId: string) {
    const endpointUrl = `${this.baseUrl}/laboratoryBranch/${campusId}`;
    return this.http.get<AnalysisTypeLaboratoryBranch[]>(endpointUrl);
  }
}
