import { AnalysisStatus } from "./enums/analysis-status.model";
import { LaboratoryBranch } from "./laboratory-branch.model";
import { Laboratory } from "./laboratory.model";
import { BillingState } from "./enums/billing-state.model";
import { AnalysisType } from "./analysis-type.model";
import { SampleState } from "./enums/sample-state.model";
import { ShippingLabel } from "./shipping-label.model";

export class SampleModified {
  id: string | undefined;
  code: string | undefined;
  sampleDate?: Date;
  deliveryEmail?: string;
  invoiceNumber?: string;
  isPaid?: boolean;
  datarEntryDate?: Date;
  datarDepartureDate?: Date;
  deliveryDate?: Date;
  arrivalDate?: Date;
  reportDate?: Date;
  patientBirthDate?: Date;
  patientName?: string;
  patientLastName?: string;
  medicalRecordNumber?: string;
  patientIdentityId?: string;
  patientFormStorageId?: string;
  patientFormFileName?: string;
  deliveryState?: SampleState;
  billingState?: BillingState;
  noValidCause?: string;
  shippingLabel?: ShippingLabel;
  shippingLabelId?: string;
  shippingCode?: string;
  laboratoryBranchId?: string;
  testType?: AnalysisType;
  testTypeId?: string;
  filed?: number;
  patientFormDocumentId?: string;
  patientFormId?: string;

  laboratory?: Laboratory;
  laboratoryId?: string;
  laboratoryBranch?: LaboratoryBranch;
  // fields?: AnalysisField[];

  // // description: string | undefined;
  // // lab?: Laboratory;
  // // campus?: LaboratoryBranch;
  // status?: AnalysisStatus;
  // roles?: any[];
  constructor() {}
}
