<div class="container">
  <ngx-spinner type="ball-spin-clockwise"></ngx-spinner>
  <!-- CODIGO PARA BREADCRUMBS -->
  <!-- <div class="w-full">
    <img *ngIf="svgPath" class="breadcrumb-top-image" [src]="svgPath" />
    <span class="primary-title breadcrumb-left">{{ firstBreadCrumb }}</span>
    <span class="breadcrumb-separator">/</span>
    <span class="secondary-title breadcrumb-right">
      {{ secondBreadCrumb }}
    </span>
  </div> -->

  <overa-stepper [items]="items" #stepper>
    <!-- <ng-container *ngIf="stepper.activeIndex === 0"> -->
    <ng-template #phase1 let-item>
      <!-- Step 1: añadir contenido de esta manera con el ngTemplate, indicando #phase y el numero de fase creamos otro step mas. -->
      <app-new-analysis></app-new-analysis>
    </ng-template>
    <!-- </ng-container> -->

    <ng-template #phase2 let-item>
      <app-new-analysis-patient></app-new-analysis-patient>
      <!-- Step 2 -->
    </ng-template>
    <ng-template #phase3 let-item>
      <!-- Step 3 -->
      <app-new-analysis-delivery></app-new-analysis-delivery>
    </ng-template>
  </overa-stepper>
  <!-- CODE below is for other proyect but adds continue button for page superior than 2 -->
  <!-- <div >

    <button class="btn-continuar" >
      CONTINUAR <i class="fa fa-arrow-right"></i>
    </button>
  </div> -->
</div>
