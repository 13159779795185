
export class BillingType {
  id?: string;
  name?: string;
  description?: string;
  maxALlowedInvoiceNumber?: number;
  monthlyBilling?: boolean;
  selected?: any;
  constructor() {

  }
}
