import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "@overa/security";
import { Observable } from "rxjs";
import { CustomerContactViewModel } from "../models/customer-contact-viewmodel.model";
import { CustomerSummaryViewModel } from "../models/customer-summary-viewmodel";
import { PaginatedResponse } from "../models/paginatedResponse.model";
import { ProjectManagerViewModel } from "../models/project-manager-viewmodel";
import { ProjectCustomerStateSummaryViewModel } from "../models/project-customer-state-summary-viewmodel.model";

@Injectable({ providedIn: "root" })
export class CustomerService {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/Projects";
  }

  constructor(private http: HttpClient) {}

  getCustomersAsync(): Observable<PaginatedResponse<CustomerSummaryViewModel>> {
    return this.http.get<PaginatedResponse<CustomerSummaryViewModel>>(
      `${this.baseUrl}/Customers`
    );
  }

  getCustomerContactsAsync(
    customerCode: string,
    pagesize?: number,
    pageIndex?: number
  ): Observable<PaginatedResponse<CustomerContactViewModel>> {
    let params = new HttpParams();

    if (pagesize !== undefined)
      params = params.set("pageSize", pagesize.toString());
    if (pageIndex !== undefined)
      params = params.set("pageIndex", pageIndex.toString());

    return this.http.get<PaginatedResponse<CustomerContactViewModel>>(
      `${this.baseUrl}/Customer/${customerCode}/Contacts`,
      { params }
    );
  }

  getCustomerStatesAsync(
    customerCode: string
  ): Observable<PaginatedResponse<ProjectCustomerStateSummaryViewModel>> {
    return this.http.get<
      PaginatedResponse<ProjectCustomerStateSummaryViewModel>
    >(`${this.baseUrl}/Customer/${customerCode}/States`);
  }

  getProjectManagers(): Observable<ProjectManagerViewModel[]> {
    // Reemplaza `any` con el tipo correcto de respuesta si lo tienes.
    return this.http.get<ProjectManagerViewModel[]>(
      `${this.baseUrl}/GetProjectManagersAsync`
    );
  }
}
