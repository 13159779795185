<div class="stepper-container" [ngClass]="gridHeaderClass">
  <ul class="list-unstyled multi-steps">
    <li
      *ngFor="let item of items; index as i"
      [ngClass]="{
        'is-active': activeIndex === i,
        'is-disabled': i > activeIndex
      }"
      (click)="i <= activeIndex && changePage(i)"
    ></li>
  </ul>
  <div class="step-content">
    <ng-container
      *ngIf="
        templates && templates.length > activeIndex;
        else defaultCardTemplate
      "
    >
      <ng-container
        *ngTemplateOutlet="
          templates.toArray()[activeIndex];
          context: { $implicit: null }
        "
      ></ng-container>
    </ng-container>

    <ng-template #defaultCardTemplate let-item>
      <h3>Uso del componente Overa Stepper</h3>
      <p>
        Este componente permite crear un "stepper" con múltiples pasos. Puedes
        agregar tantos pasos como desees utilizando la etiqueta
        &lt;ng-template&gt; y asignándoles un identificador local, como #phase1,
        #phase2, etc.
      </p>
      <p>
        Para agregar contenido a cada paso, simplemente coloca tu código HTML
        dentro de la etiqueta &lt;ng-template&gt; correspondiente. El componente
        mostrará automáticamente el contenido del paso activo según el índice
        seleccionado.
      </p>
      <p>
        También puedes pasar un array de objetos mediante el atributo [items]
        para controlar eventos y acciones personalizadas en cada paso.
      </p>
    </ng-template>
  </div>
</div>
