<ng-container
  *ngTemplateOutlet="
    cardTemplate ? cardTemplate : defaultCardTemplate;
    context: { $implicit: currentItem }
  "
></ng-container>
<ng-template #defaultCardTemplate let-currentItem>
  <div class="card custom-card" [ngClass]="cardClass" (click)="onCardClick()">
    <div class="card-header custom-header">
      <div class="card-title">
        <h3 class="custom-title key">
          {{ currentItem.title.key }}
        </h3>
        <h3 class="custom-title value-title">
          {{ currentItem.title.value }}
        </h3>
      </div>
      <div class="mid-header">
        <ng-container
          class="icons-menu"
          *ngIf="
            currentItem.headerIconsOptions &&
            currentItem.headerIconsOptions.length > 0
          "
        >
          <div class="background-icons">
            <ng-container *ngFor="let icon of currentItem.headerIconsOptions">
              <i
                [class]="
                  icon.iconClass ? 'pi ' + icon.iconClass + ' iconClass' : ''
                "
                (click)="icon.iconClick()"
                [ngStyle]="{ color: icon.color }"
                [title]="icon.tooltip"
              ></i>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="custom-percentage">
        <!-- TODO separate the next component to other project -->
        <ng-container *ngIf="showProgressSpinner">
          <lib-overa-progress-spinner
            [textColor]="bottomBorderColor ?? ''"
            [size]="50"
            [radius]="16"
            [percent]="currentItem.percentage"
            [incompletedColor]="unCompletedCircleColor"
          ></lib-overa-progress-spinner>
        </ng-container>
        <ng-container *ngIf="!showProgressSpinner">
          <i
            [class]="iconName ? 'pi ' + iconName + ' black-icon' : ''"
            (click)="onIconClick()"
            [title]="iconHint ? iconHint : ''"
          ></i>
        </ng-container>
      </div>
      <div
        class="custom-menuOptions"
        *ngIf="currentItem.menuOptions && currentItem.menuOptions.length > 0"
      >
        <ng-container class="menu">
          <div class="dropdown" (click)="toggleDropdown($event)">
            <span class="value-title custom-title">...</span>
            <div *ngIf="dropdownOpen" class="dropdown-content">
              <ng-container *ngFor="let opcion of currentItem.menuOptions">
                <div class="title-container" (click)="opcion.callBack()">
                  <p class="menu-title">
                    {{ opcion.title }}
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="card-body custom-body">
      <ng-container *ngFor="let field of currentItem.fields">
        <div class="card-info">
          <p class="custom-info key">{{ field.key }}</p>
          <span class="custom-info key">:</span>
          <p class="custom-info value-info">{{ field.value }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
