import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "overa-checkbox",
  templateUrl: "overa-checkbox.component.html",
  styleUrls: ["overa-checkbox.component.scss"],
})
export class OveraCheckboxComponent {
  @Input() checked = false;
  @Input() isDisabled = true;
  @Input() id = "1";
  @Input() controlName: string | null = null;
  @Input() formGroup?: FormGroup;
  @Output() checkedChange = new EventEmitter<boolean>();

  onCheckboxChange() {
    this.checkedChange.emit(this.checked);
  }
}
