import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core";
import { filter, Subscription } from "rxjs";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
  PopStateEvent,
} from "@angular/common";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { Menu, MenuService } from "@overa/security";
import { ConfigShared } from "@overa/shared";
import { ConfigBase, Link, NavbarComponent } from "@overa/base";
import PerfectScrollbar from "perfect-scrollbar";
import { Globals } from "../utils/globals";
import { OveraMenuItem } from "../shared/temporal_components/overa-menubar/menuitem.model";
declare const $: any;

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, OnDestroy {
  static className = "LayoutComponent";

  private lastPoppedUrl?: string;
  private yScrollStack: number[] = [];
  url?: string;
  isNewDesign = ConfigShared.appConfig.useNewDesign;

  @Input()
  links: Link[] = [];
  menuItems: Menu[] = [];
  menubarItems: OveraMenuItem[] = [];
  userPermissionsLoadedSubscription?: Subscription;
  languages!: string[];
  selectedLanguage?: string;

  @ViewChild("sidebar", { static: false }) sidebar: any;
  @ViewChild(NavbarComponent, { static: false }) navbar?: NavbarComponent;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private globals: Globals,
    private menuService: MenuService,
    private translate: TranslateService
  ) {}

  closePopup() {
    const body = document.getElementsByTagName("body")[0];
    const sidebar = document.getElementsByClassName("rout-layaout")[0];

    setTimeout(function () {
      body.classList.remove("hide-prueba");
    }, 300);
    setTimeout(function () {
      sidebar.classList.remove("animation");
    }, 600);
    sidebar.classList.add("animation");
    this.router.navigate([{ outlets: { secondRouter: null } }]);
  }

  ngOnInit() {
    this.setFixedMenuItems();
    this.calculateMenuItems();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.setFixedMenuItems();
      });

    if (this.isNewDesign)
      document.getElementsByTagName("body")[0].classList.add("newDesign");
    this.menuItems = ConfigBase.menuItems;
    this.activatedRoute.data.subscribe((data) => {
      console.log("SET LINKS", data["links"]);
      this.links = data["links"] as Link[];
    });
    const elemMainPanel = <HTMLElement>(
      document.querySelector(".container-level-2")
    );
    const elemSidebar = <HTMLElement>(
      document.querySelector(".sidebar .sidebar-wrapper")
    );
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, Number(this.yScrollStack.pop()));
        } else window.scrollTo(0, 0);
      }
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
      });
    const html = document.getElementsByTagName("html")[0];
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
      html.classList.add("perfect-scrollbar-on");
    } else {
      html.classList.add("perfect-scrollbar-off");
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.navbar?.sidebarClose();
      });
  }
  calculateMenuItems() {
    this.languages = this.translate.getLangs();
    this.selectedLanguage = this.translate.currentLang;
    let arrayItemsLang: any[] = [];
    // this.languages.forEach((lang) => {
    //   let itemLang: any = {
    //     componentName: "",
    //     enabledForAll: false,
    //     icon: "",
    //     iconPosition: null,
    //     id: "",
    //     isNavIcon: true,
    //     isVisible: true,
    //     label: "",
    //     showName: true,
    //     svg: "",
    //     toolTip: "",
    //     uiUrl: "",
    //     command: () => {},
    //   };
    //   itemLang.label = lang;
    //   arrayItemsLang.push(itemLang);
    // });
    const langs: any = {
      type: "dropdown",
      label: this.languages,
      selectedId: this.selectedLanguage,
      command: () => {},
      items: this.languages.map((l) => {
        return { id: l, label: l };
      }),
    };

    this.menuItems = ConfigBase.menuItems;
    console.log("menuItems", this.menuItems);
    var menuItemsFromService: any[] = [
      ...this.menuItems.filter(
        (x) => x.isNavIcon || x.parentId || x.toolTip === "SelectLanguage"
      ),
    ];
    console.log("menuItemsFromService", menuItemsFromService);
    menuItemsFromService.forEach((menu) => {
      if (menu.uiUrl) {
        menu.command = (event: any) => {
          this.router.navigateByUrl(menu.uiUrl);
        };
      }
      if (menu.children?.length) {
        menu.children.forEach((c: any) => {
          if (c.showName) {
            c.label = c.name;
          }
          if (c.uiUrl) {
            c.command = (event: any) => {
              this.router.navigateByUrl(c.uiUrl);
            };
          }
        });
      }
      menu.items = menu.children;
      if (menu.parentId) {
        menu.position = "left";
      }
      if (menu.name === "SelectLanguage") {
        menu.isNavIcon = true;
        menu.isVisible = true;
        // menu.collapse = "child" + menu.id.toString();
        menu.items = langs.items;
        menu.command = (key: any) => {
          if (!this.selectedLanguage || key != this.selectedLanguage) {
            this.selectedLanguage = this.languages.find((l) => l === key);
            if (this.selectedLanguage) {
              localStorage.setItem("locale", this.selectedLanguage);
              this.translate.use(this.selectedLanguage);
              this.translate.setDefaultLang(this.selectedLanguage);
              localStorage.setItem("app-language", this.selectedLanguage);
              location.reload();
            }
            console.log(
              "SELECTED LANGUAGE ------------>",
              this.selectedLanguage
            );
          }
        };
        // menu.label = langs.label;
        menu.label = "Idioma";
        menu.type = langs.type;
        menu.selectedId = this.selectedLanguage;
      }
    });

    this.menubarItems = [...this.menubarItems, ...menuItemsFromService];
    console.log("menubarItems", this.menubarItems);
  }
  public isMap() {
    if (
      this.location.prepareExternalUrl(this.location.path()) ===
      "/maps/fullscreen"
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  selectLang() {
    var url = this.router.url.toString();

    window.location.href = window.location.href;
  }

  setFixedMenuItems() {
    if (!this.globals.user.isGlobalAdmin) {
      var itemSelected = ConfigBase.menuItems.find(
        (x: any) =>
          x.name == this.globals.datarRoleName ||
          x.name == this.globals.labRoleName
      );
      var itemDiagnostica = ConfigBase.menuItems.find(
        (x: any) => x.name == this.globals.diagnosticaRoleName
      );
      if (itemSelected) {
        this.menuService.updateMainRoute(itemSelected);
      }
      if (itemDiagnostica) {
        this.menuService.updateMainRoute(itemDiagnostica);
      }
      console.log(this.menuItems);
    }
  }
}
