import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { NgxSpinnerService } from "ngx-spinner";
import { SampleExists } from "src/app/models/sample-exists.model";
import { SampleService } from "src/app/services/sample.service";

@Component({
  selector: "app-validate-sample-code-step1",
  templateUrl: "./validate-sample-code-step1.component.html",
  styleUrls: ["./validate-sample-code-step1.component.scss"],
})
export class ValidateSampleCodeStep1Component implements OnInit, OnDestroy {
  code: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private sampleService: SampleService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService
  ) {}
  ngOnInit() {
    this.code = "";
  }
  ngOnDestroy() {}
  next() {
    if (this.code !== "") {
      console.log("**code**", this.code);
      this.spinner.show("validateSampleStep1");
      this.sampleService.checkExistingSample(this.code).subscribe({
        next: (exist) => {
          let objectExists = new SampleExists();
          objectExists = exist;
          if (objectExists && objectExists.exists === true) {
            this.spinner.hide("validateSampleStep1");
            this.router.navigate(["/validateSample/2/", objectExists.code]);
          } else {
            this.spinner.hide("validateSampleStep1");
            this.messagesService.showToast(
              "MESSAGES.INFO.INFO",
              "VALIDATE_SAMPLE_STEP1.MESSAGES_INFO_CODE",
              "info",
              ""
            );
          }
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("validateSampleStep1");
        },
      });
    }
  }
}
