import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NoticePanel } from "src/app/models/notice-panel.model";

@Component({
  selector: "app-panel-notices-instructions",
  templateUrl: "./panel-notices-instructions.component.html",
  styleUrls: ["./panel-notices-instructions.component.scss"],
})
export class PanelNoticesInstructionsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() listPanel: NoticePanel[] = [];
  @Input() titleHeader: string = "";
  @Output() sendListPanel = new EventEmitter<any[]>();
  titleError: boolean = false;
  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // this.titleError = false;
    console.log(this.listPanel);
    this.titleError = false;
    this.cd.detectChanges();
  }
  ngOnDestroy() {}
  ngOnChanges(changes: SimpleChanges) {
    this.titleError = false;
    console.log(changes);
    if (changes["listPanel"]) {
      changes["listPanel"].currentValue.forEach((value: NoticePanel) => {
        if (value.error === true) {
          this.titleError = true;
        }
      });
    }
    this.cd.detectChanges();
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
