import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  ChangeDetectorRef,
} from "@angular/core";
import {
  AnalysisType,
  DetailDocumentLanguage,
  DetailInstructions,
  DetailLanguage,
  DetailStorage,
  DocumentLanguage,
} from "src/app/models/analysis-type.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AppPermissions } from "src/app/models/app-permission.model";
import { AnalysisTypeService } from "src/app/services/analysis-type.service";
import { BaseFormComponent, AccountService, Hotkeys } from "@overa/security";
import { OveraMessagesService } from "@overa/components";
import { CifValidator } from "../../../../utils/validators";
import { OveraKeyValuePair } from "../../../../shared/temporal_components/models/overa-keyValue-pair.model";
import { ConfirmationService } from "primeng/api";
import { Utils } from "../../../../utils/utils";
import { forkJoin, Observable, of } from "rxjs";
import { BillingTypeService } from "../../../../services/billing-type.service";
import { BillingType } from "../../../../models/billing-type.model";
import { LaboratoryBranchService } from "../../../../services/laboratory-branch.service";
import { AnalysisSubtype } from "../../../../models/analysis-subtype.model";
import { AnalysisSubtypeService } from "../../../../services/analysis-subtype.service";
import { DataTableColumn } from "../../../../shared/temporal_components/models/dataTablecolumn.model";
import { TestTypeInstructions } from "src/app/models/test-type-instructions.model";
import { Instructions } from "src/app/models/instructions.model";
import { ConfigStorage } from "@overa/storage";
import {
  PatientForm,
  PatientForms,
  TestTypePatientForms,
} from "src/app/models/test-type-patient-forms.model";
import {
  ManualForm,
  Manuals,
  TestTypeManuals,
} from "src/app/models/test-type-manuals.model";
interface LanguageObject {
  [lang: string]: string;
}
@Component({
  selector: "app-analysis-type-form",
  templateUrl: "./analysis-type-form.component.html",
  styleUrls: ["./analysis-type-form.component.scss"],
  providers: [ConfirmationService],
})
export class AnalysisTypeFormComponent
  extends BaseFormComponent<AnalysisType>
  implements OnInit, OnDestroy, OnChanges
{
  static className = "AnalysisTypeFormComponent";
  form: FormGroup = new FormGroup({});
  formFile: FormGroup = new FormGroup({});
  @Input()
  readonly = false;
  loadedForm = false;
  loadedFormFile = false;
  @Input()
  id: string | null = null;
  salesTypes: OveraKeyValuePair[] = [];
  selectedCampus = false;
  subtypes: AnalysisSubtype[] = [];
  languageColumns: DataTableColumn[] = [];
  newTestypeInstructions: TestTypeInstructions = new TestTypeInstructions();
  defaultSampleDeliveryInstructions: DetailInstructions[] = [];
  defaultSampleProcedureInstructions: DetailInstructions[] = [];
  patientManualLanguages: any[] = [];
  patientFormLanguages: any[] = [];
  languages: string[] = [];
  storageId: string = ConfigStorage.storageId;
  newTestypePatientForms: TestTypePatientForms = new TestTypePatientForms();
  newTestypeManuals: TestTypeManuals = new TestTypeManuals();
  isDeleting: boolean = false;
  isSaving: boolean = false;
  // nameManual: string = "Manual";
  // namePatientForm: string = "PatientForm";

  constructor(
    public override accountService: AccountService,
    public override router: Router,
    public override hotkeys: Hotkeys,
    private analysisTypeService: AnalysisTypeService,
    private analysisSubtypeService: AnalysisSubtypeService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private utils: Utils,
    private cdr: ChangeDetectorRef
  ) {
    super(accountService, router, hotkeys);
    console.log("analysis-type-form");
    this.viewPermission = AppPermissions.PERMISSIONS_DATA.viewTestType.value;
    this.createPermission =
      AppPermissions.PERMISSIONS_DATA.createTestType.value;
    this.deletePermission =
      AppPermissions.PERMISSIONS_DATA.deleteTestType.value;
    this.updatePermission =
      AppPermissions.PERMISSIONS_DATA.updateTestType.value;
    this.canView = true;
    this.canCreate = true;
    this.canUpdate = true;
    this.canDelete = true;

    this.PK = "id";
    this.baseRoute = "/maintenances/analysis-type";

    this.translate
      .get("ANALYSIS_TYPE_MAINTENANCE.NAME")
      .subscribe((a: string) => {
        this.componentTypeTranslated = a;
      });

    this.onCleanForm = () => {};

    this.onCancelForm = () => {
      this.router.navigateByUrl(this.baseRoute);
    };

    this.onEnableForm = () => {
      if (!this.readonly) {
        this.form.enable();
        this.enabled = true;
      }
    };
    this.onInit = () => this.init();
  }
  initDropdowns(): Observable<[AnalysisSubtype[]]> {
    return forkJoin([this.analysisSubtypeService.get()]);
  }

  private init() {
    this.defaultSampleProcedureInstructions = [];
    this.defaultSampleDeliveryInstructions = [];
    this.patientFormLanguages = [];
    this.patientManualLanguages = [];
    this.isDeleting = false;
    this.isSaving = false;
    this.languages = this.translate.getLangs();
    this.createFormFile();
    this.languageColumns = this.createColumns();
    this.route.paramMap.subscribe((params) => {
      this.spinner.show();
      const id = params.get("id");

      if (!this.readonly) {
        this.form.enable();
        this.enabled = true;
      }
      //this.initDropdowns().subscribe({
      //  next: ([analysisSubtype]: [AnalysisSubtype[]]) => {

      this.data = new AnalysisType();
      // this.data.subtypes = this.subtypes.length ? this.subtypes[0] : undefined;
      this.createForm();
      if (id) {
        this.spinner.show();
        if (id === "new") {
          this.setLanguages();
          this.detail = false;

          this.selectedDescription = "";

          this.spinner.hide();
          this.loadedForm = true;
        } else {
          this.detail = true;

          this.analysisTypeService.getById(id).subscribe({
            next: (response: AnalysisType) => {
              console.log(
                "RESPONSE GETBYID",
                response,
                this.translate.defaultLang
              );
              this.spinner.hide();
              this.detail = true;
              if (!this.readonly) {
                this.form.enable();
                this.enabled = true;
              }

              this.data = response;
              this.data.priceValidityDate = new Date(
                this.data.priceValidityDate || new Date()
              );
              var formData: AnalysisType = new AnalysisType();
              formData.name = this.data.name;

              formData.description = this.data.description;
              formData.deliveryTerm = this.data.deliveryTerm;
              formData.sampleType = this.data.sampleType;
              // formData.subtype = this.subtypes.find(x => x.id == this.data.subtypeId);

              formData.sampleValidityTime = this.data.sampleValidityTime;
              formData.priceValidityDate = this.data.priceValidityDate;
              formData.diagnosticaPrice = this.data.diagnosticaPrice;
              this.mapInstructions();

              this.setLanguages();
              this.form.enable();
              this.form.setValue({ ...formData });
              this.cdr.detectChanges();
              this.loadedForm = true;
              this.loadedFormFile = true;
              this.selectedDescription = response.name || "";
            },
            error: (error) => {
              this.spinner.hide();
              if (error.status === 500 || error.status === 0) {
                this.messagesService.showToast(
                  "ERROR.CRITICAL",
                  error.error,
                  "error",
                  "",
                  ""
                );
              } else {
                this.messagesService.showToast(
                  "ERROR.CRITICAL",
                  "ERROR",
                  "error",
                  "",
                  ""
                );
              }
              this.loadedForm = true;
              // TODO : Mensaje error
            },
          });
        }
      } else {
        this.selectedDescription = "";
        this.detail = false;
        this.form.disable();
        this.enabled = false;
        this.data = new AnalysisType();
        this.spinner.hide();
        this.loadedForm = true;
        this.loadedFormFile = true;
      }
      //  }, error: (err) => {
      //    console.log(err);
      //  }
      //});
    });
    // });
  }
  mapInstructions() {
    if (this.data.defaultSampleProcedureInstructions) {
      this.data.defaultSampleProcedureInstructions.documentLanguages?.forEach(
        (item) => {
          let detailLanguage = new DetailInstructions();
          detailLanguage.id = item.id;
          detailLanguage.text = item.text;
          detailLanguage.languageId = item.languageId;
          detailLanguage.name = item.language?.name;
          this.defaultSampleProcedureInstructions.push(detailLanguage);
        }
      );
    }
    if (this.data.defaultSampleDeliveryInstructions) {
      this.data.defaultSampleDeliveryInstructions.documentLanguages?.forEach(
        (item) => {
          let detailLanguage = new DetailInstructions();
          detailLanguage.id = item.id;
          detailLanguage.text = item.text;
          detailLanguage.languageId = item.languageId;
          detailLanguage.name = item.language?.name;
          this.defaultSampleDeliveryInstructions.push(detailLanguage);
        }
      );
    }
    if (this.data.manual) {
      this.data.manual.documentLanguages?.forEach((item) => {
        let detailManual = new DetailStorage();
        detailManual.id = item.id;
        detailManual.fileName = item.fileName;
        detailManual.languageId = item.languageId;
        detailManual.storageId = item.storageId;
        detailManual.language = item.language?.name;
        var fileData = {
          guid: item.storageId,
          name: item.fileName,
        };
        detailManual.fileDataJson = JSON.stringify(fileData);
        this.patientManualLanguages.push(detailManual);
      });
    } else {
      let countManual = 0;
      this.languages.forEach((item) => {
        let isFoundManual = this.patientManualLanguages.find(
          (itemDetail: DetailStorage) => itemDetail.language === item
        );
        if (isFoundManual) {
          isFoundManual.controlName = "fileManual" + countManual.toString();
          countManual++;
        } else {
          let detailManual = new DetailStorage();
          detailManual.language = item;
          detailManual.fileDataJson = "";
          detailManual.storageId = "";
          detailManual.fileName = "";
          detailManual.controlName = "fileManual" + countManual.toString();
          countManual++;
          this.patientManualLanguages.push(detailManual);
        }
      });
      console.log("patientManualLanguages", this.patientManualLanguages);
    }
    if (this.data.patientForm) {
      this.data.patientForm.documentLanguages?.forEach((item) => {
        let detailPatientForm = new DetailStorage();
        detailPatientForm.id = item.id;
        detailPatientForm.fileName = item.fileName;
        detailPatientForm.languageId = item.languageId;
        detailPatientForm.storageId = item.storageId;
        detailPatientForm.language = item.language?.name;
        var fileData = {
          guid: item.storageId,
          name: item.fileName,
        };
        detailPatientForm.fileDataJson = JSON.stringify(fileData);
        this.patientFormLanguages.push(detailPatientForm);
      });
    } else {
      let countPatient = 0;
      this.languages.forEach((item) => {
        let isFoundPatient = this.patientFormLanguages.find(
          (itemDetail: DetailStorage) => itemDetail.language === item
        );
        if (isFoundPatient) {
          isFoundPatient.controlName =
            "filePatientForm" + countPatient.toString();
          countPatient++;
        } else {
          let detailPatientForm = new DetailStorage();
          detailPatientForm.language = item;
          detailPatientForm.fileDataJson = "";
          detailPatientForm.storageId = "";
          detailPatientForm.fileName = "";
          detailPatientForm.controlName =
            "filePatientForm" + countPatient.toString();
          countPatient++;
          this.patientFormLanguages.push(detailPatientForm);
        }
      });
      console.log("patientPatientFormLanguages", this.patientFormLanguages);
    }
  }
  createForm() {
    this.form = new FormGroup({
      name: new FormControl<string>("", [Validators.required]),
      description: new FormControl<string>("", [Validators.required]),
      //  subtype: new FormControl<AnalysisSubtype>(this.data.subtype || new AnalysisSubtype()),
      sampleType: new FormControl<string>("", [Validators.required]),
      // state: new FormControl<boolean>(true, [Validators.required]),
      sampleValidityTime: new FormControl<number>(0, [Validators.required]),
      deliveryTerm: new FormControl<number>(0, [Validators.required]),
      priceValidityDate: new FormControl<Date>(new Date(), [
        Validators.required,
      ]),
      diagnosticaPrice: new FormControl<number>(0, [Validators.required]),
    });
  }
  createFormFile() {
    this.formFile = new FormGroup({});
  }
  onAddFile(event: any, attachment: any) {
    console.log(event, attachment);
    if (event?.file?.id) {
      var fileData = {
        guid: event.file.id,
        name: event.file.displayName,
      };
      attachment.fileDataJson = JSON.stringify(fileData);
      attachment.storageId = event.file.id;
      attachment.fileName = event.file.displayName;
    }
    console.log(
      attachment,
      this.patientFormLanguages,
      this.patientManualLanguages
    );
    this.patientManualLanguages = [...this.patientManualLanguages];
    this.patientFormLanguages = [...this.patientFormLanguages];
    this.cdr.detectChanges();
    console.log(this.patientFormLanguages, this.patientManualLanguages);
  }

  fileDeleted(event: any, attachment: any) {
    console.log("DELETE FILE: ", event);
    attachment.patientManualStorageId = "";
    attachment.patientManualFileName = "";
  }
  // ======================== CRUD ========================
  ngOnChanges() {}

  setLanguages() {
    var languages = this.translate.getLangs();
    // this.instructions = [];
    if (!this.defaultSampleDeliveryInstructions) {
      this.defaultSampleDeliveryInstructions = [];
    }
    if (!this.defaultSampleProcedureInstructions) {
      this.defaultSampleProcedureInstructions = [];
    }
    languages.forEach((lang) => {
      if (
        !this.defaultSampleDeliveryInstructions
          .map((i) => i.name)
          .includes(lang)
      ) {
        this.defaultSampleDeliveryInstructions.push({ name: lang, text: "" });
      }
      if (
        !this.defaultSampleProcedureInstructions
          .map((i) => i.name)
          .includes(lang)
      ) {
        this.defaultSampleProcedureInstructions.push({ name: lang, text: "" });
      }
      // if (
      //   !this.defaultSampleDeliveryInstructions.map((i) => i.key).includes(lang)
      // ) {
      //   this.defaultSampleDeliveryInstructions.push({ key: lang, value: "" });
      // }
      // if (
      //   !this.defaultSampleProcedureInstructions
      //     .map((i) => i.key)
      //     .includes(lang)
      // ) {
      //   this.defaultSampleProcedureInstructions.push({ key: lang, value: "" });
      // }
      let isLanguageIncludedManual = this.patientManualLanguages.some(
        (item: DetailStorage) => item.language === lang
      );
      let isLanguageIncludedPatientForm = this.patientFormLanguages.some(
        (item: DetailStorage) => item.language === lang
      );
      if (!isLanguageIncludedManual) {
        let detailManual = new DetailStorage();
        detailManual.language = lang;
        detailManual.fileDataJson = "";
        detailManual.storageId = "";
        detailManual.fileName = "";
        this.patientManualLanguages.push(detailManual);
      }
      if (!isLanguageIncludedPatientForm) {
        let detailPatientForm = new DetailStorage();
        detailPatientForm.language = lang;
        detailPatientForm.fileDataJson = "";
        detailPatientForm.storageId = "";
        detailPatientForm.fileName = "";
        this.patientFormLanguages.push(detailPatientForm);
      }
    });
    this.defaultSampleDeliveryInstructions =
      this.defaultSampleDeliveryInstructions.sort((a, b) => (a > b ? 1 : -1));
    this.defaultSampleProcedureInstructions =
      this.defaultSampleProcedureInstructions.sort((a, b) => (a > b ? 1 : -1));
    this.addControlNameAndAddFormControlToFormFile();
  }
  addControlNameAndAddFormControlToFormFile() {
    let count = 0;
    let count1 = 0;

    this.patientFormLanguages.forEach((item) => {
      item.controlName = "filePatientForm" + count.toString();
      this.formFile.addControl(
        item.controlName,
        new FormControl<any>(null, [])
      );
      count++;
    });
    this.patientManualLanguages.forEach((item) => {
      item.controlName = "fileManual" + count1.toString();
      this.formFile.addControl(
        item.controlName,
        new FormControl<any>(null, [])
      );
      count1++;
    });
    this.patientFormLanguages = [...this.patientFormLanguages];
    this.patientManualLanguages = [...this.patientManualLanguages];
  }

  //ngOnChanges() {
  //  this.init();
  //  this.campusService.notifyupdateRoute(null);
  //}

  setData() {
    //var instructions = [...this.data.instructions || []];

    this.data = { ...this.data, ...this.form.value };
    if (this.detail) {
      this.newTestypeInstructions.testTypeId = this.data.id;
      let instructionsDelivery = new Instructions();
      let instructionsProcedure = new Instructions();
      instructionsDelivery.defaultLanguageLocale = this.translate.defaultLang;

      if (this.defaultSampleDeliveryInstructions) {
        instructionsDelivery.languageInstructions = {};
        this.defaultSampleDeliveryInstructions.forEach((item) => {
          if (instructionsDelivery.languageInstructions) {
            if (item) {
              let lang = item.name ? item.name : "";
              let value = item.text ? item.text : "";
              instructionsDelivery.languageInstructions[lang] = value;
            }
          }
        });
      }
      instructionsProcedure.defaultLanguageLocale = this.translate.defaultLang;
      if (this.defaultSampleProcedureInstructions) {
        instructionsProcedure.languageInstructions = {};
        this.defaultSampleProcedureInstructions.forEach((item) => {
          if (instructionsProcedure.languageInstructions) {
            if (item) {
              let lang = item.name ? item.name : "";
              let value = item.text ? item.text : "";
              instructionsProcedure.languageInstructions[lang] = value;
            }
          }
        });
      }
      this.newTestypeInstructions.defaultSampleDeliveryInstructions =
        instructionsDelivery;
      this.newTestypeInstructions.defaultSampleProcedureInstructions =
        instructionsProcedure;
      console.log("newTestypeInstructions", this.newTestypeInstructions);

      delete this.data.defaultSampleDeliveryInstructions;
      delete this.data.defaultSampleProcedureInstructions;

      this.newTestypeManuals.testTypeId = this.data.id;
      let manuals = new Manuals();
      manuals.defaultLanguageLocale = this.translate.defaultLang;
      if (this.patientManualLanguages) {
        manuals.languageManuals = {};
        this.patientManualLanguages.forEach((item) => {
          // manuals.name = this.nameManual;
          if (manuals.languageManuals) {
            if (item && item.fileName !== "" && item.storageId !== "") {
              let lang = item.language ? item.language : "";
              let value = new ManualForm();
              value.fileName = item.fileName;
              value.storageId = item.storageId;
              manuals.languageManuals[lang] = value;
            }
          }
        });
      }
      this.newTestypeManuals.testTypeManuals = manuals;
      delete this.data.manual;

      this.newTestypePatientForms.testTypeId = this.data.id;
      let patientsForms = new PatientForms();
      patientsForms.defaultLanguageLocale = this.translate.defaultLang;

      if (this.patientFormLanguages) {
        patientsForms.languagePatientForms = {};
        this.patientFormLanguages.forEach((item) => {
          // patientsForms.name = this.namePatientForm;
          if (patientsForms.languagePatientForms) {
            if (item && item.fileName !== "" && item.storageId !== "") {
              let lang = item.language ? item.language : "";
              let value = new PatientForm();
              value.fileName = item.fileName;
              value.storageId = item.storageId;
              patientsForms.languagePatientForms[lang] = value;
            }
          }
        });
      }
      this.newTestypePatientForms.testTypePatientForms = patientsForms;
      delete this.data.patientForm;
    }

    this.data.priceValidityDate = this.utils.dateToUtc(
      this.data.priceValidityDate || new Date()
    );
  }

  create() {
    this.spinner.show();
    this.setData();

    this.analysisTypeService.add(this.data).subscribe({
      next: (response: any) => {
        this.data = response;

        this.messagesService.showToast(
          "MESSAGES.SUCCESS.CREATE",
          "MESSAGES.SUCCESS.CREATE_LONG",
          "success",
          this.data.name || "",
          "ANALYSIS_TYPE_MAINTENANCE.TITLE"
        );
        this.router.navigateByUrl(this.baseRoute + "/" + this.data.id);
        //this.router.navigateByUrl("/maintenances/analysisType");
        this.spinner.hide();
      },
      error: (err: any) => {
        this.spinner.hide();
        this.messagesService.showToast(
          "ERROR.CRITICAL",
          "ERROR",
          "error",
          "",
          ""
        );
      },
    });
  }

  update() {
    this.isSaving = true;
    this.spinner.show();
    this.setData();
    console.log(this.data);
    this.analysisTypeService.update(this.data).subscribe({
      next: () => {
        let hasFilenameNewTestypePatientForms =
          this.hasValidFilenameInTestTypePatientForm(
            this.newTestypePatientForms
          );
        let hasFilenameNewTestypeManuals =
          this.hasValidFilenameInTestTypeManuals(this.newTestypeManuals);
        if (
          this.detail &&
          (this.newTestypeInstructions ||
            hasFilenameNewTestypePatientForms ||
            hasFilenameNewTestypeManuals)
        ) {
          if (this.newTestypeInstructions) {
            this.updateInstructions();
          } else {
            if (hasFilenameNewTestypePatientForms) {
              this.updatePatientForms();
            } else {
              if (hasFilenameNewTestypeManuals) {
                this.updateManuals();
              } else {
                this.showMessagesAndNavigate(this.data);
              }
            }
          }
        } else {
          this.showMessagesAndNavigate(this.data);
        }
      },
      error: (err) => {
        this.isSaving = false;
        this.spinner.hide();
        this.messagesService.showToast(
          "ERROR.CRITICAL",
          "ERROR",
          "error",
          "",
          ""
        );
      },
    });
  }
  showMessagesAndNavigate(data: any) {
    this.messagesService.showToast(
      "MESSAGES.SUCCESS.UPDATE",
      "MESSAGES.SUCCESS.UPDATE_LONG",
      "success",
      data.name || "",
      "ANALYSIS_TYPE_MAINTENANCE.TITLE"
    );
    this.isSaving = false;
    // this.ngOnInit();
    this.router
      .navigateByUrl("/", {
        skipLocationChange: true,
      })
      .then(() => {
        this.router.navigate([this.baseRoute + "/" + this.data.id]);
      });
    this.spinner.hide();
  }
  updateInstructions() {
    this.analysisTypeService
      .updateDefaultSampleInstructions(this.newTestypeInstructions)
      .subscribe({
        next: () => {
          let hasFilenameNewTestypePatientForms =
            this.hasValidFilenameInTestTypePatientForm(
              this.newTestypePatientForms
            );
          let hasFilenameNewTestypeManuals =
            this.hasValidFilenameInTestTypeManuals(this.newTestypeManuals);
          if (
            hasFilenameNewTestypePatientForms ||
            hasFilenameNewTestypeManuals
          ) {
            if (hasFilenameNewTestypePatientForms) {
              this.updatePatientForms();
            } else {
              if (hasFilenameNewTestypeManuals) {
                this.updateManuals();
              } else {
                this.showMessagesAndNavigate(this.data);
              }
            }
          } else {
            this.showMessagesAndNavigate(this.data);
          }
        },
        error: (err) => {
          this.isSaving = false;
          this.spinner.hide();
          this.messagesService.showToast(
            "ERROR.CRITICAL",
            "ERROR",
            "error",
            "",
            ""
          );
        },
      });
  }
  updateManuals() {
    this.analysisTypeService
      .updateSampleManuals(this.newTestypeManuals)
      .subscribe({
        next: () => {
          this.showMessagesAndNavigate(this.data);
        },
        error: (err) => {
          this.isSaving = false;
          this.spinner.hide();
          this.messagesService.showToast(
            "ERROR.CRITICAL",
            "ERROR",
            "error",
            "",
            ""
          );
        },
      });
  }
  updatePatientForms() {
    this.analysisTypeService
      .updateSamplePatientForms(this.newTestypePatientForms)
      .subscribe({
        next: () => {
          let hasFilenameNewTestypeManuals =
            this.hasValidFilenameInTestTypeManuals(this.newTestypeManuals);
          if (hasFilenameNewTestypeManuals) {
            this.updateManuals();
          } else {
            this.showMessagesAndNavigate(this.data);
          }
        },
        error: (err) => {
          this.isSaving = false;
          this.spinner.hide();
          this.messagesService.showToast(
            "ERROR.CRITICAL",
            "ERROR",
            "error",
            "",
            ""
          );
        },
      });
  }

  delete() {
    this.isDeleting = true;
    let trmessage,
      messageAccept = "";
    this.translate.get("COMMONS.ASK_DELETE_RECORD").subscribe((a: string) => {
      trmessage = a;
    });
    this.translate.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        this.analysisTypeService.delete(this.data.id ?? "").subscribe({
          next: () => {
            this.messagesService.showToast(
              "MESSAGES.SUCCESS.DELETE",
              "MESSAGES.SUCCESS.DELETE_LONG",
              "success",
              this.data.name || "",
              "ANALYSIS_TYPE_MAINTENANCE.TITLE"
            );
            this.isDeleting = false;
            this.router.navigateByUrl(this.baseRoute);
            this.spinner.hide();
          },
          error: (err) => {
            console.log(err);
            this.messagesService.showToast(
              "ERROR.CRITICAL",
              "ERROR",
              "error",
              "",
              ""
            );
            this.isDeleting = false;
            this.spinner.hide();
          },
        });
      },
      reject: () => {
        this.isDeleting = false;
      },
    });
  }
  onSelectUser(user: any) {
    console.log(user);
  }
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "name",
        header: this.translate.instant("COMMON_FORMS.LANGUAGE"),
        type: "System.String",
        responsive: true,
        responsiveHeader: true,
      },

      {
        field: "text",
        header: this.translate.instant("COMMON_FORMS.INSTRUCTIONS_HEADER"),
        type: "OveraInputEditor",
        icon: "pi pi-pencil",
      },
    ];
  }
  hasValidFilenameInTestTypePatientForm(
    testTypePatientForms: TestTypePatientForms
  ): boolean {
    if (
      !testTypePatientForms.testTypePatientForms ||
      !testTypePatientForms.testTypePatientForms.languagePatientForms
    ) {
      return false;
    }
    const languagePatientForms =
      testTypePatientForms.testTypePatientForms.languagePatientForms;
    for (const lang in languagePatientForms) {
      if (languagePatientForms.hasOwnProperty(lang)) {
        const patientForm = languagePatientForms[lang];
        if (this.hasValidFilename(patientForm)) {
          return true;
        }
      }
    }

    return false;
  }
  hasValidFilename(form: any): boolean {
    return !!form.fileName && form.fileName.trim() !== "";
  }
  hasValidFilenameInTestTypeManuals(testTypeManuals: TestTypeManuals): boolean {
    if (
      !testTypeManuals.testTypeManuals ||
      !testTypeManuals.testTypeManuals.languageManuals
    ) {
      return false;
    }
    const languageManuals = testTypeManuals.testTypeManuals.languageManuals;
    for (const lang in languageManuals) {
      if (languageManuals.hasOwnProperty(lang)) {
        const manual = languageManuals[lang];
        if (this.hasValidFilename(manual)) {
          return true;
        }
      }
    }
    return false;
  }
}
