import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";



interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}



@Component({
  selector: "overa-autocomplete",
  templateUrl: "./overa-autocomplete.component.html",
  styleUrls: ["./overa-autocomplete.component.scss"],
})
export class OveraAutoCompleteComponent<T> {
  @Input() title: string = "";
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() formGroup: FormGroup | undefined;
  @Input() controlName: string | null = null;



  @Input() displayField: string = "name";



  @Input()
  filterFunction?: (query: string) => T[];



  @Input() selectedItems: T[] = [];



  filteredItems: T[] =[];



  filterItem(event: AutoCompleteCompleteEvent) {
    this.filteredItems = [];
    if (this.filterFunction) {
      this.filteredItems = this.filterFunction(event.query);
    }
  }



  @Output() selectedItemsChange = new EventEmitter<T[]>();
  onSelectedItemsChange(newSelectedItems: T[]) {
    this.selectedItems = newSelectedItems;
    this.selectedItemsChange.emit(this.selectedItems);
  }
}
