<ngx-spinner
  bdColor="rgba(42,55,84,0.4)"
  size="default"
  color="#e1e1e2"
  type="line-scale-pulse-out"
  [fullScreen]="false"
  name="deliveryStep2"
>
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="container-step p-4 mb-2">
  <div class="form-step">
    <div class="row">
      <div class="col-6 title-view">
        <h4 class="card-title title-inside-steps-h4">
          {{ "DELIVERY_STEP2.DELIVERY_TITLE" | translate }}
        </h4>
      </div>
      <div class="col-6 filter-text-div">
        <overa-filter-text
          (searchChangeKeyPressEvent)="onSearchTextChange($event)"
          (searchChangeClickEvent)="onSearchTextChange($event)"
          [styleClass]="filterClass"
        ></overa-filter-text>
      </div>
    </div>
    <div class="row">
      <div class="col-12 container-center" *ngIf="showTable">
        <overa-datatable
          #datatableStep2Delivery
          [columns]="columns"
          [showPaginator]="true"
          [rowsPerPage]="[10, 20]"
          [items]="listSampleDelivery!"
          [totalRecords]="totalRecords"
        >
        </overa-datatable>
      </div>
    </div>
    <div
      class="row marginContainerDeliveryNumber"
      [formGroup]="form"
      *ngIf="loadedForm"
    >
      <div class="col-3"></div>
      <!-- <div class="col-3">
        <overa-input
          [title]="'COMMON_FORMS.PAYMENT_RECEIPT' | translate"
          [isDisabled]="false"
          [controlName]="'storageDelivery'"
          [formGroup]="form"
          [isRequired]="true"
        ></overa-input>
      </div> -->
      <div class="col-6 containerStorage">
        <label class="truncate-text"
          ><b>{{
            "SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT" | translate
          }}</b></label
        >
        <overa-file-upload
          [id]="'file'"
          [form]="form"
          [controlName]="'file'"
          [fileData]="fileDataJson"
          [storageId]="storageId"
          (fileUpload)="onAddFile($event)"
          (fileDeleted)="fileDeleted($event)"
          [placeholder]="
            'SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT' | translate
          "
        >
        </overa-file-upload>
      </div>
      <div class="col-3"></div>
    </div>
    <div class="row panel-notice" *ngIf="showTable">
      <app-panel-notices-instructions
        [listPanel]="listNoticePanel"
        [titleHeader]="titlePanel"
      ></app-panel-notices-instructions>
    </div>
  </div>
  <div class="container-delivery-step2">
    <button class="btn-continuar" (click)="next()" [disabled]="!form.valid">
      {{ "ACTION.CONTINUE" | translate }} <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>
