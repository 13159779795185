import { OveraCardHeaderIconsOption } from "./overa-card-header-icons-option.model";
import { OveraCardItemField } from "./overa-card-item-field.model";
import { OveraDropDownMenuOption } from "./overa-dropdown-menu-option.model";

export class OveraCardItem {
  id: string | null;
  title: OveraCardItemField;
  percentage: number;
  percentageIsCritic: boolean;
  fields: OveraCardItemField[];
  menuOptions: OveraDropDownMenuOption[];
  headerIconsOptions: OveraCardHeaderIconsOption[];

  constructor(obj?: Partial<OveraCardItem>) {
    this.id = obj?.id ?? null;
    this.title = obj?.title ?? new OveraCardItemField("", "");
    this.percentage = obj?.percentage ?? 0;
    this.percentageIsCritic = obj?.percentageIsCritic ?? false;
    this.fields = obj?.fields ?? [];
    this.menuOptions = obj?.menuOptions ?? [];
    this.headerIconsOptions = obj?.headerIconsOptions ?? [];
  }
}
