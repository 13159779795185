<div class="p-field formField p-fluid">
  <label class="truncate-text" [title]="title"
    ><span *ngIf="isRequired">*</span><b>{{ title }}</b></label
  ><br />
  <p-autoComplete
    *ngIf="!formGroup"
    [(ngModel)]="selectedItems"
    (ngModelChange)="onSelectedItemsChange($event)"
    [suggestions]="filteredItems"
    (completeMethod)="filterItem($event)"
    [field]="displayField"
    [multiple]="true"
    [disabled]="isDisabled"
  ></p-autoComplete>
  <form *ngIf="formGroup" [formGroup]="formGroup">
    <p-autoComplete
      [suggestions]="filteredItems"
      (completeMethod)="filterItem($event)"
      [field]="displayField"
      [multiple]="true"
      [formControlName]="controlName"
    ></p-autoComplete>
  </form>
</div>
