import { DocumentApi, DocumentLanguage } from "./document-api.model";
import { Sample } from "./sample.model";

export class Report {
  id?: string;
  reportDocument?: DocumentApi;
  reportDocumentId?: string;
  sample?: Sample;

  constructor() {}
}
export class CreateReport {
  sampleId?: string;
  reportStorageId?: string;
  fileName?: string;

  constructor() {}
}
export class ReportSummaryField {
  sampleCode?: string;
  fileName?: string;
  documentLanguages?: DocumentLanguage;
  reportStorageId?: string;
  constructor() {}
}
