import { DocumentApi } from "./document-api.model";
import { Sample } from "./sample.model";

export class Delivery {
  id?: string;
  deliveryNote?: DocumentApi;
  deliveryNoteId?: string;
  samples?: Sample[];
  constructor() {}
}
export class CreateDelivery {
  sampleIdList?: string[];
  deliveryNoteStorageId?: string;
  fileName?: string;
  constructor() {}
}
export class DeliverySummaryField {
  sampleCode?: string;
  fileName?: string;
  shippingCode?: string;
  constructor() {}
}
