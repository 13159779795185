<ngx-spinner
  bdColor="rgba(42,55,84,0.4)"
  size="default"
  color="#e1e1e2"
  type="line-scale-pulse-out"
  [fullScreen]="false"
  name="spinnerListShippingLabel"
>
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog
  styleClass="containerConfirmDialog"
  acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"
></p-confirmDialog>

<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <span>{{ "SHIPPINGLABEL_MAINTENANCE.TITLE" | translate }}</span>
      </h4>
    </div>
  </div>
  <div class="card-body">
    <div class="card-content">
      <div class="row">
        <div class="col-1">
          <div class="d-flex flex-row justify-content-right"></div>
        </div>
      </div>
      <div *ngIf="showTable">
        <overa-datatable
          #datatableShippingLabel
          [columns]="columns"
          [showPaginator]="true"
          [rowsPerPage]="[10, 20]"
          [items]="listShippingLabels"
          [totalRecords]="totalRecords"
        >
        </overa-datatable>
      </div>
    </div>
  </div>
</div>
