export class OveraCardHeaderIconsOption {
  iconClass: string;
  iconClick?: () => void;
  color: string;
  tooltip: string;
  constructor(obj?: Partial<OveraCardHeaderIconsOption>) {
    this.iconClass = obj?.iconClass ?? "";
    this.iconClick = obj?.iconClick ?? undefined;
    this.color = obj?.color ?? "";
    this.tooltip = obj?.tooltip ?? "";
  }
}
