<div class="wrapper">
  <div class="sidebar">
    <app-sidebar-cmp [menuItems]="menuItems"></app-sidebar-cmp>
    <div class="sidebar-background"></div>
  </div>
  <div class="main-navbar">
    <overa-navbar #navbar [menuItems]="menuItems" [menubarItems]="menubarItems"
      (onSelectLanguage)="selectLang()"></overa-navbar>
  </div>
  <div class="container-level-2">
    <div class="root-layout">
      <router-outlet></router-outlet>

    </div>
  </div>
</div>