import { MenuDefinition } from "@overa/base";
import { AuthGuard } from "@overa/security";

import { TestComponent } from "./components/test/test.component";
import { LayoutComponent } from "./components/layout.component";

import {
  DynamicEntityListComponent,
  DynamicEntityContainerComponent,
  DynamicMaintenanceComponent,
} from "@overa/dynamic";
import { BaseStepsComponent } from "./base-steps/base-steps.component";
import { ProjectGridComponent } from "./components/project-grid/project-grid.component";
import { LaboratoryFormComponent } from "./components/maintenance/laboratory/laboratory-form/laboratory-form.component";
import { AnalysisTypeFormComponent } from "./components/maintenance/analysis-type/analysis-type-form/analysis-type-form.component";
import { LaboratoryListComponent } from "./components/maintenance/laboratory/laboratory-list/laboratory-list.component";
import { Routes } from "@angular/router";
import { AnalysisTypeListComponent } from "./components/maintenance/analysis-type/analysis-type-list/analysis-type-list.component";
import { AnalysisSubtypeListComponent } from "./components/maintenance/analysis-subtype/analysis-subtype-list/analysis-subtype-list.component";
import { AnalysisSubtypeFormComponent } from "./components/maintenance/analysis-subtype/analysis-subtype-form/analysis-subtype-form.component";
import { BillingTypeListComponent } from "./components/maintenance/billing-type/billing-type-list/billing-type-list.component";
import { BillingTypeFormComponent } from "./components/maintenance/billing-type/billing-type-form/billing-type-form.component";
import { ListShippinglabelStockComponent } from "./components/maintenance/stock-shippinglabel/list-shippinglabel-stock/list-shippinglabel-stock.component";
import { HomeComponent } from "./components/home/home.component";
import { ValidateSampleStepsComponent } from "./components/validate-sample-steps/validate-sample-steps.component";
import { BoxStockListComponent } from "./components/maintenance/box-stock/box-stock-list/box-stock-list.component";
import { InvoicesStepsComponent } from "./components/maintenance/invoices-steps/invoices-steps.component";
import { PaymentsStepsComponent } from "./components/maintenance/payments-steps/payments-steps.component";
import { DeliveryStepsComponent } from "./components/maintenance/delivery-steps/delivery-steps.component";
import { ReportsComponent } from "./components/maintenance/reports/reports.component";

const maintenances: Routes = [
  {
    path: "laboratory",
    pathMatch: "full",
    component: LaboratoryListComponent,
    data: {},
    children: [],
  },
  {
    path: "laboratory/:id",
    component: LaboratoryFormComponent,
    canDeactivate: [],
    canActivate: [],
    pathMatch: "full",
    data: {},
  },
  {
    path: "analysis-type",
    pathMatch: "full",
    component: AnalysisTypeListComponent,
    data: {},
  },
  {
    path: "analysis-type/:id",
    component: AnalysisTypeFormComponent,
    canDeactivate: [],
    canActivate: [],
    pathMatch: "full",
    data: {},
  },

  {
    path: "analysis-subtype",
    pathMatch: "full",
    component: AnalysisSubtypeListComponent,
    data: {},
  },
  {
    path: "analysis-subtype/:id",
    component: AnalysisSubtypeFormComponent,
    canDeactivate: [],
    canActivate: [],
    pathMatch: "full",
    data: {},
  },

  {
    path: "billing-type",
    pathMatch: "full",
    component: BillingTypeListComponent,
    data: {},
  },
  {
    path: "billing-type/:id",
    component: BillingTypeFormComponent,
    canDeactivate: [],
    canActivate: [],
    pathMatch: "full",
    data: {},
  },
];
export class MenuSettings {
  public static MENU: MenuDefinition = {
    routes: [
      {
        path: "",
        pathMatch: "prefix",
        component: LayoutComponent,
        canActivateChild: [AuthGuard],
        data: {
          maps: [TestComponent],
        },
        children: [
          {
            path: "core",
            loadChildren: () =>
              import("./lib-routing.module").then((m) => m.LibRoutingModule),
          },
          {
            path: "dashboard",
            loadChildren: () =>
              import("./app-dashboard-routing.module").then(
                (m) => m.AppDashboardRoutingModule
              ),
          },
          {
            path: "dynamicMaintenances",
            children: [
              {
                path: "",
                component: DynamicMaintenanceComponent,
              },
              {
                path: ":entityDefinitionId",
                component: DynamicMaintenanceComponent,
              },
            ],
          },
          {
            path: "createAnalisys",
            component: BaseStepsComponent,
            pathMatch: "full",
          },
          {
            path: "createAnalisys/:page",
            component: BaseStepsComponent,
            pathMatch: "full",
          },
          {
            path: "createAnalisys/:page/:id",
            component: BaseStepsComponent,
            pathMatch: "full",
          },
          {
            path: "validateSample",
            component: ValidateSampleStepsComponent,
            pathMatch: "full",
          },
          {
            path: "validateSample/:page",
            component: ValidateSampleStepsComponent,
            pathMatch: "full",
          },
          {
            path: "validateSample/:page/:id",
            component: ValidateSampleStepsComponent,
            pathMatch: "full",
          },
          {
            path: "invoices",
            component: InvoicesStepsComponent,
            pathMatch: "full",
          },
          {
            path: "invoices/:page",
            component: InvoicesStepsComponent,
            pathMatch: "full",
          },
          {
            path: "invoices/:page/:id",
            component: InvoicesStepsComponent,
            pathMatch: "full",
          },
          {
            path: "payments",
            component: PaymentsStepsComponent,
            pathMatch: "full",
          },
          {
            path: "payments/:page",
            component: PaymentsStepsComponent,
            pathMatch: "full",
          },
          {
            path: "payments/:page/:id",
            component: PaymentsStepsComponent,
            pathMatch: "full",
          },
          {
            path: "delivery",
            component: DeliveryStepsComponent,
            pathMatch: "full",
          },
          {
            path: "delivery/:page",
            component: DeliveryStepsComponent,
            pathMatch: "full",
          },
          {
            path: "delivery/:page/:id",
            component: DeliveryStepsComponent,
            pathMatch: "full",
          },
          {
            path: "listEntity",
            children: [
              {
                path: "",
                component: DynamicEntityListComponent,
              },
              {
                path: ":entityDefinitionId",
                component: DynamicEntityListComponent,
              },
            ],
          },
          {
            path: "listEntitySimple",
            children: [
              {
                path: "",
                component: DynamicEntityListComponent,
              },
              {
                path: ":entityDefinitionId",
                component: DynamicEntityListComponent,
              },
            ],
          },
          {
            path: "extendedEntity",
            children: [
              {
                path: ":entityDefinitionId",
                component: DynamicEntityContainerComponent,
              },
              {
                path: ":entityDefinitionId/:id",
                component: DynamicEntityContainerComponent,
              },
            ],
          },
          {
            path: "maintenances",
            children: maintenances,
            //  [
            //    {
            //      path: 'laboratory',
            //      component: LaboratoryComponent,
            //        children: [
            //            {
            //            path: '',
            //            component: LaboratoryFormComponent,
            //                pathMatch: 'full'
            //            },
            //            {
            //                path: ':id',
            //              component: LaboratoryFormComponent,
            //                pathMatch: 'full'
            //            }
            //        ]
            //    },
            //    {
            //      path: 'campus',
            //      component: CampusComponent,
            //      children: [
            //        {
            //          path: '',
            //          component: CampusFormComponent,
            //          pathMatch: 'full'
            //        },
            //        {
            //          path: ':id',
            //          component: CampusFormComponent,
            //          pathMatch: 'full'
            //        }
            //      ]
            //    },
            //    {
            //      path: 'analysis-type',
            //      component: AnalysisTypeComponent,
            //      children: [
            //        {
            //          path: '',
            //          component: AnalysisTypeFormComponent,
            //          pathMatch: 'full'
            //        },
            //        {
            //          path: ':id',
            //          component: AnalysisTypeFormComponent,
            //          pathMatch: 'full'
            //        }
            //      ]
            //    },
            //]
          },
          {
            path: "home",
            component: HomeComponent,
            // children: [
            //   {
            //     path: "datar",
            //     component: ProjectGridComponent,
            //     pathMatch: "full",
            //   },
            //   {
            //     path: "diagnostica",
            //     component: ProjectGridComponent,
            //     pathMatch: "full",
            //   },
            // ],
          },
          {
            path: "home/laboratory",
            component: ProjectGridComponent,
            pathMatch: "full",
          },
          {
            path: "home/datar",
            component: ProjectGridComponent,
            pathMatch: "full",
          },
          {
            path: "home/diagnostica",
            component: ProjectGridComponent,
            pathMatch: "full",
          },
          {
            path: "test",
            component: TestComponent,
            pathMatch: "full",
          },
          {
            path: "shippingLabels",
            component: ListShippinglabelStockComponent,
            pathMatch: "full",
          },
          {
            path: "boxStocks",
            component: BoxStockListComponent,
            pathMatch: "full",
          },
          {
            path: "reports",
            component: ReportsComponent,
            pathMatch: "full",
          },
          { path: "", redirectTo: "home", pathMatch: "full" },
        ],
      },
      {
        path: "security",
        pathMatch: "prefix",
        loadChildren: () =>
          import("./app-security-routing.module").then(
            (m) => m.AppSecurityRoutingModule
          ),
      },
    ],
  };
}
