import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { NgxSpinnerService } from "ngx-spinner";
import { SampleState } from "src/app/models/enums/sample-state.model";
import { ShortSample } from "src/app/models/short-sample.model";
import { ValidateSample } from "src/app/models/validate-sample.model";
import { SampleService } from "src/app/services/sample.service";

@Component({
  selector: "app-validate-sample-data-step2",
  templateUrl: "./validate-sample-data-step2.component.html",
  styleUrls: ["./validate-sample-data-step2.component.scss"],
})
export class ValidateSampleDataStep2Component implements OnInit, OnDestroy {
  id: string = "";
  shortSample: ShortSample = new ShortSample();
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  isNew: boolean = false;
  isReadOnly: boolean = false;
  stateIfIsNew = [SampleState.REGISTERED];
  stateIfIsNoReadOnly = [
    SampleState.REGISTERED,
    SampleState.DATARRECEIVED,
    SampleState.DATARRECEIVEDWITHERROR,
  ];
  disabledControls = [
    "sampleDate",
    "testTypeName",
    "patientName",
    "patientIdentityId",
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private sampleService: SampleService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService
  ) {}
  ngOnInit() {
    this.resetVariables();
    this.createForm();
    this.route.params.subscribe({
      next: (paramsId) => {
        this.id = paramsId["id"];
        console.log(this.id);
        if (this.id) {
          this.spinner.show("validateSampleStep2");
          this.sampleService.getValidateInfo(this.id).subscribe({
            next: (sample) => {
              console.log(sample);
              if (sample) {
                this.shortSample = sample;
                this.isReadOnly =
                  this.shortSample?.deliveryState &&
                  this.stateIfIsNoReadOnly.includes(
                    this.shortSample?.deliveryState
                  )
                    ? false
                    : true;
                if (!this.isReadOnly) {
                  this.isNew =
                    this.shortSample?.deliveryState &&
                    this.stateIfIsNew.includes(this.shortSample?.deliveryState)
                      ? true
                      : false;
                  this.route.queryParams.subscribe((queryParams) => {
                    const valid = queryParams["validate"];
                    this.shortSample.isValid = valid === "false" ? false : true;
                  });
                  if (
                    this.shortSample?.deliveryState ===
                    SampleState.DATARRECEIVEDWITHERROR
                  )
                    this.shortSample.isValid = false;
                } else {
                  if (
                    this.shortSample?.deliveryState === SampleState.REGISTERING
                  ) {
                    this.messagesService.showToast(
                      "MESSAGES.INFO.INFO",
                      "VALIDATE_SAMPLE_STEP1.MESSAGES_INFO_REGISTERING",
                      "info",
                      ""
                    );
                  }
                }

                this.setForm();
                this.loadedForm = true;
                this.spinner.hide("validateSampleStep2");
              } else {
                this.spinner.hide("validateSampleStep2");
                this.messagesService.showToast(
                  "MESSAGES.INFO.INFO",
                  "VALIDATE_SAMPLE_STEP1.MESSAGES_INFO_NOTMARKERROR",
                  "info",
                  ""
                );
                this.router.navigate(["/home"]);
              }
            },
            error: (err) => {
              console.error(err);
              this.loadedForm = true;
              this.spinner.hide("validateSampleStep2");
            },
          });
        } else {
          this.spinner.hide("validateSampleStep2");
          this.messagesService.showToast(
            "MESSAGES.INFO.INFO",
            "VALIDATE_SAMPLE_STEP1.MESSAGES_INFO_NOTFOUND",
            "info",
            ""
          );
          this.router.navigate(["/home"]);
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("validateSampleStep2");
      },
    });
  }
  ngOnDestroy() {}
  resetVariables() {
    this.shortSample = new ShortSample();
    this.loadedForm = false;
    this.isNew = false;
    this.isReadOnly = false;
  }
  createForm() {
    this.form = new FormGroup({
      sampleDate: new FormControl<Date | null>(null, [Validators.required]),
      testTypeName: new FormControl<string>("", [Validators.required]),
      patientName: new FormControl<string>("", [Validators.required]),
      patientIdentityId: new FormControl<string>("", [Validators.required]),
      isValid: new FormControl<boolean>(false, []),
      noValidCause: new FormControl<string>("", []),
    });
  }
  setForm() {
    this.form.enable();
    let date: Date | null = this.shortSample.sampleDate
      ? new Date(this.shortSample.sampleDate)
      : null;
    this.form.setValue({
      sampleDate: date || null,
      testTypeName: this.shortSample.testTypeName || null,
      patientName: this.shortSample.patientName || null,
      patientIdentityId: this.shortSample.patientIdentityId || null,
      isValid: this.shortSample.isValid || false,
      noValidCause: this.shortSample.noValidCause || "",
    });
    Object.keys(this.form.controls).forEach((controlName) => {
      if (this.disabledControls.includes(controlName)) {
        this.form.get(controlName)?.disable();
      }
    });
    if (this.isReadOnly) {
      this.form.get("isValid")?.disable();
      this.form.get("noValidCause")?.disable();
    }
  }
  save() {
    if (!this.isReadOnly) {
      let hasChanged = false;
      if (!this.isNew) {
        hasChanged = this.findChange();
      }
      if (this.isNew || (!this.isNew && hasChanged)) {
        let newValidateSample = new ValidateSample();
        newValidateSample.id = this.shortSample.id;
        newValidateSample.state =
          this.form.get("isValid")?.value === true
            ? SampleState.DATARRECEIVED
            : SampleState.DATARRECEIVEDWITHERROR;
        if (newValidateSample.state === SampleState.DATARRECEIVEDWITHERROR) {
          newValidateSample.rejectReason = this.form.get("noValidCause")?.value;
        }
        if (newValidateSample.state === SampleState.DATARRECEIVED) {
          if (!this.isNew && this.shortSample.noValidCause !== "") {
            newValidateSample.rejectReason = "";
          }
        }
        console.log("**newValidateSample**", newValidateSample);
        this.spinner.show("validateSampleStep2");
        this.sampleService.validateSample(newValidateSample).subscribe({
          next: (sample) => {
            console.log(sample);
            let shortSample = new ShortSample();
            shortSample = sample;
            if (this.isNew) {
              this.messagesService.showToast(
                "MESSAGES.SUCCESS.CREATE",
                "MESSAGES.SUCCESS.CREATE_LONG",
                "success",
                this.shortSample.code || "",
                "VALIDATE_SAMPLE_STEP1.TITLE"
              );
            } else {
              this.messagesService.showToast(
                "MESSAGES.SUCCESS.UPDATE",
                "MESSAGES.SUCCESS.UPDATE_LONG",
                "success",
                this.shortSample.code || "",
                "VALIDATE_SAMPLE_STEP1.TITLE"
              );
            }
            this.router.navigate(["/home"]);
            this.spinner.hide("validateSampleStep2");
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide("validateSampleStep2");
          },
        });
      }
    } else {
      this.router.navigate(["/home"]);
    }
  }
  findChange(): boolean {
    let hasChanged = false;
    if (this.form.get("isValid")?.value !== this.shortSample.isValid) {
      hasChanged = true;
    }
    if (
      this.form.get("noValidCause")?.value !== this.shortSample.noValidCause
    ) {
      hasChanged = true;
    }
    return hasChanged;
  }
}
