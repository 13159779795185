<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="step3">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="container-step">
  <div class="form-step" [formGroup]="form" *ngIf="loadedForm">

    <div class="container-fluid">
      <div class="row">
        <div class="col-6 title-steps">
          <h4 class="card-title title-steps-h4">
            {{ "STEP3.TITLE" | translate }}
          </h4>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <overa-input [title]="'STEP3.FORM.CODE_SHIPPING' | translate" [isDisabled]="true" [controlName]="'code'"
            [formGroup]="form"></overa-input>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <overa-input [title]="'STEP3.FORM.LINK' | translate" [isDisabled]="true" [controlName]="'link'"
            [formGroup]="form"></overa-input>
        </div>
      </div>
      <div class="row mt-2">
        <div class="panel-notice" *ngIf="loadedForm">
          <app-panel-notices-instructions [listPanel]="listNoticePanel"
            [titleHeader]="titlePanel"></app-panel-notices-instructions>
        </div>
      </div>
      <div class="row">
        <label class="step3-text-span">{{
          "STEP3.FORM.RESPONSE" | translate
          }}</label>
        <overa-checkbox [controlName]="'checked'" [formGroup]="form" [isDisabled]="false"></overa-checkbox>
      </div>
    </div>
  </div>
  <div class="container-buttons-step">
    <button class="btn-continuar" (click)="back()">
      <i class="fa fa-arrow-left"></i>{{ "ACTION.BACK_STEP" | translate }}
    </button>
    <button class="btn-continuar" (click)="save()" [disabled]="!checked">
      {{ isReadOnly ? "" : ("ACTION.CONTINUE" | translate) }}
      <i [class]="isReadOnly ? 'pi pi-home' : 'fa fa-arrow-right'"></i>
    </button>
  </div>