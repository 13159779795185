<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerAnalysisTypeLaboratoryBranchForm">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
        <span>
          {{ "ANALYSIS_TYPE_MAINTENANCE.TITLE" | translate }}
        </span>
      </h4>
    </div>
  </div>
  <div class="card-body type-laboratory">
    <div *ngIf="loadedForm">
      <div [formGroup]="form">
        <overa-dropdown [options]="listAnalysisType" [optionLabel]="'name'" [formGroup]="form"
          [controlName]="'analysisType'"></overa-dropdown>
      </div>
      <div class="container-form-actions divContainerButtons" [align]="'center'">
        <button mat-raised-button (click)="create()" [disabled]="!form.valid" *ngIf="isNew">
          {{ "ACTION.CREATE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>