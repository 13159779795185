<ng-container *ngIf="tooltip else notooltip">
  <button pButton
          pRipple
          type="button"
          [icon]="icon"
          [class]="cssClass"
          (click)="btnClick($event)"
          [pTooltip]="tooltip| translate"
          [disabled]="isDisabled"
          [tooltipStyleClass]="tooltipClass">{{value || ''}}</button>
</ng-container>
<ng-template #notooltip>
  <button pButton
          pRipple
          type="button"
          [icon]="icon"
          [class]="cssClass"
          (click)="btnClick($event)"
          [disabled]="isDisabled">{{value || ''}}</button>
</ng-template>
