<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out" [fullScreen]="false">
    <p class="loading-text">{{ 'MESSAGES.LOADING' | translate }}</p>
</ngx-spinner>

<div class="card ">
    <div class="card-header card-header-warning card-header-text ">
        <div class="card-text">
            <h4 class="card-title ">
                <button mat-icon-button fxShow (click)="back()">
                    <mat-icon aria-label="back">keyboard_backspace</mat-icon>
                </button>
                <span>{{ (selectedDescription ? ('TEST_MAINTENANCE.FORM.HEADER' | translate: { value: selectedDescription }) : 'TEST_MAINTENANCE.FORM.HEADER_NEW' | translate) }}</span>
            </h4>
        </div>
    </div>
    <div class="card-body">


    <mat-card-content *ngIf="canView && data">

  
        <form autocomplete="off">
            <mat-form-field>
                <input [formControl]="CodeCtrl" matInput required placeholder="{{ 'TEST_MAINTENANCE.FORM.LABEL.CODE' | translate }}" [(ngModel)]="data.code" maxlength="9" required />
                <mat-error *ngIf="CodeCtrl.hasError('required')">
                    {{ 'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate }}</mat-error>
                <mat-error *ngIf="CodeCtrl.hasError('maxLength')">{{ 'MESSAGES.VALIDATION.MAX_LENGTH' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input [formControl]="DescriptionCtrl" matInput required placeholder="{{ 'TEST_MAINTENANCE.FORM.LABEL.DESCRIPTION' | translate }}" [(ngModel)]="data.description" maxlength="35" required />
                <mat-error *ngIf="DescriptionCtrl.hasError('required')">
                    {{ 'MESSAGES.VALIDATION.REQUIRED_FIELD' | translate }}</mat-error>
                <mat-error *ngIf="DescriptionCtrl.hasError('maxLength')">
                    {{ 'MESSAGES.VALIDATION.MAX_LENGTH' | translate }}</mat-error>
            </mat-form-field>
        </form>
    </mat-card-content>

    <mat-card-actions [align]="'end'">
        <button mat-raised-button (click)="cancel()" *ngIf="enabled">{{ 'ACTION.CANCEL' | translate }}</button>
        <button mat-raised-button (click)="delete()" *ngIf="detail && !enabled && canDelete">{{ 'ACTION.DELETE' | translate }}</button>
        <button mat-raised-button (click)="edit()" *ngIf="detail && !enabled && canUpdate ">{{ 'ACTION.EDIT' | translate }}</button>
        <button mat-raised-button (click)="cleanForm()" *ngIf="!detail && enabled">{{ 'ACTION.CLEAN' | translate }}</button>
        <button mat-raised-button (click)="create()" *ngIf="!detail && enabled && canCreate" [disabled]="!data.code || !data.description">{{ 'ACTION.CREATE' | translate }}</button>
        <button mat-raised-button (click)="update()" color="primary" *ngIf="detail && enabled" [disabled]="!data.description">{{ 'ACTION.SAVE' | translate }}</button>
    </mat-card-actions>
  </div>
</div>
