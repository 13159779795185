import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export abstract class OveraBaseService<T> {

  abstract get baseUrl(): string;

  constructor(public http: HttpClient) { }


  get(): Observable<T[]> {
    const endpointUrl = `${this.baseUrl}`;
    return this.http.get<T[]>(endpointUrl);
  }
  getById(id: string): Observable<T> {
    const endpointUrl = `${this.baseUrl}/${id}`;
    return this.http.get<T>(endpointUrl)
  }


  add(data: T): Observable<T> {
    const endpointUrl = `${this.baseUrl}`;
    return this.http.post<T>(endpointUrl, data);
  }

  update(data: T): Observable<T> {
    const endpointUrl = `${this.baseUrl}`;
    return this.http.put<T>(endpointUrl, data);
  }

  delete(id: string): Observable<T> {
    const endpointUrl = `${this.baseUrl}/${id}`;
    return this.http.delete<T>(endpointUrl);
  }
}
