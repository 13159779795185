import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "@overa/security";
import { Observable, of, ReplaySubject } from "rxjs";
import { LaboratoryFilter } from "../models/budget-filter.model";
import { SalesTypeEnum } from "../models/enums/sales-type.model";
import { Laboratory } from "../models/laboratory.model";
import { PaginatedResponse } from "../models/paginatedResponse.model";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
//import { LaboratoryCardViewModel } from "../models/laboratory-card-viewmodel.model";
//import { LaboratoryCardSearchDTO } from "../models/laboratory-filters.model";
//import { LaboratoryDTO } from "../models/laboratoryDTO.model";
//import { LaboratoryViewModel } from "../models/laboratory-viewmodel.model";

@Injectable({ providedIn: "root" })
export class LaboratoryService extends OveraBaseService<Laboratory> {
  private laboratorySubject: ReplaySubject<Laboratory[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public laboratoryObservable: Observable<Laboratory[]> = this.laboratorySubject.asObservable();

  private routeLaboratorySubject: ReplaySubject<string | null> = new ReplaySubject(1);
  public routeLaboratoryObservable: Observable<string|null> =
    this.routeLaboratorySubject.asObservable();

  constructor(http: HttpClient) {
    super(http);

}

  get baseUrl() {
    return Config.apiBaseUrl + "/api/Laboratory";
  }


  notifyupdate(laboratorys: Laboratory[]) {
    this.laboratorySubject.next(laboratorys);
  }

  notifyupdateRoute(id: string | null) {
    console.log(id);
    this.routeLaboratorySubject.next(id);
  }

}
