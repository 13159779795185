import { Component, EventEmitter, Input, Output } from "@angular/core";
import {FormGroup} from '@angular/forms';

@Component({
  selector: "overa-text-area",
  templateUrl: "./overa-text-area.component.html",
  styleUrls: ["./overa-text-area.component.scss"],
})
export class OveraTextAreaComponent {
  @Input() title: string = "";
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() placeholder: string = "";
  @Input() controlName: string | null = null;
  @Input() formGroup: FormGroup | undefined;
  private _value: string = "";
  @Input()
  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.valueChange.emit(this._value);
  }

  @Output() valueChange = new EventEmitter<string>();
}
