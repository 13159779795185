<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
  <div [hidden]="isMobileMenu()" class="div-navbarmobile">
    <ul class="navbarmobile">
      <div class="navbar-left">
        <ng-template #containerNavbarMobile></ng-template>
      </div>
    </ul>
    <!--<div class="toggle-navbar">
          <button type="button"
                  mat-button
                  class="navbar-toggler btn-no-ripple"
                  type="button"
                  (click)="sidebarToggle()">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
          </button>
      </div>-->
  </div>
  <div class="container-fluid menu-nomobile">
    <!-- LEFT MENU ITEMS (NORMAL)-->
    <div class="navbar-wrapper">
      <div *ngIf="!isNewDesign" class="navbar-minimize">
        <button type="button" mat-raised-button (click)="minimizeSidebar()"
          class="btn btn-just-icon btn-white btn-fab btn-round">
          <i class="material-icons notranslate text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons notranslate design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
      <!-- <a class="navbar-brand" href="{{getPath()}}"> {{getTitle()}}</a> -->
      <ul *ngIf="isNewDesign" class="navbar-nav">
        <!-- MENU ITEMS -->
        <ng-container *ngIf="
          currentMenuItem &&
          currentMenuItem.type === 'sub' &&
          !currentMenuItem.isNavIcon
        ">
          <ng-container *ngFor="let childitem of currentMenuItem.children">
            <li routerLinkActive="active" class="nav-item iconMenuItem" *ngIf="childitem.isVisible">
              <!-- MENU ITEMS WITH SUBMENUS -->
              <ng-container *ngIf="childitem.children && childitem.children?.length">
                <!-- SUBMENUS -->
                <a [matMenuTriggerFor]="menu" [className]="'nav-link ' + childitem.componentName"
                  title="{{ (childitem.toolTip || '') | translate }}">
                  <span *ngIf="
                    childitem.showName && childitem.iconPosition !== 'left'
                  " class="sidebar-normal">
                    {{ (childitem.name || '') | translate }}
                    <i class="pi pi-angle-down valign-middle"></i>
                  </span>
                  <span *ngIf="
                    !childitem.svg &&
                    childitem.icon &&
                    (childitem.iconPosition || !childitem.showName)
                  " class="material-icons notranslate">
                    {{ childitem.icon }}
                    <i class="pi pi-angle-down valign-middle"></i>
                  </span>
                  <!-- <img
                                    *ngIf="
                                      childitem.svg &&
                                      (childitem.iconPosition || !childitem.showName)
                                    "
                                    class="newDesignIcon"
                                    [src]="
                                      'data:image/svg+xml;base64,' + childitem.svg
                                        | safeResourceUrl
                                    "
                                  /> -->
                  <span *ngIf="
                    childitem.showName && childitem.iconPosition === 'left'
                  " class="sidebar-normal">
                    {{ (childitem.name || '') | translate }}
                    <i class="pi pi-angle-down valign-middle"></i>
                  </span>
                </a>
                <!-- SUBMENUS ITEMS (VIEWS) -->
                <mat-menu #menu="matMenu">
                  <div id="{{ childitem.id }}">
                    <ul class="nav">
                      <ng-container *ngFor="let subchilditem of childitem.children">
                        <li routerLinkActive="active" class="nav-item iconMenuItem" *ngIf="subchilditem.isVisible">
                          <a [routerLink]="[subchilditem.uiUrl]" (click)="
                            navigationClick($event, subchilditem.uiUrl || '')
                          " [className]="
                            'nav-link ' + subchilditem.componentName
                          " title="{{ (subchilditem.toolTip || '')| translate }}">
                            <span *ngIf="
                              subchilditem.showName &&
                              subchilditem.iconPosition !== 'left'
                            " class="sidebar-normal">{{ (subchilditem.name || '') | translate }}</span>
                            <span *ngIf="
                              !subchilditem.svg &&
                              subchilditem.icon &&
                              (subchilditem.iconPosition ||
                                !subchilditem.showName)
                            " class="material-icons notranslate">{{ subchilditem.icon }}</span>
                            <!-- <img
                                                        *ngIf="
                                                          subchilditem.svg &&
                                                          (subchilditem.iconPosition ||
                                                            !subchilditem.showName)
                                                        "
                                                        class="newDesignIcon"
                                                        [src]="
                                                          'data:image/svg+xml;base64,' + subchilditem.svg
                                                            | safeResourceUrl
                                                        "
                                                      /> -->
                            <span *ngIf="
                              subchilditem.showName &&
                              subchilditem.iconPosition === 'left'
                            " class="sidebar-normal">{{ (subchilditem.name || '') | translate }}</span>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </mat-menu>
              </ng-container>
              <!-- MENU ITEMS WITHOUT SUBMENUS (VIEWS)-->
              <ng-container *ngIf="childitem.children?.length === 0">
                <a [routerLink]="[childitem.uiUrl]" [className]="'nav-link ' + childitem.componentName"
                  title="{{ (childitem.toolTip || '') | translate }}">
                  <!-- <span class="sidebar-mini">{{childitem.ab | translate}}</span> -->
                  <span *ngIf="
                    childitem.showName && childitem.iconPosition !== 'left'
                  " class="sidebar-normal">{{ (childitem.name || '') | translate }}</span>
                  <span *ngIf="
                    !childitem.svg &&
                    childitem.icon &&
                    (childitem.iconPosition || !childitem.showName)
                  " class="material-icons notranslate">{{ childitem.icon }}</span>
                  <!-- <span *ngIf="childitem.svg && (childitem.iconPosition || !childitem.showName)" class="newDesignIcon" [innerHtml] = "childitem.svg | safeHtml:true"></span> -->
                  <!-- <img
                                    *ngIf="
                                      childitem.svg &&
                                      (childitem.iconPosition || !childitem.showName)
                                    "
                                    class="newDesignIcon"
                                    [src]="
                                      'data:image/svg+xml;base64,' + childitem.svg
                                        | safeResourceUrl
                                    "
                                  /> -->
                  <span *ngIf="
                    childitem.showName && childitem.iconPosition === 'left'
                  " class="sidebar-normal">{{ (childitem.name || '') | translate }}</span>
                </a>
              </ng-container>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>

    <!-- RIGHT MENU ITEMS (ICONS)-->
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ng-template #containerNavbarRight></ng-template>
      <overa-menubar [items]="menubarItems"></overa-menubar>
    </div>
  </div>
</nav>
<section class="top-nav-responsive">
  <div class="container-fluid">
    <div class="row row-topnavbarresponsive">
      <div class="col-6 left">
        <div class="container-image-responsive">
          <img class="container-logo-responsive" src="../../assets/img/logo.svg">
        </div>
      </div>

      <div class="col-6" style="text-align: right;">
        <input id="menu_responsive-toggle" type="checkbox" />
        <label class="menu_responsive-button-container navbar-toggler" for="menu_responsive-toggle">
          <div class="menu_responsive-button"></div>
        </label>
        <div class="menu_responsive">
          <overa-menubar [items]="menubarItems"></overa-menubar>
          <div class="sidebar-mobile">
            <ng-container *ngFor="let menu of menuItems">
              <ng-container *ngIf="(!menu.isNavIcon)">
                <ng-container *ngIf="menu.uiUrl">
                  <div class="nav-item">
                    <ng-container>
                      <a [routerLink]="[menu.uiUrl]" (click)="updateMainRoute(menu)"
                        [className]="'nav-link ' + menu.componentName" title="{{ menu.toolTip }}">
                        <ng-container *ngIf="menu.svg && menu.iconPosition === 'left' ">
                          <img class="newDesignIcon" [src]="'data:image/svg+xml;base64,' + menu.svg
                                                            | safeResourceUrl " />
                        </ng-container>
                        <span *ngIf="menu.showName" class="sidebar-normal">{{ menu.name }}</span>
                        <ng-container *ngIf="menu.svg && menu.iconPosition !== 'left'">
                          <img class="newDesignIcon" [src]="'data:image/svg+xml;base64,' + menu.svg
                                                            | safeResourceUrl " />
                        </ng-container>


                      </a>
                    </ng-container>
                  </div>

                </ng-container>
                <ng-container *ngIf="!menu.uiUrl && menu.children?.length">
                  <a (click)="updateMainRoute(menu)" [className]="'nav-link ' + menu.componentName"
                    title="{{ menu.toolTip }}">
                    <ng-container *ngIf="menu.svg && menu.iconPosition === 'left' ">
                      <img width="24" height="24" class="newDesignIcon" [src]="'data:image/svg+xml;base64,' + menu.svg
                                                          | safeResourceUrl " />
                    </ng-container>
                    <span *ngIf="menu.showName" class="sidebar-normal">{{ menu.name }}</span>
                    <ng-container *ngIf="menu.svg && menu.iconPosition !== 'left'">
                      <img width="24" height="24" class="newDesignIcon" [src]="'data:image/svg+xml;base64,' + menu.svg
                                                          | safeResourceUrl " />
                    </ng-container>


                  </a>
                </ng-container>

              </ng-container>
            </ng-container>
          </div>
          <div class="overa-navbar-mobile">
            <ng-container *ngIf="
          currentMenuItem &&
          currentMenuItem.type === 'sub' &&
          !currentMenuItem.isNavIcon
        ">
              <ng-container *ngFor="let childitem of currentMenuItem.children">
                <div routerLinkActive="active" class="nav-item iconMenuItem" *ngIf="childitem.isVisible">
                  <!-- MENU ITEMS WITH SUBMENUS -->

                  <!-- MENU ITEMS WITHOUT SUBMENUS (VIEWS)-->
                  <ng-container *ngIf="childitem.children?.length === 0">
                    <a [routerLink]="[childitem.uiUrl]" (click)="navigationClick($event, childitem.uiUrl || '')"
                      [className]="'nav-link ' + childitem.componentName" title="{{ childitem.toolTip }}">
                      <ng-container *ngIf="childitem.svg && childitem.iconPosition === 'left' ">
                        <img class="newDesignIcon" [src]="'data:image/svg+xml;base64,' + childitem.svg
                                                            | safeResourceUrl " />
                      </ng-container>
                      <span *ngIf="childitem.showName" class="sidebar-normal">{{ childitem.name }}</span>
                      <ng-container *ngIf="childitem.svg && childitem.iconPosition === 'right'">
                        <img class="newDesignIcon" [src]="'data:image/svg+xml;base64,' + childitem.svg
                                                            | safeResourceUrl " />
                      </ng-container>

                    </a>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>



</section>