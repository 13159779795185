import { Injectable } from "@angular/core";
import { CreateInvoice, Invoice, SummaryField } from "../models/invoice.model";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
import { Config } from "@overa/security";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class InvoiceService extends OveraBaseService<Invoice> {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/invoice";
  }
  createInvoice(createInvoice: CreateInvoice) {
    const endpointUrl = `${this.baseUrl}/create`;
    return this.http.post<Invoice>(endpointUrl, createInvoice);
  }
  getInvoiceSummary(invoiceId: string) {
    const endpointUrl = `${this.baseUrl}/summary/${invoiceId}`;
    return this.http.get<SummaryField[]>(endpointUrl);
  }
}
