import { Component, OnDestroy, OnInit } from "@angular/core";
import { TenantStateManagementService } from "@overa/security";
import { Test } from "src/app/models/test.model";
import { TestService } from "src/app/services/test.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Globals } from "src/app/utils/globals";
import { Utils } from "src/app/utils/utils";
//import {
//  OveraGridFilter,
//  OveraGridFiltersType,
//  // OveraCardItem,
//  OveraCardItemField,
//  OveraGridColumnData,
//} from "@overa/components";
import { AnalysisService } from "../../services/analysis.service";
import { AnalysisStatus } from "../../models/enums/analysis-status.model";
import { OveraGridFilter } from "../../shared/temporal_components/models/overa-grid-filter.model";
import { OveraGridColumnData } from "../../shared/temporal_components/models/overa-grid-columns-data.model";
import { OveraGridFiltersType } from "../../shared/temporal_components/models/overa-grid-filter-types.model";

@Component({
  selector: "app-test",
  templateUrl: "./test.component.html",
  styleUrls: ["./test.component.scss"],
})
export class TestComponent {
  // showForm = false;
  // tests: Test[] = [];
  // // arrToReturn: OveraCardItem[] = [];
  // columnHeaders: string[] = [];
  // filters: OveraGridFilter[] = [];
  // isDiagnostica = false;
  // isLab = false;
  // isDatar = false;
  // containerStyles = "";
  // roleName = "";
  // itemsWithColumns: OveraGridColumnData[] = [];
  // title = "HOME";
  // constructor(
  //   private testService: TestService,
  //   private globals: Globals,
  //   private analysisService: AnalysisService,
  //   private utils: Utils
  // ) {}
  // ngOnInit() {
  //   console.log("test");
  //   this.testService.routeTestObservable.subscribe((r) => {
  //     setTimeout(() => (this.showForm = r ? true : false));
  //   });
  //   let roleName = this.globals.diagnosticaRoleName;
  //   let subscription1 = this.analysisService.getItemsReceivedDiagnostica(
  //     "ANÁLISIS REGISTRADOS",
  //     AnalysisStatus.REGISTERED, roleName
  //   );
  //   let subscription2 = this.analysisService.getItemsReceivedDiagnostica(
  //     "ANÁLISIS RECIBIDOS",
  //     AnalysisStatus.RECEIVED, roleName
  //   );
  //   let subscription3 = this.analysisService.getItemsReceivedDiagnostica(
  //     "ANÁLISIS FINALIZADOS",
  //     AnalysisStatus.FINISHED, roleName
  //   );
  //   let subscription4 = null;
  //   this.columnHeaders = [
  //     "ANÁLISIS REGISTRADOS",
  //     "ANÁLISIS RECIBIDOS",
  //     "ANÁLISIS FINALIZADOS",
  //     "ANÁLISIS ENVIADOS",
  //   ];
  //   if (this.utils.isDatarRole()) {
  //     roleName = this.globals.datarRoleName;
  //     subscription1 = this.analysisService.getItemsReceivedDiagnostica(
  //       "ANÁLISIS REGISTRADOS",
  //       AnalysisStatus.REGISTERED, roleName
  //     );
  //     subscription2 = this.analysisService.getItemsReceivedDiagnostica(
  //       "ANÁLISIS ERRÓNEOS",
  //       AnalysisStatus.ERROR, roleName, true
  //     );
  //     subscription3 = this.analysisService.getItemsReceivedDiagnostica(
  //       "INFORMES FINALIZADOS",
  //       AnalysisStatus.REPORT_FINISHED, roleName
  //     );
  //     this.isDatar = true;
  //     this.title = "HOME DATAR";
  //   }
  //   if (this.utils.isLabRole()) {
  //     this.title = "HOME LABORATORIO";
  //     roleName = this.globals.labRoleName;
  //     subscription1 = this.analysisService.getItemsReceivedDiagnostica(
  //       "ANÁLISIS REGISTRADOS",
  //       AnalysisStatus.REGISTERED, roleName
  //     );
  //     subscription2 = this.analysisService.getItemsReceivedDiagnostica(
  //       "ANÁLISIS RECIBIDOS",
  //       AnalysisStatus.RECEIVED, roleName
  //     );
  //     subscription3 = this.analysisService.getItemsReceivedDiagnostica(
  //       "INFORMES RECIBIDOS",
  //       AnalysisStatus.REPORT_RECEIVED, roleName
  //     );
  //   }
  //   if (this.utils.isDiagnosticaRole() || this.globals.user.isGlobalAdmin) {
  //     this.isDiagnostica = true;
  //     this.title = "HOME DIAGNÓSTICA";
  //     roleName = this.globals.diagnosticaRoleName;
  //     subscription4 = this.analysisService.getItemsReceivedDiagnostica(
  //       "ANÁLISIS ENVIADOS",
  //       AnalysisStatus.SENDED, roleName
  //     );
  //     this.itemsWithColumns = [subscription1, subscription2, subscription3, subscription4];
  //   }
  //   else {
  //     this.itemsWithColumns = [subscription1, subscription2, subscription3];
  //   }
  //   this.roleName = roleName.toLowerCase();
  //   //if (subscription4) {
  //   //  this.itemsWithColumns.push(subscription4);
  //   //}
  //   let filter1 = new OveraGridFilter();
  //   filter1.type = OveraGridFiltersType.TEXT;
  //   let filter2 = new OveraGridFilter();
  //   filter2.type = OveraGridFiltersType.DROPDOWN;
  //   filter2.options = [{ key: "opcion1", value: "1" }, { key: "opcion2", value: "2" }, { key: "opcion3", value: "3" }];
  //   filter2.name = "Opciones";
  //   let filter3 = new OveraGridFilter();
  //   filter3.type = OveraGridFiltersType.DATE;
  //   this.filters = [filter1, filter3, filter3, filter2, filter2];
  // }
  // onFilterChange() {}
  // ngOnDestroy() {}
}
