import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { ConfigStorage } from "@overa/storage";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { CreateReport } from "src/app/models/report.model";
import { SampleReport } from "src/app/models/sample-report.model";
import { ReportService } from "src/app/services/report.service";
import { ShippingLabelService } from "src/app/services/shipping-label.service";

@Component({
  selector: "app-report-file",
  templateUrl: "./report-file.component.html",
  styleUrls: ["./report-file.component.scss"],
})
export class ReportFileComponent implements OnInit, OnDestroy {
  isNew: boolean = false;
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  dataItem: SampleReport = new SampleReport();
  enabled: boolean = false;
  detail: boolean = false;
  fileId = "";
  fileName = "";
  fileDataJson: string = "";
  storageId = ConfigStorage.storageId;
  isReadOnly: boolean = false;
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private shippingLabelService: ShippingLabelService,
    private messagesService: OveraMessagesService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private reportService: ReportService
  ) {
    this.isNew = this.dialogConfig.data?.isNew;
    this.dataItem = this.dialogConfig.data?.dataItem;
  }
  //TODO change id in create
  ngOnInit() {
    this.spinner.show("spinnerReportFile");
    console.log(this.isNew, this.dataItem);
    this.loadedForm = false;
    this.createForm();
    if (!this.isNew) {
      if (this.dataItem.reportStorageId && this.dataItem.reportFileName) {
        var fileData = {
          guid: this.dataItem.reportStorageId,
          name: this.dataItem.reportFileName,
        };
        this.fileDataJson = JSON.stringify(fileData);
        this.fileId = this.dataItem.reportStorageId;
        this.fileName = this.dataItem.reportFileName;
        this.isReadOnly = true;
      }
      this.spinner.hide("spinnerReportFile");
    }
    this.spinner.hide("spinnerReportFile");
  }
  createForm() {
    this.form = new FormGroup({
      file: new FormControl<any>("", [Validators.required]),
    });
    this.loadedForm = true;
  }
  ngOnDestroy(): void {}
  create() {
    if (this.form.valid) {
      this.spinner.show("spinnerReportFile");
      let newCreateReport = new CreateReport();
      newCreateReport.fileName = this.fileName;
      newCreateReport.reportStorageId = this.fileId;
      newCreateReport.sampleId = this.dataItem.id;
      this.reportService.createReport(newCreateReport).subscribe({
        next: (item) => {
          this.messagesService.showToast(
            "MESSAGES.SUCCESS.CREATE",
            "MESSAGES.SUCCESS.CREATE",
            "success",
            ""
          );
          this.spinner.hide("spinnerReportFile");
          this.dialogRef.close(newCreateReport);
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("spinnerReportFile");
        },
      });
    }
  }
  cancel() {
    this.dialogRef.close();
  }
  back() {
    this.cancel();
  }
  onAddFile(event: any) {
    console.log(event);
    if (event?.file?.id) {
      this.fileId = event.file.id;
      this.fileName = event.file.displayName;
    }

    console.log("createFile");
  }
  fileDeleted(event: any) {
    console.log(event);
    this.fileDataJson = "";
  }
}
