import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { ConfigFilemanager } from "@overa/filemanager";
import { FileElement } from "@overa/filemanager/lib/models/fileelement.model";
import { AccountService } from "@overa/security";
import { ConfigStorage, StorageService } from "@overa/storage";
import { NgxSpinnerService } from "ngx-spinner";
import {
  DetailDocumentLanguage,
  DetailInstructions,
} from "src/app/models/analysis-type.model";
import { SampleState } from "src/app/models/enums/sample-state.model";
import { NoticePanel } from "src/app/models/notice-panel.model";
import { SampleModified } from "src/app/models/sample-modified.model";
import { UpdateExtraction } from "src/app/models/update-extraction.model";
import { SampleService } from "src/app/services/sample.service";
import { Globals } from "src/app/utils/globals";
import { Utils } from "src/app/utils/utils";
import { FileData } from "../../models/file-data.model";

@Component({
  selector: "app-new-analysis-patient",
  templateUrl: "./new-analysis-patient.component.html",
  styleUrls: ["./new-analysis-patient.component.scss"],
})
export class NewAnalysisPatientComponent implements OnInit, OnDestroy {
  id: string = "";
  sampleCreated: SampleModified = new SampleModified();
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  listNoticePanel: NoticePanel[] = [];
  titlePanel: string = "";
  // listDefaultSampleDeliveryInstructions: DetailInstructions[] = [];
  listDefaultSampleProcedureInstructions: DetailInstructions[] = [];
  // listFileteredLanguageDefaultSampleDeliveryInstructions: DetailInstructions[] =
  //   [];
  listFilteredLanguageDefaultSampleProcedureInstructions: DetailInstructions[] =
    [];
  defaultLanguageLocale: string = "";
  checked: boolean = false;
  isReadOnly: boolean = false;
  // storageId = ConfigFilemanager.storageId;
  storageId: string = ConfigStorage.storageId;
  fileNameWithExtension: string = "";
  numberFile: string = "";
  fileDataJson: string = "";
  fileId = "";
  fileName = "";
  file: File | "" = "";
  formDocuments: FormGroup = new FormGroup({});
  loadedFormDocuments: boolean = false;
  fileDataJsonManual: string = "";
  fileDataJsonPatientForm: string = "";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService,
    private globals: Globals,
    private sampleService: SampleService,
    private utils: Utils,
    private storageService: StorageService
  ) {}
  ngOnInit() {
    console.log("STEP 2");
    // console.log(this.router.url.toString().split("/").pop());
    this.defaultLanguageLocale = this.translateService.defaultLang;
    this.loadedForm = false;
    this.createForm();
    this.route.params.subscribe({
      next: (paramsId) => {
        this.id = paramsId["id"];
        console.log(this.id);
        if (this.id && paramsId["page"] == 2) {
          this.spinner.show("step2");
          this.sampleService.getFullSample(this.id).subscribe({
            next: (sample) => {
              console.log("GETFULLSAMPLE", sample);
              this.sampleCreated = sample;
              this.mapInstructions(sample);
              if (sample.patientFormStorageId && sample.patientFormFileName) {
                var fileData = {
                  guid: sample.patientFormStorageId,
                  name: sample.patientFormFileName,
                };
                this.fileDataJson = JSON.stringify(fileData);
                this.fileId = sample.patientFormStorageId;
                this.fileName = sample.patientFormFileName;
              }
              if (
                sample.testType &&
                sample.testType.manual &&
                sample.testType.manual.documentLanguages
              ) {
                var item = sample.testType.manual.documentLanguages.find(
                  (language) =>
                    language.language?.name === this.defaultLanguageLocale
                );

                var fileDataManual = {
                  guid: item?.storageId,
                  name: item?.fileName,
                };
                this.fileDataJsonManual = JSON.stringify(fileDataManual);
              }
              if (
                sample.testType &&
                sample.testType.patientForm &&
                sample.testType.patientForm.documentLanguages
              ) {
                var item = sample.testType.patientForm.documentLanguages.find(
                  (language) =>
                    language.language?.name === this.defaultLanguageLocale
                );

                var fileDataPatientForm = {
                  guid: item?.storageId,
                  name: item?.fileName,
                };
                this.fileDataJsonPatientForm =
                  JSON.stringify(fileDataPatientForm);
              }
              console.log(this.sampleCreated);
              this.setForm();
              this.isReadOnlyView();
              this.loadedForm = true;
              this.loadedFormDocuments = true;
              this.spinner.hide("step2");
              // this.form.get("checked")?.valueChanges.subscribe((newValue) => {
              //   this.checked = newValue;
              // });
            },
            error: (err) => {
              console.error(err);
              this.spinner.hide("step2");
            },
          });
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("step2");
      },
    });
    this.form.get("checked")?.valueChanges.subscribe((newValue) => {
      if (this.isReadOnly) {
        this.checked = newValue;
      } else if (!this.isReadOnly) {
        if (
          this.form.get("sampleDate")?.value &&
          this.form.get("file")?.value != ""
        ) {
          this.checked = newValue;
        } else if (
          this.form.get("sampleDate")?.value == null ||
          this.form.get("file")?.value == ""
        ) {
          this.checked = false;
        }
      }
    });
    this.form.get("sampleDate")?.valueChanges.subscribe((newDate) => {
      if (!this.isReadOnly && newDate == null) {
        this.form.get("checked")?.setValue(false);
      }
    });
    // this.form.get("files")?.valueChanges.subscribe((file: string | null) => {
    //   if (file && file !== "") {
    //     this.fileNameWithExtension = file.replace(/^.*\\/, "");
    //     console.log(
    //       "Nombre del archivo con extensión:",
    //       this.fileNameWithExtension
    //     );
    //   }
    // });
  }
  ngOnDestroy() {}
  isReadOnlyView() {
    if (
      this.sampleCreated.deliveryState !== SampleState.REGISTERING &&
      this.sampleCreated.deliveryState !== SampleState.REGISTERED
    ) {
      this.isReadOnly = true;
    }
    if (this.isReadOnly) {
      this.form.get("checked")?.setValue(true);
      this.form.disable();
    }
  }
  createForm() {
    this.form = new FormGroup({
      sampleDate: new FormControl<Date | null>(null, []),
      checked: new FormControl<Boolean>(false, []),
      file: new FormControl<any>(null, []),
    });
    this.formDocuments = new FormGroup({
      fileManual: new FormControl<any>(null, []),
      filePatientForm: new FormControl<any>(null, []),
    });
  }
  setForm() {
    let date: Date | null = this.sampleCreated.sampleDate
      ? new Date(this.sampleCreated.sampleDate)
      : null;

    if (this.sampleCreated.sampleDate) {
      const day = date?.getDate();
      if (day) {
        date?.setDate(day);
      }
    }
    let file: any;
    if (this.form.get("file")?.value && this.form.get("file")?.value != "")
      file = this.form.value.file;
    else {
      file = this.file;
    }
    this.form.setValue({
      sampleDate: date,
      checked: false,
      file: file,
    });
    this.formDocuments.setValue({
      fileManual: [],
      filePatientForm: [],
    });
  }
  onAddFile(event: any) {
    if (event?.file?.id) {
      this.fileId = event.file.id;
      this.fileName = event.file.displayName;
      this.file = event.file;
      this.findChanges();
    }
  }

  fileDeleted(event: any) {
    console.log("DELETE FILE: ", event);
    this.fileName = "";
    this.form.get("checked")?.setValue(false);
  }

  mapInstructions(sample: SampleModified) {
    if (
      sample.testType?.defaultSampleProcedureInstructions?.documentLanguages
    ) {
      sample.testType?.defaultSampleProcedureInstructions.documentLanguages?.forEach(
        (item) => {
          let detailLanguage = this.createDetail(item);
          this.listDefaultSampleProcedureInstructions.push(detailLanguage);
        }
      );
    }
    // if (sample.testType?.defaultSampleDeliveryInstructions?.documentLanguages) {
    //   sample.testType?.defaultSampleDeliveryInstructions?.documentLanguages?.forEach(
    //     (item) => {
    //       let detailLanguage = this.createDetail(item);
    //       this.listDefaultSampleDeliveryInstructions.push(detailLanguage);
    //     }
    //   );
    // }
    console.log("LISTAS", this.listDefaultSampleProcedureInstructions);
    this.filteredListInstructionsToLocalLanguage();
  }
  createDetail(item: DetailDocumentLanguage): DetailInstructions {
    let detailLanguage = new DetailInstructions();
    detailLanguage.id = item.id;
    detailLanguage.text = item.text;
    detailLanguage.languageId = item.languageId;
    detailLanguage.name = item.language?.name;
    return detailLanguage;
  }
  filteredListInstructionsToLocalLanguage() {
    // this.listFileteredLanguageDefaultSampleDeliveryInstructions =
    //   this.listDefaultSampleDeliveryInstructions.filter(
    //     (item) => item.name === this.defaultLanguageLocale
    //   );
    this.listFilteredLanguageDefaultSampleProcedureInstructions =
      this.listDefaultSampleProcedureInstructions.filter(
        (item) => item.name === this.defaultLanguageLocale
      );
    if (
      this.listFilteredLanguageDefaultSampleProcedureInstructions.length > 0
    ) {
      this.createNoticePanel();
    }
  }
  createNoticePanel() {
    this.titlePanel = "STEP2.NOTICE.ALERT";
    this.listFilteredLanguageDefaultSampleProcedureInstructions.forEach(
      (item) => {
        let notice = new NoticePanel();
        notice.textInnerHTML = item.text;
        this.listNoticePanel.push(notice);
      }
    );
    console.log("LISTNOTICEPANEL", this.listNoticePanel);
  }
  back() {
    this.router.navigate(["/createAnalisys/1", this.id]);
  }

  // ----------------------- CRUD ---------------
  save() {
    let hasChange = false;
    console.log(this.form.value);

    if (this.form.value.checked === true) {
      if (!this.isReadOnly) {
        hasChange = this.findChanges();
        this.spinner.show("step2");
        if (hasChange) {
          let newDate = this.utils.dateToUtcWithHours(
            this.form.value.sampleDate
          );
          let newExtraction = new UpdateExtraction();
          newExtraction.id = this.id;
          newExtraction.sampleDate = newDate;
          newExtraction.patientFormFileName = this.fileName;
          newExtraction.patientFormStorageId = this.fileId;
          this.sampleService.updateExtraction(newExtraction).subscribe({
            next: (items) => {
              this.spinner.hide("step2");
              this.router.navigate(["/createAnalisys/3", this.id]);
            },
            error: (err) => {
              console.error(err);
              this.spinner.hide("step2");
            },
          });
        } else {
          this.spinner.hide("step2");
          this.router.navigate(["/createAnalisys/3", this.id]);
        }
      } else {
        this.router.navigate(["/createAnalisys/3", this.id]);
      }
    }
  }
  findChanges(): boolean {
    let send = false;
    if (this.form.value.sampleDate) {
      let newDate = this.utils.dateToUtcWithHours(this.form.value.sampleDate);
      let dateInitial = this.sampleCreated?.sampleDate
        ? new Date(this.sampleCreated?.sampleDate)
        : null;
      if (dateInitial?.getTime() !== newDate.getTime()) {
        send = true;
      }
    }
    if (this.fileName !== "") {
      send = true;
    }
    return send;
  }
}
