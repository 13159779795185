export class LaboratoryFilter {
  partnershipCodes: string[] | undefined;
  textFilter: string | undefined;
  budgetCode: string | undefined;
  forceShowHidden: boolean | undefined;

  constructor(obj?: {
    partnershipCodes?: string[];
    textFilter?: string;
    budgetCode?: string;
    forceShowHidden?: boolean;
  }) {
    this.partnershipCodes = obj?.partnershipCodes || undefined;
    this.textFilter = obj?.textFilter || undefined;
    this.budgetCode = obj?.budgetCode || undefined;
    this.forceShowHidden = obj?.forceShowHidden || undefined;
  }
}
