import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { ConfigStorage } from "@overa/storage";
import { NgxSpinnerService } from "ngx-spinner";
import { NoticePanel } from "src/app/models/notice-panel.model";
import { CreatePayment } from "src/app/models/payment.model";
import { SamplePayment } from "src/app/models/sample-payment.model";
import { PaymentService } from "src/app/services/payment.service";
import { SampleService } from "src/app/services/sample.service";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";

@Component({
  selector: "app-payments-step2",
  templateUrl: "./payments-step2.component.html",
  styleUrls: ["./payments-step2.component.scss"],
})
export class PaymentsStep2Component implements OnInit, OnDestroy {
  laboratoryRoute?: any;
  laboratoryBranchRoute?: any;
  paymentIdRoute?: any;
  listSamplePayments?: SamplePayment[] = [];
  copyListSamplePayments?: SamplePayment[] = [];
  showTable?: boolean = false;
  columns: DataTableColumn[] = [];
  totalRecords: number = 0;
  listNoticePanel: NoticePanel[] = [];
  titlePanel: string = "STEP1.NOTICE.ALERT";
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  listIdSamples: string[] = [];
  fileDataJson: string = "";
  fileId = "";
  fileName = "";
  storageId: string = ConfigStorage.storageId;
  sampleId: string = "";
  showButtonHome: boolean = false;
  filterClass: string = "";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService,
    private cd: ChangeDetectorRef,
    private sampleService: SampleService,
    private paymentService: PaymentService
  ) {
    this.filterClass = "custom-filter";
  }
  ngOnInit() {
    this.spinner.show("paymentsStep2");
    this.resetVariables();
    this.route.queryParams.subscribe({
      next: (params) => {
        console.log(params);
        this.laboratoryRoute = params["laboratoryId"];
        this.laboratoryBranchRoute = params["laboratoryBranchId"];
        this.sampleId = params["sampleId"] || "";
        // this.paymentIdRoute = params["paymentId"];
        console.log(
          "LABORATORYS",
          this.laboratoryBranchRoute,
          this.laboratoryRoute
        );

        let subscription;
        //TODO REVISAR ENDPOINT
        if (this.laboratoryBranchRoute) {
          subscription =
            this.sampleService.getUnpaidSamplesByLaboratoryBranchId(
              this.laboratoryBranchRoute
            );
        } else {
          subscription = this.sampleService.getUnpaidSamplesByLaboratoryId(
            this.laboratoryRoute
          );
        }
        subscription.subscribe({
          next: (items) => {
            this.listSamplePayments = items ? items : [];
            if (this.sampleId !== "" && this.sampleId !== null) {
            }
            this.createForm();
            this.columns = this.createColumns();
            if (this.listSamplePayments.length > 0) {
              // this.assignedFunctionsTable();
              this.listSamplePayments.forEach((item) => {
                item.selectSamplePayment = false;
              });
              if (this.sampleId !== "" && this.sampleId !== null) {
                this.listSamplePayments = this.listSamplePayments.filter(
                  (item) => item.id === this.sampleId
                );
              }
            }
            this.totalRecords = this.listSamplePayments.length;
            this.assignedNoticePanel();
            this.copyListSamplePayments = [...this.listSamplePayments];
            this.showTable = true;
            this.loadedForm = true;
            this.spinner.hide("paymentsStep2");
            if (
              this.sampleId !== "" &&
              this.sampleId !== null &&
              !this.listSamplePayments.length
            ) {
              this.showButtonHome = true;
              this.messagesService.showToast(
                "MESSAGES.INFO.INFO",
                "PAYMENTS_STEP2.MESSAGE_EMPTY_ELEMENT",
                "info",
                ""
              );
            }
          },
          error: (err) => {
            console.error(err);
            this.spinner.show("paymentsStep2");
          },
        });
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("paymentsStep2");
      },
    });
  }
  resetVariables() {
    this.listSamplePayments = [];
    this.loadedForm = false;
    this.showTable = false;
    this.listIdSamples = [];
    this.showButtonHome = false;
  }
  assignedNoticePanel() {
    let notice = new NoticePanel();
    notice.text = this.translateService.instant(
      "PAYMENTS_STEP2.NOTICE_PANEL_1"
    );
    this.listNoticePanel.push(notice);
  }
  changeSelect(element: any) {
    console.log("ELEMENT", element);
  }
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "code",
        header: this.translateService.instant("COMMON_FORMS.CODE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "testTypeName",
        header: this.translateService.instant("COMMON_FORMS.TYPE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "sampleDate",
        header: this.translateService.instant("COMMON_FORMS.DATE_SAMPLE"),
        type: "System.DateTime",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "laboratoryBranchName",
        header: this.translateService.instant("COMMON_FORMS.LABORATORYBRANCH"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "invoiceNumber",
        header: this.translateService.instant("COMMON_FORMS.INVOICE_NUMBER"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "selectSamplePayment",
        header: this.translateService.instant("COMMON_FORMS.PAYMENT_SELECT"),
        responsive: true,
        responsiveHeader: true,
        type: "System.Boolean",
        command: (index: number) => {
          if (
            index &&
            this.listSamplePayments &&
            this.listSamplePayments[index] &&
            this.listSamplePayments[index].selectSamplePayment
          ) {
            this.listSamplePayments[index].selectSamplePayment =
              !this.listSamplePayments[index].selectSamplePayment;
          }
        },
      },
    ];
  }
  createForm() {
    this.form = new FormGroup({
      file: new FormControl<any>(null, [Validators.required]),
    });
  }
  next() {
    console.log(this.listSamplePayments);

    if (this.listSamplePayments && this.listSamplePayments.length > 0) {
      let thereAreSelect = false;
      this.listSamplePayments.forEach((payment) => {
        if (payment.selectSamplePayment === true) {
          thereAreSelect = true;
        }
      });
      if (thereAreSelect && this.form.valid) {
        this.listIdSamples = [];
        this.listSamplePayments.forEach((payment) => {
          if (
            payment.selectSamplePayment === true &&
            payment.id !== null &&
            payment.id !== undefined
          ) {
            this.listIdSamples.push(payment.id);
          }
        });

        console.log("NEW LIST ID", this.listIdSamples);

        let createPaymentNew = new CreatePayment();
        createPaymentNew.sampleIdList = this.listIdSamples;
        createPaymentNew.paymentStorageId = this.fileId;
        createPaymentNew.fileName = this.fileName;
        console.log("NEW CREATE PAyment", createPaymentNew);
        this.spinner.show("paymentsStep2");
        this.paymentService.createPayment(createPaymentNew).subscribe({
          next: (item) => {
            console.log(item);

            this.spinner.hide("paymentsStep2");
            this.router.navigate(["/payments/3"], {
              queryParams: {
                laboratoryId: this.laboratoryRoute,
                laboratoryBranchId: this.laboratoryBranchRoute,
                paymentId: item.id,
              },
            });
          },
          error: (err) => {
            console.log(err);
            this.spinner.hide("paymentsStep2");
          },
        });
      } else {
        if (!thereAreSelect) {
          this.messagesService.showToast(
            "MESSAGES.INFO.INFO",
            "PAYMENTS_STEP2.MESSAGE_EMPTY_LISTID",
            "info",
            ""
          );
        }
        if (!this.form.valid) {
          this.messagesService.showToast(
            "MESSAGES.INFO.INFO",
            "PAYMENTS_STEP2.MESSAGE_EMPTY_FORM",
            "info",
            ""
          );
        }
      }
    } else {
      this.messagesService.showToast(
        "MESSAGES.INFO.INFO",
        "PAYMENTS_STEP2.MESSAGE_EMPTY_LIST",
        "info",
        ""
      );
    }
  }
  onAddFile(event: any) {
    if (event?.file?.id) {
      this.fileId = event.file.id;
      this.fileName = event.file.displayName;
    }
  }

  fileDeleted(event: any) {
    console.log("DELETE FILE: ", event);
  }
  goHome() {
    this.router.navigate(["/home"]);
  }
  ngOnDestroy() {}
  onSearchTextChange(searchText: any) {
    console.log(searchText);
    if (searchText) {
      searchText = searchText.toLowerCase();
      if (this.copyListSamplePayments) {
        let filters = [...this.copyListSamplePayments];
        let newfilters = filters.filter((samplePayment) => {
          return (
            samplePayment.code
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            samplePayment.laboratoryBranchName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            samplePayment.sampleDate
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            samplePayment.testTypeName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            samplePayment.invoiceNumber
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        this.listSamplePayments = newfilters;
      }
    } else {
      if (this.copyListSamplePayments)
        this.listSamplePayments = [...this.copyListSamplePayments];
    }
    if (this.listSamplePayments && this.listSamplePayments?.length > 0) {
      this.listSamplePayments = [...this.listSamplePayments];
    } else {
      this.listSamplePayments = [];
    }
    console.log(this.listSamplePayments);
  }
}
