<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="main-content" *ngIf="loaded">
  <div class="container-fluid">
    <div class="row container-search">
      <div class="col-6"></div>
      <div class="col-6 filter-text-div">
        <overa-filter-text (searchChangeKeyPressEvent)="onSearchTextChange($event)"
          (searchChangeClickEvent)="onSearchTextChange($event)" [styleClass]="filterClass"></overa-filter-text>
      </div>
    </div>
    <overa-card-grid [hasGridHeader]="true" [firstBreadCrumb]="title" [hasFilters]="true" [filters]="filters"
      (filterEmitter)="onFilterChange($event)" [itemsForGridWithColummns]="itemsWithColumns"
      [allContainerClass]="containerStyles" [customGridClass]="'grid'">
      <ng-template let-item>
        <overa-card class="col-12" [cardTitle]="item.title" [cardId]="item.id" [cardInfo]="item.fields"
          [showProgressSpinner]="item.percentage != null" [percentage]="item.percentage"
          [isCritic]="item.percentageIsCritic" [cardMenuOptions]="item.menuOptions || []"
          [cardHeaderIconsOptions]="item.headerIconsOptions || []" [criticColor]="criticColor"
          [neutralColor]="neutralColor" [checkColor]="checkColor" (cardClick)="onCardClick($event)" [cardClass]="
            item.percentageIsCritic && item.percentage == 100
              ? 'border-critic '
              : item.percentageIsCritic && item.percentage == 75
              ? 'border-disabled'
              : !item.percentageIsCritic && item.percentage == 100
              ? 'border-check'
              : 'border-neutral'
          "></overa-card>
      </ng-template>
    </overa-card-grid>
  </div>
</div>