import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";

import { NgxSpinnerService } from "ngx-spinner";
import { NoticePanel } from "src/app/models/notice-panel.model";
import { PaymentSummaryField } from "src/app/models/payment.model";
import { PaymentService } from "src/app/services/payment.service";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";

@Component({
  selector: "app-payments-step3",
  templateUrl: "./payments-step3.component.html",
  styleUrls: ["./payments-step3.component.scss"],
})
export class PaymentsStep3Component implements OnInit, OnDestroy {
  listPayments?: PaymentSummaryField[] = [];
  showTable?: boolean = false;
  columns: DataTableColumn[] = [];
  totalRecords: number = 0;
  listNoticePanel: NoticePanel[] = [];
  titlePanel: string = "STEP1.NOTICE.ALERT";
  laboratoryRoute?: any;
  laboratoryBranchRoute?: any;
  paymentIdRoute?: any;
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService
  ) {}
  ngOnInit() {
    this.spinner.show("paymentsStep3");
    this.showTable = false;
    this.route.queryParams.subscribe({
      next: (params) => {
        console.log(params);
        this.laboratoryRoute = params["laboratoryId"];
        this.laboratoryBranchRoute = params["laboratoryBranchId"];
        this.paymentIdRoute = params["paymentId"];
        console.log(
          "LABORATORYS",
          this.laboratoryBranchRoute,
          this.laboratoryRoute
        );
        this.totalRecords = this.listPayments?.length || 0;

        if (this.paymentIdRoute) {
          this.paymentService.getPaymentSummary(this.paymentIdRoute).subscribe({
            next: (items) => {
              this.listPayments = items;
              this.totalRecords = this.listPayments.length;
              this.assignedNoticePanel();
              this.columns = this.createColumns();
              this.showTable = true;
              this.spinner.hide("paymentsStep3");
            },
            error: (err) => {
              console.error(err);
              this.spinner.hide("paymentsStep3");
            },
          });
        } else {
          this.assignedNoticePanel();
          this.columns = this.createColumns();
          this.showTable = true;
          this.spinner.hide("paymentsStep3");
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("paymentsStep3");
      },
    });
  }
  ngOnDestroy() {}
  assignedNoticePanel() {
    let notice = new NoticePanel();
    notice.text = this.translateService.instant(
      "INVOICES_STEP3.NOTICE_PANEL_1",
      { number: this.totalRecords }
    );
    this.listNoticePanel.push(notice);
  }
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "sampleCode",
        header: this.translateService.instant("COMMON_FORMS.CODE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "fileName",
        header: this.translateService.instant("COMMON_FORMS.PROOF"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
    ];
  }
  next() {
    console.log("next");
    this.router.navigate(["/home"]);
  }
}
