import { Component, OnInit, OnDestroy, Input, OnChanges } from "@angular/core";
import { BillingType } from "src/app/models/billing-type.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BillingTypeService } from "src/app/services/billing-type.service";
import { BaseFormComponent, AccountService, Hotkeys } from "@overa/security";
import { OveraMessagesService } from "@overa/components";
import { OveraKeyValuePair } from "../../../../shared/temporal_components/models/overa-keyValue-pair.model";
import { ConfirmationService } from "primeng/api";
import { Utils } from "../../../../utils/utils";
import { forkJoin, Observable, of } from "rxjs";

@Component({
  selector: "app-billing-type-form",
  templateUrl: "./billing-type-form.component.html",
  styleUrls: ["./billing-type-form.component.scss"],
  providers: [ConfirmationService],
})
export class BillingTypeFormComponent implements OnInit, OnDestroy, OnChanges {
  static className = "BillingTypeFormComponent";
  form: FormGroup = new FormGroup({});
  @Input()
  readonly = false;
  loadedForm = false;
  @Input()
  id: string | null = null;
  salesTypes: OveraKeyValuePair[] = [];
  selectedCampus = false;

  instructions: OveraKeyValuePair[] = [];
  data: BillingType = new BillingType();
  detail = false;
  selectedDescription = "";
  enabled = true;
  canCreate = true;
  canUpdate = true;
  canDelete = true;
  canView = true;
  baseRoute = "maintenances/billing-type";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private billingTypeService: BillingTypeService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private utils: Utils
  ) {
    console.log("billing-type-form");
  }
  ngOnInit() {
    this.init();
  }
  ngOnDestroy() {}

  private init() {
    this.route.paramMap.subscribe((params) => {
      this.spinner.show();
      const id = params.get("id");

      if (!this.readonly) {
        this.form.enable();
        this.enabled = true;
      }
      this.data = new BillingType();
      this.form = new FormGroup({
        name: new FormControl<string>("", [Validators.required]),
        description: new FormControl<string>("", [Validators.required]),
        monthlyBilling: new FormControl<boolean>(false),
        maxALlowedInvoiceNumber: new FormControl<number>(0),
      });

      if (id) {
        this.spinner.show();
        if (id === "new") {
          this.setLanguages();
          this.detail = false;
          this.data = new BillingType();
          this.selectedDescription = "";

          this.spinner.hide();
          this.loadedForm = true;
        } else {
          this.detail = true;

          this.billingTypeService.getById(id).subscribe({
            next: (response: BillingType) => {
              this.spinner.hide();
              this.detail = true;
              if (!this.readonly) {
                this.form.enable();
                this.enabled = true;
              }

              this.data = response;
              var formData: BillingType = new BillingType();
              formData.name = response.name;
              formData.description = response.description;
              formData.maxALlowedInvoiceNumber =
                response.maxALlowedInvoiceNumber || 0;
              formData.monthlyBilling = response.monthlyBilling;
              //if (this.data.instructions) {
              //  this.instructions = this.data.instructions;
              //}
              //else {
              //  this.setLanguages();
              //}
              this.setLanguages();
              this.form.enable();
              this.form.setValue({ ...formData });
              this.loadedForm = true;
              this.selectedDescription = response.name || "";
            },
            error: (error) => {
              this.spinner.hide();
              if (error.status === 500 || error.status === 0) {
                this.messagesService.showToast(
                  "ERROR.CRITICAL",
                  "ERROR",
                  "error",
                  "",
                  ""
                );
              } else {
                this.messagesService.showToast(
                  "ERROR.CRITICAL",
                  "ERROR",
                  "error",
                  "",
                  ""
                );
              }
              this.loadedForm = true;
              // TODO : Mensaje error
            },
          });
        }
      } else {
        this.selectedDescription = "";
        this.detail = false;
        this.form.disable();
        this.enabled = false;
        this.data = new BillingType();
        this.spinner.hide();
        this.loadedForm = true;
      }
    });
    // });
  }
  cancel() {
    this.router.navigateByUrl(this.baseRoute);
  }
  // ======================== CRUD ========================
  ngOnChanges() {}

  setLanguages() {
    var languages = this.translate.getLangs();
    // this.instructions = [];
    if (!this.instructions.length) {
      this.instructions = [];
    }
    languages.forEach((lang) => {
      if (!this.instructions.map((i) => i.key).includes(lang)) {
        this.instructions.push({ key: lang, value: "" });
      }
    });
    this.instructions = this.instructions.sort((a, b) => (a > b ? 1 : -1));
  }

  //ngOnChanges() {
  //  this.init();
  //  this.campusService.notifyupdateRoute(null);
  //}
  setData() {
    this.data = { ...this.data, ...this.form.value };
  }

  create() {
    this.spinner.show();
    this.setData();

    this.billingTypeService.add(this.data).subscribe({
      next: (response: any) => {
        this.data = response;

        this.router.navigateByUrl(this.baseRoute);

        this.messagesService.showToast(
          "MESSAGES.SUCCESS.CREATE",
          "MESSAGES.SUCCESS.CREATE_LONG",
          "success",
          this.data.name || "",
          "BILLING_TYPE_MAINTENANCE.TITLE"
        );
        this.spinner.hide();
      },
      error: (err: any) => {
        this.spinner.hide();
        this.messagesService.showError(err);
      },
    });
  }

  update() {
    this.spinner.show();
    this.setData();
    this.billingTypeService.update(this.data).subscribe({
      next: () => {
        this.messagesService.showToast(
          "MESSAGES.SUCCESS.UPDATE",
          "MESSAGES.SUCCESS.UPDATE_LONG",
          "success",
          this.data.name || "",
          "BILLING_TYPE_MAINTENANCE.TITLE"
        );

        this.router.navigateByUrl(this.baseRoute);

        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
        this.messagesService.showError(err);
      },
    });
  }

  delete() {
    let trmessage,
      messageAccept = "";
    this.translate.get("COMMONS.ASK_DELETE_RECORD").subscribe((a: string) => {
      trmessage = a;
    });
    this.translate.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        this.billingTypeService.delete(this.data.id ?? "").subscribe({
          next: () => {
            this.billingTypeService.get().subscribe((billingTypes) => {
              this.router.navigateByUrl(this.baseRoute);
            });
            this.spinner.hide();

            this.messagesService.showToast(
              "MESSAGES.SUCCESS.DELETE",
              "MESSAGES.SUCCESS.DELETE_LONG",
              "success",
              this.data.name || "",
              "BILLING_TYPE_MAINTENANCE.TITLE"
            );
            this.cancel();
            this.spinner.hide();
          },
          error: (err) => {
            console.log(err);
            this.messagesService.showError(err);
            this.spinner.hide();
          },
        });
      },
      reject: () => {},
    });
  }
}
