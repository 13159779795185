import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { BoxStock } from "src/app/models/box-stock.model";
import { LaboratoryBranchStock } from "src/app/models/laboratory-branch.model";
import { LaboratoryBranchService } from "src/app/services/laboratory-branch.service";

@Component({
  selector: "app-box-stock-form",
  templateUrl: "./box-stock-form.component.html",
  styleUrls: ["./box-stock-form.component.scss"],
})
export class BoxStockFormComponent implements OnInit, OnDestroy {
  isNew: boolean = false;
  laboratoryBranchId: string = "";
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  dataItem: LaboratoryBranchStock = new LaboratoryBranchStock();
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private laboratoryBranchService: LaboratoryBranchService,
    private messagesService: OveraMessagesService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) {
    this.isNew = this.dialogConfig.data?.isNew;
    this.laboratoryBranchId = this.dialogConfig.data?.laboratoryBranchId;
    this.dataItem = this.dialogConfig.data?.dataItem;
  }
  ngOnInit() {
    console.log("**stockForm**");
    console.log(this.isNew, this.laboratoryBranchId, this.dataItem);
    this.spinner.show("spinnerFormStockBox");
    this.loadedForm = false;
    this.createForm();
    if (this.laboratoryBranchId) {
      this.loadedForm = false;
      this.setForm();
      this.loadedForm = true;
      this.spinner.hide("spinnerFormStockBox");
    } else {
      this.spinner.hide("spinnerFormStockBox");
    }
  }
  ngOnDestroy() {}
  cancel() {
    // this.form.disable();
    this.dialogRef.close();
  }
  back() {
    this.cancel();
  }
  createForm() {
    this.form = new FormGroup({
      laboratoryName: new FormControl<string>("", [Validators.required]),
      laboratoryBranchName: new FormControl<string>("", [Validators.required]),
      kitCount: new FormControl<number>(0, [Validators.required]),
      boxCount: new FormControl<number>(0, [Validators.required]),
    });
  }
  setForm() {
    this.form.setValue({
      laboratoryName: this.dataItem.laboratoryName || "",
      laboratoryBranchName: this.dataItem.laboratoryBranchName || "",
      kitCount: this.dataItem.kitCount || "",
      boxCount: this.dataItem.boxCount || "",
    });
    this.form.get("laboratoryName")?.disable();
    this.form.get("laboratoryBranchName")?.disable();
  }
  create() {
    console.log(this.form.value);
    let newBoxStock = new BoxStock();
    newBoxStock.boxCount = this.form.value.boxCount;
    newBoxStock.kitCount = this.form.value.kitCount;
    this.spinner.show("spinnerFormStockBox");
    this.laboratoryBranchService
      .updateStock(this.laboratoryBranchId, newBoxStock)
      .subscribe({
        next: (item) => {
          console.log(item);
          this.spinner.hide("spinnerFormStockBox");
          this.messagesService.showToast(
            "MESSAGES.SUCCESS.UPDATE",
            "MESSAGES.SUCCESS.UPDATE",
            "success",
            ""
          );
          this.dialogRef.close(newBoxStock);
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("spinnerFormStockBox");
        },
      });
  }
}
