import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  ViewChild,
  ElementRef,
  TemplateRef,
  SimpleChanges,
  AfterViewInit,
} from "@angular/core";
import { LazyLoadEvent } from "primeng/api";

import { Table } from "primeng/table";
import { DataTableColumn } from "../models/dataTablecolumn.model";
import { Paginator } from "primeng/paginator";

export interface ConfigurationDataTable {
  showFilter?: boolean;
}

export interface DataTableButton {
  label?: string;
  ico?: string;
  style?: string;
  displayName?: string;
  clicEvent: (v: any) => void;
}

export interface RowEvent<T> {
  data: T;
  index: number;
  originalEvent: MouseEvent;
}

export interface ColumnReorderEvent {
  columns: DataTableColumn[];
  draggedColumn: DataTableColumn;
  droppedColumn: DataTableColumn;
  originalEvent: MouseEvent;
}

@Component({
  selector: "overa-datatable",
  templateUrl: "./overa-datatable.component.html",
  styleUrls: ["./overa-datatable.component.scss"],
})
export class OveraDataTableComponent<T>
  implements AfterViewInit, OnInit, OnChanges
{
  totals: any = {};

  @Input() showFooter: boolean = false;
  @Input() footerColumns: any = [];

  currentPageItems: T[] = [];

  @ViewChild("paginator", { static: true })
  paginator?: Paginator;
  col: any;

  @Input() get columns(): DataTableColumn[] {
    return this._columns;
  }

  @Input() get groupedColumns(): string[] {
    return this._groupedColumns;
  }

  @Input() get summaryColumns(): string[] {
    return this._summaryColumns;
  }

  set summaryColumns(val: string[]) {
    this._summaryColumns = val;
  }

  set groupedColumns(val: string[]) {
    this._groupedColumns = val;
  }

  set columns(val: DataTableColumn[]) {
    this._columns = val;
    this._selectedColumns = val;
    this.globalFilterFields = this._columns.map((m) => m.field);
  }

  @Input() get selectedColumns(): DataTableColumn[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: DataTableColumn[]) {
    this._selectedColumns = val;
  }

  @Input() get editableColumns(): string[] {
    return this._editableColumns;
  }

  set editableColumns(val: string[]) {
    this._editableColumns = val;
  }

  currentPage: number = 0;
  pageSize: number = 10;

  ngAfterViewInit(): void {
    this.table = this.tt;
  }

  _columns: DataTableColumn[] = [];
  _groupedColumns: string[] = [];
  _editableColumns: string[] = [];
  _summaryColumns: string[] = [];
  _selectedColumns: DataTableColumn[] = [];
  _selectedItems: T[] = [];

  @Input()
  containerClass!: string;
  @Input()
  customCardTemplate!: TemplateRef<any>;

  @Input() title = "List of ?";

  // @Input()
  // items: T[] = [];
  @Input()
  groupedItems: any[] = [];

  _items: any[] = [];

  @Input() get items(): any[] {
    return this._items;
  }

  set items(val: any[]) {
    if (val && val.length > 0) {
      if (this._groupedColumns.length > 0) {
        console.log("GROUPED COLUMNS  -> ", this._groupedColumns);
        this.groupData(val);
      } else {
        this._items = val;
      }
    } else {
      this._items = [];
    }
  }

  @Input()
  buttons: DataTableButton[] = [];
  @Input()
  configuration!: ConfigurationDataTable;
  @Input() rowsPerPage = [10, 25, 50];
  @Input() totalRecords = 0;
  @Input() showPaginator = false;
  @Input() showCurrentPageReport = false;
  @Input() currentPageReport =
    "Showing {first} of {last} of {totalRecords} registers";
  @Input() globalFilterFields: string[] = [];
  @Input() selectionMode = "single";
  @Input()
  showFilter: boolean = false;
  @Input() sortMode = "multiple";
  @Input()
  showExports: boolean = false;
  @Input()
  showTitle: boolean = false;
  @Input()
  showGlobalFilter: boolean = false;

  @Output() selectedItems = new EventEmitter<T[]>();
  @Output() editItem = new EventEmitter<T>();

  @Input()
  loadDataFunction!: (
    pag: number,
    items_per_pag: number,
    order: { field: string; order: number }[]
  ) => T[];

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  exportOptions: any[] = [];

  @ViewChild("tt") tt!: Table;
  table!: Table;

  @ViewChild("cardcontainer")
  cardContainer!: ElementRef;

  // En datatable.component.ts
  isFilterVisible = false;

  toggleFilter() {
    this.isFilterVisible = !this.isFilterVisible;
  }

  onRowSelect(event: RowEvent<T>) {
    // console.log('EVENT SELECT', event.data);
    this.selectedItems.emit([event.data]);
  }
  onRowUnselect(event: RowEvent<T>) {
    // console.log('EVENT UNSELECT', event.data);
    this.selectedItems.emit([]);
  }

  onColumnReorder(event: ColumnReorderEvent) {
    // Aquí puedes realizar acciones adicionales si es necesario
  }

  edit(rowData: T) {
    this.editItem.emit(rowData);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  public sortOrder!: number;
  public sortField!: string;
  public itemsTemp: any[] = [];
  // Agrega este método en tu componente
  onSort(event: { field: string; order: number }): void {
    this.sortField = event.field;
    this.sortOrder = event.order;
    console.log("onSort", event);
    this.loadDataPaginated(this.currentPage, this.pageSize, [
      { field: this.sortField, order: this.sortOrder },
    ]);
  }

  getColumnByFieldName(field: string) {
    return this.columns.find((c) => c.field === field);
  }

  loadDataPaginated(
    pag: number,
    items_per_pag: number,
    order: { field: string; order: number }[]
  ) {
    console.log("loadDataPaginated", pag, items_per_pag, order);

    const prevPage = pag > 0 ? pag - 1 : null;
    const nextPage =
      (pag + 1) * items_per_pag < this.items.length ? pag + 1 : null;

    const prevStart = Math.max(0, (prevPage ?? 0) * items_per_pag);
    const prevEnd = Math.min(
      this.items.length,
      ((prevPage ?? 0) + 1) * items_per_pag
    );

    const nextStart = Math.max(0, (nextPage ?? 0) * items_per_pag);
    const nextEnd = Math.min(
      this.items.length,
      ((nextPage ?? 0) + 1) * items_per_pag
    );

    const start = Math.max(0, (pag - 1) * items_per_pag);
    const end = Math.min(this.items.length, pag * items_per_pag);
    const currentPageItems = this.items.slice(start, end);

    if (prevPage !== null && !this.items.slice(prevStart, start).length) {
      const prevPageItems = this.loadDataFunction(
        prevPage,
        items_per_pag,
        order
      );
      // Agregar elementos de la página previa
      this.items.splice(prevEnd, 0, ...prevPageItems);

      // Eliminar elementos de la página previa
      const prevPageIndex = prevStart;
      const prevPageItemsCount = items_per_pag;
      this.items.splice(prevPageIndex, prevPageItemsCount);
    }

    if (nextPage !== null && !this.items.slice(end, nextEnd).length) {
      const nextPageItems = this.loadDataFunction(
        nextPage,
        items_per_pag,
        order
      );
      // Agregar elementos de la página siguiente
      this.items.splice(nextStart, 0, ...nextPageItems);

      // Eliminar elementos de la página siguiente
      const nextPageIndex = end;
      const nextPageItemsCount = items_per_pag;
      this.items.splice(nextPageIndex, nextPageItemsCount);
    }

    this.currentPage = pag;
    this.currentPageItems = currentPageItems;
    console.log("loadDataPaginated", this.items);
  }

  lazyLoad(event: LazyLoadEvent) {
    console.log("LAZYLOADEVENT", event);
  }

  ngOnInit() {
    this.exportOptions = [
      {
        label: "XLS",
        icon: "pi pi-file-excel",
        command: () => this.exportExcel(),
      },
      {
        label: "CSV",
        icon: "pi pi-file-o",
        command: () => this.table.exportCSV(),
      },
    ];

    this.items = this.loadDataFunction
      ? this.loadDataFunction(0, this.pageSize, [])
      : this.items;

    if (this.buttons) {
      this.buttons = [...this.buttons];
    } else {
      this.buttons = [];
    }
  }

  private lastScrollPosition = 0;
  private isScrollingUp = false;
  private initialTouchY!: number;

  onTouchStart(event: TouchEvent) {
    this.initialTouchY = event.touches[0].clientY;
  }

  onRemoveItem(index: number) {
    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }

  onTouchMove(event: TouchEvent) {
    const currentTouchY = event.touches[0].clientY;

    const deltaY = this.initialTouchY - currentTouchY;

    console.log("deltaY", deltaY);

    if (deltaY > 0) {
      // Incrementa la página actual
      this.currentPage += 1;

      // Llama a loadDataFunction al llegar al final del contenedor
      this.loadDataPaginated(this.currentPage, this.pageSize, [
        { field: this.sortField, order: this.sortOrder },
      ]);
    } else {
      // Decrementa la página actual
      this.currentPage = Math.max(0, this.currentPage - 1);

      // Llama a loadDataFunction al llegar al inicio del contenedor
      this.loadDataPaginated(this.currentPage, this.pageSize, [
        { field: this.sortField, order: this.sortOrder },
      ]);
    }
    this.initialTouchY = currentTouchY;
  }

  exportExcel() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.items);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, this.title);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const blob = new Blob([buffer], { type: fileType });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName + "_export_" + new Date().getTime() + ".xlsx";
    link.click();

    // Liberar la URL creada para liberar recursos
    URL.revokeObjectURL(url);
  }

  first: number = 0;

  onPageChange(event: any) {
    this.first = event.first;
    this.pageSize = event.rows;
    console.log("PAGE CHANGE", event.page, this.first, this.pageSize);

    this.items = this.loadDataFunction(event.page, this.pageSize, []);

    this.pageChange.emit(event);
  }

  get nonGroupedColumns() {
    return this.columns.filter(
      (item) => !this.groupedColumns.includes(item.field)
    );
  }

  groupData(data: any[]) {
    // Order data
    data.sort((a, b) => {
      for (let column of this._groupedColumns) {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
      }
      return 0;
    });

    // Group aux variables
    let count_keygroups = this._groupedColumns.length;
    let ginfo: number[][] = Array(data.length)
      .fill(-1)
      .map(() => Array(count_keygroups).fill(-1));
    let idx: number[] = Array(count_keygroups).fill(0);

    let grouped: { [key: string]: any } = {};

    data.forEach((item, index) => {
      let keys = this._groupedColumns.map((column) => item[column]);

      // Check all group levels, g0, g0+g1, g0+g2+g3, ...
      let key_test = "";
      for (let j = 0; j < count_keygroups; j++) {
        if (j == 0) {
          key_test = keys[0];
        } else {
          key_test += "-" + keys[j];
        }

        if (!grouped.hasOwnProperty(j + "@" + key_test)) {
          grouped[j + "@" + key_test] = {};
          idx[j] = index;
          ginfo[idx[j]][j] = 0;
        } else {
          ginfo[idx[j]][j]++;
        }
      }
    });

    grouped = {};

    data.forEach((item, index) => {
      grouped[index] = {
        ...item,
        GINFO: ginfo[index].map((m, j) => m),
      };
    });

    this.groupedItems = Object.values(grouped);

    console.log("GROUPED ITEMS:", this.groupedItems);

    this.calculateSummary();
  }

  groupData_(data: any[]) {
    let grouped: { [key: string]: any } = {};
    let i = 0;

    data.sort((a, b) => {
      for (let column of this._groupedColumns) {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
      }
      return 0;
    });

    data.forEach((item) => {
      let key = this._groupedColumns.map((column) => item[column]).join("-");

      if (!grouped.hasOwnProperty(key)) {
        grouped[key] = {
          ...item,
          rowspan: 0,
        };
        i = 0;
      } else {
        i++;
        grouped[key + "G" + i] = {
          ...item,
          rowspan: 0,
        };
      }
      grouped[key].rowspan++;
    });

    this.groupedItems = Object.values(grouped);

    this.calculateSummary();

    console.log("GROUPED ITEMS:", this.groupedItems);
  }

  onEditCompleted(event: RowEvent<T>) {
    console.log("EDIT COMPLETED", event);
    if (this.summaryColumns.length > 0) {
      this.calculateSummary();
    }
  }

  calculateSummary() {
    console.log("CALCULATE SUMMARY FOR ITEMS");
    this.totals = {};

    let items = this.groupedItems.length ? this.groupedItems : this.items;

    this.summaryColumns.forEach((col) => (this.totals[col] = 0));

    items.forEach((item) => {
      this.summaryColumns.forEach((col) => {
        if (typeof item[col] === "number") {
          this.totals[col] += item[col];
        }
      });
    });
  }
}
