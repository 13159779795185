import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { ShippingLabelService } from "src/app/services/shipping-label.service";
import { ShippingLabel } from "src/app/models/shipping-label.model";
import { ShippingLabelStock } from "src/app/models/shipping-label-stock.model";
import { FileUploadMaintenanceComponent } from "../../file-upload-maintenance/file-upload-maintenance.component";
import { ShippingLabelModified } from "src/app/models/shipping-label-modified.model";

@Component({
  selector: "app-list-shippinglabel",
  templateUrl: "./list-shippinglabel.component.html",
  styleUrls: ["./list-shippinglabel.component.scss"],
  providers: [DialogService, ConfirmationService],
})
export class ListShippinglabelComponent implements OnInit, OnDestroy {
  showTable: boolean = false;
  columns: DataTableColumn[] = [];
  listShippingLabels: ShippingLabel[] = [];
  totalRecords = 0;
  laboratoryId: string = "";
  isNew: boolean = false;
  dataItem: ShippingLabelStock = new ShippingLabelStock();
  laboratoryBranchId: string = "";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private shippingLabelService: ShippingLabelService,
    private messagesService: OveraMessagesService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) {
    this.isNew = this.dialogConfig.data?.isNew;
    this.laboratoryId = this.dialogConfig.data?.laboratoryId;
    this.dataItem = this.dialogConfig.data?.dataItem;
    this.laboratoryBranchId = this.dialogConfig.data?.laboratoryBranchId;
  }
  ngOnInit() {
    console.log(
      this.isNew,
      this.laboratoryId,
      this.dataItem,
      this.laboratoryBranchId
    );
    this.listShippingLabels = [];
    this.spinner.show("spinnerListShippingLabel");
    this.showTable = false;
    this.shippingLabelService
      .getByLaboratoryBranchId(this.laboratoryBranchId)
      .subscribe({
        next: (item) => {
          console.log("itemsGetLaboratoryId", item);
          if (item) {
            if (item.shippingLabels && item.shippingLabels.length > 0) {
              this.listShippingLabels = [...item.shippingLabels];
            } else {
              this.listShippingLabels = [];
            }

            this.totalRecords = this.listShippingLabels.length;
            this.columns = this.createColumns();
            if (this.listShippingLabels.length > 0) {
              this.assignedFunctionsTable();
            }
            this.showTable = true;
            this.spinner.hide("spinnerListShippingLabel");
          } else {
            this.listShippingLabels = [];
            this.showTable = true;
            this.spinner.hide("spinnerListShippingLabel");
          }
          console.log("listShippingLabels", this.listShippingLabels);
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("spinnerListShippingLabel");
        },
      });
  }
  ngOnDestroy() {}
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "code",
        header: this.translateService.instant("COMMON_FORMS.CODE"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "link",
        header: this.translateService.instant("COMMON_FORMS.LINK"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "addAttachedFile",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("COMMON_FORMS.SHOW_ADD"),
        type: "System.Button",
        icon: "pi pi-paperclip",
        cssClass: "button-addAttached-file-list-shipping-label",
      },
      {
        field: "deleteAction",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("ACTION.REMOVE"),
        type: "System.Button",
        icon: "pi pi-trash",
        cssClass: "button-delete-list-shipping-label",
      },
    ];
  }
  assignedFunctionsTable() {
    let itemsTemp: any[] = [];

    if (this.listShippingLabels.length > 0) {
      const goToAddAttachedFile = (index: any) => {
        if (this.listShippingLabels && this.listShippingLabels[index]) {
          this.openForm(this.listShippingLabels[index]);
        }
      };
      const toDeleteShhipingLabel = (index: any) => {
        console.log(index);

        if (this.listShippingLabels && this.listShippingLabels[index]) {
          this.delete(this.listShippingLabels[index]);
        }
      };
      this.listShippingLabels.forEach((element: any) => {
        element.addAttachedFile = {
          value: true, //TODO: add value from object
          command: goToAddAttachedFile,
        };
        element.deleteAction = {
          value: true, //TODO: add value from object
          command: toDeleteShhipingLabel,
        };
        itemsTemp.push(element);
      });
      this.listShippingLabels = [...itemsTemp];
    }
  }
  openForm(item: ShippingLabelModified | undefined) {
    let header = "";
    let translateKey = "SHIPPINGLABEL_MAINTENANCE.TITLE_FILE";
    this.translateService.get(translateKey).subscribe((a: string) => {
      header = a;
    });
    var ref = this.dialogService.open(FileUploadMaintenanceComponent, {
      width: "85%",
      height: "90%",
      header: header,
      data: {
        dataItem: item,
      },
    });
    ref.onClose.subscribe((storageFile: any) => {
      console.log(storageFile);
      this.spinner.show("spinnerListShippingLabel");
      this.ngOnInit();
    });
  }
  delete(item: ShippingLabelModified) {
    let trmessage,
      messageAccept = "";
    this.translateService
      .get("COMMONS.ASK_DELETE_RECORD")
      .subscribe((a: string) => {
        trmessage = a;
      });
    this.translateService.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show("spinnerListShippingLabel");

        if (item.id) {
          this.shippingLabelService.delete(item.id).subscribe({
            next: () => {
              this.messagesService.showToast(
                "MESSAGES.SUCCESS.DELETE",
                "MESSAGES.SUCCESS.DELETE",
                "success",
                "HEADER_TITLE.HEADER_TITLE"
              );
              this.spinner.hide("spinnerListShippingLabel");
              this.ngOnInit();
            },
            error: (err) => {
              console.log(err);
              this.spinner.hide("spinnerListShippingLabel");
            },
          });
        }
      },
      reject: () => {},
    });
  }
}
