<ngx-spinner
  bdColor="rgba(42,55,84,0.4)"
  size="default"
  color="#e1e1e2"
  type="line-scale-pulse-out"
  [fullScreen]="false"
  name="step1"
>
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="container-step p-4 mb-2">
  <div class="form-step">
    <form [formGroup]="form" *ngIf="loadedForm">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <overa-input
            [title]="'STEP1.FORM.ANALYSIS_NUMBER' | translate"
            [isDisabled]="true"
            [controlName]="'code'"
            [formGroup]="form"
          ></overa-input>
        </div>
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-field formField"
        >
          <overa-input
            [title]="'STEP1.FORM.TRANSPORT' | translate"
            [isDisabled]="true"
            [controlName]="'shippingCode'"
            [formGroup]="form"
          ></overa-input>
        </div>
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-field formField"
          *ngIf="optionsLab && optionsLab.length > 0"
        >
          <overa-dropdown
            [title]="'STEP1.FORM.LABORATORY' | translate"
            [options]="optionsLab"
            [isDisabled]="true"
            [optionLabel]="'name'"
            [formGroup]="form"
            [controlName]="'laboratory'"
          ></overa-dropdown>
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-field formField"
          *ngIf="optionsSede && optionsSede.length > 0"
        >
          <overa-dropdown
            [title]="'STEP1.FORM.LABORATORYBRANCH' | translate"
            [isRequired]="true"
            [options]="optionsSede"
            [optionLabel]="'name'"
            [formGroup]="form"
            [controlName]="'laboratoryBranch'"
          ></overa-dropdown>
        </div>
        <div class="col-2"></div>
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-field formField"
          *ngIf="optionsType"
        >
          <overa-dropdown
            [title]="'STEP1.FORM.TEST_TYPE' | translate"
            [isRequired]="true"
            [options]="optionsType"
            [optionLabel]="'name'"
            [formGroup]="form"
            [controlName]="'testType'"
          ></overa-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3">
          <overa-input
            [title]="'STEP1.FORM.PATIENT_NAME' | translate"
            [controlName]="'patientName'"
            [formGroup]="form"
          ></overa-input>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3">
          <overa-input
            [title]="'STEP1.FORM.PATIENT_LAST_NAME' | translate"
            [controlName]="'patientLastName'"
            [formGroup]="form"
          ></overa-input>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3">
          <overa-datepicker
            [title]="'STEP1.FORM.PATIENT_DATE' | translate"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            controlName="patientBirthDate"
            [formGroup]="form"
          ></overa-datepicker>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3">
          <overa-input
            [title]="'STEP1.FORM.PATIENT_DNI' | translate"
            [controlName]="'patientIdentityId'"
            [formGroup]="form"
          ></overa-input>
        </div>
      </div>
    </form>
  </div>
  <div class="panel-notice" *ngIf="loadedForm">
    <app-panel-notices-instructions
      [listPanel]="listNoticePanel"
      [titleHeader]="titlePanel"
    ></app-panel-notices-instructions>
  </div>
</div>
<div *ngIf="loadedForm">
  <button
    class="btn-continuar"
    (click)="save()"
    [disabled]="
      isReadOnly
        ? false
        : !form.valid ||
          exceededInvoices ||
          codeShippingLabel == '' ||
          codeShippingLabel == null
        ? true
        : false
    "
  >
    {{ "ACTION.CONTINUE" | translate }} <i class="fa fa-arrow-right"></i>
  </button>
</div>
