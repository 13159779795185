import { DocumentApi } from "./document-api.model";
import { Sample } from "./sample.model";

export class Payment {
  id?: string;
  proofOfPayment?: DocumentApi;
  proofOfPaymentId?: string;
  samples?: Sample[];
  constructor() {}
}
export class CreatePayment {
  sampleIdList?: string[];
  paymentStorageId?: string;
  fileName?: string;
  constructor() {}
}
export class PaymentSummaryField {
  sampleCode?: string;
  fileName?: string;
  constructor() {}
}
