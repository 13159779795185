<ngx-spinner
  bdColor="rgba(42,55,84,0.4)"
  size="default"
  color="#e1e1e2"
  type="line-scale-pulse-out"
  [fullScreen]="false"
  name="invoicesStep3"
>
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="container-step p-4 mb-2">
  <div class="form-step">
    <div class="row panel-notice" *ngIf="showTable">
      <app-panel-notices-instructions
        [listPanel]="listNoticePanel"
        [titleHeader]="titlePanel"
      ></app-panel-notices-instructions>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="containerTitleStep3">
          <h4>
            <i class="pi pi-info-circle"></i>
            {{ "INVOICES_STEP3.TITLE_TABLE" | translate }}
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8 container-center" *ngIf="showTable">
          <overa-datatable
            #datatableStep3Invoices
            [columns]="columns"
            [showPaginator]="true"
            [rowsPerPage]="[10, 20]"
            [items]="listInvoices!"
            [totalRecords]="totalRecords"
          >
          </overa-datatable>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
  <div class="container-invoices-step3">
    <!-- <button class="btn-continuar" (click)="back()">
      <i class="fa fa-arrow-left"></i> {{ "ACTION.BACK_STEP" | translate }}
    </button> -->
    <button class="btn-continuar" (click)="next()">
      {{ "ACTION.CLOSE" | translate }} <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>
