<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
    [fullScreen]="false">
    <p class="loading-text">{{ 'MESSAGES.LOADING' | translate }}</p>
</ngx-spinner>

<div class="card ">
    <div class="card-header card-header-warning card-header-text ">
        <div class="card-text">
            <h4 class="card-title ">
                <span [hidden]="!searching">{{ "TEST_MAINTENANCE.LIST.HEADER" | translate }}</span>
                <input [(ngModel)]="filter" #inputsearch [hidden]="searching"
                    placeholder="{{ 'COMMONS.SEARCH_PLACEHOLDER' | translate }}" />
            </h4>

        </div>
        <div class="keypad">
            <button mat-icon-button color="primary" (click)="searching=!searching;">
                <mat-icon aria-label="search">{{ !searching ? 'close' : 'search' }}</mat-icon>
            </button>
            <button *ngIf="canCreate" mat-icon-button color="primary" [routerLink]="['new']">
                <mat-icon aria-label="ADD">add</mat-icon>
            </button>
        </div>
    </div>
    <div class="card-body">
        <mat-card-content *ngIf="canView">

            <mat-list>
                <mat-list-item *ngIf="data.length === 0" class="hint">
                    {{ 'TEST_MAINTENANCE.EMPTY_LIST' | translate }}
                </mat-list-item>

                <cdk-virtual-scroll-viewport itemSize="10">
                    <div *cdkVirtualFor="let item of data | filterBy: ['description']: filter;">
                        <mat-list-item class="hover-color" [routerLink]="[item.id]"
                            routerLinkActive="router-link-active">
                            <h4 mat-line>{{ item.name }}</h4>
                            <p mat-line>{{ item.description }}</p>
                        </mat-list-item>
                        <!-- <mat-divider *ngIf="!lastline"></mat-divider> -->
                    </div>
                </cdk-virtual-scroll-viewport>

            </mat-list>
        </mat-card-content>
    </div>
</div>