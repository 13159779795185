import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "@overa/security";
import { Observable } from "rxjs";
import { LaboratoryFilter } from "../models/budget-filter.model";
import { PaginatedResponse } from "../models/paginatedResponse.model";
import { ProjectBudgetViewModel } from "../models/project-budget-viewmodel.model";

@Injectable({ providedIn: "root" })
export class BudgetService {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/Projects";
  }

  constructor(private http: HttpClient) {}

  getBudgetsAsync(
    filters?: LaboratoryFilter,
    pageIndex?: number,
    pageSize?: number
  ): Observable<PaginatedResponse<ProjectBudgetViewModel>> {
    let params = new HttpParams();

    if (pageSize !== undefined)
      params = params.set("pageSize", pageSize.toString());
    if (pageIndex !== undefined)
      params = params.set("pageIndex", pageIndex.toString());

    if (filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key as keyof LaboratoryFilter];
        if (Array.isArray(value) && value !== undefined && value.length > 0) {
          value.forEach((v, i) => (params = params.set(`${key}[${i}]`, v)));
        } else if (
          typeof value === "string" &&
          value !== undefined &&
          value !== ""
        ) {
          params = params.set(key, value);
        } else if (typeof value === "boolean" && value !== undefined) {
          params = params.set(key, value.toString());
        }
      });
    }

    console.log("PAGESIZE", pageSize, "PAGEINDEX", pageIndex, params);

    return this.http.get<PaginatedResponse<ProjectBudgetViewModel>>(
      `${this.baseUrl}/Budgets`,
      { params }
    );
  }

  getBudgetByIdAsync(id: string): Observable<ProjectBudgetViewModel> {
    return this.http.get<ProjectBudgetViewModel>(
      `${this.baseUrl}/Budget/${id}`
    );
  }

  //HACK: to generatre a guid, we need a external library for it
  generateGUID(): string {
    //NOSONAR
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  updateBudgetVisibility(
    budgetCode: string,
    visibility: boolean
  ): Observable<any> {
    let requestId = this.generateGUID(); //TODO: create a new GUID to pass;
    let headers = new HttpHeaders().set("x-requestid", requestId);

    return this.http.put(
      `${this.baseUrl}/Budget/${budgetCode}/Visibility`,
      visibility,
      { headers: headers }
    );
  }
}
