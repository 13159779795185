import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { OveraCardItem } from "../models/overa-card-item.model";
import { OveraGridColumnData } from "../models/overa-grid-columns-data.model";
import { OveraGridFiltersType } from "../models/overa-grid-filter-types.model";
import { OveraGridFilter } from "../models/overa-grid-filter.model";
import { MockService } from "./mock.service";

@Component({
  selector: "overa-card-grid",
  templateUrl: "./overa-card-grid.component.html",
  styleUrls: ["./overa-card-grid.component.scss"],
})
/**
 * OveraCardGridComponent
 *
 * Este componente muestra una cuadrícula de tarjetas (OveraCardComponent) con opciones de filtrado y personalización.
 * Puedes personalizar el contenido de las tarjetas en la cuadrícula utilizando un ng-template personalizado en el componente que lo contiene.
 * Ejemplo de uso:
 *
 * <lib-overa-grid>
 *   <ng-template let-item>
 *     <!-- Tu contenido personalizado para las tarjetas aquí -->
 *   </ng-template>
 * </lib-overa-grid>
 *
 */
export class OveraCardGridComponent implements OnInit {
  items: OveraCardItem[] = [];
  filterTypes = OveraGridFiltersType;
  @ContentChild(TemplateRef) cardTemplate: TemplateRef<any> | undefined;
  showFiltersModal: boolean = false;
  showFiltersModalSizeM: boolean = false;
  defaultDate: Date = new Date();

  /*
  TODO:
  add image title
  Habria que crear aparte unas columnas para que fueran los titulos de las columnas si es necesario
  */

  /**
   * Set to true if you want header for the grid
   * We can set text and filters in this header
   */
  @Input() hasGridHeader: boolean = true;
  /**
   * Literal for the first place of the breadcrumb
   */
  @Input() firstBreadCrumb: string | undefined;
  /**
   * Literal for the first place of the breadcrumb
   */
  @Input() secondBreadCrumb: string | undefined;
  /**
   * Items to show on grid if it has columns. You have to be carefull to set the correct cols and rows
   * with the next function=> itemsForGrid.lenght<=cols*rows
   * Null by default
   * Type OveraGridColumnData
   */
  @Input() itemsForGridWithColummns: OveraGridColumnData[] | undefined;
  /**
   * Items to show on grid if it doesn need columns
   * Type OveraCardItem
   */
  @Input() itemsForGrid: OveraCardItem[] | undefined;
  /**
   * To specify if the grid has filters
   * If we set this property to true ( false by default ), we need to set the filters property
   */
  @Input() hasFilters: boolean = false;
  /**
   * Array of objects where we can specify if its a dropdown, text search, and its posible items
   * Its needed if hasFilters is set to true
   */
  @Input() filters: OveraGridFilter[] | undefined;
  /**
   * If we had filters setted, we may want to use this emitter to receive the events of the filters
   */
  @Output() filterEmitter: EventEmitter<OveraGridFilter> =
    new EventEmitter<OveraGridFilter>();
  /**
   * Nombre de la clase en el proyecto que contenga los estilos para la cabecera
   */
  @Input() gridHeaderClass: string | undefined;
  /**
   * Nombre de la clase en el proyecto que contenga los estilos para cabecera e items
   */
  @Input() allContainerClass: string | undefined;
  /**
   * Clase con los estilos para las columnas de datos ( solo aplica si se le pasan objetos para las columnas )
   */
  @Input() customGridColumnsClass: string | undefined;
  /**
   * Clase con los estilos para el grid sin columnas
   */
  @Input() customGridClass: string | undefined;
  /**
   * Clase con los estilos para los filtros
   */
  @Input() customFiltersClass: string | undefined;
  /**
   * Path of the svg if its needed
   */
  @Input() svgPath: string | undefined;

  get isNeedHeaderColumns() {
    if (
      this.itemsForGridWithColummns != null &&
      this.itemsForGridWithColummns.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  constructor(public mockService: MockService) {}

  ngOnInit(): void {
    console.log("-------------->>OVERA GRID COMP ONINIT<<--------------");
    if (!this.itemsForGrid) {
      this.itemsForGrid = this.mockService.getCustomItems();
    }
  }

  onFilterTextChange(filter: OveraGridFilter, event: any) {
    filter.selected = event;
    this.filterEmitter.emit(filter);
  }

  toggleFiltersModal() {
    this.showFiltersModal = !this.showFiltersModal;
  }

  toggleSemiModalFilters() {
    this.showFiltersModalSizeM = !this.showFiltersModalSizeM;
  }
}
