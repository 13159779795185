import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { DeliverySummaryField } from "src/app/models/delivery.model";
import { NoticePanel } from "src/app/models/notice-panel.model";
import { DeliveryService } from "src/app/services/delivery.services";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";

@Component({
  selector: "app-delivery-step3",
  templateUrl: "./delivery-step3.component.html",
  styleUrls: ["./delivery-step3.component.scss"],
})
export class DeliveryStep3Component implements OnInit, OnDestroy {
  listDelivery?: DeliverySummaryField[] = [];
  showTable?: boolean = false;
  columns: DataTableColumn[] = [];
  totalRecords: number = 0;
  listNoticePanel: NoticePanel[] = [];
  titlePanel: string = "STEP1.NOTICE.ALERT";
  laboratoryRoute?: any;
  laboratoryBranchRoute?: any;
  deliveryIdRoute?: any;
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private deliveryService: DeliveryService
  ) {}
  ngOnInit() {
    this.spinner.show("deliveryStep3");
    this.showTable = false;
    this.route.queryParams.subscribe({
      next: (params) => {
        console.log(params);
        this.laboratoryRoute = params["laboratoryId"];
        this.laboratoryBranchRoute = params["laboratoryBranchId"];
        this.deliveryIdRoute = params["deliveryId"];
        console.log(
          "LABORATORYS",
          this.laboratoryBranchRoute,
          this.laboratoryRoute
        );
        this.totalRecords = this.listDelivery?.length || 0;

        if (this.deliveryIdRoute) {
          this.deliveryService
            .getDeliverySummary(this.deliveryIdRoute)
            .subscribe({
              next: (items) => {
                this.listDelivery = items;
                this.totalRecords = this.listDelivery.length;
                this.assignedNoticePanel();
                this.columns = this.createColumns();
                this.showTable = true;
                this.spinner.hide("deliveryStep3");
              },
              error: (err) => {
                console.error(err);
                this.spinner.hide("deliveryStep3");
              },
            });
        } else {
          this.assignedNoticePanel();
          this.columns = this.createColumns();
          this.showTable = true;
          this.spinner.hide("deliveryStep3");
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("deliveryStep3");
      },
    });
  }
  ngOnDestroy() {}
  assignedNoticePanel() {
    let notice = new NoticePanel();
    notice.text = this.translateService.instant(
      "INVOICES_STEP3.NOTICE_PANEL_1",
      { number: this.totalRecords }
    );
    this.listNoticePanel.push(notice);
  }
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "shippingCode",
        header: this.translateService.instant("COMMON_FORMS.SHIPPING_CODE"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "sampleCode",
        header: this.translateService.instant("COMMON_FORMS.CODE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "fileName",
        header: this.translateService.instant("COMMON_FORMS.PROOF"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
    ];
  }
  next() {
    console.log("next");
    this.router.navigate(["/home"]);
  }
}
