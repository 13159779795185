export class ProjectCardSearchDTO {
  textFilter: string;
  customerCodes: string[] | null;
  budgetCodes: string[] | null;
  categoryIds: number[] | null;
  fromDate: Date | null;
  toDate: Date | null;

  constructor(obj?: {
    textFilter?: string;
    customerCodes?: string[];
    budgetCodes?: string[];
    categoryIds?: number[];
    fromDate?: Date | string;
    toDate?: Date | string;
  }) {
    this.textFilter = obj?.textFilter ?? "";
    this.customerCodes = obj?.customerCodes ?? null;
    this.budgetCodes = obj?.budgetCodes ?? null;
    this.categoryIds = obj?.categoryIds ?? null;
    this.fromDate = obj?.fromDate ? new Date(obj.fromDate) : null;
    this.toDate = obj?.toDate ? new Date(obj.toDate) : null;
  }
}
