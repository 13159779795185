import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "overa-dropdown",
  templateUrl: "overa-dropdown.component.html",
  styleUrls: ["overa-dropdown.component.scss"],
})
export class OveraDropdownComponent implements OnChanges {
  @Input() title: string = "";
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input()
  options: any[] = [];
  @Input() optionLabel: string = "";
  @Input() controlName: string | null = null;
  @Input() formGroup: FormGroup | undefined;
  @Input() placeholder: string  = '';

  private _value: any;
  @Input()
  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
  }

  @Output() dropdownShow = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<any>();

  onModelChange(value: any) {
    this.valueChange.emit(value);
  }

  onDropdownShow() {
    this.dropdownShow.emit();
  }

  // Lazy loading trick
  ngOnChanges(changes: SimpleChanges) {
    if ("value" in changes && this.value != null && this.options?.length == 0) {
      this.options = [this.value];
    }
  }
}
