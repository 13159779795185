export class TestTypePatientForms {
  testTypeId?: string;
  testTypePatientForms?: PatientForms;
  constructor() {}
}
export class PatientForms {
  defaultLanguageLocale?: string;
  name?: string;
  languagePatientForms?: { [lang: string]: PatientForm };
  constructor() {}
}
export class PatientForm {
  fileName?: string;
  storageId?: string;
  constructor() {}
}
