import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { Invoice, SummaryField } from "src/app/models/invoice.model";
import { NoticePanel } from "src/app/models/notice-panel.model";
import { InvoiceService } from "src/app/services/invoice.service";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";

@Component({
  selector: "app-invoices-step3-summary",
  templateUrl: "./invoices-step3-summary.component.html",
  styleUrls: ["./invoices-step3-summary.component.scss"],
})
export class InvoicesStep3SummaryComponent implements OnInit, OnDestroy {
  listInvoices?: SummaryField[] = [];
  showTable?: boolean = false;
  columns: DataTableColumn[] = [];
  totalRecords: number = 0;
  listNoticePanel: NoticePanel[] = [];
  titlePanel: string = "STEP1.NOTICE.ALERT";
  laboratoryRoute?: any;
  laboratoryBranchRoute?: any;
  invoiceIdRoute?: any;
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private invoiceService: InvoiceService
  ) {}
  ngOnInit() {
    this.spinner.show("invoicesStep3");
    this.showTable = false;
    this.route.queryParams.subscribe({
      next: (params) => {
        console.log(params);
        this.laboratoryRoute = params["laboratoryId"];
        this.laboratoryBranchRoute = params["laboratoryBranchId"];
        this.invoiceIdRoute = params["invoiceId"];
        console.log(
          "LABORATORYS",
          this.laboratoryBranchRoute,
          this.laboratoryRoute
        );
        this.totalRecords = this.listInvoices?.length || 0;

        if (this.invoiceIdRoute) {
          this.invoiceService.getInvoiceSummary(this.invoiceIdRoute).subscribe({
            next: (items) => {
              this.listInvoices = items;
              this.totalRecords = this.listInvoices.length;
              this.assignedNoticePanel();
              this.columns = this.createColumns();
              this.showTable = true;
              this.spinner.hide("invoicesStep3");
            },
            error: (err) => {
              console.error(err);
              this.spinner.hide("invoicesStep3");
            },
          });
        } else {
          this.assignedNoticePanel();
          this.columns = this.createColumns();
          this.showTable = true;
          this.spinner.hide("invoicesStep3");
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("invoicesStep3");
      },
    });
  }
  ngOnDestroy() {}
  assignedNoticePanel() {
    let notice = new NoticePanel();
    notice.text = this.translateService.instant(
      "INVOICES_STEP3.NOTICE_PANEL_1",
      { number: this.totalRecords }
    );
    this.listNoticePanel.push(notice);
  }
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "sampleCode",
        header: this.translateService.instant("COMMON_FORMS.CODE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "invoiceNumber",
        header: this.translateService.instant("COMMON_FORMS.INVOICE_NUMBER"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "fileName",
        header: this.translateService.instant("COMMON_FORMS.INVOICE_RECEIPT"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
    ];
  }
  next() {
    console.log("next");
    this.router.navigate(["/home"]);
  }

  // back() {
  //   this.router.navigate(["/invoices/2"], {
  //     queryParams: {
  //       laboratoryId: this.laboratoryRoute,
  //       laboratoryBranchId: this.laboratoryBranchRoute,
  //       invoiceId: this.invoiceIdRoute,
  //     },
  //   });
  // }
}
