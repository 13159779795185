<ngx-spinner
  bdColor="rgba(42,55,84,0.4)"
  size="default"
  color="#e1e1e2"
  type="line-scale-pulse-out"
  [fullScreen]="false"
  name="validateSampleStep2"
>
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="container-step p-4 mb-2" *ngIf="loadedForm">
  <form [formGroup]="form" *ngIf="loadedForm">
    <div class="form-step">
      <div class="row">
        <div class="col-3">
          <overa-datepicker
            [title]="'VALIDATE_SAMPLE_STEP1.FORM.SAMPLE_DATE' | translate"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            controlName="sampleDate"
            [formGroup]="form"
          ></overa-datepicker>
        </div>
        <div class="p-field formField col-3">
          <overa-input
            [title]="'VALIDATE_SAMPLE_STEP1.FORM.TEST_TYPE_NAME' | translate"
            [isDisabled]="true"
            [controlName]="'testTypeName'"
            [formGroup]="form"
          ></overa-input>
        </div>
        <div class="p-field formField col-3">
          <overa-input
            [title]="'VALIDATE_SAMPLE_STEP1.FORM.PATIENT_NAME' | translate"
            [isDisabled]="true"
            [controlName]="'patientName'"
            [formGroup]="form"
          ></overa-input>
        </div>
        <div class="p-field formField col-3">
          <overa-input
            [title]="'VALIDATE_SAMPLE_STEP1.FORM.ID_PATIENT' | translate"
            [isDisabled]="true"
            [controlName]="'patientIdentityId'"
            [formGroup]="form"
          ></overa-input>
        </div>
      </div>
      <div class="row">
        <div class="col-3 containerValid">
          <label class="truncate-text"
            ><b>{{
              "VALIDATE_SAMPLE_STEP1.FORM.IS_VALID" | translate
            }}</b></label
          >
          <overa-checkbox
            [controlName]="'isValid'"
            [formGroup]="form"
            [isDisabled]="false"
          ></overa-checkbox>
        </div>
        <div class="col-9" *ngIf="form.get('isValid')?.value === false">
          <overa-text-area
            [controlName]="'noValidCause'"
            [title]="'VALIDATE_SAMPLE_STEP1.FORM.NO_VALID_CAUSE' | translate"
            [formGroup]="form"
            [placeholder]="
              'VALIDATE_SAMPLE_STEP1.FORM.NO_VALID_CAUSE_PLACEHOLDER'
                | translate
            "
          ></overa-text-area>
        </div>
      </div>
    </div>
  </form>
  <div>
    <button
      class="btn-continuar"
      (click)="save()"
      [disabled]="isReadOnly ? false : !form.valid ? true : false"
    >
      {{ isReadOnly ? "" : ("ACTION.VALIDATE" | translate) }}
      <i [class]="isReadOnly ? 'pi pi-home' : 'fa fa-arrow-right'"></i>
    </button>
  </div>
</div>
