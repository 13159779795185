import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "lib-overa-progress-spinner",
  templateUrl: "./overa-progress-spinner.component.html",
  styleUrls: ["./overa-progress-spinner.component.scss"],
})
export class OveraProgressSpinnerComponent {
  //TODO separeto to other project or create his own folder
  /**
   * Type number. How much of the percent is completed.
   * By default 0
   */
  @Input() percent: number = 0;
  /**
   * Color for the complete percentage bar
   * Html format
   * Default : #4CAF50
   */
  @Input() completedColor: string = "#4CAF50";
  /**
   * Has to fill the uncompleted percentage with this color;
   * Currently not working
   */
  @Input() incompletedColor: string | undefined; //TODO: not working, it doesnt fill the not completed percentage
  /**
   * Text color
   * Html Format
   * Default : #000
   */
  @Input() textColor: string = "#000";
  /**
   * Size of the spinner
   * Number. Default 120
   */
  @Input() size: number = 120;
  /**
   * Radious of the spinner
   * Number. Default 15.9155
   */
  @Input() radius: number = 15.9155;
  /**
   * Bar gross.
   * Number. Default 3
   */
  @Input() strokeWidth: number = 3;

  get circumference(): number {
    return this.radius * Math.PI * 2;
  }

  get dashoffset(): number {
    return this.circumference - (this.percent / 100) * this.circumference;
  }

  get fontSize(): number {
    return this.radius / 1.75;
  }

  getPath(): string {
    const offset = this.strokeWidth / 2;
    const r = this.radius - offset;
    return `M ${this.radius + offset} ${offset}
              a ${r} ${r} 0 0 1 0 ${r * 2}
              a ${r} ${r} 0 0 1 0 -${r * 2}`;
  }
}
