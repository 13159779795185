<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerListShippingLabelStock">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>

<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div>
      <!-- <h4 class="card-title">
        <span>{{ "SHIPPINGLABEL_MAINTENANCE.TITLE" | translate }}</span>
      </h4> -->
      <div class="row row-with-title">
        <div class="col-6 title-view">
          <h4 class="card-title title-inside-steps-h4">
            {{ "SHIPPINGLABEL_MAINTENANCE.TITLE" | translate }}
          </h4>
        </div>
        <div class="col-6 filter-text-div">
          <overa-filter-text (searchChangeKeyPressEvent)="onSearchTextChange($event)"
            (searchChangeClickEvent)="onSearchTextChange($event)" [styleClass]="filterClass"></overa-filter-text>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="card-content">
      <div *ngIf="showTable">
        <overa-datatable #datatableLaboratoryShippingLabel [columns]="columns" [showPaginator]="true"
          [rowsPerPage]="[10, 20]" [items]="listLaboratoryShippingLabels" [totalRecords]="totalRecords">
        </overa-datatable>
      </div>
    </div>
  </div>
</div>