
export abstract class BaseEntity {
  id: string | undefined;
  name: string | undefined;
  description: string | undefined;

  constructor() {
    
    
  }
}
