import { OveraKeyValuePair } from "../shared/temporal_components/models/overa-keyValue-pair.model";
import { AnalysisSubtype } from "./analysis-subtype.model";
import { DocumentApi } from "./document-api.model";
import { LaboratoryBranch } from "./laboratory-branch.model";

export class AnalysisType {
  id: string | undefined;
  name?: string;
  description?: string;
  diagnosticaPrice?: number;
  sampleType?: string;
  state?: boolean;
  sampleValidityTime: number = 0;
  deliveryTerm: number = 0;
  subtypes?: AnalysisSubtype[];
  //subtypeId?: string;
  //TODO gestor documental para pdfs?
  priceValidityDate?: Date;
  defaultSampleProcedureInstructionsId?: string;
  defaultSampleDeliveryInstructionsId?: string;
  defaultSampleProcedureInstructions?: DocumentLanguage;
  defaultSampleDeliveryInstructions?: DocumentLanguage;
  newDefaultSampleProcedureInstructions?: DetailInstructions[];
  newDefaultSampleDeliveryInstructions?: DetailInstructions[];
  manual?: DocumentLanguage;
  manualId?: string;
  newManual?: DetailInstructions[];
  patientForm?: DocumentLanguage;
  patientFormId?: string;
  newManuals?: DetailStorage[];
  newPatientForms?: DetailStorage[];
  // defaultPatientManuals?: DetailPatientManual[];

  constructor() {}
}
export class AnalysisTypeLaboratoryBranch {
  laboratoryBranchId?: string;
  laboratoryBranch?: LaboratoryBranch;
  testTypeId?: string;
  testType?: AnalysisType;
  instructions?: OveraKeyValuePair[];
  description: string = "";
  selected?: boolean;
  deleteAction?: boolean;
  id?: string;
  price?: number;
  name?: string;
  constructor() {}
}

export class DocumentLanguage {
  documentLanguages?: DetailDocumentLanguage[];

  constructor() {}
}
export class DetailDocumentLanguage {
  language?: DetailLanguage;
  text?: string;
  languageId?: string;
  id?: string;
  storageId?: string;
  fileName?: string;
  documentId?: string;
  document?: DocumentApi;
}
export class DetailLanguage {
  name?: string;
  constructor() {}
}

export class DetailInstructions {
  id?: string;
  text?: string;
  languageId?: string;
  name?: string;
}
export class DetailStorage {
  id?: string;
  documentId?: string;
  fileName?: string;
  storageId?: string;
  language?: string;
  fileDataJson?: any;
  languageId?: string;
  controlName?: string;
}
