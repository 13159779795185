import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { OveraCardItem } from "../models/overa-card-item.model";
import { OveraCardItemField } from "../models/overa-card-item-field.model";
import { OveraDropDownMenuOption } from "../models/overa-dropdown-menu-option.model";
import { OveraCardHeaderIconsOption } from "../models/overa-card-header-icons-option.model";

@Component({
  selector: "overa-card",
  templateUrl: "./overa-card.component.html",
  styleUrls: ["./overa-card.component.scss"],
})
/**
 * OveraCardComponent
 *
 * Este componente representa una tarjeta en el diseño de la aplicación.
 * Puedes personalizar el contenido de la tarjeta utilizando un ng-template personalizado en el componente que lo contiene.
 * Ejemplo de uso:
 *
 * <overa-card>
 *   <ng-template let-item>
 *     <!-- Tu contenido personalizado aquí -->
 *   </ng-template>
 * </overa-card>
 *
 */
export class OveraCardComponent implements OnInit {
  bottomBorderColor: string | undefined;
  unCompletedCircleColor: string = "#938B8B";
  currentItem: OveraCardItem | undefined;
  @ContentChild(TemplateRef) cardTemplate: TemplateRef<any> | undefined;
  @Input() cardId: string = "";
  @Input() cardMenuOptions: OveraDropDownMenuOption[] | undefined;
  @Input() cardHeaderIconsOptions: OveraCardHeaderIconsOption[] | undefined;
  /**
   * Se especifica si todos los bordes llevan el mismo color;
   * Si es true, colorBorders tiene que estar especificado;
   * Por defecto false
   */
  @Input() allBordersSameColor: boolean = false;
  /**
   * Propiedad boolean para especificar si necesitamos el progressSpiner.
   * Por defecto false
   */
  @Input() showProgressSpinner: boolean = true;
  /**
   * Objeto de tipo OveraCardItemField, o {key:string,value:string}
   * Se mostrara como cabecera de la card
   */
  @Input() cardTitle:
    | OveraCardItemField
    | { key: string; value: string }
    | undefined;
  /**
   * Objeto de tipo OveraCardItemField[], o {key:string,value:string}[]
   * Se mostrara la informacion en el cuerpo de la card
   */
  @Input() cardInfo:
    | OveraCardItemField[]
    | { key: string; value: string }[]
    | undefined;
  /**
   * Clase de scss o estilos para toda la card, se situa con NgStyle en el tag padre
   */
  @Input() cardClass: string | undefined;
  /**
   * Objeto de tipo number
   * Se mostrará en el componente de progress si showProgressSpinner es true ( o por defecto)
   */
  @Input() percentage: number | undefined;
  /**
   * Objeto de tipo boolean
   * Se usa para mostrar el color del borde inferior de la card, a la vez que el texto que indica %
   */
  @Input() isCritic: boolean = false;
  /**
   * Critic color to show on bottom of card;
   * Only applies if allBordersSameColor is set to false or default
   * Format HTML
   * By default '#D62E2E'
   */
  @Input() criticColor: string = "#D62E2E";
  /**
   * Complimenter color to show on bottom of card;
   * Only applies if allBordersSameColor is set to false or default
   * Format HTML
   * By default '#3b8145fb'
   */
  @Input() checkColor: string = "#3b8145fb";
  /**
   * Neutral color to show on bottom of card;
   * Only applies if allBordersSameColor is set to false or default
   * Format HTML
   * By default '#938B8B'
   */
  @Input() neutralColor: string = "#938B8B";

  @Input() iconName: string | undefined;

  @Input() iconHint: string | undefined;

  @Output() cardClick: EventEmitter<OveraCardItem> =
    new EventEmitter<OveraCardItem>();

  @Output() iconClick: EventEmitter<OveraCardItem> =
    new EventEmitter<OveraCardItem>();
  dropdownOpen: boolean = false;
  ngOnInit(): void {
    this.setCurrentItem();
    this.setBottomBorderColor();
  }
  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.dropdownOpen = !this.dropdownOpen;
  }
  private setBottomBorderColor() {
    if (!this.allBordersSameColor) {
      if (this.currentItem) {
        if (this.currentItem.percentageIsCritic) {
          this.bottomBorderColor = this.criticColor;
          this.unCompletedCircleColor = this.criticColor;
        } else if (
          !this.currentItem.percentageIsCritic &&
          this.currentItem.percentage == 100
        ) {
          this.bottomBorderColor = this.checkColor;
        } else {
          this.bottomBorderColor = this.neutralColor;
        }
      }
    }
  }

  onCardClick() {
    this.cardClick.emit(this.currentItem);
  }

  setCurrentItem() {
    this.currentItem = new OveraCardItem();
    this.currentItem.id = this.cardId;
    this.currentItem.fields = this.cardInfo ?? [];
    this.currentItem.percentage = this.percentage ?? 0;
    this.currentItem.percentageIsCritic = this.isCritic;
    this.currentItem.title = this.cardTitle ?? new OveraCardItemField();
    this.currentItem.menuOptions = this.cardMenuOptions ?? [];
    this.currentItem.headerIconsOptions = this.cardHeaderIconsOptions ?? [];
  }

  onIconClick() {
    this.iconClick.emit(this.currentItem);
  }
}
