import { AnalysisTypeLaboratoryBranch } from "./analysis-type.model";
import { BoxStock } from "./box-stock.model";
import { Laboratory } from "./laboratory.model";

export class LaboratoryBranch {
  id?: string;
  name?: string;
  description?: string | undefined;
  phoneNumber?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  country?: string;
  deliveryEmail?: string;
  //TODO ver si se asocia a usuario identity
  contact?: string;
  email?: string;
  laboratory?: Laboratory;
  laboratoryId?: string;
  laboratoryName?: string;
  laboratoryBranchTestTypes?: AnalysisTypeLaboratoryBranch[];

  constructor() {}
}
export class LaboratoryBranchUsers {
  id?: string;
  laboratory?: Laboratory;
  laboratoryId?: string;
  laboratoryBranch?: LaboratoryBranch;
  laboratoryBranchId?: string;
  name?: string;
  lastName?: string;
  email?: string;
  identityUserId?: string;
  constructor() {}
}

export class LaboratoryBranchUsersDataList {
  id?: string;
  laboratoryId?: string;
  identityUserId?: string;
  name?: string;
  lastName?: string;
  email?: string;
  listLaboratoryBranchUsers?: LaboratoryBranchUsers[];

  constructor() {}
}
export class LaboratoryBranchStock {
  laboratoryId?: string;
  laboratoryName?: string;
  laboratoryBranchId?: string;
  laboratoryBranchName?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  address?: string;
  phoneNumber?: string;
  contact?: string;
  kitCount?: number;
  boxCount?: number;

  constructor() {}
}
