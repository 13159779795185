<p-fieldset>
  <ng-template pTemplate="header">
    <div class="flex align-items-center text-primary">
      <span
        [ngClass]="
          titleError
            ? 'font-bold text-lg borderNotice textTitlePanelNoticesError'
            : 'font-bold text-lg borderNotice textTitlePanelNotices'
        "
        >{{ titleHeader | translate }}</span
      >
    </div>
  </ng-template>
  <div>
    <ng-container *ngFor="let panel of listPanel">
      <div class="row">
        <div
          [ngClass]="
            panel.error === true
              ? 'col-1 alignIcon errorImage'
              : 'col-1 alignIcon'
          "
        >
          <ng-container *ngIf="panel.iconPrimeng">
            <i [ngClass]="panel.iconPrimeng ? panel.iconPrimeng : ''"></i>
          </ng-container>
          <ng-container *ngIf="panel.iconPath">
            <img
              [src]="panel.iconPath"
              alt="Icono de PrimeNG"
              class="primeng-icon"
              style="height: 16px"
            />
          </ng-container>
        </div>
        <div class="col-11">
          <ng-container *ngIf="panel.text">
            <p class="m-0 lineInline">
              {{ panel.text | translate }} <b>{{ panel.textBoldValue }}</b>
            </p>
          </ng-container>
          <ng-container *ngIf="panel.textInnerHTML">
            <p
              class="m-0"
              [innerHtml]="panel.textInnerHTML | safeHtml : true"
            ></p>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</p-fieldset>
