import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@overa/security';
import { Test } from '../models/test.model';
import { Observable, of, Subject, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TestService {

  private testSubject: ReplaySubject<Test[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  public testObservable: Observable<Test[]> = this.testSubject.asObservable();

  private routeTestSubject: ReplaySubject<string> = new ReplaySubject(1);
  public routeTestObservable: Observable<string> = this.routeTestSubject.asObservable();

  get baseUrl() {
    return Config.apiBaseUrl + '/api';
  }

  constructor(private http: HttpClient) {}

  get() {
    return this.http.get<Test[]>(`${this.baseUrl}/test`);
  }

  getById(id: string) {
    return this.http.get<Test>(`${this.baseUrl}/test/${id}`);
  }

  add(test: Test) {
    const endpointUrl = `${this.baseUrl}/test`;
    return this.http.post<Test>(endpointUrl, test);
  }

  edit(test: Test) {
    const endpointUrl = `${this.baseUrl}/test`;
    return this.http.put<Test>(endpointUrl, test);
  }

  delete(id: string) {
    const endpointUrl = `${this.baseUrl}/test/${id}`;
    return this.http.delete(endpointUrl);
  }

  notifyupdate(tests: Test[]) {
    this.testSubject.next(tests);
  }

  notifyupdateRoute(id: string) {
    this.routeTestSubject.next(id);
  }

}
