export class OveraKeyValuePair {
  //TODO: add to public api when moved to uilib
  key: string;
  value: string;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}
