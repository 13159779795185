import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "@overa/security";
import { Observable, of, ReplaySubject } from "rxjs";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
import { LaboratoryBranchUsers } from "../models/laboratory-branch.model";
import { LaboratoryBranchUserLaboratories } from "../models/laboratory-branch-user-laboratories.model";

@Injectable({ providedIn: "root" })
export class LaboratoryBranchUserService extends OveraBaseService<LaboratoryBranchUsers> {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/laboratoryBranchUser";
  }

  constructor(http: HttpClient) {
    super(http);
  }
  getLaboratoryBranchUsersToLaboratoryId(laboratoryId: string) {
    const endpointUrl = `${this.baseUrl}/laboratory/${laboratoryId}`;
    return this.http.get<LaboratoryBranchUsers[]>(endpointUrl);
  }
  getLaboratoriesByIdentityUserId(identityUserId: string) {
    const endpointUrl = `${this.baseUrl}/identityuser/laboratories/${identityUserId}`;
    return this.http.get<LaboratoryBranchUserLaboratories>(endpointUrl);
  }
  getOccupiedUsers() {
    const endpointUrl = `${this.baseUrl}/occupied`;
    return this.http.get<LaboratoryBranchUsers[]>(endpointUrl);
  }
}
