import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { OveraMessagesService } from "@overa/components";
import { ConfigStorage } from "@overa/storage";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { UpdatedShippingLabelStorage } from "src/app/models/updated-shipping-label-storage.model";
import { ShippingLabelService } from "src/app/services/shipping-label.service";

@Component({
  selector: "app-file-upload-maintenance",
  templateUrl: "./file-upload-maintenance.component.html",
  styleUrls: ["./file-upload-maintenance.component.scss"],
  providers: [ConfirmationService],
})
export class FileUploadMaintenanceComponent implements OnInit, OnDestroy {
  dataItem: any;
  storageId = ConfigStorage.storageId;
  fileId = "";
  fileName = "";
  fileDataJson: string = "";
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  isNew: boolean = false;
  isChanged: boolean = false;
  constructor(
    private messagesService: OveraMessagesService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private shippingLabelService: ShippingLabelService,
    private spinner: NgxSpinnerService
  ) {
    this.dataItem = this.dialogConfig.data?.dataItem;
  }
  ngOnInit() {
    this.spinner.show("spinnerFileUpload");
    console.log(this.dataItem);
    this.loadedForm = false;
    this.isNew = false;
    this.isChanged = false;
    this.createForm();
    if (this.dataItem.pdfStorageId && this.dataItem.pdfName) {
      var fileData = {
        guid: this.dataItem.pdfStorageId,
        name: this.dataItem.pdfName,
      };
      this.fileDataJson = JSON.stringify(fileData);
      this.fileId = this.dataItem.pdfStorageId;
      this.fileName = this.dataItem.pdfName;
      this.spinner.hide("spinnerFileUpload");
    } else {
      this.spinner.hide("spinnerFileUpload");
    }
  }
  createForm() {
    this.form = new FormGroup({
      file: new FormControl<any>(null, []),
    });
    this.loadedForm = true;
  }
  onAddFile(event: any) {
    console.log(event);
    if (event?.file?.id) {
      this.fileId = event.file.id;
      this.fileName = event.file.displayName;
      this.isChanged =
        this.fileName != this.dataItem.pdfName &&
        this.fileId != this.dataItem.pdfStorageId
          ? true
          : false;
    }

    console.log("createAttachment");
  }
  fileDeleted(event: any) {
    console.log(event);
    this.fileDataJson = "";
  }
  cancel() {
    // this.form.disable();
    this.dialogRef.close();
  }
  back() {
    this.cancel();
  }
  update() {
    if (
      this.dataItem.pdfName != this.fileName &&
      this.dataItem.pdfStorageId != this.fileId
    ) {
      this.spinner.show("spinnerFileUpload");
      let newUpdatedShippingStorage = new UpdatedShippingLabelStorage();
      newUpdatedShippingStorage.shippingLabelId = this.dataItem.id;
      newUpdatedShippingStorage.pdfDocumentFileName = this.fileName;
      newUpdatedShippingStorage.pdfDocumentStorageId = this.fileId;
      this.shippingLabelService
        .updateStorageId(newUpdatedShippingStorage)
        .subscribe({
          next: (objectUpdate) => {
            this.messagesService.showToast(
              "MESSAGES.SUCCESS.UPDATE_LONG",
              "MESSAGES.SUCCESS.UPDATE_LONG",
              "success",
              "HEADER_TITLE.HEADER_TITLE"
            );
            this.spinner.hide("spinnerFileUpload");

            this.dialogRef.close(objectUpdate);
          },
          error: (err) => {
            console.log(err);
            this.spinner.hide("spinnerFileUpload");
          },
        });
    } else {
      this.dialogRef.close();
    }
  }
  ngOnDestroy() {}
}
