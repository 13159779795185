import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Config } from "@overa/security";
import { Observable, of, Subject, ReplaySubject } from "rxjs";
import {
  LaboratoryBranch,
  LaboratoryBranchStock,
} from "src/app/models/laboratory-branch.model";
import { AnalysisTypeLaboratoryBranch } from "../models/analysis-type.model";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
import { Globals } from "../utils/globals";
import { Utils } from "../utils/utils";
import { BoxStock } from "../models/box-stock.model";

@Injectable({ providedIn: "root" })
export class LaboratoryBranchService extends OveraBaseService<LaboratoryBranch> {
  //private campusSubject: ReplaySubject<Campus[]> = new ReplaySubject(1); // Uno es el numero de valores a reemitir
  //public campusObservable: Observable<Campus[]> =
  //  this.campusSubject.asObservable();

  //private routeCampusSubject: ReplaySubject<string | null> = new ReplaySubject(1);
  //public routeCampusObservable: Observable<string | null> =
  //  this.routeCampusSubject.asObservable();

  constructor(http: HttpClient, private utils: Utils) {
    super(http);
  }

  get baseUrl() {
    return Config.apiBaseUrl + "/api/laboratoryBranch";
  }
  getByLaboratoryId(id: string): Observable<LaboratoryBranch[]> {
    return this.http.get<LaboratoryBranch[]>(this.baseUrl + "/getall/" + id);
  }

  //notifyupdate(campus: Campus[]) {
  //  this.campusSubject.next(campus);
  //}

  //notifyupdateRoute(id: string | null) {
  //  this.routeCampusSubject.next(id);
  //}
  getAllBoxStock(): Observable<LaboratoryBranchStock[]> {
    return this.http.get<LaboratoryBranchStock[]>(
      this.baseUrl + "/stock/getall"
    );
  }
  updateStock(laboratoryBranchId: string, boxStock: BoxStock) {
    const endpointUrl = `${this.baseUrl}/stock/${laboratoryBranchId}`;
    return this.http.post<LaboratoryBranch>(endpointUrl, boxStock);
  }
}
