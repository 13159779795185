export enum HeaderStatus {
  REGISTERED,
  RECEIVED,
  FINISHED,
  SENT,
  ERRORED,
  REPORT_FINISHED,
  REPORTS_RECEIVED,
  SAMPLES_RECEIVED,
}
