<div class="p-field formField">
  <label
    ><span *ngIf="isRequired">*</span><b>{{ title }}</b></label
  ><br />
  <p-dropdown
    *ngIf="!formGroup"
    [options]="options"
    [style]="{ width: '100%' }"
    [optionLabel]="optionLabel"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange($event)"
    [disabled]="isDisabled"
    [filter]="options ? options.length > 5 : false"
    appendTo="body"
    [filterBy]="optionLabel"
    [showClear]="false"
    (onShow)="onDropdownShow()"
  ></p-dropdown>
  <ng-container *ngIf="formGroup" [formGroup]="formGroup">
    <p-dropdown
      [options]="options"
      [style]="{ width: '100%' }"
      [optionLabel]="optionLabel | translate"
      [formControlName]="controlName"
      [filter]="options.length > 5"
      appendTo="body"
      [filterBy]="optionLabel"
      [placeholder]="placeholder"
    ></p-dropdown>
  </ng-container>
</div>
