import { ShippingLabelModified } from "./shipping-label-modified.model";

export class ShippingLabelStock {
  id?: string;
  laboratoryId?: string;
  laboratoryName?: string;
  laboratoryBranchId?: string;
  laboratoryBranchName?: string;
  address?: string;
  phoneNumber?: string;
  contact?: string;
  stock?: number;
  shippingLabels?: ShippingLabelModified[];

  constructor() {}
}
