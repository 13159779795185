import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ViewContainerRef,
  EventEmitter,
  Output,
} from "@angular/core";
import { Location } from "@angular/common";
import {
  AuthService,
  AccountService,
  MenuService,
  Menu,
} from "@overa/security";
import { NavbarItem } from "./overa-navbar-item";
import { ConfigShared } from "@overa/shared";
import { OveraMenuItem } from "../overa-menubar/menuitem.model";
import { Globals } from "../../../utils/globals";


const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

export interface ComponentLoader {
  loadChildren: () => Promise<any>[];
}

declare let $: any;
@Component({
  selector: "overa-navbar",
  templateUrl: "overa-navbar.component.html",
  styleUrls: ["./overa-navbar.component.scss"],
})
export class OveraNavbarComponent implements OnInit {
  @Input() menuItems: Menu[] = [];
  @Input() listNavbarItems: NavbarItem[] = [];
  @Input() menubarItems: OveraMenuItem[] = [];

  @ViewChild("containerNavbarRight", { static: true, read: ViewContainerRef })
  containerNavbarRight?: ViewContainerRef;
  @ViewChild("containerNavbarMobile", { static: true, read: ViewContainerRef })
  containerNavbarMobile?: ViewContainerRef;

  private listTitles?: any[];
  location: Location;
  mobile_menu_visible: number = 0;
  currentMenuItem?: Menu;
  tenantPickerMenuItem?: Menu;
  languagePickerMenuItem?: Menu;
  isNewDesign = ConfigShared.appConfig.useNewDesign;
  useGoldenLayout = ConfigShared.appConfig.useGoldenLayout;
  newTabWithCtrlClick = ConfigShared.appConfig.newTabWithCtrlClick;
  isCtrlNavigation: boolean = false;
  showGlobalFilter: boolean = false;

  private toggleButton: any;
  private sidebarVisible: boolean;

  private isAdmin = false;

  @ViewChild("app-navbar-cmp", { static: false }) button: any;

  constructor(
    location: Location,
    private element: ElementRef,
    private authService: AuthService,
    private accountService: AccountService,
      private menuService: MenuService,
  ) {
    this.location = location;
    this.sidebarVisible = false;
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add("sidebar-mini");

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  logout() {
    this.authService.logout();
  }

  hideSidebar() {
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return true;
    }
    return false;
  }

  loadComponents() {
    this.containerNavbarRight?.clear();
    this.containerNavbarMobile?.clear();
  }

  ngOnInit() {
      this.isAdmin = this.accountService.isAdmin;
    this.listNavbarItems = [];

    this.loadComponents();

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains("hide-sidebar")) {
      misc.hide_sidebar_active = true;
      }
      this.menuService.updateRouteObservable.subscribe((menu: Menu) => {
          this.currentMenuItem = menu;
      });

  }

  navigationClick(event: MouseEvent, _route: string) {
    event.preventDefault();
    if (event.ctrlKey && this.newTabWithCtrlClick) {
      this.isCtrlNavigation = true;
    } else {
      this.isCtrlNavigation = false;
    }
  }

  onResize(_event: any): boolean {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    const $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    body.classList.add("nav-open");
    setTimeout(function () {
      $toggle.classList.add("toggled");
    }, 430);

    const $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    if (body.querySelectorAll(".main-panel")) {
      document.getElementsByClassName("main-panel")[0].appendChild($layer);
    } else if (body.classList.contains("off-canvas-sidebar")) {
      document
        .getElementsByClassName("wrapper-full-page")[0]
        .appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add("visible");
    }, 100);

    const self = this;

    $layer.onclick = function () {
      //asign a function
      body.classList.remove("nav-open");

      self.mobile_menu_visible = 0;
      self.sidebarVisible = false;

      $layer.classList.remove("visible");
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove("toggled");
      }, 400);
    }.bind(this);

    body.classList.add("nav-open");
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    const $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    this.sidebarVisible = false;
    body.classList.remove("nav-open");
    // $('html').removeClass('nav-open');
    body.classList.remove("nav-open");
    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
        $toggle.classList.remove("toggled");
        //$toggle.removeAttribute("checked");
    }, 400);
      var checkbox: HTMLInputElement | null = document.getElementById('menu_responsive-toggle') as HTMLInputElement;
      if (checkbox) {
          checkbox.checked = false;
      }

    this.mobile_menu_visible = 0;
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    if (this.menuItems) {
      const namee = this.location.prepareExternalUrl(this.location.path());

      let res = "";
      if (this.listTitles) {
        this.listTitles
          .filter((m) => m.uiUrl)
          .forEach((item) => {
            if (!res && item.uiUrl === namee) {
              res = item.name;
            } else if (item.children) {
              item.children.forEach(
                (child: { uiUrl: string; name: string }) => {
                  if (!res && item.uiUrl + "/" + child.uiUrl === namee) {
                    console.log("getTitle->", item.name + " " + child.name);
                    res = item.name + " - " + child.name;
                  }
                }
              );
            }
          });
      }

      return res;
    } else {
      return "";
    }
  }

  getSubmenus(event: MouseEvent, item: Menu): void {
    if (item.componentName == "GlobalFiltersComponent") {
      this.showGlobalFilter = !this.showGlobalFilter;
    }
    if (item.uiUrl) {
        this.menuService.updateMainRoute(item);
        this.currentMenuItem = item;
      this.navigationClick(event, item.uiUrl);
    }
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
    }
    updateMainRoute(item: any) {
      this.currentMenuItem = item;
  }
}
