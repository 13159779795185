import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraStepperComponent } from "@overa/components";

@Component({
  selector: "app-invoices-steps",
  templateUrl: "./invoices-steps.component.html",
  styleUrls: ["./invoices-steps.component.scss"],
})
export class InvoicesStepsComponent implements OnInit, OnDestroy {
  @ViewChild("stepper", { static: true }) stepper?: OveraStepperComponent;

  items: any[] = [];
  item: any;
  current_step: number = 0;
  firstBreadCrumb: string = "";
  secondBreadCrumb: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {}
  ngOnInit() {
    console.log("*********ONINIT VALIDATE STEPS COMPONENTE*****************");
    this.current_step = 0;
    this.route.params.subscribe((params) => {
      this.current_step = +params["page"] || 1;
      this.stepper?.changePage(this.current_step - 1);

      if (this.current_step == 2) {
      }
    });

    this.firstBreadCrumb = this.translateService.instant(
      "MASTER_STEPS.FIRSTBREADCRUMB"
    );
    this.secondBreadCrumb = this.translateService.instant(
      "MASTER_STEPS.STEPS.STEP1"
    );

    this.items = [
      {
        command: (event: any) => {
          this.firstBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.FIRSTBREADCRUMB"
          );
          this.secondBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.STEPS.STEP1"
          );
          this.current_step = 1;
          console.log("Paso 1");
        },
      },
      {
        command: (event: any) => {
          this.firstBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.FIRSTBREADCRUMB"
          );
          this.secondBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.STEPS.STEP2"
          );
          this.current_step = 2;
          console.log("Paso 2");
          // this.router.navigate(["/createProject/", this.current_step]);
        },
      },
      {
        command: (event: any) => {
          console.log("Paso 3");
          this.firstBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.FIRSTBREADCRUMB"
          );
          this.secondBreadCrumb =
            this.translateService.instant("MASTER_STEPS.STEPS.STEP3") + " ";
          this.current_step = 3;
        },
      },
    ];

    let validateStepsData = this.route.snapshot.paramMap.get("extras");
    if (validateStepsData) {
      console.log("baseStepsData", validateStepsData);
      this.changePage({ toPage: 2, extra: validateStepsData });
    }
  }
  changePage(params: any) {
    if (this.stepper) {
      this.item = params.extra;
      this.stepper.changePage(params.toPage);
      this.current_step = params.toPage;

      if (params.toPage == 1) {
        // En menusettings añadimos nueva ruta y manejamos desde aqui ( no está muy refinado pero es como quedó por ahora )
        this.router.navigate(["/delivery", this.current_step + 1], {
          queryParams: { code: params.extra.code },
        });
      }
    }
  }
  ngOnDestroy() {}
}
