import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { BillingType } from "src/app/models/billing-type.model";
import { AppPermissions } from "src/app/models/app-permission.model";
import { BillingTypeService } from "src/app/services/billing-type.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  BaseListComponent,
  AccountService,
  SecurityStateManagementService,
  Hotkeys,
  ResponseNotificationService,
} from "@overa/security";
import { OveraDataTableComponent } from "../../../../shared/temporal_components/overa-datatable/overa-datatable.component";
import { DataTableColumn } from "../../../../shared/temporal_components/models/dataTablecolumn.model";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService } from "primeng/api";
import { OveraMessagesService } from "@overa/components";

@Component({
  selector: "app-billing-type-list",
  templateUrl: "./billing-type-list.component.html",
  styleUrls: ["./billing-type-list.component.scss"],
  providers: [ConfirmationService],
})
export class BillingTypeListComponent implements OnInit, OnDestroy {
  static className = "BillingTypeListComponent";

  items: BillingType[] = [];
  itemsTemp: BillingType[] = [];
  allItems: BillingType[] = [];

  currentPageIndex: number = 0;
  currentPageSize: number = 10;
  searching = false;
  columns: DataTableColumn[] = [];
  data: BillingType[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  totalRecords = 0;
  canView = true;
  baseRoute = "maintenances/billing-type";
  canCreate = true;
  @ViewChild("datatable")
  dataTable?: OveraDataTableComponent<any>;

  showTable = false;

  constructor(
    public accountService: AccountService,
    public SSMService: SecurityStateManagementService,
    public router: Router,
    public route: ActivatedRoute,
    public hotkeys: Hotkeys,
    private billingTypeService: BillingTypeService,
    private RNService: ResponseNotificationService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private messagesService: OveraMessagesService,
    private translateService: TranslateService
  ) {
    //super(accountService, SSMService, router, hotkeys);
    //this.viewPermission = AppPermissions.PERMISSIONS_DATA.viewBillingType.value;
    //this.createPermission = AppPermissions.PERMISSIONS_DATA.createBillingType.value;
    //this.deletePermission = AppPermissions.PERMISSIONS_DATA.deleteBillingType.value;
    //this.baseRoute = '/maintenances/billingType';
    //this.onBuild = () => {
    //};
    //this.loadData = () => {
    //  this.spinner.show();
    //  this.billingTypeService.get().subscribe({
    //    next:(items) => {
    //    console.log(items);
    //    this.billingTypeService.notifyupdate(items);
    //      this.data = items;
    //      this.spinner.hide();
    //  },
    //    error:(error: any) => {
    //      this.RNService.showError(error);
    //      this.spinner.hide();
    //  }
    //  });
    //};
    //this.onInit = () => {
    //  this.loadData();
    //  this.billingTypeService.billingTypeObservable.subscribe(items => {
    //    this.data = items;
    //  });
    //};
  }
  getData() {
    this.spinner.show();

    // console.log("GETDATA ->", pageIndex, pageSize, filters);
    this.billingTypeService
      //.get(filters || new CampusFilter(), pageIndex, pageSize)
      .get()
      //.pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (values: BillingType[]) => {
          // console.log("VALUES ->", pageIndex, pageSize, values);

          this.data = values;
          this.totalRecords = this.data.length;
          this.generateItems(values);
          if (this.dataTable) this.dataTable.itemsTemp = this.itemsTemp;
          this.spinner.hide();

          //  this.loadDataFunction();
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
  }
  loadDataFunction = (): BillingType[] => {
    //if (

    //console.log("LOAD DATA FUNCTION (CHANGE PAGE)", pag, items_per_pag);
    //this.currentPageIndex = pag;
    //this.currentPageSize = items_per_pag;
    //  this.getData().finally(() => { });
    return this.items;

    //  return this.items;
  };
  ngOnInit() {
    this.columns = this.createColumns();
    setTimeout(() => this.getData(), 1);
  }

  openForm(id: string | null) {
    this.router.navigateByUrl("maintenances/billing-type/" + id);
    // this.laboratoryService.notifyupdateRoute(id);
  }

  generateItems(data: any[]) {
    const goToDetail = (index: any) => {
      if (data[index].selected) {
        this.openForm(data[index].id);
      }
    };
    const deleteFn = (index: any) => {
      if (data[index].delete) {
        this.openForm(data[index].id);
      }
    };

    let items = data;
    items.forEach((element: any) => {
      element.selected = {
        value: false, //TODO: add value from object
        command: goToDetail,
      };
      //element.delete = {
      //  value: false,
      //  command: deleteFn
      //}
      this.itemsTemp.push(element);
    });
    this.data = this.itemsTemp;
  }

  createColumns(): DataTableColumn[] {
    return [
      {
        field: "name",
        header: this.translateService.instant("COMMON_FORMS.NAME"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },

      {
        field: "selected",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("COMMON_FORMS.SELECTION"),
        type: "System.Button",
        icon: "pi pi-pencil",
        cssClass: "button-selected-billing-type-list",
      },
      //{
      //  field: "delete",

      //  header: this.translateService.instant("ACTION.DELETE"),
      //  type: "System.Button",
      //  icon: 'pi pi-trash'
      //},
    ];
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  delete(id: string) {
    let trmessage,
      messageAccept = "";
    this.translateService
      .get("COMMONS.ASK_DELETE_RECORD")
      .subscribe((a: string) => {
        trmessage = a;
      });
    this.translateService.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        this.billingTypeService.delete(id).subscribe({
          next: () => {
            this.billingTypeService.get().subscribe((billingTypes) => {
              this.router.navigateByUrl(this.baseRoute);
            });
            this.spinner.hide();

            this.messagesService.showToast(
              "MESSAGES.SUCCESS.DELETE",
              "MESSAGES.SUCCESS.DELETE_LONG",
              "success",
              "",
              "BILLING_TYPE_MAINTENANCE.TITLE"
            );
            this.ngOnInit();
            this.spinner.hide();
          },
          error: (err) => {
            console.log(err);
            this.messagesService.showError(err);
            this.spinner.hide();
          },
        });
      },
      reject: () => {},
    });
  }
}
