import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import {
  AnalysisType,
  AnalysisTypeLaboratoryBranch,
} from "src/app/models/analysis-type.model";
import { Sample } from "src/app/models/sample.model";
import { LaboratoryBranch } from "src/app/models/laboratory-branch.model";
import { LaboratoryBranchUserService } from "src/app/services/laboratory-branch-user.service";
import { Globals } from "src/app/utils/globals";
import { Laboratory } from "src/app/models/laboratory.model";
import { LaboratoryBranchService } from "src/app/services/laboratory-branch.service";
import { ShippingLabelService } from "src/app/services/shipping-label.service";
import { LaboratoryBranchAnalysisTypeService } from "src/app/services/laboratory-branch-analysis-type.service";
import { CreateSample } from "src/app/models/create-sample.model";
import { SampleService } from "src/app/services/sample.service";
import { NoticePanel } from "src/app/models/notice-panel.model";
import { Utils } from "src/app/utils/utils";
import { SampleModified } from "src/app/models/sample-modified.model";
import { b6 } from "@fullcalendar/core/internal-common";
import { CifValidator, DniValidator } from "src/app/utils/validators";
import { SampleState } from "src/app/models/enums/sample-state.model";
import { Subject, forkJoin, takeUntil } from "rxjs";

@Component({
  selector: "app-new-analysis",
  templateUrl: "./new-analysis.component.html",
  styleUrls: ["./new-analysis.component.scss"],
})
export class NewAnalysisComponent implements OnInit, OnChanges, OnDestroy {
  optionsLab: Laboratory[] = [];
  optionsSede: LaboratoryBranch[] = [];
  optionsType: AnalysisTypeLaboratoryBranch[] = [];

  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  newAnalysis: Sample = new Sample();
  identityUserId: string = "";
  laboratoryId: string = "";
  codeShippingLabel: string = "";
  listNoticePanel: NoticePanel[] = [];
  titlePanel: string = "STEP1.NOTICE.ALERT";
  id: string = "";
  codeAnalysis: string = "";
  sampleCreated: SampleModified = new SampleModified();
  modifyingForm: boolean = false;
  isCreated: boolean = false;
  isReadOnly?: boolean = false;
  noticeTextShippingCode: string = "STEP1.NOTICE.SHIPPING_CODE";
  noticeTextErrorShippingCode: string = "STEP1.NOTICE.NOT_SHIPPING_CODE";
  noticeTextAnalysisCode: string = "STEP1.NOTICE.ANALYSIS_CODE";
  noticeTextErrorExceededInvoices: string = "STEP1.NOTICE.EXCEEDEDINVOICES";
  unsubscribe$: Subject<void> = new Subject<void>();
  exceededInvoices?: boolean = false;
  firstOne?: boolean = true;
  edit?: boolean = false;
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService,
    private laboratoryBranchUserService: LaboratoryBranchUserService,
    private globals: Globals,
    private laboratoryBranchService: LaboratoryBranchService,
    private shippingLabelService: ShippingLabelService,
    private laboratoryBranchAnalysisTypeService: LaboratoryBranchAnalysisTypeService,
    private sampleService: SampleService,
    private utils: Utils,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.spinner.show("step1");
    this.resetVariables();
    this.createForm();
    if (this.globals) {
      if (this.globals.userLaboratory) {
        console.log("userLaboratory", this.globals.userLaboratory);
        let lab =
          this.globals.userLaboratory.laboratoryBranchUserLaboratories
            ?.laboratory || undefined;
        if (lab) {
          this.optionsLab.push(lab);
        } else {
          this.optionsLab = [];
        }

        this.optionsSede =
          this.globals.userLaboratory.laboratoryBranchUserLaboratories
            ?.laboratoryBranches || [];
      }
    }
    if (this.optionsLab.length > 0) {
      this.route.params.subscribe({
        next: (paramsId) => {
          this.id = paramsId["id"];
          console.log(this.id);
          if (this.id) {
            this.spinner.show("step1");
            this.sampleService.getFullSample(this.id).subscribe({
              next: (sample) => {
                console.log(sample);
                this.modifyingForm = true;
                this.isCreated = true;
                this.sampleCreated = sample;
                this.firstOne = true;
                this.laboratoryId =
                  sample?.laboratoryBranch?.laboratory?.id || "";
                this.sampleCreated.laboratory =
                  sample?.laboratoryBranch?.laboratory;
                console.log(this.sampleCreated);

                const requests = forkJoin([
                  // this.laboratoryBranchService.getByLaboratoryId(
                  //   this.laboratoryId
                  // ),
                  this.laboratoryBranchAnalysisTypeService.getLaboratoryBranchWithTestType(
                    this.sampleCreated.laboratoryBranchId || ""
                  ),
                ]);
                requests.pipe(takeUntil(this.unsubscribe$)).subscribe({
                  next: ([optionsType]) => {
                    // this.optionsSede = [...sedes];
                    this.optionsType = optionsType;
                    this.optionsType.forEach((x) => {
                      x.name = x.testType?.name;
                    });
                    this.assignedSampleCreated();
                    this.isReadOnlyView();
                    this.spinner.hide("step1");
                    this.modifyingForm = false;
                    this.cd.detectChanges();
                    this.loadedForm = true;
                    this.isCreated = true;
                    this.edit = true;
                  },
                  error: (err) => {
                    console.error(err);
                    this.spinner.hide("step1");
                  },
                });
              },
              error: (err) => {
                console.error(err);
                this.spinner.hide("step1");
              },
            });
          } else {
            console.log(this.globals);
            this.spinner.show("step1");
            let laboratoryId =
              this.globals.userLaboratory?.laboratoryBranchUserLaboratories
                ?.laboratory?.id || "";
            this.sampleService.getAvailable(laboratoryId).subscribe({
              next: (code) => {
                if (code && code.code) {
                  console.log(code);
                  this.codeAnalysis = code.code;
                  this.exceededInvoices = code.exceededInvoices;
                  if (!this.exceededInvoices) {
                    this.assignedNoticeToAnalysisCode();
                  } else {
                    this.assignedNoticeWithErrorToAnalysisCode();
                  }

                  this.assignedNewToForm();
                  this.spinner.hide("step1");
                } else {
                  this.messagesService.showToast(
                    "MESSAGES.INFO.INFO",
                    "STEP1.MESSAGES.MESSAGE_NOT_NUMBER_ANALYSIS",
                    "info",
                    ""
                  );
                  this.spinner.hide("step1");
                  this.router.navigate(["/home"]);
                }
              },
              error: (err) => {
                console.error(err);
                this.spinner.hide("step1");
              },
            });
          }
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("step1");
        },
      });
    } else {
      this.spinner.hide("step1");
      this.messagesService.showToast(
        "MESSAGES.INFO.INFO",
        "STEP1.MESSAGES.MESSAGE_USER_NOT_LABORATORY",
        "info",
        ""
      );
      this.router.navigate(["/home"]);
    }

    this.form.get("laboratoryBranch")?.valueChanges.subscribe((newValue) => {
      if (!this.modifyingForm) {
        this.getCodeShippingLabel(newValue.id || "");
        this.cd.detectChanges();
      }
      if (this.isCreated) {
        this.firstOne = false;
        this.loadedForm = false;
        this.getTypes(newValue.id || "");
        if (newValue.id != this.sampleCreated?.laboratoryBranch?.id) {
          this.form.get("testType")?.setValue(this.optionsType[0]);
        } else {
          if (this.optionsType.length > 0) {
            const selectedIndexType = this.optionsType.findIndex(
              (x) => x.testTypeId === this.sampleCreated.testTypeId
            );
            if (selectedIndexType !== -1) {
              this.form
                .get("testType")
                ?.setValue(this.optionsType[selectedIndexType]);
            }
          }
        }
        this.changeAlertsWithCodeShippingLabel();
        this.cd.detectChanges();
        this.loadedForm = true;
      }
    });
  }
  isReadOnlyView() {
    if (
      this.sampleCreated.deliveryState !== SampleState.REGISTERING &&
      this.sampleCreated.deliveryState !== SampleState.REGISTERED
    ) {
      this.isReadOnly = true;
    }
    if (this.isReadOnly) {
      this.form.disable();
    }
  }

  resetVariables() {
    this.optionsLab = [];
    this.optionsSede = [];
    this.loadedForm = false;
    this.modifyingForm = false;
    this.codeAnalysis = "";
    this.codeShippingLabel = "";
    this.listNoticePanel = [];
    this.sampleCreated = new SampleModified();
    this.isReadOnly = false;
    this.isCreated = false;
    this.firstOne = true;
    this.edit = false;
  }
  createForm() {
    this.form = new FormGroup({
      code: new FormControl<string>("", [Validators.required]),
      shippingCode: new FormControl<string>("", [
        Validators.required,
        Validators.nullValidator,
      ]),
      laboratory: new FormControl<string>("", []),
      laboratoryBranch: new FormControl<LaboratoryBranch | null>(null, [
        Validators.required,
      ]),
      testType: new FormControl<AnalysisTypeLaboratoryBranch | null>(null, [
        Validators.required,
      ]),
      patientName: new FormControl<string>("", [Validators.required]),
      patientLastName: new FormControl<string>("", []),
      patientBirthDate: new FormControl<Date | null>(null, []),
      patientIdentityId: new FormControl<string>("", [DniValidator]),
    });
  }
  setForm() {
    let date: Date | null = this.sampleCreated.patientBirthDate
      ? new Date(this.sampleCreated.patientBirthDate)
      : null;

    if (this.sampleCreated.patientBirthDate) {
      const day = date?.getDate();
      if (day) {
        date?.setDate(day);
      }
    }

    this.form.setValue({
      code: this.codeAnalysis || "",
      shippingCode: this.codeShippingLabel,
      laboratory: this.sampleCreated.laboratory || null,
      laboratoryBranch: this.sampleCreated.laboratoryBranch || null,
      testType: this.sampleCreated.testType || null,
      patientName: this.sampleCreated.patientName || "",
      patientLastName: this.sampleCreated.patientLastName || "",
      patientBirthDate: date || null,
      patientIdentityId: this.sampleCreated.patientIdentityId || null,
    });
    if (this.optionsSede.length > 0) {
      const selectedIndex = this.optionsSede.findIndex(
        (x) => x.name === this.sampleCreated.laboratoryBranch?.name
      );
      if (selectedIndex !== -1) {
        this.form
          .get("laboratoryBranch")
          ?.setValue(this.optionsSede[selectedIndex]);
      }
    }
    if (this.optionsType.length > 0) {
      const selectedIndexType = this.optionsType.findIndex(
        (x) => x.testTypeId === this.sampleCreated.testTypeId
      );
      if (selectedIndexType !== -1) {
        this.form
          .get("testType")
          ?.setValue(this.optionsType[selectedIndexType]);
      }
    }

    this.form.get("code")?.disable();
    this.form.get("shippingCode")?.disable();
  }
  assignedNewToForm() {
    this.form.get("code")?.setValue(this.codeAnalysis);
    this.form.get("code")?.disable();
    this.assignedOptionsLab();
    this.assignedOptionsSede();
  }
  assignedSampleCreated() {
    this.codeAnalysis = this.sampleCreated?.code || "";
    this.codeShippingLabel = this.sampleCreated?.shippingCode || "";
    if (this.sampleCreated.laboratory) {
      this.optionsLab.push(this.sampleCreated.laboratory);
    }
    this.form.get("laboratory")?.disable();
    this.setForm();
    this.assignedNoticeToAnalysisCode();
    this.assignedNoticeToShippingCode();
  }
  assignedOptionsLab() {
    if (this.optionsLab.length > 0) {
      let lab = new Laboratory();
      this.optionsLab.forEach((item) => {
        lab = item;
        this.laboratoryId = item.id || "";
      });
      this.form.get("laboratory")?.setValue(lab);
      this.form.get("laboratory")?.disable();
    }
  }
  assignedOptionsSede() {
    if (this.optionsSede.length > 0) {
      let sede = new LaboratoryBranch();
      sede = this.optionsSede[0];
      this.form.get("laboratoryBranch")?.setValue(sede);
      this.spinner.show("step1");
      this.shippingLabelService
        .getAvailable(this.optionsSede[0].id || "")
        .subscribe({
          next: (item) => {
            console.log("SHIPPINGCODE", item);
            this.codeShippingLabel = item?.code || "";
            this.form.get("shippingCode")?.setValue(this.codeShippingLabel);
            this.form.get("shippingCode")?.disable();
            this.spinner.hide("step1");
            // if (this.codeShippingLabel === "") {
            // this.messagesService.showToast(
            //   "MESSAGES.INFO.INFO",
            //   "STEP1.MESSAGES.MESSAGE_NOT_CODE_SHIPPINGLABEL",
            //   "info",
            //   ""
            // );
            // this.assignedNoticeToShippingCode();
            // this.changeAlertsWithCodeShippingLabelError();
            //   this.loadedForm = true;
            // } else {
            this.spinner.show("step1");
            this.laboratoryBranchAnalysisTypeService
              .getLaboratoryBranchWithTestType(this.optionsSede[0].id || "")
              .subscribe({
                next: (items) => {
                  this.optionsType = items;
                  this.optionsType.forEach((x) => {
                    x.name = x.testType?.name;
                  });
                  this.form.get("testType")?.setValue(this.optionsType[0]);
                  this.spinner.hide("step1");
                  if (this.codeShippingLabel !== "") {
                    this.assignedNoticeToShippingCode();
                  }
                  this.cd.detectChanges();
                  this.loadedForm = true;
                },
                error: (err) => {
                  console.error(err);
                  this.spinner.hide("step1");
                },
              });
            // }
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide("step1");
          },
        });

      // if (this.optionsSede.length === 1) {
      //   this.form.get("testType")?.disable();
      // }
    } else {
      this.getOptionsSede();
    }
  }
  getOptionsSede() {
    this.spinner.show("step1");
    this.laboratoryBranchService
      .getByLaboratoryId(this.laboratoryId)
      .subscribe({
        next: (items) => {
          console.log("GETLABORATORY", items);
          this.optionsSede = [...items];
          this.spinner.hide("step1");
          if (this.optionsSede.length > 0) {
            let sede = new LaboratoryBranch();
            sede = this.optionsSede[0];
            this.form.get("laboratoryBranch")?.setValue(sede);
            this.spinner.show("step1");
            this.laboratoryBranchAnalysisTypeService
              .getLaboratoryBranchWithTestType(this.optionsSede[0].id || "")
              .subscribe({
                next: (items) => {
                  this.optionsType = items;
                  this.optionsType.forEach((x) => {
                    x.name = x.testType?.name;
                  });
                  this.form.get("testType")?.setValue(this.optionsType[0]);
                  this.shippingLabelService
                    .getAvailable(this.optionsSede[0].id || "")
                    .subscribe({
                      next: (item) => {
                        console.log("SHIPPINGCODE", item);
                        this.codeShippingLabel = item?.code || "";
                        this.form
                          .get("shippingCode")
                          ?.setValue(this.codeShippingLabel);
                        this.form.get("shippingCode")?.disable();
                        this.assignedNoticeToShippingCode();
                        this.cd.detectChanges();
                        this.spinner.hide("step1");
                      },
                      error: (err) => {
                        console.error(err);
                        this.spinner.hide("step1");
                      },
                    });
                },
                error: (err) => {
                  console.error(err);
                  this.spinner.hide("step1");
                },
              });
          }
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("step1");
        },
      });
  }
  ngOnDestroy() {}
  ngOnChanges(changes: SimpleChanges) {}
  assignedNoticeToShippingCode() {
    let notice = new NoticePanel();
    notice.iconPath = `./../../../assets/img/codigo-transporte-diagnostica.svg`;
    notice.text = this.noticeTextShippingCode;
    notice.textBoldValue = this.codeShippingLabel;
    notice.error = false;
    this.listNoticePanel.push(notice);
  }
  assignedNoticeToAnalysisCode() {
    let notice = new NoticePanel();
    notice.iconPath = "./../../../assets/img/codigo-analisis-diagnostica.svg";
    notice.text = this.noticeTextAnalysisCode;
    notice.textBoldValue = this.codeAnalysis;
    notice.error = false;
    this.listNoticePanel.push(notice);
  }
  assignedNoticeWithErrorToAnalysisCode() {
    let notice = new NoticePanel();
    notice.iconPath = "./../../../assets/img/codigo-analisis-diagnostica.svg";
    notice.text = this.noticeTextErrorExceededInvoices;
    notice.error = true;
    this.listNoticePanel.push(notice);
  }
  getCodeShippingLabel(laboratoryBranchId: string) {
    if (laboratoryBranchId != this.sampleCreated?.laboratoryBranch?.id) {
      this.spinner.show("step1");
      this.shippingLabelService.getAvailable(laboratoryBranchId).subscribe({
        next: (item) => {
          console.log("SHIPPINGCODE", item);
          this.codeShippingLabel = item?.code || "";
          this.form.get("shippingCode")?.setValue(this.codeShippingLabel);
          this.form.get("shippingCode")?.disable();
          this.spinner.hide("step1");
          if (this.codeShippingLabel === "") {
            // this.messagesService.showToast(
            //   "MESSAGES.INFO.INFO",
            //   "STEP1.MESSAGES.MESSAGE_NOT_CODE_SHIPPINGLABEL",
            //   "info",
            //   ""
            // );
            let haveNoticeShippingCode = false;
            this.listNoticePanel.forEach((item) => {
              if (item.text === "STEP1.NOTICE.SHIPPING_CODE") {
                haveNoticeShippingCode = true;
              }
            });
            if (!haveNoticeShippingCode) {
              this.assignedNoticeToShippingCode();
            }

            this.changeAlertsWithCodeShippingLabelError();
          } else {
            this.spinner.show("step1");
            this.laboratoryBranchAnalysisTypeService
              .getLaboratoryBranchWithTestType(laboratoryBranchId)
              .subscribe({
                next: (items) => {
                  this.optionsType = items;
                  this.optionsType.forEach((x) => {
                    x.name = x.testType?.name;
                  });
                  this.form.get("testType")?.setValue(this.optionsType[0]);
                  this.changeAlertsWithCodeShippingLabel();
                  this.cd.detectChanges();
                  this.spinner.hide("step1");
                },
                error: (err) => {
                  console.error(err);
                  this.spinner.hide("step1");
                },
              });
          }
          this.cd.detectChanges();
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("step1");
        },
      });
    } else {
      this.spinner.show("step1");
      this.laboratoryBranchAnalysisTypeService
        .getLaboratoryBranchWithTestType(laboratoryBranchId)
        .subscribe({
          next: (items) => {
            this.optionsType = items;
            this.optionsType.forEach((x) => {
              x.name = x.testType?.name;
            });
            this.codeShippingLabel = this.sampleCreated?.shippingCode || "";
            this.listNoticePanel = [];
            this.assignedNoticeToAnalysisCode();
            this.assignedNoticeToShippingCode();
            this.listNoticePanel = [...this.listNoticePanel];
            this.form.get("shippingCode")?.enable();
            this.form.get("shippingCode")?.setValue(this.codeShippingLabel);
            this.form.get("shippingCode")?.disable();
            if (this.optionsType.length > 0) {
              const selectedIndexType = this.optionsType.findIndex(
                (x) => x.testTypeId === this.sampleCreated.testTypeId
              );
              if (selectedIndexType !== -1) {
                this.form
                  .get("testType")
                  ?.setValue(this.optionsType[selectedIndexType]);
              }
            }
            this.cd.detectChanges();
            this.spinner.hide("step1");
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide("step1");
          },
        });
    }
  }
  getTypes(laboratoryBranchId: string) {
    this.spinner.show("step1");
    this.laboratoryBranchAnalysisTypeService
      .getLaboratoryBranchWithTestType(laboratoryBranchId)
      .subscribe({
        next: (items) => {
          this.optionsType = items;
          this.optionsType.forEach((x) => {
            x.name = x.testType?.name;
          });
          if (laboratoryBranchId != this.sampleCreated?.laboratoryBranch?.id) {
            this.form.get("testType")?.setValue(this.optionsType[0]);
          } else {
            if (this.optionsType.length > 0) {
              const selectedIndexType = this.optionsType.findIndex(
                (x) => x.testTypeId === this.sampleCreated.testTypeId
              );
              if (selectedIndexType !== -1) {
                this.form
                  .get("testType")
                  ?.setValue(this.optionsType[selectedIndexType]);
              }
            }
          }
          this.cd.detectChanges();
          this.spinner.hide("step1");
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide("step1");
        },
      });
  }
  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  //------------------------- CRUD --------------------
  save() {
    if (!this.isReadOnly) {
      if (!this.id) {
        console.log(this.form.value);
        let newSample = new CreateSample();
        newSample = { ...newSample, ...this.form.value };
        if (newSample.patientBirthDate) {
          newSample.patientBirthDate = this.utils.dateToUtc(
            newSample.patientBirthDate
          );
        }
        newSample.code = this.codeAnalysis;
        newSample.shippingCode = this.codeShippingLabel;
        newSample.laboratoryBranchId = this.form.value["laboratoryBranch"].id
          ? this.form.value["laboratoryBranch"].id
          : "";
        newSample.testTypeId = this.form.get("testType")?.value["testTypeId"]
          ? this.form.value["testType"].testType.id
          : "";
        newSample.patientBirthDate = new Date(
          this.form.value["patientBirthDate"]
        );
        console.log("newSample", newSample);
        this.spinner.show("step1");
        this.sampleService.createSample(newSample).subscribe({
          next: (item) => {
            this.modifyingForm = true;
            console.log("createITEM", item);
            this.spinner.hide("step1");
            this.form.disable();
            this.router.navigate(["/createAnalisys/2", item.id]);
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide("step1");
          },
        });
      } else {
        let hasChanged = false;
        hasChanged = this.findChange();
        if (hasChanged) {
          delete this.sampleCreated.laboratory;
          delete this.sampleCreated.shippingCode;
          if (this.sampleCreated.laboratoryBranch) {
            delete this.sampleCreated.laboratoryBranch;
          }
          if (this.sampleCreated.testType) {
            delete this.sampleCreated.testType;
          }
          console.log(this.sampleCreated);
          this.spinner.show("step1");
          this.sampleService.update(this.sampleCreated).subscribe({
            next: (item) => {
              this.modifyingForm = true;
              this.isCreated = false;
              console.log("UPDATEDITEM", item);
              this.spinner.hide("step1");
              this.form.disable();
              this.router.navigate(["/createAnalisys/2", this.id]);
            },
            error: (err) => {
              console.error(err);
              this.spinner.hide("step1");
            },
          });
        } else {
          this.router.navigate(["/createAnalisys/2", this.id]);
        }
      }
    } else {
      if (this.id) {
        this.router.navigate(["/createAnalisys/2", this.id]);
      }
    }
  }
  findChange(): boolean {
    let hasChanged = false;
    if (
      this.form.get("laboratoryBranch")?.value["id"] !==
      this.sampleCreated.laboratoryBranchId
    ) {
      delete this.sampleCreated.laboratoryBranch;
      this.sampleCreated.laboratoryBranchId =
        this.form.get("laboratoryBranch")?.value["id"];
      hasChanged = true;
    }
    if (
      this.form.get("testType")?.value["testTypeId"] !==
      this.sampleCreated.testTypeId
    ) {
      delete this.sampleCreated.testType;
      this.sampleCreated.testTypeId =
        this.form.get("testType")?.value["testTypeId"];
      hasChanged = true;
    }
    if (
      this.form.get("patientName")?.value !== this.sampleCreated.patientName
    ) {
      this.sampleCreated.patientName = this.form.get("patientName")?.value;
      hasChanged = true;
    }
    if (
      this.form.get("patientLastName")?.value !==
      this.sampleCreated.patientLastName
    ) {
      this.sampleCreated.patientLastName =
        this.form.get("patientLastName")?.value;
      hasChanged = true;
    }
    if (
      this.form.get("patientIdentityId")?.value !==
      this.sampleCreated.patientIdentityId
    ) {
      this.sampleCreated.patientIdentityId =
        this.form.get("patientIdentityId")?.value;
      hasChanged = true;
    }
    // let newDate = this.utils.dateToUtcWithHours(
    //   this.form.value.patientBirthDate
    // );
    // console.log("newDate", newDate);
    let newDate = new Date(this.form.value.patientBirthDate);
    console.log("newDate1", newDate);
    let dateInitial = this.sampleCreated?.patientBirthDate
      ? new Date(this.sampleCreated?.patientBirthDate)
      : null;
    console.log("dateInitial", dateInitial);
    if (dateInitial?.getTime() !== newDate.getTime()) {
      this.sampleCreated.patientBirthDate = newDate;
      hasChanged = true;
    }
    return hasChanged;
  }
  changeAlertsWithCodeShippingLabel() {
    this.listNoticePanel.forEach((item) => {
      if (item.text === "STEP1.NOTICE.SHIPPING_CODE") {
        item.textBoldValue = this.codeShippingLabel;
        item.error = false;
      }
    });
    this.listNoticePanel = [...this.listNoticePanel];
    this.cd.detectChanges();
  }
  changeAlertsWithCodeShippingLabelError() {
    this.listNoticePanel.forEach((item) => {
      if (item.text === "STEP1.NOTICE.SHIPPING_CODE") {
        item.text = this.noticeTextErrorShippingCode;
        item.textBoldValue = "";
        item.error = true;
      }
    });
    this.listNoticePanel = [...this.listNoticePanel];
    this.cd.detectChanges();
  }
}
