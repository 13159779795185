<div class="container-fluid">
  <div class="row">
    <div class="col-6 title-steps">
      <h4 class="card-title title-steps-h4">
        {{ "TITLE_STEPPER.INVOICES" | translate }}
      </h4>
    </div>
  </div>
  <ngx-spinner type="ball-spin-clockwise"></ngx-spinner>
  <overa-stepper [items]="items" #stepper>
    <ng-template #phase1 let-item>
      <!-- Step 1: añadir contenido de esta manera con el ngTemplate, indicando #phase y el numero de fase creamos otro step mas. -->
      <app-invoices-step1-select-laboratory></app-invoices-step1-select-laboratory>
    </ng-template>
    <!-- </ng-container> -->

    <ng-template #phase2 let-item>
      <!-- Step 2 -->
      <app-invoices-step2-select-laboratory-branch></app-invoices-step2-select-laboratory-branch>
    </ng-template>
    <ng-template #phase3 let-item>
      <!-- Step 2 -->
      <app-invoices-step3-summary></app-invoices-step3-summary>
    </ng-template>
  </overa-stepper>
</div>
