import { Injectable } from "@angular/core";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
import { Config } from "@overa/security";
import { Observable } from "rxjs";
import {
  CreateReport,
  Report,
  ReportSummaryField,
} from "../models/report.model";

@Injectable({ providedIn: "root" })
export class ReportService extends OveraBaseService<Report> {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/report";
  }
  createReport(createReport: CreateReport) {
    const endpointUrl = `${this.baseUrl}/create`;
    return this.http.post<CreateReport>(endpointUrl, createReport);
  }
  getReportSummary(ReportId: string) {
    const endpointUrl = `${this.baseUrl}/summary/${ReportId}`;
    return this.http.get<ReportSummaryField[]>(endpointUrl);
  }
}
