import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { AnalysisType } from "src/app/models/analysis-type.model";
import { AppPermissions } from "src/app/models/app-permission.model";
import { AnalysisTypeService } from "src/app/services/analysis-type.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  BaseListComponent,
  AccountService,
  SecurityStateManagementService,
  Hotkeys,
  ResponseNotificationService,
} from "@overa/security";
import { OveraDataTableComponent } from "../../../../shared/temporal_components/overa-datatable/overa-datatable.component";
import { DataTableColumn } from "../../../../shared/temporal_components/models/dataTablecolumn.model";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-analysis-type-list",
  templateUrl: "./analysis-type-list.component.html",
  styleUrls: ["./analysis-type-list.component.scss"],
})
export class AnalysisTypeListComponent implements OnInit, OnDestroy {
  static className = "AnalysisTypeListComponent";
  columns2: any[] = [
    { header: "COMMON_FORMS.NAME", field: "name" },
    { header: "COMMON_FORMS.DESCRIPTION", field: "description" },
    { header: "COMMON_FORMS.SAMPLETYPE", field: "type" },
    { header: "COMMON_FORMS.ACTIVE", field: "state", type: "boolean" },
    //{ header: 'COMMON_FORMS.CIF', field: 'description' },
    //{ header: 'COMMON_FORMS.CIF', field: 'description' },
  ];
  items: AnalysisType[] = [];
  itemsTemp: AnalysisType[] = [];
  allItems: AnalysisType[] = [];

  currentPageIndex: number = 0;
  currentPageSize: number = 10;
  searching = false;
  columns: DataTableColumn[] = [];
  data: AnalysisType[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  totalRecords = 0;
  canView = true;

  canCreate = true;
  @ViewChild("datatable")
  dataTable?: OveraDataTableComponent<any>;

  showTable = false;

  constructor(
    public accountService: AccountService,
    public SSMService: SecurityStateManagementService,
    public router: Router,
    public route: ActivatedRoute,
    public hotkeys: Hotkeys,
    private analysisTypeService: AnalysisTypeService,
    private RNService: ResponseNotificationService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService
  ) {
    //super(accountService, SSMService, router, hotkeys);
    //this.viewPermission = AppPermissions.PERMISSIONS_DATA.viewAnalysisType.value;
    //this.createPermission = AppPermissions.PERMISSIONS_DATA.createAnalysisType.value;
    //this.deletePermission = AppPermissions.PERMISSIONS_DATA.deleteAnalysisType.value;
    //this.baseRoute = '/maintenances/analysisType';
    //this.onBuild = () => {
    //};
    //this.loadData = () => {
    //  this.spinner.show();
    //  this.analysisTypeService.get().subscribe({
    //    next:(items) => {
    //    console.log(items);
    //    this.analysisTypeService.notifyupdate(items);
    //      this.data = items;
    //      this.spinner.hide();
    //  },
    //    error:(error: any) => {
    //      this.RNService.showError(error);
    //      this.spinner.hide();
    //  }
    //  });
    //};
    //this.onInit = () => {
    //  this.loadData();
    //  this.analysisTypeService.analysisTypeObservable.subscribe(items => {
    //    this.data = items;
    //  });
    //};
  }
  getData() {
    this.spinner.show();

    // console.log("GETDATA ->", pageIndex, pageSize, filters);
    this.analysisTypeService
      //.get(filters || new CampusFilter(), pageIndex, pageSize)
      .get()
      //.pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (values: AnalysisType[]) => {
          // console.log("VALUES ->", pageIndex, pageSize, values);

          this.data = values;
          this.totalRecords = this.data.length;
          this.generateItems(values);
          if (this.dataTable) this.dataTable.itemsTemp = this.itemsTemp;
          this.spinner.hide();

          //  this.loadDataFunction();
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
  }
  loadDataFunction = (): AnalysisType[] => {
    //if (

    //console.log("LOAD DATA FUNCTION (CHANGE PAGE)", pag, items_per_pag);
    //this.currentPageIndex = pag;
    //this.currentPageSize = items_per_pag;
    //  this.getData().finally(() => { });
    return this.items;

    //  return this.items;
  };
  ngOnInit() {
    this.columns = this.createColumns();
    setTimeout(() => this.getData(), 1);
  }

  openForm(id: string | null) {
    this.router.navigateByUrl("maintenances/analysis-type/" + id);
    // this.laboratoryService.notifyupdateRoute(id);
  }

  generateItems(data: any[]) {
    const goToDetail = (index: any) => {
      if (data[index].selected) {
        this.openForm(data[index].id);
      }
    };

    let items = data;
    items.forEach((element: any) => {
      element.selected = {
        value: false, //TODO: add value from object
        command: goToDetail,
      };
      this.itemsTemp.push(element);
    });
    this.data = this.itemsTemp;
  }

  createColumns(): DataTableColumn[] {
    return [
      {
        field: "name",
        header: this.translateService.instant("COMMON_FORMS.NAME"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },

      {
        field: "selected",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("COMMON_FORMS.SELECTION"),
        type: "System.Button",
        icon: "pi pi-pencil",
        cssClass: "button-selected-analysis-type-list",
      },
    ];
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
