import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { ConfigStorage } from "@overa/storage";
import { NgxSpinnerService } from "ngx-spinner";
import { CreateDelivery } from "src/app/models/delivery.model";
import { NoticePanel } from "src/app/models/notice-panel.model";
import { SampleDelivery } from "src/app/models/sample-delivery.model";
import { DeliveryService } from "src/app/services/delivery.services";
import { SampleService } from "src/app/services/sample.service";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";

@Component({
  selector: "app-delivery-step2",
  templateUrl: "./delivery-step2.component.html",
  styleUrls: ["./delivery-step2.component.scss"],
})
export class DeliveryStep2Component implements OnInit, OnDestroy {
  laboratoryRoute?: any;
  laboratoryBranchRoute?: any;
  DeliveryIdRoute?: any;
  listSampleDelivery?: SampleDelivery[] = [];
  copyListSampleDelivery?: SampleDelivery[] = [];
  showTable?: boolean = false;
  columns: DataTableColumn[] = [];
  totalRecords: number = 0;
  listNoticePanel: NoticePanel[] = [];
  titlePanel: string = "STEP1.NOTICE.ALERT";
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  listIdSamples: string[] = [];
  fileDataJson: string = "";
  fileId = "";
  fileName = "";
  storageId: string = ConfigStorage.storageId;
  filterClass: string = "";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService,
    private cd: ChangeDetectorRef,
    private sampleService: SampleService,
    private deliveryService: DeliveryService
  ) {
    this.filterClass = "custom-filter";
  }
  ngOnInit() {
    this.spinner.show("deliveryStep2");
    this.resetVariables();
    this.route.queryParams.subscribe({
      next: (params) => {
        console.log(params);
        this.laboratoryRoute = params["laboratoryId"];
        this.laboratoryBranchRoute = params["laboratoryBranchId"];
        console.log(
          "LABORATORYS",
          this.laboratoryBranchRoute,
          this.laboratoryRoute
        );

        let subscription;
        if (this.laboratoryBranchRoute) {
          subscription =
            this.sampleService.getUndeliveredSamplesByLaboratoryBranchId(
              this.laboratoryBranchRoute
            );
        } else {
          subscription = this.sampleService.getUndeliveredSamplesByLaboratoryId(
            this.laboratoryRoute
          );
        }
        subscription.subscribe({
          next: (items) => {
            this.listSampleDelivery = items ? items : [];
            this.createForm();
            this.columns = this.createColumns();
            if (this.listSampleDelivery.length > 0) {
              // this.assignedFunctionsTable();
              this.listSampleDelivery.forEach((item) => {
                item.selectSampleDelivery = false;
              });
              this.copyListSampleDelivery = [...this.listSampleDelivery];
            }
            this.totalRecords = this.listSampleDelivery.length;
            this.assignedNoticePanel();

            this.showTable = true;
            this.loadedForm = true;
            this.spinner.hide("deliveryStep2");
          },
          error: (err) => {
            console.error(err);
            this.spinner.show("deliveryStep2");
          },
        });
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("deliveryStep2");
      },
    });
  }
  resetVariables() {
    this.listSampleDelivery = [];
    this.loadedForm = false;
    this.showTable = false;
    this.listIdSamples = [];
  }
  assignedNoticePanel() {
    let notice = new NoticePanel();
    notice.text = this.translateService.instant(
      "DELIVERY_STEP2.NOTICE_PANEL_1"
    );
    this.listNoticePanel.push(notice);
  }
  changeSelect(element: any) {
    console.log("ELEMENT", element);
  }
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "code",
        header: this.translateService.instant("COMMON_FORMS.CODE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "testTypeName",
        header: this.translateService.instant("COMMON_FORMS.TYPE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "sampleDate",
        header: this.translateService.instant("COMMON_FORMS.DATE_SAMPLE"),
        type: "System.DateTime",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "shippingCode",
        header: this.translateService.instant("COMMON_FORMS.CODE"),
        type: "System.Number",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "laboratoryBranchName",
        header: this.translateService.instant("COMMON_FORMS.LABORATORYBRANCH"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "selectSampleDelivery",
        header: this.translateService.instant("ACTION.SELECT"),
        responsive: true,
        responsiveHeader: true,
        type: "System.Boolean",
        command: (index: number) => {
          if (
            index &&
            this.listSampleDelivery &&
            this.listSampleDelivery[index] &&
            this.listSampleDelivery[index].selectSampleDelivery
          ) {
            this.listSampleDelivery[index].selectSampleDelivery =
              !this.listSampleDelivery[index].selectSampleDelivery;
          }
        },
      },
    ];
  }
  createForm() {
    this.form = new FormGroup({
      // storageDelivery: new FormControl<string>("", [Validators.required]),
      file: new FormControl<any>(null, [Validators.required]),
    });
  }
  next() {
    console.log(this.listSampleDelivery);
    if (this.listSampleDelivery && this.listSampleDelivery.length > 0) {
      let thereAreSelect = false;
      this.listSampleDelivery.forEach((delivery) => {
        if (delivery.selectSampleDelivery === true) {
          thereAreSelect = true;
        }
      });

      if (thereAreSelect && this.form.valid) {
        this.listIdSamples = [];
        this.listSampleDelivery.forEach((delivery) => {
          if (
            delivery.selectSampleDelivery === true &&
            delivery.id !== null &&
            delivery.id !== undefined
          ) {
            this.listIdSamples.push(delivery.id);
          }
        });

        console.log("NEW LIST ID", this.listIdSamples);

        let createDeliveryNew = new CreateDelivery();
        createDeliveryNew.sampleIdList = this.listIdSamples;
        createDeliveryNew.deliveryNoteStorageId = this.fileId;
        createDeliveryNew.fileName = this.fileName;
        console.log("NEW CREATE DELIVERY", createDeliveryNew);
        this.spinner.show("deliveryStep2");
        this.deliveryService.createDelivery(createDeliveryNew).subscribe({
          next: (item) => {
            console.log(item);
            this.spinner.hide("deliveryStep2");
            this.router.navigate(["/delivery/3"], {
              queryParams: {
                laboratoryId: this.laboratoryRoute,
                laboratoryBranchId: this.laboratoryBranchRoute,
                deliveryId: item.id,
              },
            });
          },
          error: (err) => {
            console.log(err);
            this.spinner.hide("deliveryStep2");
          },
        });
      } else {
        if (!thereAreSelect) {
          this.spinner.hide("deliveryStep2");
          this.messagesService.showToast(
            "MESSAGES.INFO.INFO",
            "DELIVERY_STEP2.MESSAGE_EMPTY_LISTID",
            "info",
            ""
          );
        }
        if (!this.form.valid) {
          this.messagesService.showToast(
            "MESSAGES.INFO.INFO",
            "DELIVERY_STEP2.MESSAGE_EMPTY_FORM",
            "info",
            ""
          );
        }
      }
    } else {
      this.messagesService.showToast(
        "MESSAGES.INFO.INFO",
        "DELIVERY_STEP2.MESSAGE_EMPTY_LIST",
        "info",
        ""
      );
    }
  }
  onAddFile(event: any) {
    if (event?.file?.id) {
      this.fileId = event.file.id;
      this.fileName = event.file.displayName;
    }
  }

  fileDeleted(event: any) {
    console.log("DELETE FILE: ", event);
  }
  ngOnDestroy() {}
  onSearchTextChange(searchText: any) {
    console.log(searchText);
    if (searchText) {
      searchText = searchText.toLowerCase();
      if (this.copyListSampleDelivery) {
        let filters = [...this.copyListSampleDelivery];
        let newfilters = filters.filter((delivery) => {
          return (
            delivery.code
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            delivery.testTypeName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            delivery.sampleDate
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            delivery.shippingCode
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            delivery.laboratoryBranchName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        this.listSampleDelivery = newfilters;
      }
    } else {
      if (this.copyListSampleDelivery)
        this.listSampleDelivery = [...this.copyListSampleDelivery];
    }
    if (this.listSampleDelivery && this.listSampleDelivery?.length > 0) {
      this.listSampleDelivery = [...this.listSampleDelivery];
    } else {
      this.listSampleDelivery = [];
    }
    console.log(this.listSampleDelivery);
  }
}
