import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "@overa/security";
import { Observable, of } from "rxjs";
import { BillingType } from "../models/billing-type.model";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";

@Injectable({ providedIn: "root" })
export class BillingTypeService extends OveraBaseService<BillingType> {

  get baseUrl() {
    return Config.apiBaseUrl + "/api/BillingType";
  }


  //override get(): Observable<BillingType[]> {
  //  return of(this.mockLaboratorys);
  //}
  constructor(http: HttpClient) {
    super(http);
  }
}

