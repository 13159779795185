import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "overa-datepicker",
  templateUrl: "overa-datepicker.component.html",
  styleUrls: ["overa-datepicker.component.scss"],
})
export class OveraDatepickerComponent {
  //TODO : add side of icon
  @Input() title: string = "";
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() showIcon: boolean = false;
  @Input() dateFormat: string = "dd/mm/yy";
  @Input() formGroup?: FormGroup;
  @Input() controlName?: string;
  @Input() showTime: boolean = false;

  private _value: Date | undefined;
  @Input()
  get value(): Date | undefined {
    return this._value;
  }

  set value(value: string | Date | undefined) {
    if (typeof value === "string") {
      const date = new Date(value);
      this._value = isNaN(date.getTime()) ? undefined : date;
    } else {
      this._value = value;
    }
    this.valueChange.emit(this._value);
  }

  @Output() valueChange = new EventEmitter<Date>();
}
