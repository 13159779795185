import { Component, OnInit, OnDestroy, Input, OnChanges } from "@angular/core";
import { AnalysisSubtype } from "src/app/models/analysis-subtype.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AnalysisSubtypeService } from "src/app/services/analysis-subtype.service";
import { BaseFormComponent, AccountService, Hotkeys } from "@overa/security";
import { OveraMessagesService } from "@overa/components";
import { OveraKeyValuePair } from "../../../../shared/temporal_components/models/overa-keyValue-pair.model";
import { ConfirmationService } from "primeng/api";
import { Utils } from "../../../../utils/utils";
import { forkJoin, Observable, of } from "rxjs";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

@Component({
  selector: "app-analysis-subtype-form",
  templateUrl: "./analysis-subtype-form.component.html",
  styleUrls: ["./analysis-subtype-form.component.scss"],
  providers: [ConfirmationService],
})
export class AnalysisSubtypeFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  static className = "AnalysisSubtypeFormComponent";
  form: FormGroup = new FormGroup({});

  readonly = false;
  loadedForm = false;

  id: string | null = null;
  salesTypes: OveraKeyValuePair[] = [];
  selectedCampus = false;

  defaultSampleProcedureInstructions: OveraKeyValuePair[] = [];

  canView = true;
  canDelete = true;
  canUpdate = true;
  canCreate = true;
  detail = false;
  selectedDescription = "";
  enabled = true;
  data: AnalysisSubtype = new AnalysisSubtype();
  analysisTypeId = "";

  constructor(
    public accountService: AccountService,
    public router: Router,
    public hotkeys: Hotkeys,
    private analysisSubtypeService: AnalysisSubtypeService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private utils: Utils,
    public dialogConfig: DynamicDialogConfig,
    //public dialogService: DialogService,
    public dialogRef: DynamicDialogRef
  ) {
    console.log("analysis-subtype-form");
    this.id = this.dialogConfig.data?.id;
    this.analysisTypeId = this.dialogConfig.data?.analysisTypeId;
  }

  ngOnInit() {
    this.init();
  }
  ngOnDestroy() {}

  private init() {
    this.route.paramMap.subscribe((params) => {
      this.spinner.show();

      if (!this.readonly) {
        this.form.enable();
        this.enabled = true;
      }
      this.data = new AnalysisSubtype();
      this.form = new FormGroup({
        name: new FormControl<string>("", [Validators.required]),
        description: new FormControl<string>("", [Validators.required]),
      });
      const id = this.id;
      if (id) {
        this.spinner.show();
        if (id === "new") {
          this.setLanguages();
          this.detail = false;
          this.data = new AnalysisSubtype();
          this.selectedDescription = "";

          this.spinner.hide();
          this.loadedForm = true;
        } else {
          this.detail = true;

          this.analysisSubtypeService.getById(id).subscribe({
            next: (response: AnalysisSubtype) => {
              this.spinner.hide();
              this.detail = true;

              this.data = { ...response };

              var formData: AnalysisSubtype = new AnalysisSubtype();
              formData.name = this.data.name || "";
              formData.description = this.data.description || "";

              this.form.setValue({ ...formData });

              this.setLanguages();

              this.form.setValue({ ...formData });
              this.loadedForm = true;
              this.selectedDescription = response.name || "";
            },
            error: (error) => {
              this.spinner.hide();
              if (error.status === 500 || error.status === 0) {
                this.messagesService.showToast(
                  "ERROR.CRITICAL",
                  "ERROR",
                  "error",
                  "",
                  ""
                );
              } else {
                this.messagesService.showToast(
                  "ERROR.CRITICAL",
                  "ERROR",
                  "error",
                  "",
                  ""
                );
              }
              this.loadedForm = true;
              // TODO : Mensaje error
            },
          });
        }
      } else {
        this.selectedDescription = "";
        this.detail = false;
        this.form.disable();
        this.enabled = false;
        this.data = new AnalysisSubtype();
        this.spinner.hide();
        this.loadedForm = true;
      }
    });
    // });
  }

  // ======================== CRUD ========================
  ngOnChanges() {}

  setLanguages() {
    var languages = this.translate.getLangs();
    // this.instructions = [];
    if (!this.defaultSampleProcedureInstructions.length) {
      this.defaultSampleProcedureInstructions = [];
    }
    languages.forEach((lang) => {
      if (
        !this.defaultSampleProcedureInstructions
          .map((i) => i.key)
          .includes(lang)
      ) {
        this.defaultSampleProcedureInstructions.push({ key: lang, value: "" });
      }
    });
    this.defaultSampleProcedureInstructions =
      this.defaultSampleProcedureInstructions.sort((a, b) => (a > b ? 1 : -1));
  }

  //ngOnChanges() {
  //  this.init();
  //  this.campusService.notifyupdateRoute(null);
  //}
  setData() {
    this.data = { ...this.data, ...this.form.value };
    this.data.testTypeId = this.analysisTypeId;
  }

  create() {
    this.setData();
    this.spinner.show();

    this.analysisSubtypeService.add(this.data).subscribe({
      next: (response: any) => {
        this.data = response;

        this.messagesService.showToast(
          "MESSAGES.SUCCESS.CREATE",
          "MESSAGES.SUCCESS.CREATE_LONG",
          "success",
          this.data.name || "",
          "ANALYSIS_TYPE_MAINTENANCE.TITLE"
        );
        this.dialogRef.close(this.data);
        //this.router.navigateByUrl("/maintenances/analysisSubtype");
        this.spinner.hide();
      },
      error: (err: any) => {
        this.spinner.hide();
        this.messagesService.showError(err);
      },
    });
  }

  update() {
    this.spinner.show();
    this.setData();

    this.analysisSubtypeService.update(this.data).subscribe({
      next: () => {
        this.messagesService.showToast(
          "MESSAGES.SUCCESS.UPDATE",
          "MESSAGES.SUCCESS.UPDATE_LONG",
          "success",
          this.data.name || "",
          "ANALYSIS_TYPE_MAINTENANCE.TITLE"
        );
        this.dialogRef.close(this.data);

        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
        this.messagesService.showError(err);
      },
    });
  }

  //delete() {
  //  let trmessage, messageAccept = '';
  //  this.translate.get('COMMONS.ASK_DELETE_RECORD').subscribe((a: string) => {
  //    trmessage = a;
  //  });
  //  this.translate.get('ACTION.YES').subscribe((a: string) => {
  //    messageAccept = a;
  //  });
  //  this.confirmationService.confirm({
  //    message: trmessage,
  //    header: '',
  //    acceptLabel: messageAccept,
  //    icon: 'pi pi-exclamation-triangle',
  //    accept: () => {
  //      this.spinner.show();
  //      this.analysisSubtypeService.delete(this.data.id ?? "").subscribe({
  //        next: () => {
  //          this.messagesService.showToast("MESSAGES.SUCCESS.DELETE", "MESSAGES.SUCCESS.DELETE_LONG", "success", this.data.name || "", "ANALYSIS_TYPE.TITLE")
  //          this.dialogRef.close();

  //          this.spinner.hide();
  //        },
  //        error: (err) => {
  //          console.log(err);
  //          this.messagesService.showError(err);
  //          this.spinner.hide();
  //        },
  //      });

  //    },
  //    reject: () => {
  //    },
  //  });
  //}
  onSelectUser(user: any) {
    console.log(user);
  }
  cancel() {
    this.dialogRef.close();
  }
  back() {
    this.cancel();
  }
}
