<div class="p-field formField">
  <label class="truncate-text" [title]="title"
    ><span *ngIf="isRequired">*</span><b>{{ title }}</b></label
  ><br />
  <p-calendar
    *ngIf="!controlName"
    [showTime]="showTime"
    [showIcon]="showIcon"
    [disabled]="isDisabled"
    [(ngModel)]="value"
    appendTo="body"
    [dateFormat]="dateFormat"
  ></p-calendar>
  <ng-container *ngIf="controlName && formGroup" [formGroup]="formGroup">
    <p-calendar
      *ngIf="controlName"
      [showTime]="showTime"
      [showIcon]="showIcon"
      [formControlName]="controlName"
      appendTo="body"
      [dateFormat]="dateFormat"
    ></p-calendar>
  </ng-container>
</div>
