<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false">
  <p class="loading-text">{{ 'MESSAGES.LOADING' | translate }}</p>
</ngx-spinner>

<div class="card ">
  <div class="card-header card-header-warning card-header-text ">
    <div class="card-text">
      <h4 class="card-title ">
        <span>{{ "BILLING_TYPE_MAINTENANCE.LIST.HEADER" | translate }}</span>

      </h4>

    </div>

  </div>
  <div class="card-body th-2-right">
    <div class="d-flex">
      <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
        (click)="openForm('new')" [pTooltip]="'ACTION.ADD'| translate" tooltipPosition="bottom"
        tooltipStyleClass="tooltipDiagnostica"></button>
    </div>
    <div class="card-content th-2-3-right">


      <overa-datatable #datatable [columns]="columns" [loadDataFunction]="loadDataFunction" [showPaginator]="true"
        [showFilter]="false" [showGlobalFilter]="false" [rowsPerPage]="[10, 20]" [items]="data"
        [totalRecords]="totalRecords">
      </overa-datatable>

    </div>

  </div>
</div>