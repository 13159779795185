<!--<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
    [fullScreen]="false">
    <p class="loading-text">{{ 'MESSAGES.LOADING' | translate }}</p>
</ngx-spinner>-->
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card ">

  <div class="card-body">

    <div class="card-content">
      <div class="row">
        <!-- SEARCH-->
        <!--<div class="col-11">
          <span class="p-input-icon-right w-100">
            <i class="pi pi-search"></i>
            <input class="w-100 overa-input"
                   pInputText
                   type="text"
                   (input)="datatable.filterGlobal($any($event.target).value, 'contains')" />
          </span>
        </div>-->
        <!-- END SEARCH-->
        <!-- ADD-->
        <div class="col-1">
          <div class="d-flex flex-row justify-content-right">
            <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
              (click)="openForm('new')" [pTooltip]="'ACTION.ADD'| translate" tooltipPosition="bottom"
              tooltipStyleClass="tooltipDiagnostica"></button>
          </div>
        </div>
        <!-- END ADD-->
      </div>
      <!--<p-table #tableCampus [columns]="columns"
           [value]="data"
           styleClass="p-datatable-striped listTableCampuses"
           [globalFilterFields]="['name','address', 'phone', 'email', 'contactName']"
           [responsive]="true"
           responsiveLayout="stack"
           breakpoint="960px"
           [autoLayout]="true"
           [paginator]="true"
           [rows]="10"
           currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
           [rowsPerPageOptions]="[10,30,50]"
           [showCurrentPageReport]="true"
           paginatorDropdownAppendTo="body"
           sortField="name"
           [sortOrder]="-1">
    <ng-template pTemplate="header" let-columns>
      <tr>-->
      <!--pSortableColumn="{{col.field}}"  -->
      <!--<th *ngFor="let col of columns" [ngClass]="'th-' +col.field" class="line-border-right">{{col.header | translate}}</th>
      </tr>

    </ng-template>
    <ng-template pTemplate="body" let-campus>
      <tr class="campo">
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.type != 'date'" class="{{col.field}}">
            <span class="p-column-title">{{col.header | translate}}</span>{{campus[col.field]}}
          </td>
          <td *ngIf="col.type == 'date'">
            <span class="p-column-title">{{col.header | translate}}</span>{{campus[col.field] | date: 'dd/MM/yy'}}
          </td>
        </ng-container>
        <td class="td-actions">
          <button pButton
                  pRipple
                  type="button"
                  icon="pi pi-pencil"
                  class="p-button-rounded editButtonRounded p-button p-ripple"
                  (click)="openForm(campus.id)"
                  [pTooltip]="'ACTION.EDIT'| translate"
                  tooltipPosition="bottom"
                  tooltipStyleClass="tooltipDiagnostica"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>-->
      <div class="th-2-3-right">
        <overa-datatable #datatable [columns]="columns" [loadDataFunction]="loadDataFunction" [showPaginator]="true"
          [rowsPerPage]="[10, 20]" [items]="data" [totalRecords]="totalRecords">
        </overa-datatable>
      </div>
    </div>

  </div>
</div>