<ngx-spinner
  bdColor="rgba(42,55,84,0.4)"
  size="default"
  color="#e1e1e2"
  type="line-scale-pulse-out"
  [fullScreen]="false"
  name="step2"
>
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="container-step">
  <div class="form-step">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 title-steps">
          <h4 class="card-title title-steps-h4">
            {{ "STEP2.TITLE" | translate }}
          </h4>
        </div>
      </div>

      <div class="row">
        <div class="panel-notice" *ngIf="loadedForm">
          <app-panel-notices-instructions
            [listPanel]="listNoticePanel"
            [titleHeader]="titlePanel"
          ></app-panel-notices-instructions>
        </div>
      </div>
      <div class="row marginDivDocument">
        <div class="card">
          <div
            class="row container-analysis-patient"
            [formGroup]="formDocuments"
            *ngIf="loadedForm"
          >
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 containerStorage"
            >
              <label class="truncate-text"
                ><b>{{
                  "SHIPPINGLABEL_MAINTENANCE.FORM.DOWNLOAD_MANUAL" | translate
                }}</b></label
              >
              <overa-file-upload
                [id]="'fileManual'"
                [form]="formDocuments"
                [controlName]="'fileManual'"
                [fileData]="fileDataJsonManual"
                [storageId]="storageId"
                (fileUpload)="onAddFile($event)"
                (fileDeleted)="fileDeleted($event)"
                [placeholder]="
                  'SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT' | translate
                "
                [readonly]="true"
              >
              </overa-file-upload>
            </div>
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 containerStorage"
            >
              <label class="truncate-text"
                ><b>{{
                  "SHIPPINGLABEL_MAINTENANCE.FORM.DOWNLOAD_PATIENT_FORM"
                    | translate
                }}</b></label
              >
              <overa-file-upload
                [id]="'filePatientForm'"
                [form]="formDocuments"
                [controlName]="'filePatientForm'"
                [fileData]="fileDataJsonPatientForm"
                [storageId]="storageId"
                (fileUpload)="onAddFile($event)"
                (fileDeleted)="fileDeleted($event)"
                [placeholder]="
                  'SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT' | translate
                "
                [readonly]="true"
              >
              </overa-file-upload>
            </div>
            <div class="col-4"></div>
          </div>
        </div>
      </div>
      <div
        class="row container-analysis-patient"
        [formGroup]="form"
        *ngIf="loadedForm"
      >
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          <overa-datepicker
            [title]="'STEP2.FORM.DATE' | translate"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            controlName="sampleDate"
            [formGroup]="form"
            [showTime]="true"
            [isRequired]="true"
          ></overa-datepicker>
        </div>
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 containerStorage"
        >
          <label class="truncate-text"
            ><b>{{
              "SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT" | translate
            }}</b></label
          >
          <overa-file-upload
            [id]="'file'"
            [form]="form"
            [controlName]="'file'"
            [fileData]="fileDataJson"
            [storageId]="storageId"
            (fileUpload)="onAddFile($event)"
            (fileDeleted)="fileDeleted($event)"
            [placeholder]="'STEP2.FORM.FORM_PATIENT' | translate"
            [readonly]="isReadOnly"
          >
          </overa-file-upload>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          <div class="container-checkbox-step2">
            <label class="step2-text-span">{{
              "STEP2.FORM.RESPONSE" | translate
            }}</label>
              <overa-checkbox
              [controlName]="'checked'"
              [formGroup]="form"
              [isDisabled]="false"            
              ></overa-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-buttons-step">
    <button class="btn-continuar" (click)="back()">
      <i class="fa fa-arrow-left"></i> {{ "ACTION.BACK_STEP" | translate }}
    </button>
    <button
      class="btn-continuar anterior"
      (click)="save()"
      [disabled]="!checked || fileName ==''"
    >
      {{ "ACTION.CONTINUE" | translate }} <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>