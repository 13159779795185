import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "@overa/security";
import { Observable } from "rxjs";
import { ProjectCategoryViewModel } from "../models/project-category-viewmodel.model";
import { PaginatedResponse } from "../models/paginatedResponse.model";

@Injectable({ providedIn: "root" })
export class CategoryService {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/Projects";
  }

  constructor(private http: HttpClient) {}

  getCategoriesAsync(): Observable<
    PaginatedResponse<ProjectCategoryViewModel>
  > {
    return this.http.get<PaginatedResponse<ProjectCategoryViewModel>>(
      `${this.baseUrl}/Categories`
    );
  }
}
