import { Injectable } from "@angular/core";
import { OveraCardItem } from "../models/overa-card-item.model";
import { OveraCardItemField } from "../models/overa-card-item-field.model";

@Injectable({
  providedIn: "root",
})
export class MockService {
  getCustomItems(): OveraCardItem[] {
    let item = new OveraCardItem();
    item.title = new OveraCardItemField();
    item.title.key = "Análisis";
    item.title.value = "00013"; // transform always the value from the object to a string
    item.percentage = 100;
    item.percentageIsCritic = false;
    item.fields = [
      { key: "Laboratorio", value: "Icua" },
      { key: "Sede", value: "Madrid" },
      { key: "Tipo de Análisis", value: "TrueBlood" },
      { key: "Fecha Muestra", value: "20/07/2023 09:00" },
      { key: "Email Envío", value: "info@analisis.com" },
      { key: "Nº Factura", value: "0005442" },
      { key: "Pagado", value: "No" },
    ];

    let itemCritic = new OveraCardItem();
    itemCritic.title = new OveraCardItemField();
    itemCritic.title.key = "Análisis";
    itemCritic.title.value = "MA 22/068"; // transform always the value from the object to a string
    itemCritic.percentage = 20;
    itemCritic.percentageIsCritic = true;
    itemCritic.fields = [
      { key: "Laboratorio", value: "Icua" },
      { key: "Sede", value: "Madrid" },
      { key: "Tipo de Análisis", value: "TrueCheck" },
      { key: "Fecha Muestra", value: "20/05/2023 08:00" },
      { key: "Email Envío", value: "info@analisis.com" },
      { key: "Nº Factura", value: "0005441" },
      { key: "Pagado", value: "Si" },
      { key: "Fecha Entrada Datar", value: "21/05/2023" },
    ];

    let itemDefault = new OveraCardItem();
    itemDefault.title = new OveraCardItemField();
    itemDefault.title.key = "Análisis";
    itemDefault.title.value = "00008"; // transform always the value from the object to a string
    itemDefault.percentage = 40;
    itemDefault.percentageIsCritic = false;
    itemDefault.fields = [
      { key: "Laboratorio", value: "Echevarne" },
      { key: "Sede", value: "Madrid" },
      { key: "Tipo de Análisis", value: "TrueBlood" },
      { key: "Fecha Muestra", value: "11/07/2023 08:00" },
      { key: "Email Envío", value: "info@analisis.com" },
      { key: "Nº Factura", value: "0005440" },
      { key: "Pagado", value: "Si" },
      { key: "Fecha Entrada Datar", value: "21/05/2023" },
      { key: "Fecha Salida Datar", value: "16/07/2023 19:00" },
      { key: "Fecha Envio", value: "19/07/2023 11:00" },
    ];

    let item9 = new OveraCardItem();
    item9.title = new OveraCardItemField();
    item9.title.key = "Análisis";
    item9.title.value = "00010"; // transform always the value from the object to a string
    item9.percentage = 40;
    item9.percentageIsCritic = false;
    item9.fields = [
      { key: "Laboratorio", value: "Echevarne" },
      { key: "Sede", value: "Madrid" },
      { key: "Tipo de Análisis", value: "TrueBlood" },
      { key: "Fecha Muestra", value: "11/07/2023 08:00" },
      { key: "Email Envío", value: "info@analisis.com" },
      { key: "Nº Factura", value: "0005440" },
      { key: "Pagado", value: "Si" },
      { key: "Fecha Entrada Datar", value: "21/05/2023 09:00" },
      { key: "Fecha Salida Datar", value: "16/07/2023 19:00" },
      { key: "Fecha Envio", value: "19/07/2023 11:00" },
    ];

    let arrToReturn = [item, itemCritic, itemDefault, item9];

    return arrToReturn;
  }
}
