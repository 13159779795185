import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { ShippingLabelService } from "src/app/services/shipping-label.service";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";
import { FormShippinglabelComponent } from "../form-shippinglabel/form-shippinglabel.component";
import { LaboratoryService } from "src/app/services/laboratory.service";
import { ShippingLabelStock } from "src/app/models/shipping-label-stock.model";
import { ListShippinglabelComponent } from "../list-shippinglabel/list-shippinglabel.component";

@Component({
  selector: "app-list--shippinglabel-stock",
  templateUrl: "./list-shippinglabel-stock.component.html",
  styleUrls: ["./list-shippinglabel-stock.component.scss"],
  providers: [DialogService, ConfirmationService],
})
export class ListShippinglabelStockComponent {
  static className = "ListLaboratoryShippinglabelComponent";
  showTable: boolean = false;
  columns: DataTableColumn[] = [];
  listLaboratoryShippingLabels: ShippingLabelStock[] = [];
  copyListLaboratoryShippingLabels: ShippingLabelStock[] = [];
  totalRecords = 0;
  filterClass: string = "";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private shippingLabelService: ShippingLabelService,
    private messagesService: OveraMessagesService
  ) {
    this.filterClass = "custom-filter";
  }
  ngOnInit() {
    console.log("ListLaboratoryShippinglabelComponent");
    this.showTable = false;
    this.spinner.show("spinnerListShippingLabelStock");
    this.shippingLabelService.getAll().subscribe({
      next: (items) => {
        console.log("itemslaboratoryService", items);
        this.listLaboratoryShippingLabels = [...items];

        this.columns = this.createColumns();
        if (this.listLaboratoryShippingLabels.length > 0) {
          this.assignedFunctionsTable();
        }
        console.log("itemslaboratoryService", items);
        this.copyListLaboratoryShippingLabels = [
          ...this.listLaboratoryShippingLabels,
        ];
        this.showTable = true;
        this.spinner.hide("spinnerListShippingLabelStock");
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("spinnerListShippingLabelStock");
      },
    });
  }
  ngOnDestroy() {}
  createColumns(): DataTableColumn[] {
    return [
      {
        field: "laboratoryName",
        header: this.translateService.instant("COMMON_FORMS.LABORATORY"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "laboratoryBranchName",
        header: this.translateService.instant("COMMON_FORMS.LABORATORYBRANCH"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "address",
        header: this.translateService.instant("COMMON_FORMS.ADDRESS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "phoneNumber",
        header: this.translateService.instant("COMMON_FORMS.PHONE"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "contact",
        header: this.translateService.instant("COMMON_FORMS.CONTACT_PERSON"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "stock",
        header: this.translateService.instant("COMMON_FORMS.STOCK"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },

      {
        field: "addShippinLabel",
        header: this.translateService.instant("ACTION.ADD"),
        type: "System.Button",
        responsive: true,
        responsiveHeader: true,
        icon: "pi pi-plus",
        cssClass: "button-add-shipping-label-stock",
      },
      {
        field: "listAction",
        header: this.translateService.instant("ACTION.TOLIST"),
        type: "System.Button",
        responsive: true,
        responsiveHeader: true,
        icon: "pi pi-list",
        cssClass: "button-list-action-shipping-label-stock",
      },
    ];
  }
  assignedFunctionsTable() {
    let itemsTemp: any[] = [];

    if (this.listLaboratoryShippingLabels.length > 0) {
      const goToCreate = (index: any) => {
        if (
          this.listLaboratoryShippingLabels &&
          this.listLaboratoryShippingLabels[index]
        ) {
          this.openFormCreate(this.listLaboratoryShippingLabels[index]);
        }
      };
      const toListShippingLabels = (index: any) => {
        console.log(index);

        if (
          this.listLaboratoryShippingLabels &&
          this.listLaboratoryShippingLabels[index]
        ) {
          this.openFormToList(this.listLaboratoryShippingLabels[index]);
        }
      };
      this.listLaboratoryShippingLabels.forEach((element: any) => {
        element.addShippinLabel = {
          value: true, //TODO: add value from object
          command: goToCreate,
        };
        element.listAction = {
          value: true, //TODO: add value from object
          command: toListShippingLabels,
        };
        itemsTemp.push(element);
      });
      this.listLaboratoryShippingLabels = [...itemsTemp];
    }
  }
  openFormCreate(item: ShippingLabelStock | undefined) {
    const isNew = !item;
    let laboratoryBranchId = item?.laboratoryBranchId
      ? item.laboratoryBranchId
      : "";
    let header = "";
    let translateKey = "SHIPPINGLABEL_MAINTENANCE.FORM.HEADER_NEW";
    // let translateKey = "SHIPPINGLABEL_MAINTENANCE.FORM.HEADER_EDIT";
    // if (isNew) {
    //   translateKey = "SHIPPINGLABEL_MAINTENANCE.FORM.HEADER_NEW";
    // }
    this.translateService.get(translateKey).subscribe((a: string) => {
      header = a;
    });
    var ref = this.dialogService.open(FormShippinglabelComponent, {
      width: "85%",
      height: "50%",
      header: header,
      data: {
        isNew: isNew,
        dataItem: item,
        laboratoryBranchId: laboratoryBranchId,
      },
    });
    ref.onClose.subscribe((shippingLabel: any) => {
      console.log(shippingLabel);
      this.spinner.show("spinnerListShippingLabelStock");
      this.ngOnInit();
    });
  }
  openFormToList(item: ShippingLabelStock | undefined) {
    const isNew = !item;
    let laboratoryBranchId = item?.laboratoryBranchId
      ? item.laboratoryBranchId
      : "";
    let laboratoryId = item?.laboratoryId ? item.laboratoryId : "";
    let header = "";
    let translateKey = "SHIPPINGLABEL_MAINTENANCE.TITLE";
    // let translateKey = "SHIPPINGLABEL_MAINTENANCE.FORM.HEADER_EDIT";
    // if (isNew) {
    //   translateKey = "SHIPPINGLABEL_MAINTENANCE.FORM.HEADER_NEW";
    // }
    this.translateService.get(translateKey).subscribe((a: string) => {
      header = a;
    });
    var ref = this.dialogService.open(ListShippinglabelComponent, {
      width: "85%",
      height: "90%",
      header: header,
      data: {
        isNew: isNew,
        dataItem: item,
        laboratoryId: laboratoryId,
        laboratoryBranchId: laboratoryBranchId,
      },
    });
    ref.onClose.subscribe((shippingLabel: any) => {
      console.log(shippingLabel);
      this.spinner.show("spinnerListShippingLabelStock");
      this.ngOnInit();
    });
  }
  onSearchTextChange(searchText: any) {
    if (searchText) {
      searchText = searchText.toLowerCase();
      this.listLaboratoryShippingLabels = [];
      let filters = [...this.copyListLaboratoryShippingLabels];
      let newfilters = filters.filter((laboratoryShipping) => {
        return (
          laboratoryShipping.laboratoryName
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchText) ||
          laboratoryShipping.laboratoryBranchName
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchText) ||
          laboratoryShipping.address
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchText) ||
          laboratoryShipping.phoneNumber
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchText) ||
          laboratoryShipping.contact
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchText) ||
          laboratoryShipping.stock
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchText)
        );
      });
      this.listLaboratoryShippingLabels = newfilters;
    } else {
      this.listLaboratoryShippingLabels = [
        ...this.copyListLaboratoryShippingLabels,
      ];
    }
    this.listLaboratoryShippingLabels = [...this.listLaboratoryShippingLabels];
  }
}
