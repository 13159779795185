<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerFileUpload">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
        <span>{{ "SHIPPINGLABEL_MAINTENANCE.TITLE" | translate }}</span>
      </h4>
    </div>
  </div>

  <div class="card-body">
    <div *ngIf="loadedForm" [formGroup]="form">
      <div class="input-form col-12 containerStorage container-form-actions divContainerButtons" [align]="'center'">
        <label class="truncate-text"><b>{{
            "SHIPPINGLABEL_MAINTENANCE.TITLE_FILE" | translate
            }}</b></label>
        <overa-file-upload [id]="'file'" [form]="form" [controlName]="'file'" [fileData]="fileDataJson"
          [storageId]="storageId" (fileUpload)="onAddFile($event)" (fileDeleted)="fileDeleted($event)" [placeholder]="
            'SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT' | translate
          ">
        </overa-file-upload>
      </div>

      <div class="container-form-actions divContainerButtons" [align]="'center'">
        <button mat-raised-button (click)="update()" [disabled]="!isChanged">
          {{ "ACTION.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>