import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OveraCardGridComponent } from "./overa-card-grid.component";
import { OveraCardComponent } from "../overa-card/overa-card.component";
import { InputTextModule } from "primeng/inputtext";
import { FormsModule } from "@angular/forms";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MultiSelectModule } from "primeng/multiselect";
import { HttpClient } from "@microsoft/signalr";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpLoaderFactory } from "@overa/shared";
import { NgPipesModule } from "ngx-pipes";
import { OveraProgressSpinnerComponent } from "../overa-progress-spinner/overa-progress-spinner.component";

@NgModule({
  declarations: [
    OveraCardGridComponent,
    OveraCardComponent,
    OveraProgressSpinnerComponent,
  ],
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    CalendarModule,
    DropdownModule,
    BrowserModule,
    BrowserAnimationsModule,
    MultiSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgPipesModule,
  ],
  exports: [
    OveraCardGridComponent,
    OveraProgressSpinnerComponent,
    OveraCardComponent,
  ],
})
export class OveraCardGridModule {}
