import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { AnalysisSubtype } from "src/app/models/analysis-subtype.model";
import { SampleReport } from "src/app/models/sample-report.model";
import { SampleService } from "src/app/services/sample.service";
import { DataTableColumn } from "src/app/shared/temporal_components/models/dataTablecolumn.model";
import { ReportFileComponent } from "./report-file/report-file.component";
import { DialogService } from "primeng/dynamicdialog";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
  providers: [DialogService, ConfirmationService],
})
export class ReportsComponent implements OnInit, OnDestroy {
  listSampleReports?: SampleReport[] = [];
  copyLlistSampleReports?: SampleReport[] = [];
  showTable?: boolean = false;
  columns: DataTableColumn[] = [];
  totalRecords: number = 0;
  loadedForm: boolean = false;
  sampleId: string = "";
  showButtonHome: boolean = false;
  filterClass: string = "";
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService,
    private cd: ChangeDetectorRef,
    private sampleService: SampleService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService
  ) {
    this.filterClass = "custom-filter";
  }
  //TODO : add storageID in next and input fileupload
  ngOnInit() {
    this.spinner.show("reports");
    this.resetVariables();
    this.route.queryParams.subscribe({
      next: (params) => {
        console.log(params);
        this.sampleId = params["sampleId"] || "";
        this.sampleService.getSamplesToReport().subscribe({
          next: (items) => {
            this.listSampleReports = items;
            this.columns = this.createColumns();
            console.log("listSampleReports", this.listSampleReports);
            if (this.listSampleReports.length > 0) {
              this.assignedFunctionsTable();
            }

            if (this.sampleId !== "" && this.sampleId !== null) {
              this.listSampleReports = this.listSampleReports.filter(
                (item) => item.id === this.sampleId
              );
            }
            this.copyLlistSampleReports = [...this.listSampleReports];
            this.totalRecords = this.listSampleReports.length;
            this.showTable = true;
            this.spinner.hide("reports");

            if (
              this.sampleId !== "" &&
              this.sampleId !== null &&
              !this.listSampleReports.length
            ) {
              this.showButtonHome = true;
              this.messagesService.showToast(
                "MESSAGES.INFO.INFO",
                "REPORT.MESSAGE_EMPTYLIST",
                "info",
                ""
              );
            }
          },
          error: (err) => {
            console.error(err);
          },
        });
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("reports");
      },
    });
  }
  resetVariables() {
    this.listSampleReports = [];
    this.loadedForm = false;
    this.showTable = false;
    this.showButtonHome = false;
  }

  createColumns(): DataTableColumn[] {
    return [
      {
        field: "code",
        header: this.translateService.instant("COMMON_FORMS.CODE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "testTypeName",
        header: this.translateService.instant("COMMON_FORMS.TYPE_ANALYSIS"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "sampleDate",
        header: this.translateService.instant("COMMON_FORMS.DATE_SAMPLE"),
        type: "System.DateTime",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "laboratoryBranchName",
        header: this.translateService.instant("COMMON_FORMS.LABORATORYBRANCH"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },
      {
        field: "addReport",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("COMMON_FORMS.REPORT"),
        type: "System.Button",
        icon: "pi pi-file",
        cssClass: "button-add-report",
      },
    ];
  }
  assignedFunctionsTable() {
    let itemsTemp: any[] = [];

    if (this.listSampleReports && this.listSampleReports.length > 0) {
      const goToAddReport = (index: any) => {
        if (this.listSampleReports && this.listSampleReports[index]) {
          this.openForm(this.listSampleReports[index]);
        }
      };
      this.listSampleReports.forEach((element: any) => {
        element.addReport = {
          value: true, //TODO: add value from object
          command: goToAddReport,
        };
        itemsTemp.push(element);
      });
      this.listSampleReports = [...itemsTemp];
    }
  }
  openForm(item: SampleReport | undefined) {
    const isNew = !item?.reportFileName;
    let header = "";
    let translateKey = "REPORT.TITLE";
    this.translateService.get(translateKey).subscribe((a: string) => {
      header = a;
    });
    var ref = this.dialogService.open(ReportFileComponent, {
      width: "85%",
      height: "90%",
      header: header,
      data: {
        isNew: isNew,
        dataItem: item,
      },
    });
    ref.onClose.subscribe((report: any) => {
      console.log(report);
      this.spinner.show("reports");
      if (this.sampleId) {
        this.router.navigate(["/home"]);
      } else {
        this.ngOnInit();
      }
    });
  }
  ngOnDestroy() {}
  onSearchTextChange(searchText: any) {
    console.log(searchText);
    if (searchText) {
      searchText = searchText.toLowerCase();
      if (this.copyLlistSampleReports) {
        let filters = [...this.copyLlistSampleReports];
        let newfilters = filters.filter((sampleReport) => {
          return (
            sampleReport.code
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            sampleReport.laboratoryBranchName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            sampleReport.testTypeName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            sampleReport.sampleDate
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText) ||
            sampleReport.laboratoryBranchName
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchText)
          );
        });
        this.listSampleReports = newfilters;
      }
    } else {
      if (this.copyLlistSampleReports)
        this.listSampleReports = [...this.copyLlistSampleReports];
    }

    this.listSampleReports =
      this.listSampleReports && this.listSampleReports.length > 0
        ? [...this.listSampleReports]
        : [];
    console.log(this.listSampleReports);
  }
}
