import { Component, OnInit, OnDestroy } from '@angular/core';
import { Test } from 'src/app/models/test.model';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppPermissions } from '../../../models/app-permission.model';
import { TestService } from 'src/app/services/test.service';
import { MatDialog } from '@angular/material/dialog';
import { BaseFormComponent, AccountService, ResponseNotificationService, Hotkeys } from '@overa/security';
import { InfoDialogComponent } from '@overa/components';

@Component({
  selector: 'app-test-form',
  templateUrl: './test-form.component.html',
  styleUrls: [
    './test-form.component.scss'
  ]
})
export class TestFormComponent extends BaseFormComponent<Test> implements OnInit, OnDestroy {

  static className = 'TestFormComponent';

  CodeCtrl = new FormControl('', [Validators.required, Validators.maxLength(9)]);
  DescriptionCtrl = new FormControl('', [Validators.required, Validators.maxLength(35)]);

  constructor(
    public override accountService: AccountService,
    public override router: Router,
    public override hotkeys: Hotkeys,
    private testService: TestService,
    private toastrService: ToastrService,
    private dialogService: MatDialog,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private responseNotificationService: ResponseNotificationService,
    private spinner: NgxSpinnerService
  ) {
    super(accountService, router, hotkeys);

    this.viewPermission = AppPermissions.PERMISSIONS_DATA.viewTests.value;
    this.createPermission = AppPermissions.PERMISSIONS_DATA.createTests.value;
    this.deletePermission = AppPermissions.PERMISSIONS_DATA.deleteTests.value;
    this.updatePermission = AppPermissions.PERMISSIONS_DATA.updateTests.value;

    this.PK = 'id';
    this.baseRoute = '/maintenances/test';

    this.translate.get('TEST_MAINTENANCE.NAME').subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });

    this.onCleanForm = () => {
    };

    this.onCancelForm = () => {
      this.CodeCtrl.disable();
      this.DescriptionCtrl.disable();
    };

    this.onEnableForm = () => {
      if (this.enabled) {
          this.CodeCtrl.enable();
          this.DescriptionCtrl.enable();
          this.enabled = true;
      }
    };

    this.onInit = this.init;

  }

  private init() {
    this.data = new Test();
    this.route.paramMap.subscribe(params => {
      this.spinner.show();
      const id = params.get('id');
      this.testService.notifyupdateRoute(id ?? "");
      if (id) {
        if (id === 'new') {
          this.detail = false;
          this.enabled = true;
          this.data = new Test();
          this.selectedDescription = '';
          this.CodeCtrl.enable();
          this.DescriptionCtrl.enable();
          this.spinner.hide();
        } else {
          this.detail = true;
          this.enabled = false;
          this.CodeCtrl.disable();
          this.DescriptionCtrl.disable();

          this.testService.getById(id).subscribe(
            response => {
              this.spinner.hide();
              this.detail = true;
              this.enabled = false;
              this.CodeCtrl.disable();
              this.DescriptionCtrl.disable();
              this.data = response;
              this.selectedDescription = response.code + ' - ' + response.description;
            },
            error => {
              this.spinner.hide();
              if (error.status === 500 || error.status === 0) {
                const dialog = this.dialogService.open(InfoDialogComponent, {
                  data: {
                    title: this.translate.get('ERROR.CRITICAL'),
                    content: error.error,
                    exit: true
                  }
                });
                dialog.afterClosed().subscribe(() => {
                  this.cancel();
                });
              } else {
                this.toastrService.error(
                  error.status,
                  'Major Error',
                  {
                    timeOut: 3000
                  }
                );
              }
              // TODO : Mensaje error
            }
          );
        }
      } else {
        this.selectedDescription = '';
        this.detail = false;
        this.CodeCtrl.disable();
        this.DescriptionCtrl.disable();
        this.enabled = false;
        this.data = new Test();
        this.spinner.hide();
      }
    });
  }

  // ======================== CRUD ========================

  create() {
    this.spinner.show();
    this.testService.add(this.data).subscribe(
      response => {
        this.data = response;
        this.testService.get().subscribe(tests => {
            this.testService.notifyupdate(tests);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.CREATE').subscribe((a: string) => { toastTitle = a; });
        this.translate
          .get('MESSAGES.SUCCESS.CREATE_LONG', { type: this.componentTypeTranslated, code: this.data.code })
          .subscribe((a: string) => { toastBody = a; });

        this.responseNotificationService.showSuccess(toastTitle, toastBody);
        this.cancel();
        this.router.navigateByUrl('/maintenances/test');
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.responseNotificationService.showError(error);
      }
    );
  }

  update() {
    this.spinner.show();
    this.testService.edit(this.data).subscribe(
      () => {
        this.testService.get().subscribe(tests => {
            this.testService.notifyupdate(tests);
        });
        let toastTitle = '';
        let toastBody = '';
        this.translate.get('MESSAGES.SUCCESS.UPDATE').subscribe((a: string) => { toastTitle = a; });
        this.translate
          .get('MESSAGES.SUCCESS.UPDATE_LONG', { type: this.componentTypeTranslated, code: this.data.code })
          .subscribe((a: string) => { toastBody = a; });
        this.responseNotificationService.showSuccess(toastTitle, toastBody);

        this.cancel();
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.responseNotificationService.showError(error);
      }
    );
  }

  delete() {
    let title = '';
    let body = '';

    this.translate
      .get('COMMONS.CONFIRM_DELETE_TITLE', { type: this.componentTypeTranslated.toLowerCase() })
      .subscribe((a: string) => {
        title = a;
      });

    this.translate
      .get('COMMONS.CONFIRM_DELETE_BODY', {
        type: this.componentTypeTranslated.toLowerCase(),
        value: this.data.description,
      })
      .subscribe((a: string) => {
        body = a;
      });

    const confirm = this.dialogService.open(InfoDialogComponent, {
      data: {
        title,
        content: body,
        buttons: true,
      },
    });
    confirm.afterClosed().subscribe(result => {
      this.spinner.show();
      if (result) {
        this.testService.delete(this.data.id ?? "").subscribe(() => {
          this.testService.get().subscribe(tests => {
              this.testService.notifyupdate(tests);
          });
          this.spinner.hide();
          let titleR = '';
          let bodyR = '';
          this.translate.get('MESSAGES.SUCCESS.DELETE').subscribe((a: string) => { titleR = a; });
          this.translate
            .get('MESSAGES.SUCCESS.DELETE_LONG', { type: this.componentTypeTranslated, code: this.data.code })
            .subscribe((a: string) => { bodyR = a; });

          this.responseNotificationService.showSuccess(titleR, bodyR);
          this.cancel(true);
        },
          error => {
            this.spinner.hide();
            this.responseNotificationService.showError(error);
          },
        );
      } else {
        this.spinner.hide();
      }
    });
  }
}
