import { Component, OnInit, OnDestroy, Input, OnChanges } from "@angular/core";
import { Laboratory } from "src/app/models/laboratory.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AppPermissions } from "src/app/models/app-permission.model";
import { LaboratoryService } from "src/app/services/laboratory.service";
import { BaseFormComponent, AccountService, Hotkeys } from "@overa/security";
import { OveraMessagesService } from "@overa/components";
import { CifValidator } from "../../../../utils/validators";
import { OveraKeyValuePair } from "../../../../shared/temporal_components/models/overa-keyValue-pair.model";
import { ConfirmationService } from "primeng/api";
import { Utils } from "../../../../utils/utils";
import { forkJoin, Observable } from "rxjs";
import { BillingTypeService } from "../../../../services/billing-type.service";
import { BillingType } from "../../../../models/billing-type.model";
import { LaboratoryBranchService } from "../../../../services/laboratory-branch.service";

@Component({
  selector: "app-laboratory-form",
  templateUrl: "./laboratory-form.component.html",
  styleUrls: ["./laboratory-form.component.scss"],
  providers: [ConfirmationService],
})
export class LaboratoryFormComponent
  extends BaseFormComponent<Laboratory>
  implements OnInit, OnDestroy, OnChanges
{
  static className = "LaboratoryFormComponent";
  form: FormGroup = new FormGroup({});
  @Input()
  readonly = false;
  loadedForm = false;
  @Input()
  id: string | null = null;
  billingTypes: BillingType[] = [];
  selectedCampus = false;

  constructor(
    public override accountService: AccountService,
    public override router: Router,
    public override hotkeys: Hotkeys,
    private laboratoryService: LaboratoryService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    private billingTypeService: BillingTypeService,
    private spinner: NgxSpinnerService,
    private campusService: LaboratoryBranchService,
    private utils: Utils
  ) {
    super(accountService, router, hotkeys);
    console.log("laboratory-form");
    this.viewPermission = AppPermissions.PERMISSIONS_DATA.viewLaboratorys.value;
    this.createPermission =
      AppPermissions.PERMISSIONS_DATA.createLaboratorys.value;
    this.deletePermission =
      AppPermissions.PERMISSIONS_DATA.deleteLaboratorys.value;
    this.updatePermission =
      AppPermissions.PERMISSIONS_DATA.updateLaboratorys.value;

    this.PK = "id";
    this.baseRoute = "/maintenances/laboratory";

    this.translate.get("LABORATORY_MAINTENANCE.NAME").subscribe((a: string) => {
      this.componentTypeTranslated = a;
    });

    this.onCleanForm = () => {};

    this.onCancelForm = () => {
      this.router.navigateByUrl(this.baseRoute);
    };

    this.onEnableForm = () => {
      if (!this.readonly) {
        this.form.enable();
        this.enabled = true;
      }
    };
    this.onInit = () => this.init();
  }
  initDropdowns(): Observable<[BillingType[]]> {
    return forkJoin([this.billingTypeService.get()]);
  }

  private init() {
    this.route.paramMap.subscribe((params) => {
      this.spinner.show("spinnerLaboratoryForm");
      const id = params.get("id");

      if (!this.readonly) {
        this.form.enable();
        this.enabled = true;
      }
      this.initDropdowns().subscribe({
        next: ([billingTypes]: [BillingType[]]) => {
          console.log(billingTypes);
          this.billingTypes = billingTypes;

          this.data = new Laboratory();
          this.data.billingType = this.billingTypes[0];

          this.form = new FormGroup({
            name: new FormControl<string>("", [Validators.required]),
            //  description: new FormControl<string>('', [Validators.required]),
            vat: new FormControl<string>("", [CifValidator]),
            billingData: new FormControl<string>("", []),
            // observations: new FormControl<string>('', []),
            billingType: new FormControl<BillingType>(this.data.billingType, [
              Validators.required,
            ]),
          });

          if (id) {
            this.spinner.show("spinnerLaboratoryForm");
            if (id === "new") {
              this.detail = false;
              this.data = new Laboratory();
              this.selectedDescription = "";

              this.spinner.hide("spinnerLaboratoryForm");
              this.loadedForm = true;
            } else {
              this.detail = true;

              this.laboratoryService.getById(id).subscribe({
                next: (response: Laboratory) => {
                  this.spinner.hide("spinnerLaboratoryForm");
                  this.detail = true;
                  if (this.readonly) {
                    this.form.disable();
                    this.enabled = false;
                  }

                  this.data = response;
                  var formData: Laboratory = new Laboratory();
                  formData.name = this.data.name;

                  //formData.description = this.data.description;
                  formData.billingData = this.data.billingData;
                  formData.billingType = this.billingTypes.find(
                    (x) => x.id == this.data.billingTypeId
                  );
                  formData.vat = this.data.vat;
                  // formData.observations = this.data.observations;

                  this.form.enable();
                  this.form.setValue({ ...formData });

                  this.selectedDescription = response.name;
                  this.loadedForm = true;
                },
                error: (error) => {
                  this.spinner.hide("spinnerLaboratoryForm");
                  if (error.status === 500 || error.status === 0) {
                    this.messagesService.showToast(
                      "ERROR.CRITICAL",
                      error.error,
                      "error",
                      "",
                      ""
                    );
                  } else {
                    this.messagesService.showToast(
                      "ERROR.CRITICAL",
                      "ERROR",
                      "error",
                      "",
                      ""
                    );
                  }
                  this.loadedForm = true;
                  // TODO : Mensaje error
                },
              });
            }
          } else {
            this.selectedDescription = "";
            this.detail = false;
            this.form.disable();
            this.enabled = false;
            this.data = new Laboratory();
            this.spinner.hide("spinnerLaboratoryForm");
            this.loadedForm = true;
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    });
    // });
  }

  // ======================== CRUD ========================
  ngOnChanges() {}

  //ngOnChanges() {
  //  this.init();
  //  this.campusService.notifyupdateRoute(null);
  //}
  setData() {
    this.data = { ...this.data, ...this.form.value };
  }

  create() {
    this.spinner.show("spinnerLaboratoryForm");
    this.setData();
    this.data.billingTypeId = this.data.billingType?.id;
    delete this.data.billingType;

    this.laboratoryService.add(this.data).subscribe({
      next: (response: any) => {
        this.data = response;
        //this.laboratoryService.get().subscribe((laboratorys) => {
        //  this.laboratoryService.notifyupdate(laboratorys);
        //});
        this.messagesService.showToast(
          "MESSAGES.SUCCESS.CREATE",
          "MESSAGES.SUCCESS.CREATE_LONG",
          "success",
          this.data.name,
          "LABORATORY_MAINTENANCE.TITLE"
        );
        /*this.router.navigateByUrl(this.baseRoute);*/
        this.router.navigateByUrl(this.baseRoute + "/" + this.data.id);

        //this.router.navigateByUrl("/maintenances/laboratory");
        this.spinner.hide("spinnerLaboratoryForm");
      },
      error: (err: any) => {
        this.spinner.hide("spinnerLaboratoryForm");
        this.messagesService.showError(err);
      },
    });
  }

  update() {
    this.spinner.show("spinnerLaboratoryForm");
    this.setData();
    this.laboratoryService.update(this.data).subscribe({
      next: () => {
        this.laboratoryService.get().subscribe((laboratorys) => {
          this.laboratoryService.notifyupdate(laboratorys);
        });

        this.messagesService.showToast(
          "MESSAGES.SUCCESS.UPDATE",
          "MESSAGES.SUCCESS.UPDATE_LONG",
          "success",
          this.data.name,
          "LABORATORY_MAINTENANCE.TITLE"
        );

        this.router.navigateByUrl(this.baseRoute);
        this.spinner.hide("spinnerLaboratoryForm");
      },
      error: (err) => {
        this.spinner.hide("spinnerLaboratoryForm");
        this.messagesService.showError(err);
      },
    });
  }

  delete() {
    let trmessage,
      messageAccept = "";
    this.translate.get("COMMONS.ASK_DELETE_RECORD").subscribe((a: string) => {
      trmessage = a;
    });
    this.translate.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show("spinnerLaboratoryForm");
        this.laboratoryService.delete(this.data.id ?? "").subscribe({
          next: () => {
            this.laboratoryService.get().subscribe((laboratorys) => {
              this.laboratoryService.notifyupdate(laboratorys);
            });
            this.spinner.hide("spinnerLaboratoryForm");

            this.messagesService.showToast(
              "MESSAGES.SUCCESS.DELETE",
              "MESSAGES.SUCCESS.DELETE_LONG",
              "success",
              this.data.name,
              "LABORATORY_MAINTENANCE.TITLE"
            );
            this.router.navigateByUrl(this.baseRoute);
            this.spinner.hide("spinnerLaboratoryForm");
          },
          error: (err) => {
            console.log(err);
            this.messagesService.showError(err);
            this.spinner.hide("spinnerLaboratoryForm");
          },
        });
      },
      reject: () => {},
    });
  }
  onSelectUser(user: any) {
    console.log(user);
  }
}
