import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Config } from "@overa/security";
import { Observable, of, ReplaySubject } from "rxjs";
import { AnalysisSubtype } from "../models/analysis-subtype.model";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";

@Injectable({ providedIn: "root" })
export class AnalysisSubtypeService extends OveraBaseService<AnalysisSubtype>{
  

  get baseUrl() {
    return Config.apiBaseUrl + "/api/testSubtype";
  }

  getByAnalysisType(analysisTypeId: string): Observable<AnalysisSubtype[]> {
    return this.http.get<AnalysisSubtype[]>(this.baseUrl + "/getAll/test/" + analysisTypeId);

  }
  constructor(http: HttpClient) {
    super(http);
  }


  



}
