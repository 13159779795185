import { Sample } from "./sample.model";

export class Invoice {
  id?: string;
  invoiceNumber?: string;
  stateId?: string;
  invoiceDocumentId?: string;
  samples?: Sample[];
  constructor() {}
}

export class CreateInvoice {
  invoiceNumber?: string;
  sampleIdList?: string[];
  invoiceStorageId?: string;
  fileName?: string;
  constructor() {}
}
export class SummaryField {
  invoiceNumber?: string;
  sampleCode?: string;
  fileName?: string;
  constructor() {}
}
