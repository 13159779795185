import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Config } from "@overa/security";
import { Observable, of, ReplaySubject } from "rxjs";
import { AnalysisType } from "src/app/models/analysis-type.model";
import { AnalysisSubtype } from "../models/analysis-subtype.model";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
import { TestTypeInstructions } from "../models/test-type-instructions.model";
import { TestTypePatientForms } from "../models/test-type-patient-forms.model";
import { TestTypeManuals } from "../models/test-type-manuals.model";

@Injectable({ providedIn: "root" })
export class AnalysisTypeService extends OveraBaseService<AnalysisType> {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/testType";
  }

  constructor(http: HttpClient) {
    super(http);
  }

  updateDefaultSampleInstructions(
    testTypeInstructions: TestTypeInstructions
  ): Observable<any> {
    const url = `${this.baseUrl}/instructions`;
    return this.http.patch(url, testTypeInstructions);
  }
  updateSamplePatientForms(
    testTypePatientForms: TestTypePatientForms
  ): Observable<any> {
    const url = `${this.baseUrl}/patientforms`;
    return this.http.patch(url, testTypePatientForms);
  }
  updateSampleManuals(testTypeManuals: TestTypeManuals): Observable<any> {
    const url = `${this.baseUrl}/manuals`;
    return this.http.patch(url, testTypeManuals);
  }
}
