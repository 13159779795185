import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraStepperComponent } from "@overa/components";

@Component({
  selector: "app-base-steps",
  templateUrl: "./base-steps.component.html",
  styleUrls: ["./base-steps.component.scss"],
})
export class BaseStepsComponent implements OnInit {
  @ViewChild("stepper", { static: true }) stepper?: OveraStepperComponent;

  items: any[] = [];
  item: any;

  svgPath: string = "/assets/icons/hoja-breadcrumb.svg";
  firstBreadCrumb: string = "";
  secondBreadCrumb: string = "";
  current_step: number = 0;
  continue_text: string = "Texto Boton Continuar";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {}

  changePage(params: any) {
    if (this.stepper) {
      this.item = params.extra;
      this.stepper.changePage(params.toPage);
      this.current_step = params.toPage;

      if (params.toPage == 1) {
        // En menusettings añadimos nueva ruta y manejamos desde aqui ( no está muy refinado pero es como quedó por ahora )
        this.router.navigate(["/createAnalysis", this.current_step + 1], {
          queryParams: { code: params.extra.code },
        });
      }
    }
  }

  changePageByContinueButton() {
    // Metodo para cambiar de pagina en el boton continuar ( solo abilitado a partir del paso 2)
    let nextPage = this.current_step;
    // current step is 1 more the real page

    this.changePage({
      toPage: nextPage,
      // extra: { projectId: this.projectId },
    });
  }

  ngOnInit() {
    console.log("*********ONINIT BASE STEPS COMPONENTE*****************");
    this.current_step = 0;
    this.route.params.subscribe((params) => {
      this.current_step = +params["page"] || 1;
      this.stepper?.changePage(this.current_step - 1);

      if (this.current_step == 2) {
        // other project code
        // let code = { code: this.route.snapshot.queryParamMap.get("code") };
        // if (this.detailsProject) {
        //   this.callDataDetails(code);
        // } else {
        //   this.waitExecute(code);
        // }
      }
    });

    this.firstBreadCrumb = this.translateService.instant(
      "MASTER_STEPS.FIRSTBREADCRUMB"
    );
    this.secondBreadCrumb = this.translateService.instant(
      "MASTER_STEPS.STEPS.STEP1"
    );
    //TANTOS elementos en items como pasos vaya a haber
    this.items = [
      {
        command: (event: any) => {
          this.firstBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.FIRSTBREADCRUMB"
          );
          this.secondBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.STEPS.STEP1"
          );
          this.current_step = 1;
          console.log("Paso 1");
          // this.router.navigate(["/createProject/", this.current_step]);
        },
      },
      {
        command: (event: any) => {
          this.firstBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.FIRSTBREADCRUMB"
          );
          this.secondBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.STEPS.STEP2"
          );
          this.current_step = 2;
          console.log("Paso 2");
          // this.router.navigate(["/createProject/", this.current_step]);
        },
      },
      {
        command: (event: any) => {
          console.log("Paso 3");
          this.firstBreadCrumb = this.translateService.instant(
            "MASTER_STEPS.FIRSTBREADCRUMB"
          );
          this.secondBreadCrumb =
            this.translateService.instant("MASTER_STEPS.STEPS.STEP3") + " ";
          this.current_step = 3;
          // this.router.navigate([
          //   "/createProject/",
          //   this.current_step,
          //   this.projectId,
          // ]);
        },
      },
    ];

    let baseStepsData = this.route.snapshot.paramMap.get("extras");
    if (baseStepsData) {
      console.log("baseStepsData", baseStepsData);
      this.changePage({ toPage: 2, extra: baseStepsData });
    }
  }
}
