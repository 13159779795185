<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="deliveryStep1">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<div class="container-step step-1 p-4 mb-2">
  <div class="form-step">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container-center">
        <overa-dropdown [title]="'COMMON_FORMS.LABORATORY' | translate" [isRequired]="true" [options]="optionsLab"
          [optionLabel]="'name'" [formGroup]="form" [controlName]="'laboratory'"
          [placeholder]="'COMMON_FORMS.LABORATORY' | translate"></overa-dropdown>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container-center">
        <overa-dropdown [title]="'COMMON_FORMS.LABORATORYBRANCH' | translate" [isRequired]="false"
          [options]="optionsSede" [optionLabel]="'name'" [formGroup]="form" [controlName]="'laboratoryBranch'"
          [placeholder]="'COMMON_FORMS.LABORATORYBRANCH' | translate"></overa-dropdown>
      </div>
    </div>
  </div>
  <div>
    <button class="btn-continuar" (click)="next()" [disabled]="!form.valid">
      {{ "ACTION.CONTINUE" | translate }} <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>