import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "overa-button",
  templateUrl: "overa-button.component.html",
  styleUrls: ["overa-button.component.scss"],
})
export class OveraButtonComponent {
  @Input() tooltip?: string;
  @Input() tooltipClass: string = '';
  @Input() isDisabled: boolean = false;
  @Input() cssClass: string = '';
  @Input() icon: string = '';
  @Input() value?: string;

  btnClick(event: any) {
    this.onClick.emit(event);
  }

  @Output() onClick = new EventEmitter<any>();
}
