import { Component, Input, OnInit } from "@angular/core";
import { ContextMenu } from "primeng/contextmenu";
import { OveraMenuItem } from "./menuitem.model";
import { Dropdown } from "primeng/dropdown";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "overa-menubar",
  templateUrl: "./overa-menubar.component.html",
  styleUrls: ["./overa-menubar.component.scss"],
})
export class OveraMenuBarComponent implements OnInit {
  @Input() items: OveraMenuItem[] | undefined;
  @Input() buttonLabel: string = "";

  @Input() zIndex: number = 99999;

  openedMenu: ContextMenu | null = null;

  dropdowns: Dropdown[] = [];

  selectedItem: { [key: string]: any } = {};
  constructor(private translate: TranslateService) {}
  ngOnInit(): void {
    this.items?.forEach((menuitem) => {
      if (menuitem.items)
        menuitem.items.forEach((item) => {
          this.translate.get(item.label || "").subscribe({
            next: (value) => {
              if (value) item.label = value;
            },
          });
        });
    });
  }
  openCm(
    event: MouseEvent,
    button: HTMLElement,
    menuItem: OveraMenuItem,
    cm: ContextMenu
  ) {
    for (let dropdown of this.dropdowns) {
      dropdown.hide();
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.openedMenu && this.openedMenu !== cm) {
      this.openedMenu.hide();
    }

    if (menuItem.command) {
      menuItem.command(event);
    }

    if (!menuItem.items || menuItem.items.length === 0) {
      return false;
    }

    const rect = button.getBoundingClientRect();

    const customEvent = new MouseEvent("click", {
      clientX: rect.right,
      clientY: rect.bottom + 5,
    });

    cm.show(customEvent);

    this.openedMenu = cm;

    return true;
  }

  onDropdownChange(item: OveraMenuItem, value: string) {
    item.selectedId = value;
    if (item.command) {
      item.command(value);
    }
  }
}
