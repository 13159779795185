<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerFormShippingLabel">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
        <span>{{ "SHIPPINGLABEL_MAINTENANCE.FORM.HEADER" | translate }}</span>
      </h4>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="loadedForm">
      <form autocomplete="off" [formGroup]="form" *ngIf="loadedForm">
        <div class="row">
          <div class="input-form col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
            <overa-input [controlName]="'code'" [title]="'COMMON_FORMS.CODE' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <!--<div class="input-form"><overa-input [controlName]="'description'" [title]="'COMMON_FORMS.DESCRIPTION' | translate" [formGroup]="form"></overa-input></div>-->
          <div class="input-form col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
            <overa-input [controlName]="'link'" [title]="'COMMON_FORMS.LINK' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <div class="input-form containerStorage col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
            <label class="truncate-text"><b>{{
                "SHIPPINGLABEL_MAINTENANCE.FORM.ATTACHMENT" | translate
                }}</b></label>
            <overa-file-upload [id]="'file'" [form]="form" [controlName]="'file'" [storageId]="storageId"
              (fileUpload)="createAttachment($event)" (fileDeleted)="fileDeleted($event)">
            </overa-file-upload>
            <!--<button
              mat-raised-button
              (click)="createAttachment()"
              *ngIf="loadedForm"
              [disabled]="!form.valid"
            >
              <mat-icon>attach_file</mat-icon>
            </button>-->
          </div>
        </div>
      </form>
    </div>
    <div class="container-form-actions divContainerButtons" [align]="'end'">
      <button mat-raised-button (click)="cancel()" *ngIf="enabled" class="cancel-button-dialog">
        {{ "ACTION.CANCEL" | translate }}
      </button>
      <button mat-raised-button (click)="create()" *ngIf="detail && enabled" [disabled]="!form.valid">
        {{ "ACTION.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>