import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  ViewChild,
} from "@angular/core";
import { LaboratoryBranch } from "src/app/models/laboratory-branch.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { LaboratoryBranchService } from "src/app/services/laboratory-branch.service";
import { OveraMessagesService } from "@overa/components";
import { ConfirmationService } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DataTableColumn } from "../../../../shared/temporal_components/models/dataTablecolumn.model";
import { OveraDataTableComponent } from "../../../../shared/temporal_components/overa-datatable/overa-datatable.component";
import {
  AnalysisType,
  AnalysisTypeLaboratoryBranch,
} from "../../../../models/analysis-type.model";
import { AnalysisTypeService } from "../../../../services/analysis-type.service";
import { NullLogger } from "@microsoft/signalr";
import { LaboratoryBranchAnalysisTypeService } from "src/app/services/laboratory-branch-analysis-type.service";

@Component({
  selector: "app-analysis-type-laboratory-branch-form",
  templateUrl: "./analysis-type-laboratory-branch-form.component.html",
  styleUrls: ["./analysis-type-laboratory-branch-form.component.scss"],
  providers: [ConfirmationService],
})
export class AnalysisTypeLaboratoryBranchFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  static className = "AnalysisTypeLaboratoryBranchFormComponent";
  form: FormGroup = new FormGroup({});
  //@Input()
  readonly = false;
  loadedForm = false;
  //@Input()
  id: string | null = null;
  //@Input()
  laboratoryId?: string = undefined;

  selectedDescription: string = "";
  detail = false;
  enabled = false;
  data: LaboratoryBranch = new LaboratoryBranch();
  //TODO check
  canView = true;
  canDelete = true;
  canUpdate = true;
  canCreate = true;
  @ViewChild("datatableTypes") datatableTypes?: OveraDataTableComponent<any>;
  columns: DataTableColumn[] = [
    {
      field: "name",
      header: this.translate.instant("COMMON_FORMS.NAME"),
      type: "System.String",
      responsive: true,
      filterMatchMode: "contains",
      responsiveHeader: true,
    },

    {
      field: "selected",
      responsive: true,
      responsiveHeader: true,
      header: this.translate.instant("COMMON_FORMS.SELECTION"),
      type: "System.Button",
      icon: "pi pi-pencil",
      cssClass: "button-selected-analysis-type-laboratory-branch-form",
    },
  ];
  listAnalysisType: AnalysisType[] = [];
  isNew: boolean = false;
  selectedAnalisisType: AnalysisType = new AnalysisType();
  selectedAnalisisTypeCampus: AnalysisTypeLaboratoryBranch =
    new AnalysisTypeLaboratoryBranch();
  constructor(
    private analysisTypeService: AnalysisTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private messagesService: OveraMessagesService,
    private confirmationService: ConfirmationService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private campusAnalysisTypeService: LaboratoryBranchAnalysisTypeService
  ) {
    console.log("campus-form analysisType", this.dialogConfig.data);
    this.data = this.dialogConfig.data?.campus;
    this.selectedAnalisisTypeCampus =
      this.dialogConfig.data?.analysisTypeCampus;
    this.isNew = this.dialogConfig.data?.isNew;
  }

  ngOnInit() {
    console.log(this.data);
    console.log("selectedAnalisisType", this.selectedAnalisisTypeCampus);
    console.log("isNew", this.isNew);
    this.loadedForm = false;
    this.spinner.show("spinnerAnalysisTypeLaboratoryBranchForm");
    this.analysisTypeService.get().subscribe({
      next: (items) => {
        console.log("items", items);
        this.listAnalysisType = [...items];
        this.initialForm();
        if (this.isNew) {
          this.form.get("analysisType")?.setValue(this.listAnalysisType[0]);
        } else {
          this.form
            .get("analysisType")
            ?.setValue(
              this.listAnalysisType.find(
                (x) => x.id === this.selectedAnalisisTypeCampus.testType?.id
              )
            );
          this.form.get("analysisType")?.disable();
        }
        this.spinner.hide("spinnerAnalysisTypeLaboratoryBranchForm");
        this.loadedForm = true;
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("spinnerAnalysisTypeLaboratoryBranchForm");
      },
    });
  }
  initialForm() {
    this.form = new FormGroup({
      analysisType: new FormControl<AnalysisType | null>(null, []),
    });
  }

  // ======================== CRUD ========================
  ngOnChanges() {
    this.ngOnInit();
  }

  create() {
    //TODO implement
    console.log("create");
    console.log(this.form.value);
    this.selectedAnalisisType = this.form.value["analysisType"];
    let newAnalisysTypeCampus = new AnalysisTypeLaboratoryBranch();
    newAnalisysTypeCampus.laboratoryBranchId = this.data.id;
    newAnalisysTypeCampus.testTypeId = this.selectedAnalisisType.id;

    console.log(newAnalisysTypeCampus);
    this.spinner.show("spinnerAnalysisTypeLaboratoryBranchForm");
    this.campusAnalysisTypeService.add(newAnalisysTypeCampus).subscribe({
      next: (item) => {
        console.log(item);
        let analisysTypeCampus = new AnalysisTypeLaboratoryBranch();
        analisysTypeCampus = item;
        this.selectedAnalisisType = new AnalysisType();
        this.messagesService.showToast(
          "MESSAGES.SUCCESS.CREATE",
          "MESSAGES.SUCCESS.CREATE",
          "success",
          "ANALYSIS_TYPE_MAINTENANCE.TITLE"
        );
        this.spinner.hide("spinnerAnalysisTypeLaboratoryBranchForm");
        this.dialogRef.close(analisysTypeCampus);
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("spinnerAnalysisTypeLaboratoryBranchForm");
      },
    });
  }
  cleanForm() {
    this.form.reset();
  }
  edit() {
    this.form.enable();
    this.enabled = true;
  }

  update() {
    //TODO implement
  }

  ngOnDestroy() {}
  cancel() {
    this.dialogRef.close();
  }
  back() {
    this.cancel();
  }
}
