import { Globals } from "src/app/utils/globals";
import { Injectable } from "@angular/core";

import { OveraKeyValuePair } from "../shared/temporal_components/models/overa-keyValue-pair.model";

@Injectable()
export class Utils {
  constructor(private globals: Globals) {}

  public isDiagnosticaRole() {
    return this.globals.user.roles
      .map((x: any) => x.name)
      .some((r: any) => r == this.globals.diagnosticaRoleName);
  }
  public isDatarRole() {
    return this.globals.user.roles
      .map((x: any) => x.name)
      .some((r: any) => r == this.globals.datarRoleName);
  }
  public isLabRole() {
    return this.globals.user.roles
      .map((x: any) => x.name)
      .some((r: any) => r == this.globals.labRoleName);
  }

  public mapEntitiesToOveraKeyValue(entities: any[]): OveraKeyValuePair[] {
    let overaEntities: OveraKeyValuePair[] = [];
    entities.forEach((entity) => {
      overaEntities.push(this.mapEntityToOveraKeyValue(entity));
    });
    return overaEntities;
  }

  mapEntityToOveraKeyValue(entity: any): OveraKeyValuePair {
    let key,
      value = "";
    if (entity.id) {
      key = entity.id;
    }
    if (entity.name) {
      value = entity.name;
    } else if (entity.description) {
      value = entity.description;
    }
    let overaEntity = new OveraKeyValuePair(key, value);
    return overaEntity;
  }
  public generateGUID(): string {
    //NOSONAR
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  public dateToUtc(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  }
  public dateToUtcWithHours(date: Date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getUTCHours(),
        date.getUTCMinutes()
      )
    );
  }
}
