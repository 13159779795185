import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@overa/security";

export function setTranslations(translate: TranslateService, accountService?: AccountService){
    var defaultLang = localStorage.getItem('app-language');
    if(!defaultLang){
      defaultLang = navigator && navigator.language && translate.langs && translate.langs.includes(navigator.language) ? navigator.language : 'es';
    }
    console.log("DEFAULT LANGUAGE:", defaultLang);
    try {
    translate.setDefaultLang(defaultLang);
    translate.use(defaultLang);
    } catch {}
}
