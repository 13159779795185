<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false">
  <p class="loading-text">{{ 'MESSAGES.LOADING' | translate }}</p>
</ngx-spinner>

<div class="card ">
  <div class="card-header card-header-warning card-header-text ">
    <div class="card-text">
      <h4 class="card-title ">
        <span>{{ "ANALYSIS_TYPE_MAINTENANCE.LIST.HEADER" | translate }}</span>
        <!--<input [(ngModel)]="filter" #inputsearch [hidden]="searching"
            placeholder="{{ 'COMMONS.SEARCH_PLACEHOLDER' | translate }}" />-->
      </h4>

    </div>
    <!--<div class="keypad">
        <button mat-icon-button color="primary" (click)="searching=!searching;">
            <mat-icon aria-label="search">{{ !searching ? 'close' : 'search' }}</mat-icon>
        </button>
        <button *ngIf="canCreate" mat-icon-button color="primary"  (click)="openForm('new')">
            <mat-icon aria-label="ADD">add</mat-icon>
        </button>
    </div>-->
  </div>
  <div class="card-body">
    <div class="d-flex">
      <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
        (click)="openForm('new')" [pTooltip]="'ACTION.ADD'| translate" tooltipPosition="bottom"
        tooltipStyleClass="tooltipDiagnostica"></button>
    </div>
    <div class="card-content th-2-right">


      <overa-datatable #datatable [columns]="columns" [loadDataFunction]="loadDataFunction" [showPaginator]="true"
        [showFilter]="false" [showGlobalFilter]="false" [rowsPerPage]="[10, 20]" [items]="data"
        [totalRecords]="totalRecords">
      </overa-datatable>
      <!--<p-table [columns]="columns"
           [value]="data"
           styleClass="p-datatable-striped listTableAnalysisTypes"
           [globalFilterFields]="['name','cif']"
           [responsive]="true"
           responsiveLayout="stack"
           breakpoint="960px"
           [autoLayout]="true"
           [paginator]="true"
           [rows]="10"
           currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
           [rowsPerPageOptions]="[10,30,50]"
           [showCurrentPageReport]="true"
           paginatorDropdownAppendTo="body"
           sortField="name"
           [sortOrder]="-1">
    <ng-template pTemplate="header" let-columns>
      <tr>-->
      <!--pSortableColumn="{{col.field}}"  -->
      <!--<th *ngFor="let col of columns" [ngClass]="'th-' +col.field" class="line-border-right">{{col.header | translate}}</th>
        <th></th>
      </tr>
      <tr>
        <th *ngFor="let col of columns" class="filterTh table-filters">
          <p-columnFilter *ngIf="col.type === 'date' " type="date" field="{{col.field}}" matchMode="contains">
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <p-calendar (onSelect)="filter($event)" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
            </ng-template>
          </p-columnFilter>
          <p-columnFilter *ngIf="col.type != 'date'" type="text" field="{{col.field}}" class="colFilteranalysisTypes" matchMode="contains"></p-columnFilter>
        </th>
        <th></th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-analysisTypes>
      <tr class="campo">
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.type != 'date'" class="{{col.field}}">
            <span class="p-column-title">{{col.header | translate}}</span>{{analysisTypes[col.field]}}
          </td>
          <td *ngIf="col.type == 'date'">
            <span class="p-column-title">{{col.header | translate}}</span>{{analysisTypes[col.field] | date: 'dd/MM/yy'}}
          </td>

        </ng-container>
        <td class="td-actions">
          <span class="p-column-title">{{'ACTION.EDIT' | translate}}</span>
          <button pButton
                  pRipple
                  type="button"
                  icon="pi pi-pencil"
                  class="p-button-rounded editButtonRounded p-button p-ripple"
                  (click)="openForm(analysisTypes.id)"
                  [pTooltip]="'ACTION.EDIT'| translate"
                  tooltipPosition="bottom"
                  tooltipStyleClass="tooltipDiagnostica"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>-->
    </div>



    <!--<mat-list>
    <mat-list-item *ngIf="data.length === 0" class="hint">
        {{ 'TEST_MAINTENANCE.EMPTY_LIST' | translate }}
    </mat-list-item>

    <cdk-virtual-scroll-viewport itemSize="10">
        <div *cdkVirtualFor="let item of data | filterBy: ['name','description']: filter;">
            <mat-list-item class="hover-color" (click)="openForm(item.id)"
                routerLinkActive="router-link-active">
                <span>{{ item.description }}</span>

            </mat-list-item>-->
    <!-- <mat-divider *ngIf="!lastline"></mat-divider> -->
    <!--</div>
        </cdk-virtual-scroll-viewport>

    </mat-list>-->



  </div>
</div>