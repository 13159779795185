import { OveraGridFiltersType } from "./overa-grid-filter-types.model";
import { OveraKeyValuePair } from "./overa-keyValue-pair.model";

export class OveraGridFilter {
  name: string;
  type: OveraGridFiltersType | null;
  options?: OveraKeyValuePair[];
  selected: OveraKeyValuePair[] | Date | OveraKeyValuePair | null;
  key: string;
  minDate: Date | null;
  maxDate: Date | null;

  constructor(obj?: Partial<OveraGridFilter>) {
    this.name = obj?.name || "";
    this.type = obj?.type || null;
    this.options = obj?.options || [];
    this.selected = obj?.selected || [];
    this.key = obj?.key || "";
    this.minDate = obj?.minDate || null;
    this.maxDate = obj?.maxDate || null;
  }
}
