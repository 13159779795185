import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { LaboratoryBranch } from "src/app/models/laboratory-branch.model";
import { AppPermissions } from "src/app/models/app-permission.model";
import { LaboratoryBranchService } from "src/app/services/laboratory-branch.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  BaseListComponent,
  AccountService,
  SecurityStateManagementService,
  Hotkeys,
  ResponseNotificationService,
} from "@overa/security";
import { DataTableColumn } from "../../../../shared/temporal_components/models/dataTablecolumn.model";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { OveraDataTableComponent } from "../../../../shared/temporal_components/overa-datatable/overa-datatable.component";
import { LaboratoryBranchFormComponent } from "../laboratory-branch-form/laboratory-branch-form.component";
import { DialogService } from "primeng/dynamicdialog";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-laboratory-branch-list",
  templateUrl: "./laboratory-branch-list.component.html",
  styleUrls: ["./laboratory-branch-list.component.scss"],
  providers: [DialogService, ConfirmationService],
})
export class LaboratoryBranchListComponent implements OnInit, OnDestroy {
  static className = "LaboratoryBranchListComponent";
  items: LaboratoryBranch[] = [];
  itemsTemp: LaboratoryBranch[] = [];
  allItems: LaboratoryBranch[] = [];
  filter: string = "";
  @ViewChild("datatable")
  dataTable?: OveraDataTableComponent<any>;
  currentPageIndex: number = 0;
  currentPageSize: number = 10;
  searching = false;
  columns: DataTableColumn[] = [];
  data: LaboratoryBranch[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  totalRecords = 0;
  canView = true;
  canCreate = true;
  @Input()
  laboratoryId: string = "";
  showTable = false;
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private campusService: LaboratoryBranchService,
    private RNService: ResponseNotificationService,
    private spinner: NgxSpinnerService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService
  ) {
    //super(accountService, SSMService, router, hotkeys);
    //this.viewPermission = AppPermissions.PERMISSIONS_DATA.viewCampuss.value;
    //this.createPermission = AppPermissions.PERMISSIONS_DATA.createCampuss.value;
    //this.deletePermission = AppPermissions.PERMISSIONS_DATA.deleteCampuss.value;
    //this.baseRoute = '/maintenances/campus';
    //this.onBuild = () => {
    //};
    //this.loadData = () => {
    //  this.spinner.show();
    //  this.campusService.get().subscribe({
    //    next: (items) => {
    //      console.log(items);
    //     // this.campusService.notifyupdate(items);
    //      this.data = items;
    //      this.spinner.hide();
    //    },
    //    error: (error: any) => {
    //      this.RNService.showError(error);
    //      this.spinner.hide();
    //    }
    //  });
    //};
    //this.onInit = () => {
    //  this.loadData();
    //};
  }
  ngOnInit() {
    this.items = [];
    this.columns = this.createColumns();
    setTimeout(() => this.getData(), 1);
  }

  //openForm(id: string) {
  //  this.router.navigateByUrl('maintenances/campus/' + id);
  //  // this.campusService.notifyupdateRoute(id);
  //}

  getData() {
    this.spinner.show();

    // console.log("GETDATA ->", pageIndex, pageSize, filters);
    this.campusService
      //.get(filters || new CampusFilter(), pageIndex, pageSize)
      .getByLaboratoryId(this.laboratoryId)
      //.pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (values: LaboratoryBranch[]) => {
          // console.log("VALUES ->", pageIndex, pageSize, values);

          this.data = values;
          this.totalRecords = this.data.length;
          this.generateItems(values);
          if (this.dataTable) this.dataTable.itemsTemp = this.itemsTemp;
          this.spinner.hide();

          //  this.loadDataFunction();
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
  }
  loadDataFunction = (): LaboratoryBranch[] => {
    //if (

    //console.log("LOAD DATA FUNCTION (CHANGE PAGE)", pag, items_per_pag);
    //this.currentPageIndex = pag;
    //this.currentPageSize = items_per_pag;
    //  this.getData().finally(() => { });
    return this.items;

    //  return this.items;
  };

  delete(id: string) {
    let trmessage,
      messageAccept = "";
    this.translateService
      .get("COMMONS.ASK_DELETE_RECORD")
      .subscribe((a: string) => {
        trmessage = a;
      });
    this.translateService.get("ACTION.YES").subscribe((a: string) => {
      messageAccept = a;
    });
    this.confirmationService.confirm({
      message: trmessage,
      header: "",
      acceptLabel: messageAccept,
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.spinner.show();
        this.campusService.delete(id ?? "").subscribe({
          next: () => {
            this.ngOnInit();
            this.spinner.hide();

            //this.messagesService.showToast("MESSAGES.SUCCESS.DELETE", "MESSAGES.SUCCESS.DELETE_LONG", "success", "", "BILLING_TYPE.TITLE")
            //this.cancel();
            this.spinner.hide();
          },
          error: (err) => {
            console.log(err);
            //this.messagesService.showError(err);
            this.spinner.hide();
          },
        });
      },
      reject: () => {},
    });
  }

  generateItems(data: any[]) {
    this.itemsTemp = [];
    const deleteCampus = (index: any) => {
      if (data[index].deleteAction) {
        this.delete(data[index].id);
      }
    };
    const goToDetail = (index: any) => {
      if (data[index].selected) {
        this.openForm(data[index].id);
      }
    };

    let items = data;
    items.forEach((element: any) => {
      element.selected = {
        value: false, //TODO: add value from object
        command: goToDetail,
      };
      element.deleteAction = {
        value: false, //TODO: add value from object
        command: deleteCampus,
      };
      this.itemsTemp.push(element);
    });
    this.data = this.itemsTemp;
  }

  createColumns(): DataTableColumn[] {
    return [
      {
        field: "name",
        header: this.translateService.instant("COMMON_FORMS.NAME"),
        type: "System.String",
        responsive: true,
        filterMatchMode: "contains",
        responsiveHeader: true,
      },

      {
        field: "selected",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("COMMON_FORMS.SELECTION"),
        type: "System.Button",
        icon: "pi pi-pencil",
        cssClass: "button-selected-laboratory-branch-list",
      },

      {
        field: "deleteAction",
        responsive: true,
        responsiveHeader: true,
        header: this.translateService.instant("ACTION.DELETE"),
        type: "System.Button",
        icon: "pi pi-trash",
        cssClass: "button-delete-laboratory-branch-list",
      },
    ];
  }

  openForm(id: string | null) {
    let header = "";
    let translateKey = "CAMPUS_MAINTENANCE.FORM.HEADER_EDIT";
    if (id == "new") {
      translateKey = "CAMPUS_MAINTENANCE.FORM.HEADER_NEW";
    }
    this.translateService.get(translateKey).subscribe((a: string) => {
      header = a;
    });

    var ref = this.dialogService.open(LaboratoryBranchFormComponent, {
      width: "85%",
      height: "90%",
      header: header,
      data: {
        isNew: id == "new",
        id: id,
        laboratoryId: this.laboratoryId,
      },
    });

    ref.onClose.subscribe((campus: any) => {
      console.log(campus);
      if (campus) {
        //TODO improve
        this.ngOnInit();
      }
      this.ngOnInit();
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
