import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OveraMessagesService } from "@overa/components";
import { AccountService } from "@overa/security";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { LaboratoryBranch } from "src/app/models/laboratory-branch.model";
import { Laboratory } from "src/app/models/laboratory.model";
import { LaboratoryBranchService } from "src/app/services/laboratory-branch.service";
import { LaboratoryService } from "src/app/services/laboratory.service";

@Component({
  selector: "app-payments-step1",
  templateUrl: "./payments-step1.component.html",
  styleUrls: ["./payments-step1.component.scss"],
})
export class PaymentsStep1Component implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  loadedForm: boolean = false;
  isReadOnly?: boolean = false;
  modifyingForm?: boolean = false;
  withoutLaboratoryBranch?: boolean = false;

  unsubscribe$: Subject<void> = new Subject<void>();

  optionsLab: any[] = [];
  optionsSede: any[] = [];
  laboratoryRoute?: any;
  laboratoryBranchRoute?: any;
  constructor(
    public accountService: AccountService,
    public router: Router,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private messagesService: OveraMessagesService,
    private cd: ChangeDetectorRef,
    private laboratoryService: LaboratoryService,
    private laboratoryBranchService: LaboratoryBranchService
  ) {}
  ngOnInit() {
    this.spinner.show("paymentsStep1");
    this.resetVariables();
    this.createForm();
    this.laboratoryService.get().subscribe({
      next: (items) => {
        this.optionsLab = [...items];

        this.route.queryParams.subscribe({
          next: (params) => {
            if (params !== null && "laboratoryId" in params) {
              console.log(params);
              this.laboratoryRoute = params["laboratoryId"];
              this.laboratoryBranchRoute = params["laboratoryBranchId"];
              console.log(
                "LABORATORYS",
                this.laboratoryBranchRoute,
                this.laboratoryRoute
              );
              this.setForm();
              this.spinner.hide("paymentsStep1");
              this.modifyingForm = true;
            } else {
              this.spinner.hide("paymentsStep1");
              this.modifyingForm = true;
            }
          },
          error: (err) => {
            console.error(err);
            this.spinner.hide("paymentsStep1");
          },
        });
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("paymentsStep1");
      },
    });
    this.form.get("laboratory")?.valueChanges.subscribe((newValue) => {
      if (this.modifyingForm) {
        this.getLaboratoryBranch(newValue.id || "");
        this.cd.detectChanges();
      }
    });
  }
  resetVariables() {
    this.loadedForm = false;
    this.isReadOnly = false;
    this.modifyingForm = false;
    this.withoutLaboratoryBranch = false;
  }
  createForm() {
    this.form = new FormGroup({
      laboratory: new FormControl<Laboratory | null>(null, [
        Validators.required,
      ]),
      laboratoryBranch: new FormControl<LaboratoryBranch | null>(null, []),
    });
  }
  getLaboratoryBranch(id: string) {
    this.spinner.show("paymentsStep1");
    this.laboratoryBranchService.getByLaboratoryId(id).subscribe({
      next: (items) => {
        this.optionsSede = [null, ...items];
        this.spinner.hide("paymentsStep1");
      },
      error: (err) => {
        console.error(err);
        this.spinner.hide("paymentsStep1");
      },
    });
  }
  setForm() {
    let setLaboratory: any;
    let setLaboratoryBranch: any;
    if (this.laboratoryRoute) {
      setLaboratory = this.optionsLab.find(
        (item) => item.id === this.laboratoryRoute
      );
    }
    if (this.laboratoryBranchRoute) {
      setLaboratoryBranch = this.optionsSede.find(
        (item) => item.id === this.laboratoryBranchRoute
      );
    }
    this.form.setValue({
      laboratory: setLaboratory || null,
      laboratoryBranch: setLaboratoryBranch || null,
    });
  }
  next() {
    if (this.form.valid) {
      this.modifyingForm = false;
      console.log(this.form.value);
      this.router.navigate(["/payments/2"], {
        queryParams: {
          laboratoryId: this.form.value.laboratory?.id,
          laboratoryBranchId: this.form.value.laboratoryBranch?.id,
        },
      });
    }
  }
  ngOnDestroy() {}
}
