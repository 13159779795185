import { Component, OnInit } from '@angular/core';
import { AuthService, Config } from '@overa/security';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';
import { setTranslations } from './translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  static className = 'AppComponent';

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    translate: TranslateService,
    private auth: AuthService) {

    setTranslations(translate);


    // router.resetConfig(this.buildRoutes());
    // // }
    // if(!this.auth.isLoggedIn()) {
    //   router.navigateByUrl('security/login');
    // }
  }


  // buildRoutes(): Route[] {
  //   return menu.routes;
  // }

  ngOnInit(): void {
    console.log('APPCOMPONENT ONINIT');
    // TODO: Controlar timeout por tipo de usuario
    this.auth.userLoginMessages.subscribe((message) => {
      if (message === 'login') {
        // sets an idle timeout of 25 minutes.
        this.idle.setIdle(25 * 60);
        // sets a timeout period of 3 minutes. after 25 minutes of inactivity, the user will be considered timed out.
        this.idle.setTimeout(3 * 60);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
          console.log('No longer idle.');
        });

        this.idle.onTimeout.subscribe(() => {
          alert('Cierre de sesión automático por falta de actividad!');
          console.log('IDLE: Timed out!');
          this.timedOut = true;
          this.auth.logout();
        });
        this.idle.onIdleStart.subscribe(() => console.log('You\'ve gone idle!'));
        this.idle.onTimeoutWarning.subscribe((countdown) => {
          console.log('You will time out in ' + countdown + ' seconds!');
        });

        // sets the ping interval to 60 seconds
        this.keepalive.interval(2 * 60);

        this.keepalive.request(Config.identityBaseUrl + '/account/ka');

        this.keepalive.onPing.subscribe(() => {
          console.log('PING!');
          this.lastPing = new Date();
        });

        this.reset();
      } else {
        this.keepalive.stop();
      }
    });

  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

}
