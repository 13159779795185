<div class="p-field formField p-fluid">
  <label class="truncate-text" [title]="title"><span *ngIf="isRequired">*</span><b>{{ title }}</b></label><br />
  <textarea rows="4" *ngIf="!controlName"
            cols="10"
            pInputTextarea
            [(ngModel)]="value"
            [disabled]="isDisabled"
            [placeholder]="placeholder"></textarea>
  <ng-container [formGroup]="formGroup" *ngIf="controlName && formGroup">
    <textarea [rows]="5" id="float-input3" pInputTextarea  [formControlName]="controlName"></textarea>

  </ng-container>
</div>
