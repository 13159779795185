<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerLaboratoryForm">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
        <span>{{
          selectedDescription
          ? ("LABORATORY_MAINTENANCE.FORM.HEADER"
          | translate : { value: selectedDescription })
          : ("LABORATORY_MAINTENANCE.FORM.HEADER_NEW" | translate)
          }}</span>
      </h4>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="canView && data && loadedForm">
      <form autocomplete="off" [formGroup]="form" *ngIf="loadedForm">
        <div class="row">
          <div class="input-form col-8">
            <overa-input [controlName]="'name'" [title]="'COMMON_FORMS.NAME' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <!--<div class="input-form"><overa-input [controlName]="'description'" [title]="'COMMON_FORMS.DESCRIPTION' | translate" [formGroup]="form"></overa-input></div>-->
          <div class="input-form col-4">
            <overa-input [controlName]="'vat'" [title]="'COMMON_FORMS.CIF' | translate"
              [formGroup]="form"></overa-input>
          </div>
        </div>
        <div class="row">
          <div class="input-form col-6">
            <overa-text-area [controlName]="'billingData'" [title]="'COMMON_FORMS.SALES_DATA' | translate"
              [formGroup]="form"></overa-text-area>
          </div>
          <div class="input-form col-6">
            <overa-dropdown [options]="billingTypes" [optionLabel]="'name'"
              [title]="'COMMON_FORMS.SALES_TYPE' | translate" [formGroup]="form" [controlName]="'billingType'"
              appendTo="body"></overa-dropdown>
          </div>
        </div>
        <!--<div class="textarea">
    <overa-text-area [controlName]="'observations'"
                    [title]="'COMMON_FORMS.OBSERVATIONS' | translate" [formGroup]="form"></overa-text-area>
  </div>-->
        <!--<div>
    <overa-dropdown [options]="salesTypes" [optionLabel]="'key'" [title]="'COMMON_FORMS.SALES_TYPE' | translate" [formGroup]="form"
                    [controlName]="'salesType'"
                    appendTo="body"></overa-dropdown>
  </div>-->
      </form>
      <!--<div class="campus" *ngIf="detail">
    <h5 class="div-button-collapse">
      {{'CAMPUS_MAINTENANCE.LIST.HEADER' | translate}}
      <button class="btn btn-link btn-collapse"
              type="button"
              data-toggle="collapse"
              data-target="#collapseCampus"
              aria-expanded="false"
              aria-controls="collapseCampus">
        <span class="p-button-icon pi pi-chevron-down"
              aria-hidden="true"></span>
        <span class="p-button-icon pi pi-chevron-up"
              aria-hidden="true"></span>
      </button>
    </h5>
    <div id="collapseCampus" class="collapse">
      <app-campus  [laboratoryId]="data.id"></app-campus>
    </div>
  </div>-->

      <div class="tabviewLabsContainer">
        <p-tabView class="tabviewLabs" *ngIf="detail">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>
                {{ "CAMPUS_MAINTENANCE.LIST.HEADER" | translate }}
              </span>
            </ng-template>
            <ng-container *ngIf="loadedForm">
              <app-laboratory-branch-list [laboratoryId]="data.id || ''"></app-laboratory-branch-list>
            </ng-container>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span>
                {{ "USERS_MAINTENANCE.HEADER_TABPANEL" | translate }}
              </span>
            </ng-template>
            <ng-container *ngIf="loadedForm">
              <app-users-list [laboratoryId]="data.id || ''"></app-users-list>
            </ng-container>
          </p-tabPanel>
        </p-tabView>
      </div>

      <div class="container-form-actions divContainerButtons" [align]="'center'">
        <button mat-raised-button (click)="delete()" *ngIf="detail && canDelete" class="cancel-button">
          {{ "ACTION.DELETE" | translate }}
        </button>
        <button mat-raised-button (click)="create()" *ngIf="!detail && enabled && canCreate" [disabled]="!form.valid">
          {{ "ACTION.CREATE" | translate }}
        </button>
        <button mat-raised-button (click)="update()" color="primary" *ngIf="detail && enabled" [disabled]="!form.valid">
          {{ "ACTION.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>