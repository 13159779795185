<ngx-spinner bdColor="rgba(42,55,84,0.4)" size="default" color="#e1e1e2" type="line-scale-pulse-out"
  [fullScreen]="false" name="spinnerLaboratoryBranchForm">
  <p class="loading-text">{{ "MESSAGES.LOADING" | translate }}</p>
</ngx-spinner>
<p-confirmDialog styleClass="containerConfirmDialog" acceptButtonStyleClass="styleButtonYesConfirmDialog"
  rejectButtonStyleClass="styleButtonNoConfirmDialog"></p-confirmDialog>
<div class="card">
  <div class="card-header card-header-warning card-header-text">
    <div class="card-text">
      <h4 class="card-title">
        <button mat-icon-button fxShow (click)="back()">
          <mat-icon aria-label="back">keyboard_backspace</mat-icon>
        </button>
        <span>{{
          selectedDescription
          ? ("CAMPUS_MAINTENANCE.FORM.HEADER"
          | translate : { value: selectedDescription })
          : ("CAMPUS_MAINTENANCE.FORM.HEADER_NEW" | translate)
          }}</span>
      </h4>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="canView && data && loadedForm">
      <form autocomplete="off" [formGroup]="form" *ngIf="loadedForm">
        <div class="row">
          <div class="col-6 input-form">
            <overa-input [controlName]="'name'" [title]="'COMMON_FORMS.NAME' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <div class="col-6 input-form">
            <overa-input [controlName]="'deliveryEmail'" [title]="'COMMON_FORMS.DELIVERY_EMAIL' | translate"
              [formGroup]="form"></overa-input>
          </div>
        </div>
        <div class="row">
          <!--<div class="input-form"><overa-input [controlName]="'description'" [title]="'COMMON_FORMS.DESCRIPTION' | translate" [formGroup]="form"></overa-input></div>-->
          <div class="col-6 input-form">
            <overa-input [controlName]="'contact'" [title]="'COMMON_FORMS.CONTACT_PERSON' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <div class="col-6 input-form">
            <overa-input [controlName]="'phoneNumber'" [title]="'COMMON_FORMS.PHONE' | translate"
              [formGroup]="form"></overa-input>
          </div>
        </div>
        <div class="row">
          <div class="col-4 input-form">
            <overa-input [controlName]="'street'" [title]="'COMMON_FORMS.STREET' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <div class="col-4 input-form">
            <overa-input [controlName]="'postalCode'" [title]="'COMMON_FORMS.POSTAL_CODE' | translate"
              [formGroup]="form"></overa-input>
          </div>
          <div class="col-4 input-form">
            <overa-input [controlName]="'country'" [title]="'COMMON_FORMS.COUNTRY' | translate"
              [formGroup]="form"></overa-input>
          </div>
        </div>
      </form>

      <div class="campus-test-types-list" *ngIf="detail">
        <!--<h6>{{ "ANALYSIS_TYPE_MAINTENANCE.LIST.HEADER" | translate }}</h6>-->
        <p-tabView class="tabviewLabs" *ngIf="detail">
          <!--<div class="test-type-element" *ngFor="let testType of data.analysisTypes">-->
          <p-tabPanel>
            <!--<div class="instructions mt-4">-->

            <ng-template pTemplate="header">
              <span>
                <span>{{
                  "ANALYSIS_TYPE_MAINTENANCE.LIST.HEADER" | translate
                  }}</span>

                <div class="mt-3 mb-3">
                  <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded addButtonRounded"
                    (click)="openFormAnalysisType(undefined)" [pTooltip]="'ACTION.ADD' | translate"
                    tooltipPosition="bottom" tooltipStyleClass="tooltipDiagnostica"></button>
                </div>
              </span>
            </ng-template>
            <ng-container>
              <div class="th-2-3-right">
                <overa-datatable #datatableTypes [columns]="columns" [items]="data.laboratoryBranchTestTypes || []">
                </overa-datatable>
              </div>
              <!--<div class="form-analysis-type">
                <form autocomplete="off">
                  <div class="row">
                    <div class="input-form col-6"><overa-input [value]="testType.name" [isDisabled]="true"  [title]="'COMMON_FORMS.NAME' | translate" ></overa-input></div>
                    <div class="input-form col-6"><overa-input [value]="testType.description"  [isDisabled]="true"   [title]="'COMMON_FORMS.DESCRIPTION' | translate" ></overa-input></div>
                  </div>

                </form>
              </div>
                <div class="instructions">

                  <table>
                    <thead>
                      <tr>
                        <th>{{'COMMON_FORMS.INSTRUCTIONS.LANGUAGE' | translate}}</th>
                        <th>{{'COMMON_FORMS.INSTRUCTIONS.HEADER' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let lang of testType.instructions">
                        <td>
                          {{lang.key}}

                        </td>
                        <td>
                          <overa-text-area [value]="lang.value"></overa-text-area>
                        </td>
                      </tr>
                    </tbody>
                  </table>

              </div>-->
            </ng-container>

            <!--</div>-->
          </p-tabPanel>

          <!--</div>-->
        </p-tabView>
      </div>
    </div>

    <div class="container-form-actions" [align]="'end'">
      <button mat-raised-button (click)="cancel()" *ngIf="enabled" class="cancel-button">
        {{ "ACTION.CANCEL" | translate }}
      </button>
      <button mat-raised-button (click)="create()" *ngIf="!detail && enabled" [disabled]="!form.valid">
        {{ "ACTION.CREATE" | translate }}
      </button>
      <button mat-raised-button (click)="update()" color="primary" *ngIf="detail && enabled" [disabled]="!form.valid">
        {{ "ACTION.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>