<div class="search-container" [ngClass]="styleClass">
  <i
    *ngIf="iconRightSide"
    [ngClass]="iconClass"
    (click)="onSearchTextClickChange()"
  ></i>
  <input
    [(ngModel)]="searchText"
    (input)="onSearchTextInputChange()"
    placeholder="{{ placeholder | translate }}"
    (keyup.enter)="onSearchTextKeyChange()"
  />
  <i
    *ngIf="!iconRightSide"
    [ngClass]="iconClass"
    (click)="onSearchTextClickChange()"
  ></i>
</div>
