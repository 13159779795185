import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "@overa/security";
import { Observable, of, ReplaySubject } from "rxjs";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
import { Sample } from "../models/sample.model";
import { SampleModified } from "../models/sample-modified.model";
import { CreateSample } from "../models/create-sample.model";
import { SampleCode } from "../models/sample-code.model";
import { UpdateExtraction } from "../models/update-extraction.model";
import { SampleShippingInfo } from "../models/sample-shipping-info.model";
import { DiagnosticaSampleWrapper } from "../models/grid-home/diagnostica-sample-wrapper.model";
import { DatarSampleWrapper } from "../models/grid-home/datar-sample-wrapper.model";
import { LaboratorySampleWrapper } from "../models/grid-home/laboratory-sample-wrapper.model";
import { ValidateSample } from "../models/validate-sample.model";
import { ShortSample } from "../models/short-sample.model";
import { SampleExists } from "../models/sample-exists.model";
import { SampleInvoice } from "../models/sample-invoice.model";
import { SamplePayment } from "../models/sample-payment.model";
import { SampleDelivery } from "../models/sample-delivery.model";
import { SampleReport } from "../models/sample-report.model";
import { identity } from "lodash";

@Injectable({ providedIn: "root" })
export class SampleService extends OveraBaseService<Sample> {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/sample";
  }

  constructor(http: HttpClient) {
    super(http);
  }

  createSample(sample: CreateSample) {
    const endpointUrl = `${this.baseUrl}/create`;
    return this.http.post<CreateSample>(endpointUrl, sample);
  }

  getFullSample(sampleId: string): Observable<SampleModified> {
    return this.http.get<SampleModified>(this.baseUrl + "/full/" + sampleId);
  }

  getByLaboratory(laboratoryId: string): Observable<Sample[]> {
    return this.http.get<Sample[]>(
      this.baseUrl + "/laboratory/" + laboratoryId
    );
  }
  getAvailable(laboratoryId: string): Observable<SampleCode> {
    return this.http.get<SampleCode>(
      this.baseUrl + "/available/" + laboratoryId
    );
  }
  updateExtraction(updateExtraction: UpdateExtraction): Observable<Sample> {
    return this.http.patch<Sample>(
      this.baseUrl + "/extraction",
      updateExtraction
    );
  }
  getShippingInfo(sampleId: string): Observable<SampleShippingInfo> {
    return this.http.get<SampleShippingInfo>(
      this.baseUrl + "/shippinginfo/" + sampleId
    );
  }

  getDiagnosticSampleDto(): Observable<DiagnosticaSampleWrapper> {
    return this.http.get<DiagnosticaSampleWrapper>(
      this.baseUrl + "/diagnostic/organized/"
    );
  }
  getDatarSamples(): Observable<DatarSampleWrapper> {
    return this.http.get<DatarSampleWrapper>(
      this.baseUrl + "/datar/organized/"
    );
  }
  getLaboratorySamples(
    laboratoryId: string
  ): Observable<LaboratorySampleWrapper> {
    return this.http.get<LaboratorySampleWrapper>(
      this.baseUrl + "/laboratory/organized/" + laboratoryId
    );
  }
  validateSample(validateSample: ValidateSample) {
    const endpointUrl = `${this.baseUrl}/validate`;
    return this.http.post<ValidateSample>(endpointUrl, validateSample);
  }
  getValidateInfo(code: string): Observable<ShortSample> {
    return this.http.get<ShortSample>(this.baseUrl + "/validate/info/" + code);
  }
  checkExistingSample(code: string): Observable<SampleExists> {
    return this.http.get<SampleExists>(this.baseUrl + "/exists/" + code);
  }
  getPendingSamplesByLaboratoryId(
    laboratoryId: string
  ): Observable<SampleInvoice[]> {
    return this.http.get<SampleInvoice[]>(
      this.baseUrl + "/pending/laboratory/" + laboratoryId
    );
  }
  getPendingSamplesByLaboratoryBranchId(
    laboratoryBranchId: string
  ): Observable<SampleInvoice[]> {
    return this.http.get<SampleInvoice[]>(
      this.baseUrl + "/pending/laboratorybranch/" + laboratoryBranchId
    );
  }
  getUnpaidSamplesByLaboratoryId(
    laboratoryId: string
  ): Observable<SamplePayment[]> {
    return this.http.get<SamplePayment[]>(
      this.baseUrl + "/unpaid/laboratory/" + laboratoryId
    );
  }
  getUnpaidSamplesByLaboratoryBranchId(
    laboratoryBranchId: string
  ): Observable<SamplePayment[]> {
    return this.http.get<SamplePayment[]>(
      this.baseUrl + "/unpaid/laboratorybranch/" + laboratoryBranchId
    );
  }
  getUndeliveredSamplesByLaboratoryId(
    laboratoryId: string
  ): Observable<SampleDelivery[]> {
    return this.http.get<SampleDelivery[]>(
      this.baseUrl + "/undelivered/laboratory/" + laboratoryId
    );
  }
  getUndeliveredSamplesByLaboratoryBranchId(
    laboratoryBranchId: string
  ): Observable<SampleDelivery[]> {
    return this.http.get<SampleDelivery[]>(
      this.baseUrl + "/undelivered/laboratorybranch/" + laboratoryBranchId
    );
  }
  getSamplesToReport(): Observable<SampleReport[]> {
    return this.http.get<SampleReport[]>(this.baseUrl + "/report");
  }
  archiveAsync(id: string) {
    console.log("archive");
    return this.http.patch<Sample>(`${this.baseUrl}/archive/${id}`, null);
  }
}
