import { Component, OnInit, OnDestroy } from '@angular/core';
import { Test } from '../../../models/test.model';
import { AppPermissions } from '../../../models/app-permission.model';
import { TestService } from 'src/app/services/test.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseListComponent, AccountService, SecurityStateManagementService, Hotkeys, ResponseNotificationService } from '@overa/security';

@Component({
  selector: 'app-test-list',
  templateUrl: './test-list.component.html',
  styleUrls: [
    './test-list.component.scss'
  ]
})
export class TestListComponent extends BaseListComponent<Test> implements OnInit, OnDestroy {

  static className = 'TestListComponent';

  constructor(
    public override accountService: AccountService,
    public override SSMService: SecurityStateManagementService,
    public override router: Router,
    public route: ActivatedRoute,
    public override hotkeys: Hotkeys,
    private testService: TestService,
    private RNService: ResponseNotificationService,
    private spinner: NgxSpinnerService,
  ) {
    super(accountService, SSMService, router, hotkeys);

    this.viewPermission = AppPermissions.PERMISSIONS_DATA.viewTests.value;
    this.createPermission = AppPermissions.PERMISSIONS_DATA.createTests.value;
    this.deletePermission = AppPermissions.PERMISSIONS_DATA.deleteTests.value;

    this.baseRoute = '/maintenances/test';

    this.onBuild = () => {
    };

    this.loadData = () => {
      this.spinner.show();
      this.testService.get().subscribe(items => {
        this.testService.notifyupdate(items);
        this.data = items;
      }, error => {
        this.RNService.showError(error);
      }, () => {
        this.spinner.hide();
      });
    };

    this.onInit = () => {
      this.loadData();

      this.testService.testObservable.subscribe(items => {
        this.data = items;
      });
    };

  }
}
