import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "@overa/security";
import { Observable, of, ReplaySubject } from "rxjs";
import { OveraBaseService } from "../shared/temporal-services/overa-base.service";
import { ShippingLabel } from "../models/shipping-label.model";
import { ShippingLabelStock } from "../models/shipping-label-stock.model";
import { CreateShippingLabel } from "../models/create-shipping-label.model";
import { UpdatedShippingLabelStorage } from "../models/updated-shipping-label-storage.model";

@Injectable({ providedIn: "root" })
export class ShippingLabelService extends OveraBaseService<ShippingLabel> {
  get baseUrl() {
    return Config.apiBaseUrl + "/api/shippingLabel";
  }

  constructor(http: HttpClient) {
    super(http);
  }

  getByLaboratoryId(laboratoryId: string): Observable<ShippingLabelStock[]> {
    return this.http.get<ShippingLabelStock[]>(
      this.baseUrl + "/getall/laboratory/" + laboratoryId
    );
  }
  getAll(): Observable<ShippingLabelStock[]> {
    return this.http.get<ShippingLabelStock[]>(this.baseUrl + "/getall");
  }
  getByLaboratoryBranchId(
    laboratoryBranchId: string
  ): Observable<ShippingLabelStock> {
    return this.http.get<ShippingLabelStock>(
      this.baseUrl + "/getall/branch/" + laboratoryBranchId
    );
  }
  getAvailable(laboratoryBranchId: string): Observable<ShippingLabel> {
    return this.http.get<ShippingLabel>(
      this.baseUrl + "/available/" + laboratoryBranchId
    );
  }
  createWithStorageId(createShippingLabel: CreateShippingLabel) {
    const endpointUrl = `${this.baseUrl}/create`;
    return this.http.post<ShippingLabel>(endpointUrl, createShippingLabel);
  }
  updateStorageId(updateShippingLabelStorage: UpdatedShippingLabelStorage) {
    const endpointUrl = `${this.baseUrl}/update`;
    return this.http.put<ShippingLabel>(
      endpointUrl,
      updateShippingLabelStorage
    );
  }
}
