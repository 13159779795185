import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "overa-inputnumber",
  templateUrl: "overa-inputnumber.component.html",
  styleUrls: ["overa-inputnumber.component.scss"],
})
export class OveraInputNumberComponent {
  @Input() title: string = "";
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() controlName: string | null = null;
  @Input() formGroup: FormGroup | undefined;
  @Input() validationMessage: string | undefined;

  @Input() decimalDigits: number = 0;

  @Input() showButtons: boolean = false;

  private _value: string = "";
  @Input()
  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.valueChange.emit(this._value);
  }

  @Output() valueChange = new EventEmitter<string>();
}
