import { Injectable } from "@angular/core";
import { LaboratoryBranchUserLaboratories } from "../models/laboratory-branch-user-laboratories.model";

@Injectable()
export class Globals {
  user: any;
  diagnosticaRoleName = "Diagnostica";
  labRoleName = "Laboratorio";
  datarRoleName = "Datar";
  gridTitle = "ANALYSIS.TITLE";
  userLaboratory?: UserLaboratory;
}

export class UserLaboratory {
  identityUserId?: string;
  laboratoryBranchUserLaboratories?: LaboratoryBranchUserLaboratories;
}
